import { ItemTypes } from "../../../../shareable/types";

export function getTypeName(type) {
  switch (type) {
    // MINISITE
    case ItemTypes.SLIDER:
      return "Slider";
    case ItemTypes.SLIDER_MULTIPLE:
      return "Slider multiple";
    case ItemTypes.SLIDER_WITH_RIGHT_IMAGES:
      return "Slider avec images";
    case ItemTypes.TAB_PRODUCT:
      return "Produits";
    case ItemTypes.IMAGE_ROW:
      return "Images Instagram";
    case ItemTypes.SECTION_TITLE:
      return "Section de titre";
    case ItemTypes.THREE_IMAGES_GRID:
      return "Grille d'images";
    case ItemTypes.THREE_IMAGES_ROW:
      return "Bandeau d'images";
    case ItemTypes.BLOC_TEXT:
      return "Bloc de texte";
    case ItemTypes.GIFT_FINDER:
      return "Gift finder";
    case ItemTypes.INFORMATION_BANNER:
      return "Bandeau d'information";
    case ItemTypes.LINK_BANNER:
      return "Bandeau lien";
    case ItemTypes.CATEGORY:
      return "Catégorie";
    case ItemTypes.TEXT_IMAGE:
      return "Texte image";
    case ItemTypes.SLIDER_PRODUCT:
      return "Slider produit";
    case ItemTypes.TEXT_VIDEO:
      return "Texte vidéo";
    // OFFER
    case ItemTypes.OFFER_FRONT_PAGE:
      return "Page de garde";
    case ItemTypes.OFFER_SECTION_TITLE:
      return "Section de titre";
    case ItemTypes.OFFER_ABOUT_SLIDE:
      return "À propos";
    case ItemTypes.OFFER_TRIPLE_BLOCK_LIST:
      return "Équipe";
    case ItemTypes.OFFER_TRIPLE_BLOCK:
      return "Division de 3 blocs";
    case ItemTypes.OFFER_TRIPLE_BLOCK_TEXT:
      return "Division de 3 textes";
    case ItemTypes.OFFER_TEXT_WITH_IMAGE:
      return "Référence client";
    case ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE:
      return "Texte et image";
    case ItemTypes.OFFER_DEVIS:
      return "Devis";
    // GAME
    case ItemTypes.PRE_HEADER_GAME:
      return "Pre-Header";
    case ItemTypes.HOME_GAME:
      return "Homepage";
    case ItemTypes.HOME_COMPLETE_FORM_GAME:
      return "Homepage avec formulaire";
    case ItemTypes.HOME_COMPLETE_FORM_GAME_TOULOUSE:
      return "Homepage avec formulaire pour Toulouse";
    case ItemTypes.EXPLANATIONS_GAME:
      return "Explications";
    case ItemTypes.EXPLANATIONS_ACV_GAME:
      return "Section d'explications ACV";
    case ItemTypes.EXPLANATIONS_BF_GAME:
      return "Section d'explications BF";
    case ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME:
      return "Section d'explications complète";
    case ItemTypes.BASIC_BLOC:
      return "Bloc de présentation";
    case ItemTypes.BASIC_BLOC2:
      return "Bloc de présentation 2";
    case ItemTypes.REDIRECT_ON_GAME:
      return "Bloc de Redirection";
    case ItemTypes.REDIRECT_ON_GAME2:
      return "Bloc de Redirection 2";
    case ItemTypes.REDIRECT_ON_GAME3:
      return "Bloc de Redirection 2";
    case ItemTypes.REDIRECT_ON_GAME4:
      return "Bloc de Redirection 3";
    case ItemTypes.BANNER_IMAGE:
      return "Bannière d'image";
    case ItemTypes.ALREADYPLAYED_GAME_SIMPLE:
      return "Bloc déjà joué simple";
    case ItemTypes.RECAP_WISHLIST:
      return "Récapitulatif de la Wishlist";
    // AMAZING GAME BLOC
    case ItemTypes.AMAZING_GAME:
      return "Bloc à compléter";
    case ItemTypes.TITLE_GAME:
      return "Titre";
    case ItemTypes.PARAGRAPH_GAME:
      return "Paragraphe";
    case ItemTypes.RULES_AMAZING_GAME:
      return "Règles";
    case ItemTypes.RULES_INLINE_FLEX_AMAZING_GAME:
      return "Règles en ligne";
    case ItemTypes.DOTATIONS_ONE_IMAGE:
      return "Image";
    case ItemTypes.SOCIAL_LIST:
      return "Socials";
    case ItemTypes.BUTTON_GAME:
      return "Boutton";
    case ItemTypes.GALLERY_GAME:
      return "Galerie";
    //MINI GAMES
    case ItemTypes.MINI_GAMES:
      return "Liste mini-jeux";
    case ItemTypes.GAME_RAIN:
      return "Pluie d'objets";
    case ItemTypes.GAME_CLICKER:
      return "Image à cliquer";
    case ItemTypes.GAME_QUIZ_PERSONALITY:
      return "Test de personnalité";
    case ItemTypes.GAME_CODE_FINDER:
      return "Code Finder";
    case ItemTypes.GAME_SCRATCH_IMAGE:
      return "Images à gratter";
    case ItemTypes.GAME_WISHLIST:
      return "Wishlist";
    case ItemTypes.GAME_PLACEHOLDER:
      return "Bloc de jeu";
    //CMS BLOC
    case ItemTypes.CMS_SLIDER:
      return "Slider Top";
    case ItemTypes.CMS_SLIDER_ABOUT:
      return "Slider à propos";
    case ItemTypes.CMS_LIST_CATEGORIES:
      return "Liste catégories";
    case ItemTypes.CMS_SLIDER_BY_TAB:
      return "Slider par catégorie";
    case ItemTypes.CMS_SLIDER_INSTAGRAM:
      return "Slider Instagram";
    case ItemTypes.CMS_SLIDER_BLOCS_TEXT_MEDIA:
      return "Slider texte & média";
    case ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA:
      return "Slider médias";
    case ItemTypes.CMS_SLIDER_MOMENTS:
      return "Slider moments";
    case ItemTypes.CMS_SLIDER_IMAGE:
      return "Slider images";
    case ItemTypes.CMS_GRID_IMAGE:
      return "Grille d'images";
    case ItemTypes.CMS_TEXT_MEDIA:
      return "Bloc de texte & média";
    case ItemTypes.CMS_MULTIPLE_TEXT:
      return "Bloc de texte multiple";
    case ItemTypes.CMS_BANNER_MEDIA:
      return "Bannière média";
    case ItemTypes.CMS_SECTION_DESCRIPTION_PAGE:
      return "Section description";
    case ItemTypes.CMS_SECTION_MANIFEST_BLOCK:
      return "Section du bloc manifeste";
    case ItemTypes.CMS_CATEGORIES_HOME:
      return "Catégories multiples";
    case ItemTypes.CMS_COLLECTIONS_HOME:
      return "Collections multiples";
    case ItemTypes.CMS_PRESENTATION_DUO:
      return "Présentation duo";
    case ItemTypes.CMS_SLIDER_PRODUCTS_HOME:
      return "Slider de produits";
    case ItemTypes.CMS_BLOC_STORELOCATOR_HOME:
      return "Bloc storelocator";
    case ItemTypes.CMS_ENTETE:
      return "Bloc entête";
    case ItemTypes.CMS_TOP_IMAGE_FULLSCREEN:
      return "Bloc top image";
    case ItemTypes.CMS_SLOGAN:
      return "Bloc slogan";
    case ItemTypes.CMS_TITLE_TEXT:
      return "Bloc titre et texte";
    case ItemTypes.CMS_SEPARATOR:
      return "Séparateur";
    case ItemTypes.CMS_CUSTOM:
      return "Bloc personnalisable";
    case ItemTypes.CMS_BLOC:
      return "Bloc";
    //FLIPBOOK BLOC
    case ItemTypes.HEADER_BOOK:
      return "Header Flipbook";
    case ItemTypes.HEADER_CART_BOOK:
      return "Header Cart Flipbook";
    case ItemTypes.FOOTER_BOOK:
      return "Footer Flipbook";
    case ItemTypes.TOP_HOMEPAGE_BOOK:
      return "Top Homepage Flipbook";
    case ItemTypes.POSTCARD_BOOK:
      return "PostCard Flipbook";
    case ItemTypes.RECAP_BLOCK_BOOK:
      return "Recap block Flipbook";
    case ItemTypes.RESULTS_BOOK:
      return "Results Flipbook";
    case ItemTypes.BANNER_LINK_BOOK:
      return "Banner link Flipbook";
    case ItemTypes.EMAIL_SUCCESS_BOOK:
      return "Email Success Flipbook";
    case ItemTypes.FLIPBOOK:
      return "Flipbook Bloc";
    case ItemTypes.FLIPBOOK_WITH_CART:
      return "Flipbook with cart";
    case ItemTypes.COPYRIGHT_BOOK:
      return "Copyright";
    case ItemTypes.HEADER_GAME:
      return "Header";
    case ItemTypes.FOOTER_GAME:
      return "Footer";
    // case ItemTypes.FLIPBOOK_WITH_WISHLIST: return "Flipbook wishlist";
    default:
      return "n/a";
  }
}
