import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function formBrand(type='add',handleMediaPicker) {      
    let steps = [
        {
            labelName: 'Information marque',
            isOptionnal: false,
            optionsInputs:[
                {
                    type: 'mediaPicker',
                    label: 'Image',
                    helper: {
                        text: 'Image de votre look',
                        link: false,
                    },
                    handleMediaPicker: handleMediaPicker,
                    required: false,
                    stateName:'brandImage',
                },
                {
                    type: 'text',
                    label: 'Nom de la marque',
                    helper: {
                        text: 'Indiquez le nom de la marque',
                        link: false,
                    },
                    required: true,
                    stateName: 'brandName',
                    disabled: false,
                },
                {                    
                    type: 'textareaSimple',
                    label: 'Description',
                    helper: {
                        text: '',
                        link: false,
                    },
                    required: false,
                    stateName: 'brandDesc',
                },
                {
                    type: 'text',
                    label: 'Url du site de la marque',
                    helper: {
                        text: 'Indiquez l\'URL du site de la marque',
                        link: false,
                    },
                    required: true,
                    stateName: 'brandLink',
                    disabled: false,
                },
                {                    
                    type: 'switch',
                    label: 'Actif',
                    helper: {
                        label: 'État',
                        text: 'Indiquez l\'état',
                        link: false,
                    },
                    required: true,
                    stateName:'isActive',
                    value: [
                        {
                            value: true,
                            label: 'Actif',
                        },
                        {
                            value: false,
                            label: 'Inactif',
                        },
                    ]
                },
            ]
        },
        {
            labelName: 'Gestion des écarts',
            isOptionnal: false,
            optionsInputs:[
                {
                    type: 'rows',
                    translated: false,
                    required: true,
                    stateName:'brandRows',
                    autoLabels :{
                        show:true,
                        textStart : "Site référent "
                    },
                    helper: {
                        text: 'Indiquez le nom de la marque',
                        link: false,
                    },                    
                    dictionary: false,
                    separator:true,
                    simpleField:true,
                    stateForCompare : 'oneBrand'
                    // dictionarySelect: currentTypeInput?.node.input === 'select' ? true : false,
                },
                {                    
                    titleSection: 'Réglage des alertes',
                    type: 'number',
                    label: 'Écart négatif',
                    helper: {
                        text: 'Mon produit est - cher',
                        link: false,
                    },                    
                    required: true,
                    stateName: 'brandEcartNegative',
                    doubleInput: true,                    
                    max:0,
                    suffix : "%"
                },
                {
                    type: 'number',
                    label: 'Écart positif',
                    helper: {
                        text: 'Mon produit est + cher',
                        link: false,
                    },                    
                    required: true,
                    stateName: 'brandEcartPositive',
                    disabled: false,
                    doubleInput: true,
                    min:0,
                    max:100,
                    suffix : "%"
                },
                {                    
                    type: 'switch',
                    label: 'Alerte activé',
                    helper: {
                        label: 'État',
                        text: 'Indiquez l\'état',
                        link: false,
                    },
                    required: true,
                    stateName:'isActiveAlert',
                    value: [
                        {
                            value: true,
                            label: 'Oui',
                        },
                        {
                            value: false,
                            label: 'Non',
                        },
                    ]
                },
            ]                
        },
        {
            labelName: 'Gestion du scrapping',
            isOptionnal: false,
            optionsInputs:[
                {
                    type: 'mapper',
                    translated: false,
                    required: true,
                    stateName: "mapOn",                    
                    helper: {
                        text: 'Indiquez le nom de la marque',
                        link: false,
                    },         
                    mapOn:"brandRows",           
                    dictionary: false,                    
                    mapperType : 'brand',
                    // dictionarySelect: currentTypeInput?.node.input === 'select' ? true : false,
                },
            ],
        },
    ]
    var obj = {
        titleForm: type === "add" ? 'Ajouter une marque' : 'Modifier une marque',
        subTitleForm: type === "add" ? 'Veuillez compléter les champs ci-dessous pour créer votre marque' : 'Veuillez modifier les champs ci-dessous pour éditer votre marque',
        // langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Action réussi.',
                subtitle: type === "add" ? 'La marque a été ajouté' : 'La marque a été modifié',
                textButton: 'Enregistrer'
            },
            children: steps
        }
    };

    return obj;
}