import React, { useEffect } from 'react';
import { Box, Grid, MenuItem, Select } from '@material-ui/core';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import '../../../navigation/DrawerLeft.scss';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import { listSettings, listMappers, perPageOptions } from './config/discounts/listDiscounts.config';
import Listing from '../../../layouts/Listing/Listing';
import * as moment from 'moment';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import { DELETE_ASSET_TOP_MESSAGE } from '../../../../queries/asset_top_message';
import discountsEdit from './config/discounts/discountsEdit.config';
import { eventService } from '../../../../js/services/event.service';
import { GET_ASSET_BY_ID_NAVIGATION } from '../../../../queries/assets';
import { saveElement, updateElement } from '../../../../js/utils/functions';
import DialogModal from '../../../ui/dialog/DialogModal';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import SearchBar from '../../../ui/search/SearchBar';
import Button from '../../../ui/button/Button';
import styled from 'styled-components';
import discountsCards from './config/discounts/discountsCards.config';
import { DELETE_ASSET_CART_RULE, DELETE_ASSET_CART_RULE_COUPON } from '../../../../queries/asset_cart_rule';
import { GET_PRODUCTS_BY_SKU } from '../../../../queries/products';
import request from '../../../../js/utils/fetch';
import OurTypography from '../../../ui/typography/Typography';
import Circle from 'react-color/lib/components/circle/Circle';
import DeleteDialogModal from '../../../ui/dialog/DeleteDialogModal';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import EmptyAsset from '../../../../assets/pictos/empty-picto/empty_assets.png';

const SelectCustom = styled(Select)`
    .MuiSelect-outlined{
        padding: 12px 10px;
    }
`

function EngineAssetsDetailsDiscounts(props) {
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [searchStatusLocal, setSearchStatusLocal] = React.useState("-1");
    const [searchNameLocal, setSearchNameLocal] = React.useState(null);
    const [searchStatus, setSearchStatus] = React.useState("-1");
    const [searchName, setSearchName] = React.useState(null);
    const [editType, setEditType] = React.useState(null);
    const [dataLayout, setDataLayout] = React.useState(null);
    const [typeDiscount, setTypeDiscount] = React.useState(null);
    const [typeOf, setTypeOf] = React.useState(null);
    const [openFormCards, setOpenFormCards] = React.useState(false);
    const [openForm, setOpenForm] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [reloadDiscounts, setReloadDiscounts] = React.useState(false);
    const [currentDiscount, setCurrentDiscount] = React.useState(null);
    const [alreadyHasCoupon, setAlreadyHasCoupon] = React.useState(null);
    const [listSkuNotFound, setListSkuNotFound] = React.useState([]);
    const [showListNotFound, setShowListNotFound] = React.useState(false);
    const [states, setStates] = React.useState({
        discountId: null,
        discountName: null,
        discountDesc: null,
        discountDest: 'all',
        discountNbUtils: {
            onlyOne: true,
            limitTotal: false,
        },
        discountNbTotal: null,
        discountStartAt: null,
        discountEndAt: null,
        discountHasEnd: {
            hasIt: false,
        },
        discountXYHasMin: {
            hasIt: false,
        },
        discountXYQty: null,
        discountXYOfferQty: null,
        discountXYPercent: null,
        discountFreeDelivery: {
            free: false,
        },
        discountMethod: 'none',
        discountMethodReducProducts: 'code',
        discountMethodReducValue: 'percent',
        discountApplicationReduc: 'in',
        discountReducValue: null,
        discountListProducts: [],
        discountTotalPrice: null,
        discountTotalQty: null,
        isActive: true
    });

    const filterDiscountsTypes = [
        { type: 'reduction_produit', libelle: 'Réduction produit', secondaryText: 'Travaillez vos réductions sur un ou plusieurs produits' },
        { type: 'reduction_panier', libelle: 'Réduction panier', secondaryText: 'Travaillez vos réductions sur le panier d’achat client' },
        { type: 'x_pour_le_prix_de_y', libelle: 'X pour le prix de Y', secondaryText: 'Travaillez vos offres promotionnelles' },
        // { type: 'frais_de_livraison', libelle: 'Frais de livraison', secondaryText: 'Optimisez vos frais de livraison' }
    ]

    const initFormCards = () => {
        setDataLayout(discountsCards)
        let newTypeOf = filterDiscountsTypes.map(discount => ({
            ...discount,
            typeGQL: 'flat',
            textButton: props.t("spread.active_assets.configure"),
            onClick: () => initForm(discount.type),
            isLocaleImage: true,
            secondaryStyle: false,
            hasNoImage: true
        }));
        setTypeOf(newTypeOf)
        setOpenFormCards(true)
    }
    const initForm = (type) => {
        setErrors({})
        setTypeDiscount(type)
        setEditType('add');

        let getState = { ...states };
        getState['discountId'] = null;
        getState['discountName'] = null;
        getState['discountDesc'] = null;
        getState['discountDest'] = 'all';
        getState['discountNbUtils'] = {
            onlyOne: true,
            limitTotal: false,
        };
        getState['discountNbTotal'] = null;
        getState['discountStartAt'] = null;
        getState['discountEndAt'] = null;
        getState['discountHasEnd'] = {
            hasIt: false,
        };
        getState['discountXYHasMin'] = {
            hasIt: false,
        };
        getState['discountXYQty'] = null;
        getState['discountXYOfferQty'] = null;
        getState['discountXYPercent'] = null;
        getState['discountFreeDelivery'] = {
            free: false,
        };
        getState['discountMethod'] = 'none';
        getState['discountMethodReducProducts'] = 'code';
        getState['discountMethodReducValue'] = 'percent';
        getState['discountApplicationReduc'] = 'in';
        getState['discountReducCode'] = null;
        getState['discountReducValue'] = null;
        getState['discountListProducts'] = [];
        getState['discountTotalPrice'] = null;
        getState['discountTotalQty'] = null;
        getState['isActive'] = true;

        setStates(getState);

        handleToggleDrawer('openForm');
    }

    useEffect(() => {
        checkRouting(props)
        setLoading(false)
        if (!JSON.parse(localStorage.getItem('ASSET_CURRENT'))) {
            initNavAsset()
        }
    }, []);

    const initNavAsset = () => {
        return new Promise((resolve, reject) => {
            props.client.query({
                query: GET_ASSET_BY_ID_NAVIGATION,
                variables: { id: `/api/assets/${props.match.params.id}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                window.setAssetNameMenu(result.data.asset)
                resolve()
            })
        })
    }

    const editHandler = (discount) => {
        setEditType('edit')
        setTypeDiscount(discount.formType)
        let getState = { ...states };

        getState['discountId'] = discount.id;
        getState['discountName'] = discount.name;
        getState['discountDesc'] = discount.description;
        getState['discountDest'] = discount.isOnlyForConnectedCustomers ? 'onlyClient' : 'all';
        getState['discountNbUtils'] = {
            onlyOne: discount.usePerCustomer ? true : false,
            limitTotal: discount.usePerCoupon ? true : false,
        };
        getState['discountNbTotal'] = discount.usePerCoupon;
        getState['discountStartAt'] = moment(discount.startedAt).format('YYYY-MM-DD');
        getState['discountEndAt'] = moment(discount.endedAt).format('YYYY-MM-DD');
        getState['discountHasEnd'] = {
            hasIt: discount.endedAt ? true : false,
        };
        getState['discountXYHasMin'] = {
            hasIt: discount.minCartAmount ? true : false,
        };
        getState['discountXYQty'] = discount.buyXGetYQty;
        getState['discountXYOfferQty'] = discount.buyXGetYOffer;
        getState['discountXYPercent'] = discount.discount;
        getState['discountFreeDelivery'] = {
            free: discount.hasFreeShipping,
        };
        getState['discountMethod'] = discount.minCartAmount ? 'totalPrice' : discount.minProductQty ? 'totalQty' : 'none';
        getState['discountTotalPrice'] = discount.minCartAmount;
        getState['discountTotalQty'] = discount.minProductQty;
        getState['discountMethodReducProducts'] = discount.hasCoupon ? 'code' : 'auto';
        getState['discountReducCode'] = discount.assetCartRuleCouponUnique?.coupon;
        getState['discountMethodReducValue'] = discount.discountType;
        getState['discountReducValue'] = discount.discount;
        getState['discountApplicationReduc'] = discount.productSelectionType;
        getState['discountListProducts'] = discount.products.edges.map(e => e.node.sku);
        getState['isActive'] = discount.isActive;

        setAlreadyHasCoupon(discount.assetCartRuleCouponUnique?.id)
        setStates(getState);
        handleToggleDrawer('openForm');
    }

    const getDiscountCallback = () => {
        setReloadDiscounts(false)
    }

    const resetErrors = (stateName, value) => {
        switch (stateName) {
            case 'discountMethodReducProducts':
                if (value !== 'code') {
                    handleFormError('discountReducCode', false)
                }
                break;
            case 'discountMethod':
                if (value === 'none') {
                    handleFormError('discountTotalPrice', false)
                    handleFormError('discountTotalQty', false)
                }
                break;
            case 'discountHasEnd':
                if (!value) {
                    handleFormError('discountEndAt', false)
                }
                break;
            case 'discountXYHasMin':
                if (value) {
                    handleFormError('discountListProducts', false)
                } else {
                    handleFormError('discountTotalPrice', false)
                }
                break;
            case 'discountNbUtils':
                if (!states[stateName].limitTotal) {
                    handleFormError('discountNbTotal', false)
                }
                break;
            case 'discountApplicationReduc':
                if (value === 'all') {
                    handleFormError('discountListProducts', false)
                }
                break;

            default:
                break;
        }
    }

    const stateCallback = (stateName, value, custom, translated, callback) => {
        let getState = { ...states };
        if (stateName === "discountNbUtils" || stateName === "discountHasEnd" || stateName === "discountFreeDelivery" || stateName === "discountXYHasMin") {
            getState[stateName][custom] = value.target.checked;
            resetErrors(stateName, value.target.checked)
        } else {
            getState[stateName] = value?.target?.value ?? value;
            resetErrors(stateName, value?.target?.value ?? value)
        }
        setStates(getState)
    };

    const handleButtonGroupChange = (stateName, value) => {
        let getState = { ...states };
        getState[stateName] = value;
        setStates(getState);
    };

    const handleFormError = (stateName, error) => {
        let getErrors = errors;
        getErrors[stateName] = error;
        setErrors(getErrors)
    };

    const toggleDialog = () => {
        setOpenDialog(!openDialog);
    };

    const hasErrors = () => {
        if (errors) {
            for (let error in errors) {
                if (errors[error])
                    return true;
            }
        }

        return false;
    };

    const handlerMutation = async () => {
        if (hasErrors()) {
            props.snack(ALERT_ERROR, props.t("spread.active_assets.toastCheckField"));
            return eventService.fire();
        }
        props.startLoading();
        let variables = null;
        let queryCoupon = null;
        let productListIds = []
        let productListSkuNotFound = []
        if (states.discountApplicationReduc !== 'all') {
            if (typeDiscount === "x_pour_le_prix_de_y") {
                if (!states.discountXYHasMin.hasIt) {
                    await request(`${process.env.REACT_APP_API}/json/product-ids`, 'post', states.discountListProducts).then((result) => {
                        for (let element of states.discountListProducts) {
                            if (result.find(e => e.sku === element)) {
                                productListIds.push(`/api/products/${result.find(e => e.sku === element).id}`)
                            } else {
                                productListSkuNotFound.push(element)
                            }
                            setListSkuNotFound(productListSkuNotFound)
                        }
                    })
                }
            } else {
                await request(`${process.env.REACT_APP_API}/json/product-ids`, 'post', states.discountListProducts).then((result) => {
                    for (let element of states.discountListProducts) {
                        if (result.find(e => e.sku === element)) {
                            productListIds.push(`/api/products/${result.find(e => e.sku === element).id}`)
                        } else {
                            productListSkuNotFound.push(element)
                        }
                        setListSkuNotFound(productListSkuNotFound)
                    }
                })
            }
        }
        if (editType === "add") {
            if (states.discountMethodReducProducts === "code") {
                try {
                    queryCoupon = await saveElement('assetCartRuleCoupon', { coupon: states.discountReducCode }, { enableLoad: false, setDates: false })
                } catch (e) {
                    if (e.find(error => error.debugMessage.includes('coupon'))) {
                        props.snack(ALERT_ERROR, 'Code déjà utilisé')
                        props.stopLoading();
                        return eventService.fire({ stateName: 'discountReducCode', errorMessage: 'Ce code est déjà utilisé et n\'est donc pas valide.' });
                    } else {
                        props.stopLoading();
                        props.snack(ALERT_ERROR, e[0].debugMessage)
                        return eventService.fire()
                    }
                }
            }

            variables = {
                asset: `/api/assets/${props.match.params.id}`,
                name: states.discountName,
                formType: typeDiscount,
                description: states.discountDesc,
                minProductQty: states.discountMethod === "totalQty" ? states.discountTotalQty : null,
                minCartAmount: states.discountMethod === "totalPrice" ? typeDiscount === "x_pour_le_prix_de_y" ? productListIds.length === 0 ? states.discountTotalPrice : null : states.discountTotalPrice : null,
                usePerCustomer: states.discountNbUtils.onlyOne ? 1 : 0,
                usePerCoupon: states.discountNbTotal,
                isOnlyForConnectedCustomers: states.discountDest === "onlyClient" ? true : false,
                hasFreeShipping: states.discountFreeDelivery.free,
                startedAt: states.discountStartAt,
                endedAt: states.discountHasEnd.hasIt ? states.discountEndAt : null,
                hasCoupon: states.discountMethodReducProducts === "code" ? true : false,
                assetCartRuleCouponUnique: states.discountMethodReducProducts === "code" ? queryCoupon.id : null,
                discountType: typeDiscount === "x_pour_le_prix_de_y" ? 'buy_x_get_y' : states.discountMethodReducValue,
                discount: typeDiscount === "x_pour_le_prix_de_y" ? states.discountXYPercent : states.discountReducValue,
                productSelectionType: typeDiscount === "reduction_panier" ? 'all' : states.discountApplicationReduc ? states.discountApplicationReduc : 'all',
                products: typeDiscount !== "reduction_panier" ? productListIds : [],
                isActive: states.isActive,
                buyXGetYQty: states.discountXYQty,
                buyXGetYOffer: states.discountXYOfferQty
            }
            try {
                let createElement = await saveElement('assetCartRule', variables, { enableLoad: false, setDates: false })
                if (createElement) {
                    props.snack(ALERT_SUCCESS, 'Règle de réduction ajoutée avec succès');
                    handleToggleDrawer('openForm', false);
                    setOpenFormCards(false);
                    setReloadDiscounts(true);
                    if (productListSkuNotFound.length > 0) {
                        setShowListNotFound(true)
                    }
                }
            } catch {
                props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création de la règle');
            }
        } else {
            if (states.discountMethodReducProducts === "code" && !alreadyHasCoupon) {
                queryCoupon = await saveElement('assetCartRuleCoupon', { coupon: states.discountReducCode }, { enableLoad: false, setDates: false })
            } else if (states.discountMethodReducProducts === "code" && alreadyHasCoupon) {
                queryCoupon = await updateElement(states, 'assetCartRuleCoupon', { id: alreadyHasCoupon, coupon: states.discountReducCode }, null, { enableLoad: false, setDates: false })
            } else {
                if (alreadyHasCoupon) {
                    await props.client.mutate({
                        mutation: DELETE_ASSET_CART_RULE_COUPON,
                        variables: { id: alreadyHasCoupon }
                    })
                }
            }

            variables = {
                id: states.discountId,
                name: states.discountName,
                formType: typeDiscount,
                description: states.discountDesc,
                minProductQty: states.discountMethod === "totalQty" ? states.discountTotalQty : null,
                minCartAmount: states.discountMethod === "totalPrice" ? typeDiscount === "x_pour_le_prix_de_y" ? productListIds.length === 0 ? states.discountTotalPrice : null : states.discountTotalPrice : null,
                usePerCustomer: states.discountNbUtils.onlyOne ? 1 : 0,
                usePerCoupon: states.discountNbTotal,
                isOnlyForConnectedCustomers: states.discountDest === "onlyClient" ? true : false,
                hasFreeShipping: states.discountFreeDelivery.free,
                startedAt: states.discountStartAt,
                endedAt: states.discountHasEnd.hasIt ? states.discountEndAt : null,
                hasCoupon: states.discountMethodReducProducts === "code" ? true : false,
                assetCartRuleCouponUnique: states.discountMethodReducProducts === "code" ? queryCoupon.id : null,
                discountType: typeDiscount === "x_pour_le_prix_de_y" ? 'buy_x_get_y' : states.discountMethodReducValue,
                discount: typeDiscount === "x_pour_le_prix_de_y" ? states.discountXYPercent : states.discountReducValue,
                productSelectionType: typeDiscount === "reduction_panier" ? 'all' : states.discountApplicationReduc ? states.discountApplicationReduc : 'all',
                products: typeDiscount !== "reduction_panier" ? states.discountXYHasMin.hasIt ? [] : productListIds : [],
                isActive: states.isActive,
                buyXGetYQty: states.discountXYQty,
                buyXGetYOffer: states.discountXYOfferQty
            }
            try {
                let updateElementResult = await updateElement(states, 'assetCartRule', variables, null, { enableLoad: false, setDates: false });

                if (updateElementResult) {
                    props.snack(ALERT_SUCCESS, 'Règle de réduction modifiée avec succès');
                    handleToggleDrawer('openForm', false);
                    setOpenFormCards(false);
                    setReloadDiscounts(true);
                    if (productListSkuNotFound.length > 0) {
                        setShowListNotFound(true)
                    }
                }
            } catch {
                props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la modification du message promotionnel');
            }
        }
        props.stopLoading();
    };

    const deleteMutation = async () => {
        props.startLoading();
        await props.client.mutate({
            mutation: DELETE_ASSET_CART_RULE,
            variables: { id: currentDiscount }
        }).then(result => {
            props.snack(ALERT_SUCCESS, 'Règle de réduction supprimée avec succès');
        }).catch(error => {
            props.snack(ALERT_ERROR, `Impossible de supprimer la règle de réduction`);
        });
        setReloadDiscounts(true);
        setOpenDialog(false)
        props.stopLoading();
    };

    const handleToggleDrawer = (stateDrawer, reset) => {
        setOpenForm(!openForm)
    };
    const filterTopMessages = () => {
        setSearchName(searchNameLocal)
        setSearchStatus(searchStatusLocal)
    };

    const handleClickMenu = (event, id) => {
        setCurrentDiscount(id)
    };

    return (
        <div>
            <TopPanel
                colorIcomoon={colors.blue.darker.hue300}
                title={props.t('spread.discounts.title')}
                subtitle={props.t('spread.discounts.subtitle')}
                handlerAdd={() => initFormCards()}
                textAdd={"+ Créer une réduction"}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                windowWidth={props.windowWidth}
                currentLang={currentLang}
                locales={props.locales}
                hasBorder={true}
                buttonAvailable={true}
            />
            <Grid container>
                <AccordionCustom defaultExpanded={true} expanded={false} title={"Filtres et Recherche"} custommargin={"16px 22px 22px"} style={{ borderRadius: 0 }}>
                    <Grid container spacing={2} alignItems={"flex-end"}>
                        <Grid item xs={8} md={(searchNameLocal || searchStatusLocal !== '-1') ? 7 : 8}>
                            {/* Search by name */}
                            <SearchBar noIcon
                                label={"Recherche par nom"}
                                variant="standard"
                                underlined={false}
                                style={{
                                    margin: '0',
                                    width: '100%',
                                    background: 'white',
                                }}
                                customstyle={{
                                    marginTop: '5px',
                                }}
                                onChange={(e) => setSearchNameLocal(e.target.value.trim())}
                                value={searchNameLocal}
                            />
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <label>Recherche par Statut</label>
                            <SelectCustom
                                style={{ marginTop: '5px' }}
                                labelId="simple-select-status-label"
                                id="simple-select-status-label"
                                value={searchStatusLocal}
                                fullWidth
                                variant="outlined"
                                onChange={(e) => setSearchStatusLocal(e.target.value)}
                            >
                                <MenuItem value={"-1"}>Tout</MenuItem>
                                <MenuItem value={"active"}>{props.t("spread_builder.asset_view.active")}</MenuItem>
                                <MenuItem value={"inactive"}>{props.t("spread_builder.asset_view.inactive")}</MenuItem>
                            </SelectCustom>
                            {/* Filter buttons */}
                        </Grid>
                        <Grid item xs={12} md={(searchNameLocal || searchStatusLocal !== '-1') ? 2 : 1}>
                            <Grid container justifyContent="flex-end" style={{ gap: 8 }}>
                                {
                                    searchNameLocal || searchStatusLocal !== '-1' ?
                                        <Button onClick={() => { setSearchName(null); setSearchNameLocal(null); setSearchStatusLocal('-1'); setSearchStatus('-1') }} color={colors.red.darker} bgcolor={colors.red.lighterv2} bgcolorhover={colors.red.lighterv2Hover} style={{ marginBottom: 0, borderRadius: 0, marginTop: 0, border: `1px solid ${colors.red.darker}` }}>Effacer</Button>
                                        : null
                                }
                                <Button onClick={() => {
                                    filterTopMessages()
                                }} style={{ marginBottom: 0, borderRadius: 0, marginTop: 0 }}>Filtrer</Button>
                            </Grid>
                        </Grid>

                    </Grid>

                </AccordionCustom>
                <Box mt={2} width="100%">
                    <AccordionCustom defaultExpanded={false} forcedExpanded={true} title={"Visualiser par:"} custommargin={"16px 22px 22px"} style={{ borderRadius: 0, minHeight: '315px' }}>
                        <Grid container>
                            {
                                !loading ? (
                                    <Listing
                                        label='réductions'
                                        settings={listSettings}
                                        cardProps={{
                                            openForm: openForm,
                                            currentLang: currentLang,
                                            handleClickMenu: handleClickMenu,
                                            textButton: 'Modifier',
                                            handlerButton: editHandler,
                                            textButtonDelete: 'Supprimer',
                                            handlerButtonDelete: toggleDialog,
                                            windowWidth: props.windowWidth,
                                        }}
                                        perPageOptions={perPageOptions}
                                        mappers={listMappers}
                                        currentLang={currentLang}
                                        identifier='assetCartRules'
                                        queryVariables={{
                                            asset: `/api/assets/${props.match.params.id}`,
                                            isActive: searchStatus !== "-1" ? searchStatus === "active" ? true : false : null,
                                            name: searchName
                                        }}
                                        propsToPass={props}
                                        viewsOptions={{
                                            current: 'card',
                                            settings: ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                                        }}
                                        reload={reloadDiscounts}
                                        listingCallback={getDiscountCallback}
                                        noResultComponent={() => <EmptyCard title={props.t("spread.active_assets.reductionNotConfigured")} subtitle={props.t("spread.active_assets.clickToAdd")} textButton={props.t("spread.active_assets.addReduction")} onClick={() => initFormCards()} picto={EmptyAsset} openForm={openFormCards} xsImg={openFormCards ? 4 : 2} />}
                                    />
                                ) : <PageLoader />
                            }
                        </Grid>
                    </AccordionCustom>
                </Box>
                {
                    dataLayout ?
                        (
                            <LayoutBuilder
                                isSublayout={false}
                                opened={openFormCards}
                                forClose={() => { setOpenFormCards(false) }}
                                dataLayout={dataLayout}
                                dataCard={typeOf}
                                allState={{ topPanel: { title: '+ Créer nouvelle une réduction', subtitle: 'Veuillez compléter les champs ci-dessous pour configurer votre asset' } }}
                            />
                        ) : null
                }
                <LayoutBuilder
                    isSublayout={true}
                    opened={openForm}
                    forClose={() => handleToggleDrawer('openForm', true)}
                    handlerSetup={() => { }}
                    dataLayout={editType === "add" ? discountsEdit('add', typeDiscount, states) : discountsEdit('edit', typeDiscount, states)}
                    allState={states}
                    stateCallback={stateCallback}
                    errorCallback={handleFormError}
                    checkError={() => { }}
                    // // handleButtonGroupChange={this.handleButtonGroupChange}
                    validateButton={true}
                    handlerMutation={handlerMutation}
                    handleButtonGroupChange={handleButtonGroupChange}
                />
                <DeleteDialogModal
                    open={openDialog}
                    title={'Êtes-vous sûr de vouloir supprimer cette réduction ?'}
                    primaryAction={() => deleteMutation()}
                    secondaryAction={toggleDialog}
                    windowWidth={props.windowWidth}
                >
                    <OurTypography text={'Si vous supprimez cette réduction celle-ci ne sera plus accessible. Si vous ne l\'utilisez plus mais que vous ne souhaitez pas la supprimer, annulez la suppression et déclarer ce message comme inactif.'} colortext={colors.grey.lighter.hue600} style={{ fontSize: '16px' }} />
                </DeleteDialogModal>
                {
                    showListNotFound && listSkuNotFound.length > 0 ?
                        <DialogModal
                            open={showListNotFound}
                            title={`Informations sur la règle ${editType === "add" ? 'créée' : 'modifiée'}`}
                            primaryAction={() => setShowListNotFound(false)}
                            primarycolor={colors.grey.regular}
                            primarybgcolor={colors.white}
                            primarybgcolorhover={colors.grey.lighter.hue900}
                            primaryborder={`1px solid ${colors.grey.regular}`}
                            primaryText="Fermer"
                        // primaryAction={() => deleteMutation()}
                        // primarybgcolor={colors.red.regular} primarybgcolorhover={colors.red.darker} primaryText="Supprimer"
                        >
                            <OurTypography variant="h4" style={{ textDecoration: 'underline' }}>Liste des sku non trouvés: </OurTypography>
                            <ul style={{ margin: 0, paddingLeft: 30, maxHeight: "54vh", overflow: 'auto' }}>
                                {listSkuNotFound.map((sku, index) => {
                                    return (
                                        <li style={{ color: colors.red.regular }}>{sku}</li>
                                    )
                                })}
                            </ul>
                        </DialogModal>
                        : null
                }

            </Grid>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(EngineAssetsDetailsDiscounts)))));