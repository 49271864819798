import { ItemTypes } from '../../../shareable';

export { default as SliderTop } from './_sliderTop.config';
export { default as SliderInstagram } from './_sliderInstagram.config';
export { default as SliderImage } from './_sliderImage.config';
export { default as SliderPresentationMedia } from './_sliderPresentationMedia.config';
export { default as SliderMoments } from './_sliderMoments.config';
export { default as GridImage } from './_gridImage.config';
export { default as SliderAbout } from './_sliderAbout.config';
export { default as CategoriesHome } from './_categoriesHome.config';
export { default as Bloc } from './_bloc.config';
export { default as CollectionsHome } from './_collectionsHome.config';
export { default as SliderProductsHome } from './_sliderProductsHome.config';
export { default as BlocHomeStorelocator } from './_blocHomeStorelocator.config';
export { default as Entete } from './_entete.config';
export { default as TopImageFullscreen } from './_topImageFullscreen.config';
export { default as Slogan } from './_slogan.config';
export { default as TitleText } from './_titleText.config';
export { default as Separator } from './_separator.config';
export { default as BlocTextMedia } from './_blocTextAndMedias.config';
export { default as BannerMedia } from './_bannerMedia.config';
export { default as ListCategories } from './_listCategories.config';
export { default as SliderByTab } from './_sliderByTab.config';
export { default as SectionDescriptionPage } from './_sectionDescriptionPage.config';
export { default as SectionManifestBlock } from './_sectionManifestBlock.config';
export { default as SliderBlocTextMedia } from './_sliderBlocTextAndMedias.config';
export { default as CustomComponent } from './_customComponent.config';
export { default as PresentationDuo } from './_presentationDuo.config';
export { default as BlocMultipleText } from './_blocMultipleText.config';

export const getScripts = (item) => {
    switch(item) {
        case ItemTypes.CMS_SLIDER:
            return `
                console.log('Swiper init');

                new Swiper('.swiper-container-top', {
                    direction: 'horizontal',
                    pagination: {
                        el: '.swiper-pagination-top',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-top',
                        prevEl: '.swiper-button-prev-top',
                    },
                    slidesPerView: 1,
                });
            `;
        case ItemTypes.CMS_SLIDER_PRODUCTS_HOME:
            return `
                console.log('Swiper init');

                new Swiper('.swiper-container-products', {
                    direction: 'horizontal',
                    scrollbar: {
                        el: '.swiper-scrollbar',
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-products',
                        prevEl: '.swiper-button-prev-products',
                    },
                    spaceBetween: 10,
                    breakpoints: {
                        300:{
                            slidesPerView: 2.2,
                        },
                        700:{
                            slidesPerView: 3.2,
                        },
                        1100:{
                            slidesPerView: 4.2,
                        },
                    },
                });
            `;
        case ItemTypes.CMS_SLIDER_ABOUT:
            return `
                console.log('Swiper init');

                new Swiper('.swiper-container-about', {
                    direction: 'horizontal',
                    navigation: {
                        nextEl: '.swiper-button-next-about',
                        prevEl: '.swiper-button-prev-about',
                    },
                    scrollbar: {
                        el: '.swiper-scrollbar-about',
                    },
                    spaceBetween: 16,
                    breakpoints: {
                        300:{
                            slidesPerView: 1.2,
                        },
                        700:{
                            slidesPerView: 2.1,
                        },
                        1100:{
                            slidesPerView: 3.1,
                        },
                    },
                });
            `;
        case ItemTypes.CMS_LIST_CATEGORIES:
            return `
                console.log('Swiper init');

                new Swiper('.swiper-container-listCategories', {
                    direction: 'horizontal',
                    navigation: {
                        nextEl: '.swiper-button-next-listCategories',
                        prevEl: '.swiper-button-prev-listCategories',
                    },
                    scrollbar: {
                        el: '.swiper-scrollbar-listCategories',
                    },
                    spaceBetween: 16,
                    breakpoints: {
                        300:{
                            slidesPerView: 1.2,
                        },
                        700:{
                            slidesPerView: 2.1,
                        },
                        1100:{
                            slidesPerView: 3.1,
                        },
                    },
                });
            `;
        case ItemTypes.CMS_SLIDER_INSTAGRAM:
            return `
                console.log('Swiper init');

                new Swiper('.swiper-container-instagram', {
                    direction: 'horizontal',
                    scrollbar: {
                        el: '.swiper-scrollbar-instagram',
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-instagram',
                        prevEl: '.swiper-button-prev-instagram',
                    },
                    spaceBetween: 10,
                    breakpoints: {
                        300:{
                            slidesPerView: 1,
                        },
                        700:{
                            slidesPerView: 2,
                        },
                        1100:{
                            slidesPerView: 3.2,
                        },
                    }
                });
            `;
        case ItemTypes.CMS_SLIDER_IMAGE:
            return `
                console.log('Swiper init');

                new Swiper('.swiper-container-image-carre', {
                    direction: 'horizontal',
                    scrollbar: {
                        el: '.swiper-scrollbar-image',
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-image',
                        prevEl: '.swiper-button-prev-image',
                    },
                    spaceBetween: 10,
                    breakpoints: {
                        300:{
                            slidesPerView: 1,
                        },
                        700:{
                            slidesPerView: 2,
                        },
                        1100:{
                            slidesPerView: 3.2,
                        },
                    }
                });
                
                new Swiper('.swiper-container-image-horizontal', {
                    direction: 'horizontal',
                    scrollbar: {
                        el: '.swiper-scrollbar-image',
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-image',
                        prevEl: '.swiper-button-prev-image',
                    },
                    spaceBetween: 10,
                    slidesPerView: 1.3,
                });           
                
                new Swiper('.swiper-container-image-fullscreen', {
                    direction: 'horizontal',
                    scrollbar: {
                        el: '.swiper-scrollbar-image',
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-image',
                        prevEl: '.swiper-button-prev-image',
                    },
                    spaceBetween: 10,
                    slidesPerView: 1,
                });
            `;
        case ItemTypes.CMS_SLIDER_BY_TAB:
            return `
                new Swiper('.swiper-container-sliderbytab', {
                    direction: 'horizontal',
                    navigation: {
                        nextEl: '.swiper-button-next-sliderbytab',
                        prevEl: '.swiper-button-prev-sliderbytab',
                    },
                    spaceBetween: 10,
                    slidesPerView: 1,
                });
            `;
        case ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA:
            return`
                new Swiper('.swiper-container-presentation', {
                    direction: 'horizontal',
                    scrollbar: {
                        el: '.swiper-scrollbar-presentation',
                        hide: seeLess,
                        draggable: true,
                    },
                    navigation: {
                        nextEl: ".swiper-button-next-presentation",
                        prevEl: ".swiper-button-prev-presentation",
                    },
                    slidesPerView: 1,
                });
            `;
        case ItemTypes.CMS_SLIDER_MOMENTS:
            return`
                new Swiper('.swiper-container-moments', {
                    direction: 'horizontal',
                    scrollbar: {
                        el: '.swiper-scrollbar-moments',
                        draggable: true,
                    },
                    navigation: {
                        nextEl: ".swiper-button-next-moments",
                        prevEl: ".swiper-button-prev-moments",
                    },
                    breakpoints: {
                        300:{
                            slidesPerView: 1.1,
                            spaceBetween: 8
                        },
                        500:{
                            slidesPerView: 1.2,
                            spaceBetween: 16,
                        }
                    }
                });
            `;
        case ItemTypes.CMS_COLLECTIONS_HOME:
            return`
                new Swiper('.swiper-container-collections', {
                    direction: 'horizontal',
                    scrollbar: {
                        el: '.swiper-scrollbar-collections',
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-collections',
                        prevEl: '.swiper-button-prev-collections',
                    },
                    spaceBetween: 10,
                    breakpoints: {
                        300:{
                            slidesPerView: 1.1,
                        },
                        800:{
                            slidesPerView: 2.2,
                        },
                        1200:{
                            slidesPerView: 3.2,
                        },
                    }
                });
            `;
        case ItemTypes.CMS_SLIDER_BLOCS_TEXT_MEDIA:
            return`
                new Swiper('.swiper-container-bloc-text-media', {
                    direction: 'horizontal',
                    scrollbar: {
                        el: '.swiper-scrollbar-image',
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-image',
                        prevEl: '.swiper-button-prev-image',
                    },
                    spaceBetween: 10,
                    slidesPerView: 1
                });
            `;
        default: return '';
    }
}
