import React, { useState, useEffect } from 'react';
import Swiper from 'swiper';
import Div from '../shared/div';
import Img from '../shared/img';
import styled from 'styled-components';

const DivContainerSliderByTab = styled(Div)`
    min-height: 500px;
    padding-top: 70px;
    padding-bottom: 90px;
    .logo{
        display: block;
        margin: auto;
        max-width: 90%;
        width: 400px;
    }
    .descriptionBlock{
        text-align: center;
        display: block;
        max-width: 1200px;
        margin: 70px auto;
        font-size: 2.375rem;
        line-height: 1.2;
        font-family: 'DinRegular', sans-serif;
        padding: 0 20px;
    }
    @media screen and (max-width: 1200px){
        padding-top: 50px;
        padding-bottom: 70px;
        .descriptionBlock{
            margin: 40px auto;
        }
    }
    @media screen and (max-width: 991px){
        padding-top: 40px;
        padding-bottom: 60px;
        .descriptionBlock{
            margin: 30px auto;
            font-size: 2rem;
        }
    }
    @media screen and (max-width: 700px){
        padding-top: 30px;
        padding-bottom: 50px;
        .descriptionBlock{
            margin: 25px auto 35px;
            font-size: 1.5rem;
        }
    }
    @media screen and (max-width: 500px){
        padding-top: 20px;
        padding-bottom: 40px;
        .descriptionBlock{
            margin: 16px auto 25px;
        }
    }
`;

const DivContainerTab = styled(Div)`
    width: 1200px;
    max-width: 90%;
    display: block;
    margin: auto;
`;

const DivContainerButton = styled(Div)`
    display: flex; 
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    button{
        font-size: 1.25rem;
        line-height: 1.2;
        padding: 1.25rem 5rem;
        border: 1px solid white;
        border-right: 0;
        font-family: 'DINBold', sans-serif;
        cursor: pointer;
        background-color: ${props => props.backgroundButtonTab || 'rgba(0, 14, 40, 1)'}; 
        color: ${props => props.colorButtonTab || '#fff'};
        &.buttonTabCategoriesActive{
            background-color: ${props => props.backgroundButtonTabActive || 'rgba(0, 14, 40, 1)'}; 
            color: ${props => props.colorButtonTabActive || '#fff'};
        }
        &:hover{
            opacity: 0.8;
        }
        &:last-child{
            border-right: 1px solid white;
        }
    }
`;

const DivContainerTabContent = styled(Div)`
    &.tabCategories{
        display: none;
        &.tabCategoriesActive{
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 4rem 0;
        }
    }
    .img_slider{
        max-width: 100%;
    }
    @media screen and (min-width: 1101px){
        &>*{
            &.swiper-container-sliderbytab{
                flex-basis: 60%;
                max-height: 400px;
            }
            &:last-child{
                flex-basis: 40%;
                padding: 0 1rem;
                font-family: 'Univers', sans-serif;
            }
            p{
                margin: 0;
            }
        }
    }
    @media screen and (max-width: 1100px){
        &.tabCategories.tabCategoriesActive{
            flex-direction: column;
            padding: 20px 0 40px;
            &>*{
                max-width: 700px;
                width: 90%;
                margin: auto;
                &:last-child{
                    flex-basis: 40%;
                    padding: 16px 0;
                    font-family: 'Univers', sans-serif; 
                }
                p{
                    margin: 0;
                }
            }
        }
    }
`;

const Link = styled.a`
    display: block;
    width: ${props => props.fullWidth ? '100%' : '300px'};
    margin: auto;
    padding: 1rem;
    color: ${props => props.color ? props.color : '#FFF'};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#000'};
    font-family: 'DINOffc-bold', sans-serif;
    text-align: center;
    text-decoration: none;
    transition: all ease 200ms;
    &:hover{
        color: ${props => props.backgroundColor ? props.backgroundColor : '#000'};
        background-color: ${props => props.color ? props.color : '#FFF'};
    }
    @media screen and (max-width: 1100px){
        max-width: 700px;
        width: 90%;
        margin: auto;
    }
`;

const SliderByTab = (props) => {
    const [hover, setHover] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-sliderbytab', {
            direction: 'horizontal',
            navigation: {
                nextEl: '.swiper-button-next-sliderbytab',
                prevEl: '.swiper-button-prev-sliderbytab',
            },
            spaceBetween: 10,
            slidesPerView: 1,
        });

        var tab_btn = document.querySelector("#tab-btn");
        if(tab_btn){
            tab_btn.addEventListener("click", function(e) {
                var btn = tab_btn.children;
                var page = document.querySelector("#tab-page").children;
                
                if(e.target.tagName === "BUTTON") {
                    for(var i=0, len=btn.length; i<len; i++) {
                        btn[i].index = i;
                        btn[i].classList.remove("buttonTabCategoriesActive");
                        page[i].classList.remove("tabCategoriesActive");
                    }
                    e.target.classList.add("buttonTabCategoriesActive");
                    page[e.target.index].classList.add("tabCategoriesActive");
                }
            }, false);
        }
    }, []);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-sliderbytab', {
            direction: 'horizontal',
            navigation: {
                nextEl: '.swiper-button-next-sliderbytab',
                prevEl: '.swiper-button-prev-sliderbytab',
            },
            spaceBetween: 10,
            slidesPerView: 1,
        });
    }, [props, selectedTab]);

    return (
        <div style={{ position: 'relative', background: data.inputs.backgroundColor.value }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <DivContainerSliderByTab>
                <Img
                    className="logo"
                    src={
                        typeof data.blocks.logo?.inputs.value.value === 'string' ? 
                            data.blocks.logo?.inputs.value.value 
                        : data.blocks.logo?.inputs.value.value.filePath ?
                            `${process.env.REACT_APP_MEDIAS}/${data.blocks.logo?.inputs.value.value.filePath}` 
                        : null
                    }
                    alt="Logo Bloc"
                />
                <Div className="descriptionBlock" style={{color: data.blocks.text?.inputs?.color.value}} dangerouslySetInnerHTML={{__html: data.blocks.text?.inputs?.value.value}} />
                <DivContainerTab>
                    <DivContainerButton 
                        id="tab-btn"
                        colorButtonTabActive={data.inputs.colorButtonTabActive.value} 
                        colorButtonTab={data.inputs.colorButtonTab.value} 
                        backgroundButtonTabActive={data.inputs.backgroundButtonTabActive.value} 
                        backgroundButtonTab={data.inputs.backgroundButtonTab.value}
                    >
                        {
                            data.blocks.categories.config.map((categorie, index) => (
                                <button
                                    className={index === 0 ? 'buttonTabCategoriesActive buttonTabCategories' : 'buttonTabCategories'}
                                    onClick={() => setSelectedTab(index)}
                                >
                                    {categorie.blocks.title.inputs.value.value}
                                </button>
                            ))
                        }
                    </DivContainerButton>
                    <div id="tab-page">
                        {
                            data.blocks.categories.config.map((categorie, index0) => (
                                <DivContainerTabContent className={index0 === 0 ? 'tabCategoriesActive tabCategories' : 'tabCategories'}>
                                    <Div className="swiper-container swiper-container-sliderbytab">
                                        <div className="swiper-wrapper">
                                            {
                                                categorie.blocks.subItem.config.map((item, index) => (
                                                    <Div className="swiper-slide w100">
                                                        <Img 
                                                            className="img_slider"
                                                            src={
                                                                typeof item.blocks.image?.inputs.value.value === 'string' ? 
                                                                    item.blocks.image?.inputs.value.value 
                                                                : item.blocks.image?.inputs.value.value.filePath ? 
                                                                    `${process.env.REACT_APP_MEDIAS}/${item.blocks.image?.inputs.value.value.filePath}` 
                                                                : null
                                                            }
                                                            alt={categorie.blocks.title.inputs.value.value}
                                                        />
                                                    </Div>
                                                ))
                                            }
                                        </div>
                                        <div className="swiper-button-prev swiper-button-prev-sliderbytab"></div>
                                        <div className="swiper-button-next swiper-button-next-sliderbytab"></div>
                                    </Div>
                                    <Div dangerouslySetInnerHTML={{__html: categorie.blocks.description?.inputs.value.value}} style={{color: categorie.blocks.description?.inputs.color.value}} />
                                </DivContainerTabContent>
                            ))
                        }
                    </div>
                    <Link 
                        href={data.blocks.link?.inputs.link.value} 
                        backgroundColor={data.blocks.link?.inputs.backgroundColor.value}
                        color={data.blocks.link?.inputs.color.value}
                        fullWidth={data.blocks.link?.inputs.fullWidth?.value}
                    >
                        {data.blocks.link?.inputs.value?.value ? data.blocks.link?.inputs.value.value : 'Découvrir'}
                    </Link>
                </DivContainerTab>
            </DivContainerSliderByTab>
        </div>
    )
};

export default SliderByTab;