import React from 'react';

import { Box, Grid } from '@material-ui/core';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components'
import iconSet from "../../../../assets/selection.json";
import IcomoonReact from "icomoon-react";
import { withTranslation } from 'react-i18next';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import Button from '../../../ui/button/Button';
import { ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH } from '../../../../js/constants/route-names';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';



const ImageContainer=styled(Box)`
    height: 417px;
    width: 100%;
`

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);


function CardMerchandisingMain(props){
    let getImageDefault = null
    let getImageCurrent = null
    let getTitleDefault = null
    let getTitleCurent = null
    if (props.data.categoryDatas.edges.length > 0){
        getImageDefault = props.data.categoryDatas.edges.filter(e => e.node?.attribute.identifier === "category_image")?.[0]?.node?.media?.filePath
        getImageCurrent = props.data.categoryDatas.edges.filter(e => e.node?.attribute.identifier === "category_image")?.find(e => e.node.locale.code === props.currentLang)?.node?.media?.filePath
        getTitleDefault = props.data.categoryDatas.edges.filter(e => e.node?.attribute.identifier === "category_name")?.[0]?.node?.value
        getTitleCurent = props.data.categoryDatas.edges.filter(e => e.node?.attribute.identifier === "category_name")?.find(e => e.node.locale.code === props.currentLang)?.node?.value
    }
    const goTo = (route) => {
        props.history.push({
            pathname : route,
        });
    };
    return(
        <Box width={'100%'} height={'100%'} display={"flex"} flexDirection={"column"} style={{cursor: 'pointer'}} onClick={() => {goTo(ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH.replace(':id', props.match.params.id).replace(':idCat', props.data.id.replace('/api/categories/', '')))}}>
            {/* <Grid container>
                <ImageContainer style={{height: props.isSub ? 160 : 320, backgroundImage: getImageCurrent ? `url(${process.env.REACT_APP_MEDIAS}/${getImageCurrent})` : getImageDefault ? `url(${process.env.REACT_APP_MEDIAS}/${getImageDefault})` : `url('/img/not-found.png')`,backgroundSize: "cover",backgroundPosition: "center",backgroundRepeat: "no-repeat"}}></ImageContainer>
            </Grid> */}
            <Box padding={'16px'} display={"flex"} flexDirection={"column"} flex={1}>
                <Box mb={3} padding={"0 5px"} display={"flex"} flexDirection={"column"} flex={1}>
                    <Box mt={1}>
                        <Typography variant={"h2"}>{getTitleCurent ? getTitleCurent : getTitleDefault ? getTitleDefault : props.data.libelle}</Typography>
                    </Box>
                    <Box mb={2} mt={0.5} flex={1}>
                        <ResponsiveEllipsis
                            unsafeHTML={`Tous les merchandisings créés pour la catégorie ${getTitleCurent ? getTitleCurent : getTitleDefault ? getTitleDefault : props.data.libelle}.`}
                            maxLine={2}
                            ellipsis='...'
                            basedOn='letters'
                        />
                    </Box>
                    <Typography variant={"body1"}>Nombre : <span style={{marginLeft: 72, fontWeight: "bold"}}>{props.data.merchandisings?.totalCount > 0 ? props.data.merchandisings?.totalCount : "N/A"} {props.data.merchandisings?.totalCount > 1 ? "versions" : "version"}</span></Typography>
                </Box>
                    <Button margin={"0px"} style={{width:"100%", paddingTop: 12, paddingBottom: 12,marginRight:12}} onClick={() => {goTo(ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH.replace(':id', props.match.params.id).replace(':idCat', props.data.id.replace('/api/categories/', '')))}}>Voir le détail</Button>
                {/* <Box display={"flex"}>
                    <Button 
                        icon={"edit"} 
                        text={"Modifier"} 
                        bgcolor={colors.blue.lighter.hue900} 
                        color={colors.blue.darker.hue300} 
                        bgcolorhover={colors.blue.lighter.hue600}  
                        colorhover={colors.blue.darker.hue300}  
                        border={`1px solid ${colors.blue.darker.hue300}`}
                        onClick={props.editForm}
                        stopPropagation={true}
                    />                                        
                </Box> */}
            </Box>            
        </Box>
    );
}

export default withTranslation()(CardMerchandisingMain);