import React from 'react';
import { ItemTypes } from '../../../../types';

import { 
    SliderTop,
    SliderInstagram,
    SliderImage,
    SliderPresentationMedia,
    SliderMoments,
    GridImage,
    BlocTextMedia,
    BannerMedia,
    SectionDescriptionPage,
    SectionManifestBlock,
    SliderAbout,
    CategoriesHome,
    Bloc,
    CollectionsHome,
    SliderProductsHome,
    BlocHomeStorelocator,
    Entete,
    TopImageFullscreen,
    Slogan,
    TitleText,
    SeparatorBlock,
    ListCategories,
    SliderByTab,
    SliderBlocTextMedia,
    CustomComponent,
    PresentationDuo,
    BlocMultipleText
} from ".";

export const getComponent = (element, index) => {
    switch(element.type) {
        case ItemTypes.CMS_SLIDER:
            return <SliderTop 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_SLIDER_ABOUT:
            return <SliderAbout 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_LIST_CATEGORIES:
            return <ListCategories 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_SLIDER_BY_TAB:
            return <SliderByTab 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_SLIDER_INSTAGRAM:
            return <SliderInstagram 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_SLIDER_BLOCS_TEXT_MEDIA:
            return <SliderBlocTextMedia 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA:
            return <SliderPresentationMedia 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_SLIDER_MOMENTS:
            return <SliderMoments 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_CATEGORIES_HOME:
            return <CategoriesHome 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_BLOC:
            return <Bloc 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_SLIDER_PRODUCTS_HOME:
            return <SliderProductsHome 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
                products={[]} // todo
            />;
        case ItemTypes.CMS_COLLECTIONS_HOME:
            return <CollectionsHome 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_PRESENTATION_DUO:
            return <PresentationDuo 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_BLOC_STORELOCATOR_HOME:
            return <BlocHomeStorelocator 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_SLIDER_IMAGE:
            return <SliderImage 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_GRID_IMAGE:
            return <GridImage 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_TEXT_MEDIA:
            return <BlocTextMedia 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_BANNER_MEDIA:
            return <BannerMedia 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_SECTION_DESCRIPTION_PAGE:
            return <SectionDescriptionPage 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_SECTION_MANIFEST_BLOCK:
            return <SectionManifestBlock 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_ENTETE:
            return <Entete 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_TOP_IMAGE_FULLSCREEN:
            return <TopImageFullscreen 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;    
        case ItemTypes.CMS_SLOGAN:
            return <Slogan 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_TITLE_TEXT:
            return <TitleText 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;    
        case ItemTypes.CMS_MULTIPLE_TEXT:
            return <BlocMultipleText 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;  
        case ItemTypes.CMS_SEPARATOR:
            return <SeparatorBlock 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        case ItemTypes.CMS_CUSTOM:
            return <CustomComponent 
                key={index}
                id={element.key}
                preview={true} 
                noToolbar={true} 
                data={element.data}
            />;
        default: return null;
    }
};

