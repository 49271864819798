import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { START_LOADING } from '../../../../js/constants/action-types';

import { Box, Grid, Tooltip } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import JohnDoe from '../../../../assets/images/john-doe.png';

function CardUser(props) {
    const user = props.userProperty.node;
    const listRoles = props.listRoles;
    const listGroups = props.listGroups;
    const index = props.index;
    
    return (
        <Grid key={index} container style={{height: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column"}}>
            <Grid container style={{paddingBottom: 16, position: 'relative'}}>
                <Grid item xs={3} style={{
                    width: 80,
                    maxWidth: 80,
                    marginRight: 20,
                    height: 80,
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: '50%'
                }}>
                    <img src={user?.image ? `${process.env.REACT_APP_API_ROOT}/${user.image}` : JohnDoe} style={{
                        height: 'auto',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                    }} />
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h4" colortext={colors.black.regular} style={{width: '85%'}} component="div">
                        <Box pb={1} fontWeight="bold" style={{width: '100%', display: 'block', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                            {user?.firstname} {user?.lastname ? user.lastname : user?.name ? user.name : null}
                        </Box>
                    </Typography>
                    <Typography variant="body1" component="div">
                        {user?.userGroup?.id ? 
                            (
                                <Box pb={1}>
                                    {listGroups?.map((group) => user.userGroup.id === group.node.id ? `Groupe: ${group.node.code}` : null)}
                                </Box>
                            ) : null
                        }
                        { user?.phone ? (
                            <Box pb={1}>
                                Téléphone : {user.phone} 
                            </Box>
                            ) : null
                        }
                    </Typography>
                </Grid>
                {
                    !props.noAction ? (
                        <Grid item xs={1} style={{position: 'absolute',right: '0'}}>
                            <Tooltip title="Voir l'utilisateur" arrow placement="top">
                                <Box>
                                    <Button 
                                        bgcolor="transparent" 
                                        bgcolorhover="transparent" 
                                        color={colors.grey.lighter.hue600} 
                                        colorhover={colors.grey.regular} 
                                        disableRipple={true} 
                                        style={{
                                            margin:0,
                                            padding: 0,
                                            maxWidth: 50, 
                                            minWidth: 10, 
                                            width: "100%"
                                        }} 
                                        onClick={() => props.editUser(user)}>
                                            <VisibilityIcon/>
                                        </Button>
                                </Box>
                            </Tooltip>
                        </Grid>
                    ) : null
                }
            </Grid>
            <Grid container style={{borderTop: `1px solid ${colors.grey.lighter.hue900}`}} justifyContent="center" align="center">
                <a href={`mailto: ${user?.email}`} style={{textDecoration: 'none'}}><Button bgcolor="transparent" bgcolorhover="transparent" style={{margin:5}} color={colors.blue.darker.hue300}><MailOutlineIcon style={{marginRight: 8}}/> Contact</Button></a>
            </Grid>
        </Grid>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        user: state.user,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardUser));