import React, { useEffect, Fragment } from "react";
import { 
    SliderTop,
    SliderAbout,
    SliderInstagram,
    SliderImage,
    SliderPresentationMedia,
    SliderMoments,
    GridImage,
    BlocTextMedia,
    BannerMedia,
    SectionDescriptionPage,
    SectionManifestBlock,
    CategoriesHome,
    CollectionsHome,
    Bloc,
    SliderProductsHome,
    BlocHomeStorelocator,
    Entete,
    TopImageFullscreen,
    Slogan,
    TitleText,
    SeparatorBlock,
    ListCategories,
    SliderByTab,
    CustomComponent,
    SliderBlocTextMedia,
    PresentationDuo,
    BlocMultipleText
} from "../../../../shareable/components/assets/cms/components";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Toolbar } from "../../../../shareable/tools";
import { ItemTypes, Pages } from "../../../../shareable/types";

export const getComponent = (element, index, preview, assetToken, Droppable, params, config, apiConfig, updateConfig, inputCallback, deleteCallback) => {
    switch (element.type) {
        case ItemTypes.CMS_SLIDER:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderTop
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderTop>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLIDER_ABOUT:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderAbout
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderAbout>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_LIST_CATEGORIES:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <ListCategories
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </ListCategories>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLIDER_BY_TAB:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderByTab
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderByTab>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLIDER_INSTAGRAM:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderInstagram
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderInstagram>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLIDER_BLOCS_TEXT_MEDIA:
                return(<Fragment key={element.key}>
                    <DndProvider backend={HTML5Backend}>
                        <SliderBlocTextMedia
                            id={element.key}
                            preview={preview}
                            data={element.data}
                            inputCallback={inputCallback}
                            spread={true}
                        >
                            <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                        </SliderBlocTextMedia>
                        <Droppable type={ItemTypes.CMS_SLIDER_BLOCS_TEXT_MEDIA} id={element.key} index={index} />
                    </DndProvider>
                </Fragment>);
        case ItemTypes.CMS_SLIDER_IMAGE:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderImage
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderImage>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderPresentationMedia
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderPresentationMedia>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLIDER_MOMENTS:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderMoments
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderMoments>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_GRID_IMAGE:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <GridImage
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </GridImage>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_TEXT_MEDIA:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <BlocTextMedia
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </BlocTextMedia>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_MULTIPLE_TEXT:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <BlocMultipleText
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </BlocMultipleText>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_BANNER_MEDIA:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <BannerMedia
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </BannerMedia>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SECTION_DESCRIPTION_PAGE:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SectionDescriptionPage
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SectionDescriptionPage>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SECTION_MANIFEST_BLOCK:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SectionManifestBlock
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SectionManifestBlock>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_CATEGORIES_HOME:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <CategoriesHome
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </CategoriesHome>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_BLOC:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <Bloc
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </Bloc>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLIDER_PRODUCTS_HOME:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SliderProductsHome
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                        products={apiConfig?.products}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SliderProductsHome>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_COLLECTIONS_HOME:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <CollectionsHome
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </CollectionsHome>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_PRESENTATION_DUO:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <PresentationDuo
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </PresentationDuo>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_BLOC_STORELOCATOR_HOME:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <BlocHomeStorelocator
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </BlocHomeStorelocator>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_ENTETE:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <Entete
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </Entete>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_TOP_IMAGE_FULLSCREEN:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <TopImageFullscreen
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </TopImageFullscreen>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SLOGAN:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <Slogan
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </Slogan>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_TITLE_TEXT:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <TitleText
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </TitleText>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_SEPARATOR:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <SeparatorBlock
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </SeparatorBlock>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);
        case ItemTypes.CMS_CUSTOM:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <CustomComponent
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} />
                    </CustomComponent>
                    <Droppable type={ItemTypes.SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>);

        default:
            return null;
    }
}