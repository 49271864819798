import React, { useEffect, useState, useRef } from "react";
import Div from '../shared/div';
import Img from '../shared/img';
import styled from 'styled-components';

const DivBannerMedia = styled(Div)`
    width: 100%;
    video{
        width: 100%;
    }
`;

const BannerMedia = (props) => {
    const [hover, setHover] = useState(false);
    const vidRef = useRef(null);
    
    let {
        preview = false, 
        data = null,
    } = props;
    
    const handlePlayVideo = () => {
        if(vidRef.current){
            vidRef.current.play();
        }
    }

    useEffect(() => {
        handlePlayVideo();
    }, []);
    useEffect(() => {
        handlePlayVideo();
    }, [props]);

    const VIDEOS = ["video/mpeg","video/webm","video/mp4"];

    let isVideo = VIDEOS.includes(data.blocks.media.inputs.value.value.type) ? true : false;

    return (
        <Div style={{position: 'relative'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <DivBannerMedia>
                <Div>
                    <Div>
                        {
                            isVideo ? (
                                <video autoPlay={true} muted={true} loop={true} ref={vidRef}>
                                    <source src={`${process.env.REACT_APP_MEDIAS}/${data.blocks.media.inputs.value.value.filePath}`} type="video/mp4"/>
                                </video>
                            ) : (
                                <Img 
                                    className="w100" 
                                    src={
                                        typeof data.blocks.media.inputs.value.value === 'string' ? 
                                            data.blocks.media.inputs.value.value 
                                        : data.blocks.media.inputs.value.value.filePath ? 
                                            `${process.env.REACT_APP_MEDIAS}/${data.blocks.media.inputs.value.value.filePath}` 
                                        : null
                                    }
                                    style={{display: 'block'}}
                                    alt="Bannière médias"
                                />
                            )
                        }
                    </Div>
                </Div>
            </DivBannerMedia>
        </Div>
    );
};

export default BannerMedia;