import React from "react";
import styled from 'styled-components';

const CustomImg = styled.img`
    &.w100 {
        width: 100%;
        min-height: 300px;
    }
    &.absolute {
        position: absolute;
    }
`;

export const PictureTag = ({ media, alt, classes, spread, lazy, isOptimised = true }) => {
    let jpg = media?.filePath
        ? `${process.env.REACT_APP_MEDIAS}/${media?.filePath}`
        : media ? media : null;

    let webp = media?.filePath
        ? media?.filePath.replace(/\.[^/.]+$/, "") + '.webp'
        : media ? media : null;

    if (spread || !isOptimised) {
        return (
            <CustomImg
                className={classes}
                src={jpg}
                alt={alt}
                loading={lazy ? 'lazy' : 'eager'}
            />
        )
    }

    return (
        <picture>
            {/* <source srcSet={`${process.env.REACT_APP_MEDIAS}/${webp}`} type="image/webp" /> */}
            <source srcSet={jpg} type="image/jpeg" />
            <CustomImg
                className={classes}
                src={jpg}
                alt={alt}
                loading={lazy ? 'lazy' : 'eager'}
            />
        </picture>
    );
};