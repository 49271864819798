import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import store from "./js/redux/store/index";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import * as moment from 'moment';
import 'moment-duration-format';
import patch from './patch';
import i18n from './i18n';
import './i18n';
import {
    QueryClient,
    QueryClientProvider
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { deleteLocalStorage } from './js/utils/deleteLocalStorage';

const queryClient = new QueryClient();

export const client = new ApolloClient({
    uri: process.env.REACT_APP_API_GQL,
    request: (operation) => {
        const token = localStorage.getItem('AUTH_TOKEN');

        operation.setContext({
            headers: { Authorization: token ? `Bearer ${token}` : '' }
        });
    },
    onError: (error) => {
        if (error.networkError && error.networkError.statusCode === 401) {
            deleteLocalStorage();

            
            window.location.href = `/login?returnTo=${window.location.href}`;
        }
    }
});

if(localStorage.getItem('DXP_LANG')){
    let lang = localStorage.getItem('DXP_LANG');
    i18n.changeLanguage(lang);
} else {
    localStorage.setItem('DXP_LANG','fr_FR');
    i18n.changeLanguage('fr_FR');
}
patch(client);

ReactDOM.render((
    <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
            <Provider store={store}>
                <Router>
                    <DndProvider backend={HTML5Backend}>
                        <Suspense fallback={null}>
                            <App />
                        </Suspense>
                    </DndProvider>
                </Router>
            </Provider>
            {/* <div id="tawkToScript"></div> */}
        </ApolloProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Auto redirect if token is expired

function logout() {
    deleteLocalStorage();

    window.location.href = '/login';

    // client.query({
    //     fetchPolicy: 'no-cache',
    //     query: gql`{ locales { totalCount } }`
    // });
};

setInterval(function() {
    let isLogin = window.location.href.indexOf('login') > -1 || window.location.href.indexOf('password') > -1;

    if (isLogin) return;

    let user    = localStorage.getItem('AUTH_USER');
    let expiry  = localStorage.getItem('AUTH_TOKEN_EXPIRY');

    if (!user || !expiry) {
        // in case another browser tab already cleared local storage
        window.location.href = '/login';
        return;
    }

    try {
        expiry = moment(expiry);

        let remaingSeconds = expiry.diff(moment(), 'seconds');

        console.log(`Remaining session time : ${remaingSeconds}s (${moment.duration(remaingSeconds, 'seconds').format('h[h]:mm[m]:ss[s]')})`);

        if (remaingSeconds < 0) logout();
    } catch(e) {
        console.log(e);
    }
}, 10000);

/* const drawersBackgrounds = document.querySelectorAll('.MuiBackdrop-root')
// Set the first drawer background to black 
drawersBackgrounds[0].style.backgroundColor = 'rgba(0,0,0,0.5)' */

// Tawk.to

// setTimeout(function() {
//     let currentUrl = window.location.href;
//     if(currentUrl.indexOf('builder-preview')<0 && currentUrl.indexOf('card/product')<0){

//         const tawkToScript = document.getElementById('tawkToScript');
//         // See https://www.tawk.to/knowledgebase/getting-started/adding-a-widget-to-your-website/ for widget creation
//         const s1 = document.createElement('script');
//         let tawkToId = '5ef200c64a7c6258179b2e3d';
//         s1.id = 'tawkToScript';
//         s1.async = true;
//         s1.src = 'https://embed.tawk.to/'+tawkToId+'/default';
//         s1.setAttribute('crossorigin', '*');
//         const s0 = document.getElementsByTagName('script')[0];
//         if (!s0 || !s0.parentNode) {
//             console.log('DOM is missing')
//         }
//         s0.parentNode.insertBefore(s1, s0);
    
//         document.body.appendChild(s0);
//         document.body.appendChild(s1);
    
//         var check = function(callback) {
            
//             if (window && window.Tawk_API && window.Tawk_API.getStatus() !== undefined) {
//                 callback(window.Tawk_API);
//                 return
//             }
//             setTimeout(function() {
//                 check(callback)
//             }, 0)
//         };

//     }

// }, 3000);