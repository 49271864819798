import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import FolderSharpIcon from '@material-ui/icons/FolderSharp';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AddCircleSharpIcon from '@material-ui/icons/AddCircleSharp';
import AddSharpIcon from '@material-ui/icons/AddSharp';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import Pagination from '@material-ui/lab/Pagination';
import styled from 'styled-components';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Query, withApollo } from 'react-apollo';
import { GET_MEDIA_CATEGORIES_COMPLETE } from '../../../../queries/mediaCategories';
import { GET_MEDIAS, GET_MEDIAS_IMAGES, GET_MEDIAS_CURSOR } from '../../../../queries/medias';
import PageLoader from "../../../../components/ui/loadings/page-loader/PageLoader";
import SearchBar from '../../../../components/ui/search/SearchBar';
import axios from '../../../../js/utils/axios';
import ItemCard from './ItemCard';
import ChipMedias from '../../../../components/screens/dashboard/medias/components/ChipMedias';
import { ALLOWED, DOCUMENTS, VIDEOS, IMAGES } from '../../../../js/constants/medias-types';
import { SNACK } from "../../../../js/constants/action-types";
import { ALERT_SUCCESS, ALERT_ERROR } from "../../../../js/constants/alert-types";
import { connect } from "react-redux";
import colors from "../../../../config/theme/colors";
import IcomoonReact from 'icomoon-react';
import CardCustom from '../../../../components/layouts/Card/CardCustom';
import './MediaSelector.scss'
import BreadcrumbCustom from '../../../../components/ui/breadcrumb/Breadcrumb';
import { Tooltip } from '@material-ui/core';
import OurTooltip from '../../../../components/ui/tooltip/Tooltip';
import SeeMore from '../../../../components/ui/see-more/SeeMore';
import clsx from 'clsx';
import formCategoriesEdit from '../../../../components/screens/dashboard/products/config/formCategoriesEdit.config';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 15
    },
    top: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `0 ${theme.spacing(1)}px`
    },
    title: {
        margin: theme.spacing(1)
    },
    margin: {
        margin: `4px ${theme.spacing(1)}px`,
        width: 'calc(100% - 16px)'
    },
    chip: {
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: 154,
        height: 154,
        margin: '0 auto',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    allMedias: {
        overflowY: 'auto',
        height: 'calc(192px * 1.75)'
    },
    none: {
        display: 'block'
    },
    category: {
        padding: '3px 4px',
        maxHeight: '25px',
        fontSize: '12px',
        fontWeight: 'bold',
        gap: '0px'
    },
    list: {
        marginTop: '9px',
        flexWrap: 'wrap',
        gap: '8px',
        flex: '1 1 150px'
    },
    subtitle: {
        marginTop: '17px',
        marginBottom: '17px',
        fontWeight: 'bold',
        fontSize: '12px'
    },
    item: {
        marginBottom: theme.spacing(1)
    },
    autocomplete: {
        width: '100%',
        display: 'flex',
        gap: '10px'
    },
    mediasTop: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    validateButton: {
        marginRight: theme.spacing(2)
    },
    labelFab: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    folder: {
        backgroundColor: colors.white,
        border: `0.5px solid ${colors.grey.lighter.hue700}`,
        padding: '10px 15px',
        color: colors.black.regular,
        fontWeight: 'bold',
        flexWrap: 'nowrap',
        width: 'calc((100%/4) - 6px)',
        height: '52px',
        gap: '10px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: colors.grey.lighter.hue900
        }
    },
}));
const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;
const CustomFab = withStyles((theme) => ({
    label: {
        height: '100%'
    }
}))(Fab);

const ColorButton = withStyles((theme) => ({
    root: {
        color: '#fff',
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700]
        }
    }
}))(Button);

const supportedTypes = IMAGES;
let timer = null;

function MediaSelector({ onSelect, client, xs, stateName, handleOpenMediaPicker, translated, isBuilder, allowedTypes, snack, windowWidth }) {
    const classes = useStyles();
    const allowedTypesInitial = allowedTypes;
    const [currentFolder, setCurrentFolder] = useState(null);
    const [previousFolders, setPreviousFolders] = useState([]);
    const [selected, setSelected] = useState(null);
    const [search, setSearch] = useState('');
    const [searchedItems, setSearchedItems] = useState(null);
    const [searching, setSearching] = useState(false);
    const [page, setPage] = useState(1);
    const [countPage, setCountPage] = useState(0);
    const [cursor, setCursor] = useState(null);
    const [cursorsList, setCursorsList] = useState([]);
    const [filterByType, setFilterByType] = useState(allowedTypesInitial?.length > 0 ? allowedTypesInitial : supportedTypes);
    const [totalElements, setTotalElements] = useState(0);
    const [isSeeMoreOpen, setIsSeeMoreOpen] = useState(false);
    const [height, setHeight] = useState(0)
    const ref = useRef(null)

    const nbperpage = 19;
    const filterTypes = allowedTypesInitial?.length > 0 ? allowedTypesInitial : supportedTypes;

    useEffect(() => {
        if (ref.current) {
            setHeight(ref.current.clientHeight + 10)
        }
    })

    useEffect(() => {
        client.query({
            query: GET_MEDIAS_CURSOR,
            variables: { types: filterByType },
            fetchPolicy: 'no-cache'
        }).then(result => {
            setCursorsList(result.data.mediaObjects.edges);
        })
    }, []);

    function getCursors(filter) {
        client.query({
            query: GET_MEDIAS_CURSOR,
            variables: { types: filter ? filter : filterByType },
            fetchPolicy: 'no-cache'
        }).then(result => {
            setCursorsList(result.data.mediaObjects.edges);
        })
    }
    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            height: undefined,
        });

        useEffect(() => {
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }

            // Add event listener
            window.addEventListener("resize", handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount
        return windowSize;
    }

    const sizeWindow = useWindowSize();

    const changePage = (page) => {
        let index = 0;
        if (page > 1) {
            index = (page * nbperpage) - nbperpage - 1
        }
        setPage(page);
        setCursor(cursorsList[index].cursor)
    }

    const mediaMap = (e, i) => {
        return <ItemCard
            key={i}
            item={e}
            selected={selected}
            setSelected={setSelected}
            xs={sizeWindow.width < 600 ? 6
                : sizeWindow.width < 1300 ? 4
                    : sizeWindow.width < 1500 ? 3
                        : xs ? xs
                            : 6}
            allowedTypes={filterTypes}
            style={{
                boxShadow: 'none'
            }}
        />;
    };
    const typeFilter =
        [
            {
                libelle: 'All',
                type: 'all',
            },
            filterTypes.some(array => IMAGES.indexOf(array) >= 0) ?
                {
                    libelle: 'Images',
                    type: IMAGES,
                } : null,
            filterTypes.some(array => VIDEOS.indexOf(array) >= 0) ?
                {
                    libelle: 'Vidéos',
                    type: VIDEOS,
                } : null,
            filterTypes.some(array => DOCUMENTS.indexOf(array) >= 0) ?
                {
                    libelle: 'Documents',
                    type: DOCUMENTS,
                } : null,
        ]

    /* TODO: Add types */
    const filterByTypeFunction = (cat) => {
        let filter;
        if (cat === 'all') {
            filter = filterTypes;
        }
        else {
            filter = cat;
        }
        setFilterByType(filter);
        getCursors(filter);
    }

    const getClickedCategory = (id) => {
        if (id) {
            const selectedFolderId = previousFolders.filter(folder => folder).findIndex(folder => folder.node.id === id)
            /* Change the current folder. Not counting the Root */
            setCurrentFolder(previousFolders[selectedFolderId + 1])
            /* Change the previous folders. Remove the clicked one and forward. Not counting the Root */
            previousFolders.splice(selectedFolderId + 1)
        } else {
            setCurrentFolder(null)
            setPreviousFolders([])
        }
    }

    const handleSeeMoreCategories = () => {
        setIsSeeMoreOpen(!isSeeMoreOpen)
    }

    useEffect(() => {
        console.log('isSeeMoreOpen', isSeeMoreOpen)
    }, [isSeeMoreOpen])

    return (
        <>
            <CardCustom style={{ background: '#FAFAFA' }}>
                {/* Search by name */}
                <Grid container>
                    <Grid container justifyContent='space-between'>
                        <Typography variant='body1' style={{
                            color: colors.black.regular,
                            fontSize: '12px',
                            marginBottom: '9px'
                        }}>Recherche par nom du média</Typography>
                    </Grid>
                    <SearchBar noIcon style={{
                        margin: '0 auto 8px auto',
                        width: '100%',
                        background: 'white'
                    }} onChange={(e) => {
                        let value = e.target.value.trim();

                        clearTimeout(timer);
                        setSearch(value);

                        timer = setTimeout(() => {
                            setSearching(true);

                            client.query({
                                query: GET_MEDIAS_IMAGES,
                                variables: { types: filterTypes, filePath: value },
                                fetchPolicy: 'no-cache'
                            }).then(result => {
                                if (value === '') {
                                    setSearchedItems(null);
                                } else {
                                    setSearchedItems(result.data.mediaObjects.edges);
                                }
                                setPage(1)
                                setCursor(null)
                                setSearching(false);
                                setCountPage(Math.ceil(result.data.mediaObjects.totalCount / nbperpage))
                            }).catch((e) => {
                                setSearching(false);
                            });
                        }, 250);
                    }} />
                </Grid>

                {/* Breadcrumb */}
                <BreadcrumbCustom
                    getClickedCategory={getClickedCategory}
                    previousFolders={previousFolders.map(folder => folder ? folder.node : folder)}
                    currentFolder={currentFolder ? currentFolder.node : currentFolder}
                    separator={<ArrowForwardIosSharpIcon fontSize='small' />}
                    nbPreviousItems={2}
                    style={{ margin: '20px 0px' }}
                    windowWidth={windowWidth}
                />

                <div className={classes.categories}>
                    <Query
                        query={GET_MEDIA_CATEGORIES_COMPLETE}
                        variables={{
                            types: filterByType
                        }}
                        fetchPolicy="no-cache"
                    >
                        {({ loading, error, data }) => {
                            if (error) return `Erreur ! ${error.message}`;

                            let categories = [];
                            let root = null;

                            if (!loading) {
                                root = data.mediaObjectCategories.edges.find(e => e.node.parent === null);

                                categories = data.mediaObjectCategories.edges.filter(e => {
                                    return currentFolder
                                        ? e.node.parent !== null && e.node.parent.id === currentFolder.node.id
                                        : e.node.parent?.id === root.node.id ?? false;
                                });
                            }

                            return (
                                <>
                                    {loading ? (
                                        <PageLoader />
                                    ) : (
                                        <>
                                            {/* Search by categories */}
                                            <Grid container>
                                                <Grid container justifyContent='space-between'>
                                                    <Typography variant="h4" style={{ margin: '0px', fontWeight: 'initial' }} className={classes.subtitle}>
                                                        Recherche par catégorie
                                                    </Typography>
                                                </Grid>
                                                <Grid container
                                                    className={'seeMoreWrapper'}
                                                    style={{ maxHeight: categories.length < 8 ? 'none' : isSeeMoreOpen ? height || '200px' : '125px' }}
                                                >
                                                    {
                                                        !loading && categories.length > 0 ? (
                                                            /* List of categories */
                                                            <Grid container className={classes.list} style={searchedItems ? { display: 'none' } : null} ref={ref}>
                                                                {
                                                                    categories.map((e, i) => {
                                                                        return (
                                                                            <OurTooltip title={e.node.libelle} arrow>
                                                                                <Grid
                                                                                    container
                                                                                    className={classes.folder}
                                                                                    key={`cat-${e.node.id}`}
                                                                                    alignItems={'center'}
                                                                                >
                                                                                    {/* Icon */}
                                                                                    <Grid item>
                                                                                        <FolderSharpIcon style={{ marginTop: '3px' }} />
                                                                                    </Grid>
                                                                                    <Grid item
                                                                                        style={{
                                                                                            overflow: 'hidden',
                                                                                            whiteSpace: 'nowrap',
                                                                                            textOverflow: 'ellipsis'
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            previousFolders.push(currentFolder);
                                                                                            setCurrentFolder(e);
                                                                                            setPreviousFolders(previousFolders);
                                                                                        }}>
                                                                                        {e.node.libelle}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </OurTooltip>
                                                                        );
                                                                    })
                                                                }
                                                            </Grid>
                                                        ) : (
                                                            /* Message when there are no categories */
                                                            <Typography variant='body1' color='primary' style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '10px' }}>Pas de catégories</Typography>
                                                        )
                                                    }
                                                </Grid>

                                                {/* Divider +/- categoriesd */}
                                                {!loading && categories.length > 8 && (
                                                    <SeeMore isOpen={isSeeMoreOpen} handleClick={handleSeeMoreCategories}></SeeMore>
                                                )}

                                            </Grid>

                                            {/* Medias info */}
                                            <div className={classes.mediasTop}>
                                                <Typography variant="h4" className={classes.subtitle} style={{ fontWeight: 'bold', marginTop: '12px' }}>
                                                    Médias +{totalElements}
                                                </Typography>

                                                {
                                                    selected && <ColorButton
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.validateButton}
                                                        startIcon={<CheckCircleIcon />}
                                                        onClick={
                                                            () => {
                                                                onSelect(selected, stateName, translated);
                                                                if (handleOpenMediaPicker) {
                                                                    handleOpenMediaPicker();
                                                                }
                                                            }}
                                                    >
                                                        Valider
                                                    </ColorButton>
                                                }
                                            </div>
                                            {/* Medias list */}
                                            {
                                                loading ? null : (
                                                    <>
                                                        <div className={classes.medias}>
                                                            <Query
                                                                query={GET_MEDIAS_IMAGES}
                                                                variables={{
                                                                    types: filterByType,
                                                                    categories: [currentFolder ? currentFolder.node.id : root.node.id],
                                                                    nbperpage: nbperpage, cursor: cursor ? cursor : null
                                                                }}
                                                                fetchPolicy="no-cache"
                                                                onCompleted={(data) => { setCountPage(Math.ceil(data.mediaObjects.totalCount / nbperpage)) }}
                                                            >
                                                                {({ loading, error, data, refetch }) => {
                                                                    if (error) return `Erreur ! ${error.message}`;

                                                                    let medias = [];
                                                                    let countPages = 0;

                                                                    if (!loading) {
                                                                        medias = data.mediaObjects.edges;
                                                                        setTotalElements(data.mediaObjects.totalCount)
                                                                    }

                                                                    return (
                                                                        <>
                                                                            {
                                                                                searchedItems ? null :
                                                                                    loading ? (
                                                                                        <PageLoader />
                                                                                    ) : (
                                                                                        <Grid
                                                                                            container
                                                                                            direction="row"
                                                                                            justifyContent="flex-start"
                                                                                            alignItems="center"
                                                                                            spacing={0}
                                                                                            className={classes.allMedias}
                                                                                            style={{ paddingBottom: isBuilder ? 40 : 0 }}
                                                                                        >
                                                                                            {/* Add media */}
                                                                                            <Grid
                                                                                                item
                                                                                                xs={sizeWindow.width < 600 ? 6
                                                                                                    : sizeWindow.width < 1300 ? 4
                                                                                                        : sizeWindow.width < 1500 ? 3
                                                                                                            : xs ? xs
                                                                                                                : 6}
                                                                                                className={classes.item}
                                                                                                key={`media-create`}
                                                                                            >
                                                                                                <Paper
                                                                                                    className={classes.paper}
                                                                                                    style={{
                                                                                                        cursor: 'default',
                                                                                                        border: `1px dashed ${colors.blue.darker.hue300}`,
                                                                                                        boxShadow: "none"
                                                                                                    }}
                                                                                                >
                                                                                                    <input
                                                                                                        id={'add-media'}
                                                                                                        type="file"
                                                                                                        variant="outlined"
                                                                                                        style={{ display: 'none' }}
                                                                                                        accept={filterTypes?.length > 0 ? filterTypes : "image/*"}
                                                                                                        hidden={true}
                                                                                                        onChange={event => {


                                                                                                            event.preventDefault();


                                                                                                            let file = event.target.files[0];
                                                                                                            let reader = new FileReader();
                                                                                                            let newName = file['name'].replaceAll(' ', '_');
                                                                                                            newName = newName.replaceAll("'", "");
                                                                                                            newName = newName.replaceAll('"', '');
                                                                                                            reader.readAsDataURL(file);

                                                                                                            event.target.value = null;

                                                                                                            reader.onloadend = async () => {
                                                                                                                let fileTypes = filterTypes;

                                                                                                                if (fileTypes.includes(file.type)) {
                                                                                                                    if (file.size < (process.env.REACT_APP_FILE_UPLOAD_MAX ? process.env.REACT_APP_FILE_UPLOAD_MAX : 30000000)) {
                                                                                                                        let formData = new FormData();

                                                                                                                        formData.append('file', file, newName);
                                                                                                                        formData.append('type', file.type);
                                                                                                                        formData.append('name', newName);
                                                                                                                        formData.append('alt', newName)

                                                                                                                        let id = currentFolder ? currentFolder.node.id : root.node.id;

                                                                                                                        formData.append('categories[]', id.replace('/api/media-object-categories/', ''));

                                                                                                                        await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                                                                                                                        refetch();
                                                                                                                    } else {
                                                                                                                        snack(ALERT_ERROR, 'Fichier trop volumineux (30 Mo max)')
                                                                                                                    }
                                                                                                                }
                                                                                                            };
                                                                                                        }}
                                                                                                    />

                                                                                                    <label htmlFor={'add-media'}>
                                                                                                        <AddCircleSharpIcon fontSize='large' style={{ transform: 'scale(1.2)', cursor: 'pointer', color: colors.blue.darker.hue300 }} />
                                                                                                    </label>
                                                                                                </Paper>
                                                                                                <div style={{ height: 30, margin: 'auto', marginTop: 8, width: 120, height: 30, overflow: 'hidden', textAlign: 'center' }}>

                                                                                                    <Typography variant="h5" component="div" style={{ color: colors.blue.darker.hue300 }}>
                                                                                                        Fichier de 30 Mo maximum
                                                                                                    </Typography>
                                                                                                </div>
                                                                                            </Grid>

                                                                                            {medias.map(mediaMap)}


                                                                                        </Grid>
                                                                                    )
                                                                            }
                                                                            {countPage > 1 ?
                                                                                <Grid item xs={12}>
                                                                                    <Grid container justifyContent="center">
                                                                                        <PaginationCustom count={countPage} page={page} onChange={(event, page) => { changePage(page) }} />
                                                                                    </Grid>
                                                                                </Grid>
                                                                                : null}

                                                                            {
                                                                                searchedItems === null ? null : searching ? (
                                                                                    <PageLoader />
                                                                                ) : (
                                                                                    <Grid
                                                                                        container
                                                                                        direction="row"
                                                                                        justifyContent="flex-start"
                                                                                        alignItems="center"
                                                                                        spacing={0}
                                                                                        className={classes.allMedias}
                                                                                    >
                                                                                        {searchedItems.map(mediaMap)}
                                                                                        {searchedItems.length > 0 ?
                                                                                            countPage > 1 ?
                                                                                                <Grid item xs={12}>
                                                                                                    <Grid container justifyContent="center">
                                                                                                        <PaginationCustom count={countPage} page={page} onChange={(event, page) => { changePage(page) }} />
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                : null
                                                                                            : 'Aucun résultat'}

                                                                                    </Grid>
                                                                                )
                                                                            }
                                                                        </>
                                                                    );
                                                                }}
                                                            </Query>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>
                                    )}
                                </>
                            );
                        }}
                    </Query>
                </div>
            </CardCustom>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withApollo(connect(null, mapDispatchToProps)(MediaSelector));