import {
    ROUTE_STATS_ORDERS,
    ROUTE_STATS_RETAILERS,
    ROUTE_STATS_ASSETS,
    ROUTE_STATS_CAMPAIGNS,
    ROUTE_STATS_GOOGLE_ANALYTICS,
    ROUTE_DIFFUSION,
    ROUTE_DIFFUSION_CALENDAR,
    ROUTE_DIFFUSION_PERMANENT,
    ROUTE_COMMUNITIES_PERMANENTS_ADD_POST,
    ROUTE_COMMUNITIES_PERMANENTS_POSTS,
    ROUTE_DIFFUSION_PERMANENTS_PAGE,
    ROUTE_DIFFUSION_PERMANENTS_HEADERS_ASSET,
    ROUTE_DIFFUSION_PERMANENTS_PAGES_CHILDS_ASSET,
    ROUTE_DIFFUSION_CAMPAIGNS,
    ROUTE_DIFFUSION_CAMPAIGNS_ADD,
    ROUTE_DIFFUSION_CAMPAIGNS_DETAIL,
    ROUTE_DIFFUSION_RETAILERS,
    ROUTE_ENGINE,
    ROUTE_ENGINE_ASSETS,
    ROUTE_ENGINE_ASSETS_DETAIL_STATS,
    ROUTE_ENGINE_ASSETS_DETAIL,
    ALL_ROUTE_CATALOGS,
    ROUTE_CATALOGS,
    ROUTE_CATALOGS_LIST,
    ROUTE_CATALOGS_DETAIL,
    ROUTE_MEDIAS,
    ROUTE_MEDIAS_CATEGORIES,
    ROUTE_MEDIAS_ATTRIBUTS,
    ROUTE_MEDIAS_LIST,
    ROUTE_CHANNELS_MEDIAS,
    ROUTE_MEDIAS_TAGS,
    ROUTE_CONTENU,
    ROUTE_CONTENU_CATEGORIES,
    ROUTE_CONTENU_LIST,
    ROUTE_CONTENU_LIST_DETAIL,
    ROUTE_CONTENU_ADD,
    ROUTE_CONTENU_ATTRIBUTES,
    ROUTE_PRODUCTS,
    ROUTE_PRODUCTS_CATEGORIES,
    ROUTE_PRODUCTS_ATTRIBUTS,
    ROUTE_PRODUCTS_LIST,
    ROUTE_PRODUCTS_ADD,
    ROUTE_PRODUCTS_LIST_DETAIL,
    ROUTE_PRODUCTS_STORELOCATOR,
    ROUTE_PRODUCTS_STORELOCATOR_DETAILS,
    ROUTE_PRODUCTS_COMPLETUDE,
    ROUTE_DIFFUSION_PERMANENTS_SHOP_THE_LOOK,
    ROUTE_ATTRIBUTS_ATTRIBUTS,
    ROUTE_ORDERS,
    ROUTE_ORDERS_LIST,
    ROUTE_ORDERS_LIST_DELAY,
    ROUTE_ORDERS_LIST_RETURN,
    ROUTE_ORDERS_LIST_PREORDER,
    ROUTE_ORDERS_DETAIL,
    ROUTE_CHANNELS,
    ROUTE_CHANNELS_RETAILERS,
    ROUTE_CHANNELS_BRANDS,
    ROUTE_CHANNELS_MARKETPLACES,
    ROUTE_SALES_MARKETPLACES,
    ROUTE_CHANNELS_SOCIALS,
    ROUTE_CHANNELS_CMS,
    ROUTE_CHANNELS_MAILER,
    ROUTE_CHANNELS_CONTENTS,
    ROUTE_CHANNELS_CONNECTORS,
    ROUTE_SETTINGS,
    ROUTE_SETTINGS_LANGUAGES,
    ROUTE_SETTINGS_CURRENCIES,
    ROUTE_SYSTEM_GROUPRATES,
    ROUTE_SETTINGS_TAXRATES,
    ROUTE_SETTINGS_USERS,
    ROUTE_SETTINGS_ACCESS,
    ROUTE_BRAND_GUIDELINE,
    ROUTE_BRAND_GUIDELINE_ADD,
    ROUTE_BRAND_GUIDELINE_UPDATE,
    ROUTE_BRAND_FLOW,
    ROUTE_BRAND_FLOW_DETAILS,
    ALL_ROUTE_STATS,
    ALL_ROUTE_ENGINE,
    ALL_ROUTE_MEDIAS,
    ALL_ROUTE_PRODUCTS,
    ALL_ROUTE_CHANNELS,
    ALL_ROUTE_BRAND,
    ALL_ROUTE_SETTINGS,
    ALL_ROUTE_DIFFUSION,
    ALL_ROUTE_CONTENU,
    ALL_ROUTE_ORDERS,
    ROUTE_SETTINGS_DETAIL_USER,
    ROUTE_ATTRIBUTS,
    ROUTE_ATTRIBUTS_CATEGORIES,
    ROUTE_ATTRIBUTS_POINT,
    ALL_ROUTE_ATTRIBUTS,
    ROUTE_ATTRIBUTS_ATTRIBUTES,
    ALL_ROUTE_SHELLS,
    ROUTE_SHELLS,
    ROUTE_SHELLS_LIST,
    ROUTE_SHELLS_CUSTOM,
    ROUTE_SHELLS_CUSTOM_ATTRIBUTES,
    ROUTE_SHELLS_CUSTOM_LIST,
    ROUTE_SHELLS_CUSTOM_DETAIL,
    ALL_ROUTE_SHELLS_CUSTOM,
    ROUTE_PRODUCTS_COLLECTIONS,
    ROUTE_HISTORY,
    ROUTE_HISTORY_DASHBOARD,
    ROUTE_HISTORY_IMPORT,
    ROUTE_HISTORY_IMPORT_PRODUCT,
    ROUTE_HISTORY_ACTIONS_LOGS,
    ALL_ROUTE_HISTORY,
    ROUTE_SALES,
    ROUTE_SALES_GLOBAL,
    ROUTE_SALES_ESHOP,
    ROUTE_SALES_RETAILERS,
    ROUTE_SALES_SHOPS,
    ROUTE_SALES_SOCIAL_COMMERCE,
    ALL_ROUTE_SALES,
    ROUTE_COMMUNITIES,
    ALL_ROUTE_COMMUNITIES,
    ROUTE_COMMUNITIES_GAMES,
    ROUTE_COMMUNITIES_MINISITES,
    ROUTE_COMMUNITIES_NEWSLETTERS,
    ROUTE_COMMUNITIES_SOCIALS,
    ROUTE_CATALOGS_SETTINGS,
    ROUTE_PRODUCTS_SETTINGS,
    ROUTE_MEDIAS_SETTINGS,
    ROUTE_CONTENU_SETTINGS,
    ROUTE_SYSTEM,
    ALL_ROUTE_SYSTEM,
    ROUTE_SYSTEM_CONNECTORS,
    ROUTE_SYSTEM_CURRENCIES,
    ROUTE_SYSTEM_LANGUAGES,
    ROUTE_SYSTEM_TAXRATES,
    ROUTE_SETTINGS_GUIDELINE,
    ROUTE_SETTINGS_GUIDELINE_ADD,
    ROUTE_SETTINGS_GUIDELINE_UPDATE,
    ROUTE_SALES_ESHOP_DETAIL,
    ROUTE_SALES_ESHOP_DETAIL_STATS,
    ROUTE_COMMUNITIES_GAMES_DETAIL,
    ROUTE_COMMUNITIES_GAMES_DETAIL_STATS,
    ROUTE_COMMUNITIES_MINISITES_DETAIL,
    ROUTE_COMMUNITIES_MINISITES_DETAIL_STATS,
    ROUTE_SALES_SOCIAL_COMMERCE_DETAILS,
    ROUTE_SALES_SHOPS_DETAILS,
    ROUTE_COMMUNITIES_GAMES_DIFFUSION,
    ROUTE_COMMUNITIES_GAMES_DIFFUSION_PAGE,
    ROUTE_SALES_ESHOP_DETAIL_HEADERS_ASSET,
    ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING,
    ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS,
    ROUTE_SALES_ESHOP_DETAIL_SHOP_THE_LOOK,
    ROUTE_SALES_ESHOP_DETAIL_INFOS_MANAGEMENTS,
    ROUTE_SALES_ESHOP_FOOTER,
    ROUTE_COMMUNITIES_MINISITES_DIFFUSION_PAGE,
    ROUTE_COMMUNITIES_MINISITES_DIFFUSION,
    ROUTE_SALES_MARKETPLACES_DETAILS,
    ROUTE_COMMUNITIES_SOCIALS_DETAIL,
    ROUTE_SALES_APPLICATIONS,
    ROUTE_SALES_APPLICATIONS_DETAILS,
    ROUTE_SALES_APPLICATIONS_NOTIFICATIONS,
    ROUTE_SETTINGS_USERS_GROUPS,
    ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING,
    ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH,
    ROUTE_SALES_ESHOP_DETAIL_CACHE_CATEGORIES,
    ROUTE_PRODUCTS_COVER_RATE,
    ROUTE_PRODUCTS_COVER_RATE_DETAIL,
    ROUTE_SALES_ESHOP_DETAIL_CROSSSELL,
    ROUTE_DIFFUSION_SOCIALS,
    ROUTE_STATS,
    ROUTE_SALES_ESHOP_DETAIL_MANAGE_CONTENTS,
    ROUTE_SALES_ESHOP_DETAIL_MANAGE_SELLS,
    ROUTE_SALES_ESHOP_DETAIL_MANAGE_SELLS_OFFERS,
    ROUTE_STATS_ORDERS_BY_SITE,
    ROUTE_STATS_ORDERS_BY_MARKETPLACES,
    ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME,
    ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME_CHILDS,
    ROUTE_COMMUNITIES_CMS,
    ROUTE_COMMUNITIES_CMS_DETAIL,
    ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING,
    ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING_CHILDS,
    ROUTE_COMMUNITIES_CMS_DETAIL_MANAGE_CONTENTS,
    ROUTE_PRODUCTS_PRICE_COMPARE,
    ROUTE_SALES_ESHOP_DETAIL_TOP_MESSAGES,
    ROUTE_PRODUCTS_PRICE_COMPARE_DASHBOARD,
    ROUTE_PRODUCTS_PRICE_COMPARE_LIST,
    ROUTE_PRODUCTS_PRICE_COMPARE_DETAIL,
    ROUTE_SALES_ESHOP_DETAIL_DISCOUNTS,
    ROUTE_PRODUCTS_BRANDS_LIST,
    ROUTE_PRODUCTS_BRANDS_DETAIL,
    ROUTE_PRODUCTS_WORKFLOW_DELTA,
    ROUTE_PRODUCTS_WORKFLOW_NEW,
    ROUTE_PRODUCTS_WORKFLOW_TO_COMPLETE,
    ROUTE_PRODUCTS_WORKFLOW_DELAYED,
    ROUTE_PRODUCTS_WORKFLOW_ERRORS,
    ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER,
    ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER_CHILDS
} from '../../js/constants/route-names';

import {
    ROUTE_CRM_STATS,
    ROUTE_CRM_STATS_CLIENTS,
    ROUTE_CRM_STATS_RESSOURCES,
    ROUTE_CRM_STATS_CAMPAIGNS,
    ROUTE_CRM_AGENCY,
    ROUTE_CRM_PRICES,
    ROUTE_CRM_TAXES,
    ROUTE_CRM_PAYMENT_TERMS,
    ROUTE_CRM_PAYMENT_DEADLINES,
    ROUTE_CRM_CGV,
    ROUTE_CRM_OFFERS,
    ROUTE_CRM_OFFERS_ADD,
    ROUTE_CRM_OFFERS_EDIT,
    ROUTE_CRM_OFFERS_LIST,
    ROUTE_CRM_OFFERS_PROJECTS,
    ROUTE_CRM_OFFERS_SETTINGS,
    ROUTE_CRM_OFFERS_MODELES,
    ROUTE_CRM_OFFERS_MODELES_ADD,
    ROUTE_CRM_OFFERS_MODELES_EDIT,
    ROUTE_CRM_OFFERS_MODELES_PRESENTATIONS,
    ROUTE_CRM_OFFERS_PRESENTATIONS,
    ROUTE_CRM_MEDIAS,
    ROUTE_CRM_MEDIAS_CATEGORIES,
    ROUTE_CRM_CONTACTS,
    ROUTE_CRM_CONTACTS_SOCIETIES,
    ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS,
    ROUTE_CRM_CONTACTS_CLIENTS,
    ROUTE_CRM_CONTACTS_LEADS,
    ROUTE_CRM_CONTACTS_LIST,
    ROUTE_CRM_SETTINGS,
    ROUTE_CRM_SETTINGS_LANGUAGES,
    ROUTE_CRM_SETTINGS_USERS,
    ROUTE_CRM_SETTINGS_DETAIL_USER,
    ALL_ROUTE_CRM_STATS,
    ALL_ROUTE_CRM_OFFERS,
    ALL_ROUTE_CRM_OFFERS_SETTINGS,
    ALL_ROUTE_CRM_MEDIAS,
    ALL_ROUTE_CRM_CONTACTS,
    ALL_ROUTE_CRM_SETTINGS,
    ALL_ROUTE_CRM_AGENCY
} from '../../js/constants/route-names';

import { ROUTE_RESTAURANTS, ROUTE_RESTAURANTS_DEMANDS, ALL_ROUTE_RESTAURANTS } from '../../js/constants/route-names';


import { hasRights } from '../../js/utils/rights';
import { menuEntry } from '../../js/utils/menuEntry';
import {
    VIEW,
    DASHBOARD,
    DASHBOARD_ASSETS,
    DASHBOARD_CAMPAIGN,
    DASHBOARD_RETAILERS,
    DASHBOARD_STATISTICS,
    DASHBOARD_GOOGLE_ANALYTICS,
    PRODUCTS,
    PRODUCTS_PRODUCTS,
    PRODUCTS_DASHBOARD,
    PRODUCTS_CATEGORIES,
    PRODUCTS_ATTRIBUTES,
    BROADCAST_CALENDAR,
    BROADCAST,
    BROADCAST_CAMPAIGN,
    BROADCAST_RETAILERS,
    BUILDER,
    BUILDER_DASHBOARD,
    BUILDER_ASSETS,
    CATALOGS,
    CATALOGS_DASHBOARD,
    CATALOGS_CATALOGS,
    MEDIAS,
    MEDIAS_DASHBOARD,
    MEDIAS_CATEGORIES,
    MEDIAS_MEDIAS,
    MEDIAS_TAGS,
    CONTENTS,
    CONTENTS_DASHBOARD,
    CONTENTS_CATEGORIES,
    CONTENTS_CONTENTS,
    CONTENTS_MODELS,
    CONNECTORS,
    CONNECTORS_DASHBOARD,
    CONNECTORS_RETAILERS,
    CONNECTORS_MARKETPLACES,
    CONNECTORS_SOCIAL_NETWORK,
    CONNECTORS_CMS,
    CONNECTORS_EMAILS,
    CONNECTORS_CONTENTS,
    CONNECTORS_MEDIAS,
    CONNECTORS_CONNECTORS,
    BRAND,
    BRAND_GUIDELINE,
    BRAND_FLOW,
    SETTINGS,
    SETTINGS_LANGUAGES,
    SETTINGS_CURRENCIES,
    SETTINGS_USERS,
    CRM_AGENCIES,
    CRM_AGENCIES_POLES,
    CRM_AGENCIES_RATES,
    CRM_AGENCIES_TAX_RATES,
    CRM_AGENCIES_PAYMENT_TERMS,
    CRM_AGENCIES_PAYMENT_DEADLINES,
    CRM_OFFERS,
    CRM_OFFERS_DASHBOARD,
    CRM_OFFERS_OFFERS,
    CRM_OFFERS_MODELES,
    CRM_OFFERS_PROJECTS,
    CRM_MEDIAS,
    CRM_MEDIAS_DASHBOARD,
    CRM_MEDIAS_DAM,
    CRM_CONTACT,
    CRM_CONTACT_DASHBOARD,
    CRM_CONTACT_SOCIETIES,
    CRM_CONTACT_CLIENTS,
    CRM_CONTACT_LEADS,
    CRM_CONTACT_CONTACTS,
    CRM_SETTINGS,
    CRM_SETTINGS_LANGUAGES,
    CRM_SETTINGS_USERS,
    CRM_STATISTICS,
    CRM_STATISTICS_MY_STATS,
    CRM_STATISTICS_BY_CUSTOMER,
    CRM_STATISTICS_BY_RESOURCE,
    CRM_STATISTICS_BY_CAMPAIGN,
} from '../../js/constants/constant-rights';
import store from '../../js/redux/store';
import { CancelPresentationOutlined } from '@material-ui/icons';

export default function setUpDrawer(isCRM) {
    let menuItems;
    let reduxStore = store.getState();
    let shellsNotSystem = reduxStore.attributes?.eavTypes.filter(e => e.node.isSystem === false) ?? [];
    if (isCRM) {
        menuItems = [
            {
                name: 'Statistiques',
                route: ROUTE_CRM_STATS,
                allRoutes: ALL_ROUTE_CRM_STATS,
                childrens: [
                    hasRights(CRM_STATISTICS, CRM_STATISTICS_MY_STATS, VIEW) ?
                        {
                            name: 'Mes statistiques',
                            route: ROUTE_CRM_STATS,
                            icone: 'icon-dashboard'
                        } : null,
                    hasRights(CRM_STATISTICS, CRM_STATISTICS_BY_CUSTOMER, VIEW) ?
                        {
                            name: 'Par clients',
                            route: ROUTE_CRM_STATS_CLIENTS,
                            icone: 'icon-clients'
                        } : null,
                    hasRights(CRM_STATISTICS, CRM_STATISTICS_BY_RESOURCE, VIEW) ?
                        {
                            name: 'Par ressources',
                            route: ROUTE_CRM_STATS_RESSOURCES,
                            icone: 'icon-ressources'
                        } : null,
                    hasRights(CRM_STATISTICS, CRM_STATISTICS_BY_CAMPAIGN, VIEW) ?
                        {
                            name: 'Par campagnes',
                            route: ROUTE_CRM_STATS_CAMPAIGNS,
                            icone: 'icon-campagne'
                        } : null,
                ]

            },
            // {
            //     name : 'Agence',
            //     route: ROUTE_CRM_AGENCY,
            //     allRoutes : ALL_ROUTE_CRM_AGENCY, 
            //     childrens:[
            //         hasRights(CRM_AGENCIES, CRM_AGENCIES_POLES, VIEW) ?
            //         {
            //             name : 'Gestion des pôles',
            //             route: ROUTE_CRM_AGENCY,
            //             icone:'ico_gestion-pole'   
            //         } : null,
            //         hasRights(CRM_AGENCIES, CRM_AGENCIES_RATES, VIEW) ?
            //         {
            //             name : 'Gestion des tarifs',
            //             route: ROUTE_CRM_PRICES,
            //             icone:'picto-prix'   
            //         } : null,
            //         hasRights(CRM_AGENCIES, CRM_AGENCIES_TAX_RATES, VIEW) ?
            //         {
            //             name : 'Gestion des taxes',
            //             route: ROUTE_CRM_TAXES,
            //             icone:'ico_gestion-taxe'   
            //         } : null,
            //         hasRights(CRM_AGENCIES, CRM_AGENCIES_PAYMENT_TERMS, VIEW) ?
            //         {
            //             name : 'Conditions de règlement',
            //             route: ROUTE_CRM_PAYMENT_TERMS,
            //             icone:'icon-costs'   
            //         } : null,
            //         hasRights(CRM_AGENCIES, CRM_AGENCIES_PAYMENT_DEADLINES, VIEW) ?
            //         {
            //             name : 'Délais de paiement',
            //             route: ROUTE_CRM_PAYMENT_DEADLINES,
            //             icone:'icon-costs'   
            //         } : null,
            //         // hasRights(CRM_AGENCIES, CRM_AGENCIES_PAYMENT_DEADLINES, VIEW) ?
            //         {
            //             name : 'Gestion des CGVs',
            //             route: ROUTE_CRM_CGV,
            //             icone:'picto-content'   
            //         } 
            //         // : null,
            //     ]
            // },
            {
                name: 'Offres commerciales',
                route: ROUTE_CRM_OFFERS,
                allRoutes: ALL_ROUTE_CRM_OFFERS,
                childrens: [
                    hasRights(CRM_OFFERS, CRM_OFFERS_DASHBOARD, VIEW) ?
                        {
                            name: 'Dashboard',
                            route: ROUTE_CRM_OFFERS,
                            icone: 'icon-dashboard',
                        } : null,
                    hasRights(CRM_OFFERS, CRM_OFFERS_OFFERS, VIEW) ?
                        {
                            name: 'Gestion des devis',
                            route: ROUTE_CRM_OFFERS_LIST,
                            icone: 'icon-catalogue',
                            childrens: [ROUTE_CRM_OFFERS_ADD, ROUTE_CRM_OFFERS_EDIT]
                        } : null,
                    // hasRights(CRM_OFFERS, CRM_OFFERS_MODELES, VIEW) ?
                    // {
                    //     name : 'Gérer les modèles de devis',
                    //     route:ROUTE_CRM_OFFERS_MODELES,
                    //     icone:'icon-costs',  
                    //     childrens:[ROUTE_CRM_OFFERS_MODELES_ADD, ROUTE_CRM_OFFERS_MODELES_EDIT]
                    // } : null,
                    {
                        name: 'Gérer les modèles d\'offres',
                        route: ROUTE_CRM_OFFERS_MODELES_PRESENTATIONS,
                        icone: 'ico_gestion-modeles',
                        // childrens:[ROUTE_CRM_OFFERS_MODELES_ADD, ROUTE_CRM_OFFERS_MODELES_EDIT]
                    },
                    {
                        name: 'Gestion des offres',
                        route: ROUTE_CRM_OFFERS_PRESENTATIONS,
                        icone: 'picto-content',
                        // childrens:[ROUTE_CRM_OFFERS_MODELES_ADD, ROUTE_CRM_OFFERS_MODELES_EDIT]
                    },
                    hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, VIEW) ?
                        {
                            name: 'Gestion des projets',
                            route: ROUTE_CRM_OFFERS_PROJECTS,
                            icone: 'icon-abonement'
                        } : null,
                    // {
                    //     name : 'Paramètres',
                    //     route:ROUTE_CRM_OFFERS_SETTINGS,
                    //     // icone:'picto-content',  
                    //     isAccordion: true,
                    //     allRoutes : ALL_ROUTE_CRM_OFFERS_SETTINGS, 
                    //     childrens: [
                    //         {
                    //             name : 'Gestion des offres',
                    //             route:ROUTE_CRM_OFFERS_PRESENTATIONS,
                    //             icone:'picto-content',  
                    //             // childrens:[ROUTE_CRM_OFFERS_MODELES_ADD, ROUTE_CRM_OFFERS_MODELES_EDIT]
                    //         },
                    //     ]
                    //     // childrens:[ROUTE_CRM_OFFERS_MODELES_ADD, ROUTE_CRM_OFFERS_MODELES_EDIT]
                    // },
                ]

            },
            {
                name: 'Médias',
                route: ROUTE_CRM_MEDIAS,
                allRoutes: ALL_ROUTE_CRM_MEDIAS,
                childrens: [
                    hasRights(CRM_MEDIAS, CRM_MEDIAS_DASHBOARD, VIEW) ?
                        {
                            name: 'Dashboard',
                            route: ROUTE_CRM_MEDIAS,
                            icone: 'icon-dashboard'
                        } : null,
                    hasRights(CRM_MEDIAS, CRM_MEDIAS_DAM, VIEW) ?
                        {
                            name: 'Digital Assets Manager',
                            route: ROUTE_CRM_MEDIAS_CATEGORIES,
                            icone: 'icon-medias'
                        } : null,

                ]
            },
            {
                name: 'Contacts',
                route: ROUTE_CRM_CONTACTS,
                allRoutes: ALL_ROUTE_CRM_CONTACTS,
                childrens: [
                    hasRights(CRM_CONTACT, CRM_CONTACT_DASHBOARD, VIEW) ?
                        {
                            name: 'Dashboard',
                            route: ROUTE_CRM_CONTACTS,
                            icone: 'icon-dashboard'
                        } : null,
                    hasRights(CRM_CONTACT, CRM_CONTACT_SOCIETIES, VIEW) ?
                        {
                            name: 'Sociétés',
                            route: ROUTE_CRM_CONTACTS_SOCIETIES,
                            icone: 'icon-business',
                            childrens: [ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS]
                        } : null,
                    hasRights(CRM_CONTACT, CRM_CONTACT_CLIENTS, VIEW) ?
                        {
                            name: 'Clients',
                            route: ROUTE_CRM_CONTACTS_CLIENTS,
                            icone: 'icon-clients'
                        } : null,
                    hasRights(CRM_CONTACT, CRM_CONTACT_LEADS, VIEW) ?
                        {
                            name: 'Leads',
                            route: ROUTE_CRM_CONTACTS_LEADS,
                            icone: 'icon-leads'
                        } : null,
                    hasRights(CRM_CONTACT, CRM_CONTACT_CONTACTS, VIEW) ?
                        {
                            name: 'Contacts',
                            route: ROUTE_CRM_CONTACTS_LIST,
                            icone: 'icon-contacts'
                        } : null,

                ]
            },
            {
                name: 'Paramètres',
                route: ROUTE_CRM_SETTINGS,
                allRoutes: ALL_ROUTE_CRM_SETTINGS,
                childrens: [
                    hasRights(CRM_SETTINGS, CRM_SETTINGS_LANGUAGES, VIEW) ?
                        {
                            name: 'Gestion des langues',
                            route: ROUTE_CRM_SETTINGS_LANGUAGES,
                            icone: 'picto-langues'
                        } : null,
                    hasRights(CRM_SETTINGS, CRM_SETTINGS_USERS, VIEW) ?
                        {
                            name: 'Gestion des utilisateurs',
                            route: ROUTE_CRM_SETTINGS_USERS,
                            icone: 'picto-profil',
                            childrens: [ROUTE_CRM_SETTINGS_DETAIL_USER]
                        } : null,
                    hasRights(CRM_AGENCIES, CRM_AGENCIES_POLES, VIEW) ?
                        {
                            name: 'Gestion des pôles',
                            route: ROUTE_CRM_AGENCY,
                            icone: 'ico_gestion-pole'
                        } : null,
                    hasRights(CRM_AGENCIES, CRM_AGENCIES_RATES, VIEW) ?
                        {
                            name: 'Gestion des tarifs',
                            route: ROUTE_CRM_PRICES,
                            icone: 'picto-prix'
                        } : null,
                    hasRights(CRM_AGENCIES, CRM_AGENCIES_TAX_RATES, VIEW) ?
                        {
                            name: 'Gestion des taxes',
                            route: ROUTE_CRM_TAXES,
                            icone: 'ico_gestion-taxe'
                        } : null,
                    hasRights(CRM_AGENCIES, CRM_AGENCIES_PAYMENT_TERMS, VIEW) ?
                        {
                            name: 'Conditions de règlement',
                            route: ROUTE_CRM_PAYMENT_TERMS,
                            icone: 'icon-costs'
                        } : null,
                    hasRights(CRM_AGENCIES, CRM_AGENCIES_PAYMENT_DEADLINES, VIEW) ?
                        {
                            name: 'Délais de paiement',
                            route: ROUTE_CRM_PAYMENT_DEADLINES,
                            icone: 'icon-costs'
                        } : null,
                    // hasRights(CRM_AGENCIES, CRM_AGENCIES_PAYMENT_DEADLINES, VIEW) ?
                    {
                        name: 'Gestion des CGVs',
                        route: ROUTE_CRM_CGV,
                        icone: 'picto-content'
                    }
                    // : null,
                ]
            },
        ]
    }

    else {
        menuItems = [
            {
                name: 'drawer.dashboard',
                route: ROUTE_STATS,
                allRoutes: ALL_ROUTE_STATS,
                childrens: [
                    menuEntry("dashboard_statisticsOrders") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.dashboard_statisticsOrders',
                            route: ROUTE_STATS_ORDERS,
                            icone: 'ico_stats',
                            childrens: [ROUTE_STATS_ORDERS, ROUTE_STATS_ORDERS_BY_SITE, ROUTE_STATS_ORDERS_BY_MARKETPLACES],
                            hasSubmenu: true,
                            staticItem: {
                                title: 'Les statistiques'
                            },
                            childrensStructure: [
                                process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                    {
                                        name: "drawer.dashboard_statisticsOrdersView",
                                        route: ROUTE_STATS_ORDERS,
                                        parent: ROUTE_STATS_ORDERS
                                    } : null,
                                process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                    {
                                        name: 'drawer.dashboard_statisticsOrdersSiteClient',
                                        route: ROUTE_STATS_ORDERS_BY_SITE,
                                        parent: ROUTE_STATS_ORDERS
                                    } : null,
                                process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                    {
                                        name: 'drawer.dashboard_statisticsOrdersMarketplaces',
                                        route: ROUTE_STATS_ORDERS_BY_MARKETPLACES,
                                        parent: ROUTE_STATS_ORDERS
                                    } : null,
                            ]
                        } : null,
                    // menuEntry("dashboard_statisticsGames") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    // {
                    //     name : 'drawer.dashboard_statisticsGames',
                    //     route:  ROUTE_STATS_RETAILERS,
                    //     icone:'ico_star'   
                    // } : null,
                    // menuEntry("dashboard_analytics") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    // {
                    //     name : 'drawer.dashboard_analytics',
                    //     route:  ROUTE_STATS_ASSETS,
                    //     icone:'ico_analytics'   
                    // } : null,
                    // {
                    //     name : 'Paramètres',
                    //     route:ROUTE_CRM_OFFERS_SETTINGS,
                    //     icone:'picto-content',  
                    //     isAccordion: true,
                    //     allRoutes : ALL_ROUTE_CRM_OFFERS_SETTINGS, 
                    //     // childrens:[ROUTE_CRM_OFFERS_MODELES_ADD, ROUTE_CRM_OFFERS_MODELES_EDIT]
                    // },
                ]
            },
            {
                name: 'drawer.history',
                route: ROUTE_HISTORY,
                allRoutes: ALL_ROUTE_HISTORY,
                childrens: [
                    menuEntry("history") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.dashboard',
                            route: ROUTE_HISTORY_DASHBOARD,
                            icone: 'ico_dash-1'
                        } : null,
                    menuEntry("history_imports") ?
                        {
                            name: 'drawer.history_imports_product',
                            route: ROUTE_HISTORY_IMPORT_PRODUCT,
                            icone: 'ico_historique',
                            // childrens:[ROUTE_DIFFUSION_PERMANENTS_PAGE, ROUTE_COMMUNITIES_PERMANENTS_ADD_POST, ROUTE_COMMUNITIES_PERMANENTS_POSTS, ROUTE_DIFFUSION_PERMANENTS_HEADERS_ASSET, ROUTE_DIFFUSION_PERMANENTS_PAGES_CHILDS_ASSET]
                        } : null,
                    menuEntry("history_imports") && process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
                        {
                            name: 'drawer.history_imports',
                            route: ROUTE_HISTORY_IMPORT,
                            icone: 'ico_historique',
                            // childrens:[ROUTE_DIFFUSION_PERMANENTS_PAGE, ROUTE_COMMUNITIES_PERMANENTS_ADD_POST, ROUTE_COMMUNITIES_PERMANENTS_POSTS, ROUTE_DIFFUSION_PERMANENTS_HEADERS_ASSET, ROUTE_DIFFUSION_PERMANENTS_PAGES_CHILDS_ASSET]
                        } : null,
                    // hasRights(BROADCAST, BROADCAST_CAMPAIGN, VIEW,{role:'lcs_admin',operator:'!='}) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    // {
                    //     name : 'drawer.action_logs',
                    //     route: ROUTE_HISTORY_ACTIONS_LOGS,
                    //     icone:'ico_actions',
                    //     // childrens:[ROUTE_DIFFUSION_CAMPAIGNS_ADD, ROUTE_DIFFUSION_CAMPAIGNS_DETAIL]
                    // } : null,
                ]
            },
            {
                name: 'drawer.sells',
                route: ROUTE_SALES,
                allRoutes: ALL_ROUTE_SALES,
                childrens: [
                    menuEntry("sells_eshop") && process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
                        {
                            name: 'drawer.sells_eshop',
                            route: ROUTE_SALES_ESHOP,
                            icone: 'ico_eshop',
                            childrens: [ROUTE_SALES_ESHOP_DETAIL, ROUTE_SALES_ESHOP_DETAIL_STATS, ROUTE_SALES_ESHOP_DETAIL_HEADERS_ASSET, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS, ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING, ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH, ROUTE_SALES_ESHOP_DETAIL_CACHE_CATEGORIES, ROUTE_SALES_ESHOP_FOOTER, ROUTE_SALES_ESHOP_DETAIL_SHOP_THE_LOOK, ROUTE_SALES_ESHOP_DETAIL_INFOS_MANAGEMENTS, ROUTE_SALES_ESHOP_DETAIL_CROSSSELL, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME_CHILDS, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS, ROUTE_SALES_ESHOP_DETAIL_TOP_MESSAGES, ROUTE_SALES_ESHOP_DETAIL_DISCOUNTS],
                            childrensStructure: [
                                {
                                    route: ROUTE_SALES_ESHOP_DETAIL,
                                    name: "drawer.dashboard"
                                },
                                {
                                    noRoute: true,
                                    route: ROUTE_SALES_ESHOP_DETAIL_MANAGE_CONTENTS,
                                    name: "Gestion du contenu",
                                    childrens: [ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME, ROUTE_SALES_ESHOP_FOOTER, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME_CHILDS],
                                    childrensStructure: [
                                        {
                                            route: ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME,
                                            name: "drawer.home",
                                            childrens: [ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME_CHILDS],
                                            parent: ROUTE_SALES_ESHOP_DETAIL_MANAGE_CONTENTS
                                        },
                                        {
                                            route: ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING,
                                            name: "drawer.landing",
                                            childrens: [ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS],
                                            parent: ROUTE_SALES_ESHOP_DETAIL_MANAGE_CONTENTS
                                        },
                                        {
                                            route: ROUTE_SALES_ESHOP_FOOTER,
                                            name: "drawer.eshop_footer",
                                            parent: ROUTE_SALES_ESHOP_DETAIL_MANAGE_CONTENTS
                                        }
                                    ]
                                },
                                {
                                    noRoute: true,
                                    route: ROUTE_SALES_ESHOP_DETAIL_MANAGE_SELLS,
                                    name: "Animations des ventes",
                                    childrens: [ROUTE_SALES_ESHOP_DETAIL_SHOP_THE_LOOK, ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING, ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH, ROUTE_SALES_ESHOP_DETAIL_CROSSSELL],
                                    childrensStructure: [
                                        {
                                            route: ROUTE_SALES_ESHOP_DETAIL_SHOP_THE_LOOK,
                                            name: "drawer.shop_the_look",
                                            parent: ROUTE_SALES_ESHOP_DETAIL_MANAGE_SELLS
                                        },
                                        {
                                            route: ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING,
                                            name: "drawer.merchandising",
                                            parent: ROUTE_SALES_ESHOP_DETAIL_MANAGE_SELLS,
                                            childrens: [ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH]
                                        },
                                        {
                                            route: ROUTE_SALES_ESHOP_DETAIL_CROSSSELL,
                                            name: "drawer.crossSell",
                                            parent: ROUTE_SALES_ESHOP_DETAIL_MANAGE_SELLS
                                        }
                                    ]
                                },
                                {
                                    noRoute: true,
                                    route: ROUTE_SALES_ESHOP_DETAIL_MANAGE_SELLS_OFFERS,
                                    name: "Offres commerciales",
                                    childrens: [ROUTE_SALES_ESHOP_DETAIL_INFOS_MANAGEMENTS, ROUTE_SALES_ESHOP_DETAIL_TOP_MESSAGES, ROUTE_SALES_ESHOP_DETAIL_DISCOUNTS],
                                    childrensStructure: [
                                        // {
                                        //     route: ROUTE_SALES_ESHOP_DETAIL_INFOS_MANAGEMENTS,
                                        //     name: "drawer.infoManagement",
                                        //     parent: ROUTE_SALES_ESHOP_DETAIL_MANAGE_SELLS_OFFERS
                                        // },
                                        // {
                                        //     route: ROUTE_SALES_ESHOP_DETAIL_DISCOUNTS,
                                        //     name: "drawer.discounts",
                                        //     parent: ROUTE_SALES_ESHOP_DETAIL_MANAGE_SELLS_OFFERS
                                        // },
                                        {
                                            route: ROUTE_SALES_ESHOP_DETAIL_TOP_MESSAGES,
                                            name: "drawer.messagesPromo",
                                            parent: ROUTE_SALES_ESHOP_DETAIL_MANAGE_SELLS_OFFERS
                                        },
                                    ]
                                },
                                {
                                    route: ROUTE_SALES_ESHOP_DETAIL_CACHE_CATEGORIES,
                                    name: "drawer.clear_cache_categories"
                                },
                            ]
                        } : null,
                    menuEntry("sells_applications") && process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
                        {
                            name: 'drawer.applications',
                            route: ROUTE_SALES_APPLICATIONS,
                            icone: 'ico-applications',
                            childrens: [ROUTE_SALES_APPLICATIONS_DETAILS, ROUTE_SALES_APPLICATIONS_NOTIFICATIONS],
                            childrensStructure: [
                                {
                                    route: ROUTE_SALES_APPLICATIONS_DETAILS,
                                    name: "drawer.dashboard"
                                },
                                {
                                    route: ROUTE_SALES_APPLICATIONS_NOTIFICATIONS,
                                    name: "drawer.notifications"
                                },
                            ]
                        } : null,
                    menuEntry("sells_social_commerce") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.sells_social_commerce',
                            route: ROUTE_SALES_SOCIAL_COMMERCE,
                            icone: 'ico_social-commerce',
                            childrens: [ROUTE_SALES_SOCIAL_COMMERCE_DETAILS],
                            // childrensStructure:[
                            //     {
                            //         route: ROUTE_SALES_SOCIAL_COMMERCE_DETAILS,
                            //         name: "drawer.dashboard"
                            //     },
                            // ]
                        } : null,
                    menuEntry("sells_marketplaces") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.marketplaces',
                            route: ROUTE_SALES_MARKETPLACES,
                            icone: 'ico_marketplace',
                            childrens: [ROUTE_SALES_MARKETPLACES_DETAILS]
                        } : null,
                    menuEntry("sells_shops") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.shops',
                            route: ROUTE_SALES_SHOPS,
                            icone: 'ico_magasin',
                            childrens: [ROUTE_SALES_SHOPS_DETAILS],
                            // childrensStructure:[
                            //     {
                            //         route: ROUTE_SALES_SHOPS_DETAILS,
                            //         name: "drawer.dashboard"
                            //     },
                            // ]
                        } : null,
                    menuEntry("sells_retailers") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.retailers',
                            route: ROUTE_SALES_RETAILERS,
                            icone: 'ico_retailer',
                            // childrens:[ROUTE_DIFFUSION_CAMPAIGNS_ADD, ROUTE_DIFFUSION_CAMPAIGNS_DETAIL]
                        } : null,
                ]
            },
            {
                name: 'drawer.communities',
                route: ROUTE_COMMUNITIES,
                allRoutes: ALL_ROUTE_COMMUNITIES,
                childrens: [
                    menuEntry("community_contests") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.community_contests',
                            route: ROUTE_COMMUNITIES_GAMES,
                            icone: 'ico_jeux-concours',
                            childrens: [ROUTE_COMMUNITIES_GAMES_DETAIL, ROUTE_COMMUNITIES_GAMES_DETAIL_STATS, ROUTE_COMMUNITIES_GAMES_DIFFUSION, ROUTE_COMMUNITIES_GAMES_DIFFUSION_PAGE, ROUTE_COMMUNITIES_GAMES_DETAIL_STATS],
                            childrensStructure: [
                                {
                                    route: ROUTE_COMMUNITIES_GAMES_DETAIL,
                                    name: "drawer.dashboard"
                                },
                                {
                                    route: ROUTE_COMMUNITIES_GAMES_DIFFUSION,
                                    name: "drawer.manage_games"
                                },
                                {
                                    route: ROUTE_COMMUNITIES_GAMES_DETAIL_STATS,
                                    name: "drawer.statistics"
                                },
                            ]
                        } : null,
                    menuEntry("community_minisites") && process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
                        {
                            name: 'drawer.community_minisites',
                            route: ROUTE_COMMUNITIES_MINISITES,
                            icone: 'ico_web',
                            childrens: [ROUTE_COMMUNITIES_MINISITES_DETAIL, ROUTE_COMMUNITIES_MINISITES_DETAIL_STATS, ROUTE_COMMUNITIES_MINISITES_DIFFUSION, ROUTE_COMMUNITIES_MINISITES_DIFFUSION_PAGE],
                            childrensStructure: [
                                {
                                    route: ROUTE_COMMUNITIES_MINISITES_DETAIL,
                                    name: "drawer.dashboard"
                                },
                                {
                                    route: ROUTE_COMMUNITIES_MINISITES_DIFFUSION,
                                    name: "drawer.manage_pages",
                                    childrens: [ROUTE_COMMUNITIES_MINISITES_DIFFUSION_PAGE]
                                },
                                // {
                                //     route: ROUTE_COMMUNITIES_MINISITES_DETAIL_STATS,
                                //     name: "drawer.statistics"
                                // },
                            ]
                        } : null,
                    menuEntry("community_newsletters") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.community_newsletters',
                            route: ROUTE_COMMUNITIES_NEWSLETTERS,
                            icone: 'ico_newsletter',
                            // childrens:[ROUTE_DIFFUSION_CAMPAIGNS_ADD, ROUTE_DIFFUSION_CAMPAIGNS_DETAIL]
                        } : null,
                    menuEntry("community_social_networks") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.social_networks',
                            route: ROUTE_COMMUNITIES_SOCIALS,
                            icone: 'ico_rs',
                            childrens: [ROUTE_COMMUNITIES_SOCIALS_DETAIL, ROUTE_COMMUNITIES_PERMANENTS_POSTS, ROUTE_COMMUNITIES_PERMANENTS_ADD_POST],
                            childrensStructure: [
                                {
                                    route: ROUTE_COMMUNITIES_PERMANENTS_POSTS,
                                    name: "drawer.spread"
                                },
                            ]
                        } : null,
                    menuEntry("community_cms") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.cms',
                            route: ROUTE_COMMUNITIES_CMS,
                            icone: 'ico_bookmarks',
                            childrens: [
                                ROUTE_COMMUNITIES_CMS_DETAIL,
                                ROUTE_COMMUNITIES_CMS_DETAIL_MANAGE_CONTENTS,
                                ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING,
                                ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING_CHILDS
                            ],
                            childrensStructure: [
                                {
                                    route: ROUTE_COMMUNITIES_CMS_DETAIL,
                                    name: "drawer.dashboard"
                                },
                                {
                                    noRoute: true,
                                    route: ROUTE_COMMUNITIES_CMS_DETAIL_MANAGE_CONTENTS,
                                    name: "Gestion du contenu",
                                    childrens: [
                                        ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING,
                                        ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING_CHILDS,
                                        ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER,
                                        ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER_CHILDS
                                    ],
                                    childrensStructure: [
                                        {
                                            route: ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING,
                                            name: "drawer.landing",
                                            childrens: [ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING_CHILDS],
                                            parent: ROUTE_COMMUNITIES_CMS_DETAIL_MANAGE_CONTENTS
                                        },
                                        {
                                            route: ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER,
                                            name: "drawer.footer",
                                            childrens: [ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER_CHILDS],
                                            parent: ROUTE_COMMUNITIES_CMS_DETAIL_MANAGE_CONTENTS
                                        },
                                    ]
                                },
                            ]
                        } : null,
                ]
            },
            {
                name: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'drawer.catalogs' : 'drawer.warehouses',
                route: ROUTE_CATALOGS,
                allRoutes: ALL_ROUTE_CATALOGS,
                // notAccordion: true,
                childrens: [
                    menuEntry("catalogs") ?
                        {
                            name: 'drawer.dashboard',
                            route: ROUTE_CATALOGS,
                            icone: 'ico_dash-1',
                        } : null,
                    menuEntry("catalogs_listing") ?
                        {
                            name: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'drawer.catalogs' : 'drawer.warehouses',
                            route: ROUTE_CATALOGS_LIST,
                            icone: 'ico_catalogs',
                            childrens: [ROUTE_CATALOGS_DETAIL]
                        } : null,
                    // hasRights(CATALOGS, CATALOGS_CATALOGS, VIEW) ?
                    // {
                    //     name : 'drawer.configuration',
                    //     route:  ROUTE_CATALOGS_SETTINGS,
                    //     icone:'ico_parametre',
                    // } : null,
                ]
            },
            {
                name: 'drawer.products',
                route: ROUTE_PRODUCTS,
                allRoutes: ALL_ROUTE_PRODUCTS,
                childrens: [
                    menuEntry("products") ?
                        {
                            name: 'drawer.dashboard',
                            route: ROUTE_PRODUCTS,
                            icone: 'icon-dashboard'
                        } : null,
                    menuEntry("products_listing") ?
                        {
                            name: 'drawer.products',
                            route: ROUTE_PRODUCTS_LIST,
                            icone: 'picto-produit',
                            childrens: [ROUTE_PRODUCTS_ADD, ROUTE_PRODUCTS_LIST_DETAIL]
                        }
                        : null,
                    menuEntry("products_listing_cover_rate") ?
                        {
                            name: 'drawer.products_cover_rate',
                            route: ROUTE_PRODUCTS_COVER_RATE,
                            icone: 'ico-cover-rate',
                            childrens: [ROUTE_PRODUCTS_COVER_RATE_DETAIL]
                        }
                        : null,
                    process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_ENABLE_PRICE_WATCHER === "true" ?
                        {
                            name: 'drawer.brands',
                            route: ROUTE_PRODUCTS_BRANDS_LIST,
                            icone: 'ico_hanger',
                            childrens: [
                                ROUTE_PRODUCTS_BRANDS_LIST,
                                ROUTE_PRODUCTS_BRANDS_DETAIL
                            ],
                            childrens: [ROUTE_PRODUCTS_BRANDS_DETAIL]
                        }
                        : null,
                    process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_ENABLE_PRICE_WATCHER === "true" ?
                        {
                            name: 'drawer.workflows',
                            route: ROUTE_PRODUCTS_WORKFLOW_NEW,
                            icone: 'ico_workflow',
                            childrens: [
                                ROUTE_PRODUCTS_WORKFLOW_NEW,
                                ROUTE_PRODUCTS_WORKFLOW_DELTA,
                            ],
                            hasSubmenu: true,
                            childrensStructure: [
                                {
                                    name: 'drawer.new',
                                    route: ROUTE_PRODUCTS_WORKFLOW_NEW
                                },
                                {
                                    noRoute: true,
                                    name: 'drawer.products_delta',
                                    route: ROUTE_PRODUCTS_WORKFLOW_DELTA,
                                    parent: ROUTE_PRODUCTS_WORKFLOW_NEW,
                                }
                            ]
                        }
                        : null,
                        process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_ENABLE_PRODUCT_WORKFLOW === "true" ?
                        {
                            name: 'drawer.workflows',
                            route: ROUTE_PRODUCTS_WORKFLOW_TO_COMPLETE,
                            icone: 'ico_workflow',
                            childrens: [
                                ROUTE_PRODUCTS_WORKFLOW_TO_COMPLETE,
                                ROUTE_PRODUCTS_WORKFLOW_DELAYED,
                                ROUTE_PRODUCTS_WORKFLOW_ERRORS,
                            ],
                            hasSubmenu: true,
                            childrensStructure: [
                                {
                                    name: 'drawer.workflow_products_to_complete',
                                    route: ROUTE_PRODUCTS_WORKFLOW_TO_COMPLETE
                                },
                                {
                                    name: 'drawer.workflow_products_delayed',
                                    route: ROUTE_PRODUCTS_WORKFLOW_DELAYED,
                                    parent: ROUTE_PRODUCTS_WORKFLOW_TO_COMPLETE,
                                },
                                {
                                    name: 'drawer.workflow_products_errors',
                                    route: ROUTE_PRODUCTS_WORKFLOW_ERRORS,
                                    parent: ROUTE_PRODUCTS_WORKFLOW_TO_COMPLETE,
                                }
                            ]
                        }
                        : null,
                        process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_ENABLE_PRICE_WATCHER === "true" ?
                        {
                            name: 'drawer.products_price_compare',
                            route: ROUTE_PRODUCTS_PRICE_COMPARE,
                            icone: 'ico_compare',
                            childrens: [
                                ROUTE_PRODUCTS_PRICE_COMPARE,
                                ROUTE_PRODUCTS_PRICE_COMPARE_LIST,
                                ROUTE_PRODUCTS_PRICE_COMPARE_DETAIL
                            ],
                            hasSubmenu: true,
                            childrensStructure: [
                                {
                                    name: 'drawer.dashboard',
                                    route: ROUTE_PRODUCTS_PRICE_COMPARE
                                },
                                {
                                    noRoute: true,
                                    name: 'drawer.products_listing',
                                    route: ROUTE_PRODUCTS_PRICE_COMPARE_LIST,
                                    parent: ROUTE_PRODUCTS_PRICE_COMPARE,
                                    hasSubmenu: true,
                                    childrens: [
                                        ROUTE_PRODUCTS_PRICE_COMPARE_DETAIL,
                                    ],
                                    childrensStructure: [
                                        {
                                            name: 'drawer.categories',
                                            route: ROUTE_PRODUCTS_PRICE_COMPARE_DETAIL,
                                            parent: ROUTE_PRODUCTS_PRICE_COMPARE_LIST,
                                        },
                                    ]
                                }
                            ]
                        }
                        : null,
                    menuEntry("products_configuration") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.configuration',
                            route: ROUTE_PRODUCTS_SETTINGS,
                            icone: 'ico_parametre',
                            childrens: [ROUTE_PRODUCTS_CATEGORIES, ROUTE_PRODUCTS_ATTRIBUTS, ROUTE_PRODUCTS_COMPLETUDE],
                            hasSubmenu: true,
                            childrensStructure: [
                                process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                    {
                                        name: 'drawer.categories',
                                        route: ROUTE_PRODUCTS_CATEGORIES,
                                        icone: 'picto-categorie',
                                        parent: ROUTE_PRODUCTS_SETTINGS
                                    } : null,
                                process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
                                    {
                                        name: 'drawer.attributes',
                                        route: ROUTE_PRODUCTS_ATTRIBUTS,
                                        icone: 'picto-produit',
                                        parent: ROUTE_PRODUCTS_SETTINGS
                                    } : null,
                                process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                    {
                                        name: 'drawer.completeness',
                                        route: ROUTE_PRODUCTS_COMPLETUDE,
                                        icone: 'picto-produit',
                                        parent: ROUTE_PRODUCTS_SETTINGS
                                    } : null,
                            ]
                        } : null,
                ]
            },
            {
                name: 'drawer.medias',
                route: ROUTE_MEDIAS,
                allRoutes: ALL_ROUTE_MEDIAS,
                childrens: [
                    menuEntry("medias_listing") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.dashboard',
                            route: ROUTE_MEDIAS,
                            icone: 'icon-dashboard',
                        } : null,

                    menuEntry("medias_listing") && process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
                        {
                            name: 'drawer.medias',
                            route: ROUTE_MEDIAS_LIST,
                            icone: 'icon-medias'
                        } : null,

                    menuEntry("medias_configuration") && process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
                        {
                            name: 'drawer.configuration',
                            route: ROUTE_MEDIAS_SETTINGS,
                            icone: 'ico_parametre',
                            childrens: [ROUTE_MEDIAS_TAGS, ROUTE_MEDIAS_CATEGORIES, ROUTE_MEDIAS_ATTRIBUTS],
                            hasSubmenu: true,
                            childrensStructure: [
                                process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
                                    {
                                        name: 'drawer.categories',
                                        route: ROUTE_MEDIAS_CATEGORIES,
                                        parent: ROUTE_MEDIAS_SETTINGS
                                    } : null,
                                // process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
                                // {
                                //     name : 'drawer.attributes',
                                //     route: ROUTE_MEDIAS_ATTRIBUTS,
                                //     parent: ROUTE_MEDIAS_SETTINGS 
                                // } : null,
                                process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
                                    {
                                        name: 'drawer.tags',
                                        route: ROUTE_MEDIAS_TAGS,
                                        parent: ROUTE_MEDIAS_SETTINGS
                                    } : null,
                            ]
                        } : null,
                ]
            },
            {
                name: 'drawer.content',
                route: ROUTE_CONTENU,
                allRoutes: ALL_ROUTE_CONTENU,
                childrens: [
                    menuEntry("content") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.dashboard',
                            route: ROUTE_CONTENU,
                            icone: 'icon-dashboard'
                        } : null,

                    menuEntry("content_listing") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.content',
                            route: ROUTE_CONTENU_LIST,
                            icone: 'ico_contenu',
                            childrens: [ROUTE_CONTENU_ADD, ROUTE_CONTENU_LIST_DETAIL]
                        } : null,

                    menuEntry("content_configuration") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                        {
                            name: 'drawer.configuration',
                            route: ROUTE_CONTENU_SETTINGS,
                            icone: 'ico_parametre',
                            childrens: [ROUTE_CONTENU_CATEGORIES, ROUTE_CONTENU_ATTRIBUTES],
                            hasSubmenu: true,
                            childrensStructure: [
                                process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                    {
                                        name: 'drawer.categories',
                                        route: ROUTE_CONTENU_CATEGORIES,
                                        parent: ROUTE_CONTENU_SETTINGS
                                    } : null,
                                process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                    {
                                        name: 'drawer.models',
                                        route: ROUTE_CONTENU_ATTRIBUTES,
                                        parent: ROUTE_CONTENU_SETTINGS
                                    } : null,
                            ]
                        } : null,
                ]
            },

            // {
            //     name : 'drawer.spread',
            //     route: ROUTE_DIFFUSION,
            //     allRoutes : ALL_ROUTE_DIFFUSION, 
            //     childrens:[
            //         hasRights(BROADCAST, BROADCAST_CALENDAR, VIEW,{role:'lcs_admin',operator:'!='}) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
            //         {
            //             name : 'drawer.spread_calendar',
            //             route: ROUTE_DIFFUSION_CALENDAR,
            //             icone: 'icon-calendrier'
            //         } : null,
            //         hasRights(BUILDER, BUILDER_ASSETS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
            //         {
            //             name : 'drawer.active_assets',
            //             route: ROUTE_DIFFUSION_PERMANENT,
            //             icone:'icon-permanent',
            //             childrens:[ROUTE_DIFFUSION_PERMANENTS_PAGE, ROUTE_COMMUNITIES_PERMANENTS_ADD_POST, ROUTE_COMMUNITIES_PERMANENTS_POSTS, ROUTE_DIFFUSION_PERMANENTS_HEADERS_ASSET, ROUTE_DIFFUSION_PERMANENTS_PAGES_CHILDS_ASSET]
            //         } : null,
            //         hasRights(BROADCAST, BROADCAST_CAMPAIGN, VIEW,{role:'lcs_admin',operator:'!='}) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
            //         {
            //             name : 'drawer.campaigns',
            //             route: ROUTE_DIFFUSION_CAMPAIGNS,
            //             icone:'icon-campagne',
            //             childrens:[ROUTE_DIFFUSION_CAMPAIGNS_ADD, ROUTE_DIFFUSION_CAMPAIGNS_DETAIL]

            //         } : null,
            //         hasRights(BROADCAST, BROADCAST_RETAILERS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
            //         {
            //             name : 'drawer.retailers',
            //             route:  ROUTE_DIFFUSION_RETAILERS,
            //             icone:'picto-retailer'   
            //         } : null,
            //     ]
            // },
            // {
            //     name : 'drawer.spread_builder',
            //     route: ROUTE_ENGINE,
            //     allRoutes : ALL_ROUTE_ENGINE, 
            //     childrens:[
            //         hasRights(BUILDER, BUILDER_DASHBOARD, VIEW,{role:'lcs_admin',operator:'!='}) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
            //         {
            //             name : 'drawer.dashboard',
            //             route: ROUTE_ENGINE,
            //             icone:'icon-dashboard'   
            //         } : null,
            //         hasRights(BUILDER, BUILDER_ASSETS, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
            //         {
            //             name : 'drawer.assets_views',
            //             route: ROUTE_ENGINE_ASSETS,
            //             icone:'icon-asset',
            //             childrens:[ROUTE_ENGINE_ASSETS_DETAIL_STATS,ROUTE_ENGINE_ASSETS_DETAIL] 
            //         } : null,
            //     ]
            // },
            {
                name: 'drawer.orders',
                route: ROUTE_ORDERS,
                allRoutes: ALL_ROUTE_ORDERS,
                childrens: [
                    process.env.REACT_APP_MODE_SPREAD === "hub" ?
                        {
                            name: 'drawer.orders',
                            route: ROUTE_ORDERS_LIST,
                            icone: 'icon-costs',
                            childrens: [ROUTE_ORDERS_DETAIL]
                        }
                        : null,
                    process.env.REACT_APP_MODE_SPREAD === "hub" ?
                        {
                            name: 'drawer.preorder',
                            route: ROUTE_ORDERS_LIST_PREORDER,
                            icone: 'icon-costs',
                            // childrens:[ROUTE_ORDERS_DETAIL]   
                        }
                        : null,
                    process.env.REACT_APP_MODE_SPREAD === "hub" ?
                        {
                            name: 'drawer.delayed_orders',
                            route: ROUTE_ORDERS_LIST_DELAY,
                            icone: 'icon-costs',
                            // childrens:[ROUTE_ORDERS_DETAIL]   
                        }
                        : null,
                    process.env.REACT_APP_MODE_SPREAD === "hub" ?
                        {
                            name: 'drawer.returned_orders',
                            route: ROUTE_ORDERS_LIST_RETURN,
                            icone: 'icon-costs',
                            // childrens:[ROUTE_ORDERS_DETAIL]   
                        }
                        : null,
                ]
            },
            {
                name: 'drawer.restaurants',
                route: ROUTE_RESTAURANTS,
                allRoutes: ALL_ROUTE_RESTAURANTS,
                childrens: [
                    process.env.REACT_APP_BUNDLE_RESTAURANT === "true" ?
                        {
                            name: 'drawer.manage_restaurants',
                            route: ROUTE_RESTAURANTS,
                            icone: 'icon-costs',
                            childrens: [ROUTE_ORDERS_DETAIL]
                        }
                        : null,
                    process.env.REACT_APP_BUNDLE_RESTAURANT === "true" ?
                        {
                            name: 'drawer.manage_demands',
                            route: ROUTE_RESTAURANTS_DEMANDS,
                            icone: 'icon-costs',
                        }
                        : null,
                ]
            },
            // {
            //     name : 'drawer.attributes',
            //     route: ROUTE_ATTRIBUTS,
            //     allRoutes : ALL_ROUTE_ATTRIBUTS, 
            //     childrens:[
            //         hasRights(PRODUCTS, PRODUCTS_ATTRIBUTES, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
            //         {
            //             name : 'drawer.products',
            //             route: ROUTE_ATTRIBUTS_ATTRIBUTS,
            //             icone:'picto-produit'   
            //         } : null,
            //         hasRights(PRODUCTS, PRODUCTS_ATTRIBUTES, VIEW) && process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_BUNDLE_RESTAURANT !== 'true' ?
            //         {
            //             name : 'drawer.categories',
            //             route: ROUTE_ATTRIBUTS_CATEGORIES,
            //             icone:'picto-categorie'   
            //         } : null,
            //         // hasRights(PRODUCTS, PRODUCTS_ATTRIBUTES, VIEW) && process.env.REACT_APP_BUNDLE_STORELOCATOR === 'true' ?
            //         // {
            //         //     name : 'Magasins',
            //         //     route: ROUTE_ATTRIBUTS_POINT,
            //         //     icone:'picto-retailer'   
            //         // } : null,
            //     ]
            // },

        ]
    }
    if (menuEntry("shells") && process.env.REACT_APP_ALLOW_SHELLS === "true") {
        menuItems.push(
            {
                name: 'drawer.shells',
                route: ROUTE_SHELLS,
                allRoutes: ALL_ROUTE_SHELLS,
                childrens: [
                    {
                        name: 'drawer.manage_shells',
                        route: ROUTE_SHELLS_LIST,
                        icone: 'picto-attribut'
                    },
                ]
            }
        )
    }
    if (menuEntry("shells") && shellsNotSystem.length > 0 && process.env.REACT_APP_ALLOW_SHELLS === "true") {
        for (let shell of shellsNotSystem) {
            let idShell = shell.node?.id.replace('/api/eav-types/', '')
            menuItems.push({
                name: 'drawer.shell_code',
                nameArgs: { code: shell.node?.name ?? shell.node?.code },
                route: ROUTE_SHELLS_CUSTOM.replace(':alias', shell.node.code).replace(':id', idShell),
                allRoutes: ALL_ROUTE_SHELLS_CUSTOM,
                childrens: [
                    {
                        name: shell.node?.name ?? shell.node?.code,
                        route: ROUTE_SHELLS_CUSTOM_LIST.replace(':alias', shell.node.code).replace(':id', idShell),
                        icone: 'icon-dashboard',
                        childrens: [ROUTE_SHELLS_CUSTOM_DETAIL]
                    },
                    {
                        name: 'drawer.manage_attributes',
                        route: ROUTE_SHELLS_CUSTOM_ATTRIBUTES.replace(':alias', shell.node.code).replace(':id', idShell),
                        icone: 'picto-attribut',
                        customState: {
                            alias: shell.node.code,
                            id: shell.node.id,
                        }
                    },
                ]
            })
        }
    }
    menuItems.push(
        {
            name: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'drawer.connectors' : 'drawer.channels',
            route: ROUTE_CHANNELS,
            allRoutes: ALL_ROUTE_CHANNELS,
            childrens: [
                menuEntry("connectors") ?
                    {
                        name: 'drawer.dashboard',
                        route: ROUTE_CHANNELS,
                        icone: 'icon-dashboard'
                    } : null,
                // hasRights(CONNECTORS, CONNECTORS_RETAILERS, VIEW,{role:'lcs_admin',operator:'!='}) ?
                // {
                //     name : process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'drawer.retailers' : "drawer.warehouses",
                //     route: ROUTE_SALES_RETAILERS,
                //     icone:'picto-retailer'
                // } : null,
                menuEntry("connectors_brands") ?
                    {
                        name: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'drawer.brands' : "drawer.erp",
                        route: ROUTE_CHANNELS_BRANDS,
                        icone: 'icon-business'
                    } : null,
                menuEntry("connectors_marketplaces") ?
                    {
                        name: 'drawer.marketplaces',
                        route: ROUTE_CHANNELS_MARKETPLACES,
                        icone: 'picto-marketplace'
                    } : null,
                menuEntry("connectors_social_networks") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name: 'drawer.social_networks',
                        route: ROUTE_CHANNELS_SOCIALS,
                        icone: 'picto-rs'
                    } : null,
                menuEntry("connectors_cms") ?
                    {
                        name: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'drawer.cms' : "drawer.sells",
                        route: ROUTE_CHANNELS_CMS,
                        icone: 'icon-cms'
                    } : null,
                // hasRights(CONNECTORS, CONNECTORS_EMAILS, VIEW,{role:'lcs_admin',operator:'!='}) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                // {
                //     name : 'drawer.emails',
                //     route: ROUTE_CHANNELS_MAILER,
                //     icone:'picto-newsletter'   
                // } : null,
                menuEntry("connectors_contents") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name: 'drawer.contents',
                        route: ROUTE_CHANNELS_CONTENTS,
                        icone: 'picto-connector-content'
                    } : null,
                menuEntry("connectors_medias") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name: 'drawer.medias',
                        route: ROUTE_CHANNELS_MEDIAS,
                        icone: 'picto-media'
                    } : null,
                menuEntry("connectors_custom") ?
                    {
                        name: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'drawer.connectors_custom' : 'drawer.coordinators',
                        route: ROUTE_CHANNELS_CONNECTORS,
                        icone: 'picto-connector'
                    } : null,
            ]
        },
        // {
        //     name : 'drawer.brand',
        //     route: ROUTE_BRAND_GUIDELINE,
        //     allRoutes : ALL_ROUTE_BRAND, 
        //     childrens:[
        //         hasRights(BRAND, BRAND_GUIDELINE, VIEW) ?
        //         {
        //             name : 'drawer.guideline',
        //             route: ROUTE_BRAND_GUIDELINE,
        //             icone: 'icon-guideline',
        //             childrens: [ROUTE_BRAND_GUIDELINE_ADD, ROUTE_BRAND_GUIDELINE_UPDATE]  
        //         } : null,
        //         hasRights(BRAND, BRAND_FLOW, VIEW,{role:'lcs_admin',operator:'!='}) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
        //         {
        //             name : 'drawer.my_streams',
        //             route: ROUTE_BRAND_FLOW,
        //             icone: 'icon-flux',
        //             childrens: [ROUTE_BRAND_FLOW_DETAILS]
        //         } : null,
        //     ]
        // },
        {
            name: 'drawer.settings',
            route: ROUTE_SETTINGS,
            allRoutes: ALL_ROUTE_SETTINGS,
            childrens: [
                menuEntry("settings_brand_guidelines") ?
                    {
                        name: 'drawer.guideline',
                        route: ROUTE_SETTINGS_GUIDELINE,
                        icone: 'icon-guideline',
                        childrens: [ROUTE_SETTINGS_GUIDELINE_ADD, ROUTE_SETTINGS_GUIDELINE_UPDATE]
                    } : null,
                // hasRights(PRODUCTS, PRODUCTS_PRODUCTS, VIEW,{role:'lcs_admin',operator:'!='}) ?
                // {
                //     name : 'drawer.collections',
                //     route: ROUTE_PRODUCTS_COLLECTIONS,
                //     icone:'picto-collections',
                // }
                // : null,
                // hasRights(SETTINGS, SETTINGS_LANGUAGES, VIEW,{role:'lcs_admin',operator:'!='}) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                // {
                //     name : 'drawer.system_locales',
                //     route: ROUTE_SETTINGS_LANGUAGES,
                //     icone:'picto-langues'   
                // } : null,
                // hasRights(SETTINGS, SETTINGS_CURRENCIES, VIEW,{role:'lcs_admin',operator:'!='}) && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                // {
                //     name : 'drawer.system_currencies',
                //     route: ROUTE_SETTINGS_CURRENCIES,
                //     icone:'picto-devises'
                // } : null,
                // hasRights(null,null,null,{role:'lcs_admin',operator:'!='}) ?
                // {
                //     name : 'drawer.manage_group_taxes',
                //     route: ROUTE_SYSTEM_GROUPRATES,
                //     icone:'picto-prix'
                // } : null,
                // hasRights(null,null,null,{role:'lcs_admin',operator:'!='}) ?
                // {
                //     name : 'drawer.system_taxrates',
                //     route: ROUTE_SETTINGS_TAXRATES,
                //     icone:'picto-prix'
                // }:null,
                menuEntry("settings_users") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name: 'drawer.manage_users',
                        route: ROUTE_SETTINGS_USERS,
                        icone: 'ico_gestion',
                        childrens: [ROUTE_SETTINGS_DETAIL_USER]
                    } : null,
                menuEntry("settings_manage_users_groups") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name: 'drawer.settings_manage_users_groups',
                        route: ROUTE_SETTINGS_USERS_GROUPS,
                        icone: 'ico_gestion_acces',
                    } : null,
                menuEntry("settings_manage_accesses") && process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    {
                        name: 'drawer.settings_manage_accesses',
                        route: ROUTE_SETTINGS_ACCESS,
                        icone: 'ico_access_manager',
                    } : null,
                // hasRights(null,null,null,{role:'lcs_admin',operator:'!='}) && process.env.REACT_APP_ALLOW_SHELLS === "true" ?
                // {
                //     name : 'drawer.manage_shells',
                //     route: ROUTE_SHELLS_LIST,
                //     icone:'picto-attribut',
                // } : null,                
            ]
        },
        {
            name: 'drawer.system',
            route: ROUTE_SYSTEM,
            allRoutes: ALL_ROUTE_SYSTEM,
            childrens: [
                // hasRights(null,null,null,{role:'lcs_admin',operator:'!='}) ?
                // {
                //     name : 'drawer.connectors',
                //     route:  ROUTE_SYSTEM_CONNECTORS,
                //     icone: 'ico_connecteur'   
                // } : null,
                menuEntry("system_currencies") ?
                    {
                        name: 'drawer.system_currencies',
                        route: ROUTE_SYSTEM_CURRENCIES,
                        icone: 'ico_devises'
                    } : null,
                menuEntry("system_locales") ?
                    {
                        name: 'drawer.system_locales',
                        route: ROUTE_SYSTEM_LANGUAGES,
                        icone: 'picto-langues'
                    } : null,
                menuEntry("system_taxrates") ?
                    {
                        name: 'drawer.system_taxrates',
                        route: ROUTE_SYSTEM_TAXRATES,
                        icone: 'ico_taxes',
                        childrens: [ROUTE_SYSTEM_GROUPRATES]
                    } : null,
            ]
        },
    )
    return menuItems
}