import React from 'react';
import {Grid} from '@material-ui/core';

import { withRouter } from 'react-router';
import { connect } from "react-redux";
import ReactEcharts from 'echarts-for-react';
import InputBuilder from '../../ui/form/InputBuilder';

class PieCharts extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            selectedDataPie: this.props.selectDataPie.value[0].value
        };
    }
    
    getOptionPie(selectedDataPie){
        var colorPalette = ['#0B3449', '#E51C52', '#67A9DC','#37DBE0', '#BC8DF3', '#59C870'];
        var options = {
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    var colorSpan = color => `<span style="display: inline-block; margin-right: 5px; border-radius: 10px; width: 12px; height: 12px; background-color: ${color}"></span>`;
                    var tooltipText = `<p>${colorSpan(params.color)} ${params.data.name}: ${params.data.value} (${params.percent}%)</p>`
                    return tooltipText;
                } 
            },
            grid:{
                width: '100%',
                containLabel: true,
            },
            legend: {
                icon: 'circle',
                orient: 'vertical',
                bottom: '0%',
                left: '50%',
                itemGap: 10,
                formatter: function (name) {
                    let itemValue = selectedDataPie.filter(item => item.name === name)
                    return '{a|' + name + '}\n{b|' + itemValue[0].value + '}'
                },
                textStyle: {
                    // verticalAlign: 'top',
                    rich: {
                        a: {
                            color: '#A2A7B3',
                            fontSize: 14,
                            lineHeight: 14,
                            verticalAlign: 'top',
                            fontWeight: 'bold',
                            fontFamily: 'Soleil',
                        },
                        b: {
                            color: '#A2A7B3',
                            fontSize: 12
                        },
                    }
                }
                
            },
            series: [
                {
                    name: 'Test',
                    type: 'pie',
                    // selectedMode: 'single',
                    radius: ['82%', '90%'],
                    center: ['23%', '50%'],
                    // hoverOffset: 5,
                    // avoidLabelOverlap: false,
                    width: '45%',
                    left: '15%',
                    color: colorPalette,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '14',
                            fontWeight: 'bold',
                            width: '20px',
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: selectedDataPie
                }
            ]
        };
        return options

    };

    render() {
        return (
            <div>
                {
                    this.props.selectDataPie.value.length > 1  ? (
                        <Grid container justifyContent={'flex-end'} style={{position: 'absolute', right: 10, zIndex: 2, width: '50%'}}>
                            <Grid item xs={12}>
                                <Grid container justifyContent={'flex-end'}>
                                    <InputBuilder value={this.props.selectedDataPie} input={this.props.selectDataPie} stateCallback={this.props.handleSelectPie} /> 
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : null
                }
                <ReactEcharts option={this.getOptionPie(this.props.selectedDataPie)} style={{padding: '16px 0px 16px 24px'}} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
    };
};

export default withRouter(connect(mapStateToProps, null)(PieCharts));
