import { withTranslation } from "react-i18next";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../js/constants/action-types";
import { withRouter } from "react-router";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import OurButton from "../../ui/button/Button";
import { Box, MenuItem, Modal, Select, TextField, Typography } from "@material-ui/core";
import parseHtml from "../../../js/utils/parseHtml";
import React from "react";
import EditIcon from '@material-ui/icons/Edit';
import styled from "styled-components";
import CloseIcon from '@material-ui/icons/Close';    
import request from "../../../builder/shareable/tools/Fetch"
import colors from "../../../config/theme/colors";
import IconeCheck from "../../../assets/pictos/ico-verifier.svg"

const BoxClose = styled(Box)`
    width: 80px;
    padding-bottom: 0;
    color: ${colors.black.regular};
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: bold;
    &:hover{
        text-decoration: underline;
    }
    span{
        margin-left: 4px;
    }
`;

const RichDescription = (props)=>{
    const [openRichText, setOpenRichText] = React.useState(false);
    const [richTextOption, setRichTextOption] = React.useState("luxury");
    const [richTextValue, setRichTextValue] = React.useState(props.value || " ");
    const [richTextDisabled, setRichTextDisabled] = React.useState(true);
    const [richTextLoading, setRichTextLoading] = React.useState(false);


    const getRichText = ()=>{
        setRichTextLoading(true)
        const variables ={
            description:  props.value,
            ton: richTextOption,
            sku: props.sku
        }
        request(`https://external-connectors.sinfin.dev/api/openai/rewording/product`, 'post',variables)
        .then((result)=>{            
            setRichTextDisabled(false)
            setRichTextValue(result.rewording)
            setRichTextLoading(false)
        })
    }

    return (
        <>
            <OurButton
                onClick={()=>{setOpenRichText(!openRichText)}}                                                
            >Variante IA</OurButton>
            <Modal
              open={openRichText}                              
              onClose={()=>setOpenRichText(!openRichText)}  
              style={{display:'flex',alignItems:'center',justifyContent:'center'}}                                                        
            >
                <Box
                    style={{
                        background:"white",
                        width:"50%",
                        padding:"30px 60px"
                    }}
                >
                    <BoxClose onClick={()=>setOpenRichText(!openRichText)} pb={2} style={{ cursor: 'pointer' }}>
                        <CloseIcon /> <span>Fermer</span>
                    </BoxClose>                                    
                    <Typography style={{fontSize:33,color:colors.black.regular,padding:"20px 0",fontWeight:"bold"}}>Variante IA</Typography>
                    <Typography>Faite une description enrichie en fonction du ton que vous voulez donner</Typography>
                    <Box style={{
                        margin : "20px 0"
                    }}>
                        <Typography style={{color:colors.black.regular,marginBottom:5,fontWeight:"bold"}}>Ton de la description</Typography>
                        <Select
                            value={richTextOption}                                        
                            fullWidth
                            variant="outlined"
                            onChange={(e)=>setRichTextOption(e.target.value)}
                        >
                            <MenuItem value={"luxury"}>
                                Luxe
                            </MenuItem>
                            <MenuItem value={"urban"}>
                                Urbain
                            </MenuItem>
                            <MenuItem value={"classical"}>
                                Classique
                            </MenuItem>
                        </Select>
                    </Box>       
                    <Box style={{
                        margin : "20px 0"
                    }}>
                        <Typography style={{color:colors.black.regular,marginBottom:5,fontWeight:"bold"}}>Description</Typography>
                        <TextField
                            multiline
                            fullWidth
                            minRows={5}
                            maxRows={5}
                            disabled={richTextDisabled}
                            value={parseHtml(richTextValue)}
                            variant="outlined"
                            onChange={(e)=>setRichTextValue(e.target.value)}
                        />                                        
                    </Box>
                    <Box
                        style={{
                            display:"flex",
                            justifyContent:"end",
                            gap:20
                        }}
                    >
                        <OurButton
                            bgcolor={"#F0F9FD"}
                            bgcolorhover={"#F0F9FD"}
                            color={"#0273A5"}
                            border={"0.5px solid #0273A5"}
                            icon={
                                <img src={IconeCheck} alt={"Icone check"} style={{ marginRight: '5px', height: '15px' }}/>                                
                            }
                            onClick={getRichText}
                            loading={richTextLoading}
                        >
                            Générer
                        </OurButton>
                        <OurButton
                            bgcolor={colors.green.regular}
                            bgcolorhover={colors.green.regular}
                            onClick={()=>{
                                props.stateCallback(richTextValue)
                                setOpenRichText(!openRichText)
                            }}
                        >
                            Valider la description
                        </OurButton>
                    </Box>                             
                </Box>                   
            </Modal>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
        locales: state.locales
    };
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(RichDescription))));