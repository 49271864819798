import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import colors from '../../../config/theme/colors';
import Button from '../button/Button';
import { Box } from '@material-ui/core';

const DialogModal = (props) => {
    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={props.onClose ?? null}
            maxWidth={props.maxWidth}
            fullWidth={props.fullWidth}
            style={{ ...props.customStyle }}
            PaperProps={{
                style: {
                    display: 'flex',
                    flexDirection: 'row',
                    ...props.customStylePaper
                }
            }}
        >
            {props.icon ? (<Box>
                {props.icon}
            </Box>) : null}
            <Box>
                <DialogTitle id="alert-dialog-title" style={{
                    paddingLeft: props.icon ? "20px" : null,
                }}>{props.title}</DialogTitle>

                <DialogContent style={{
                    paddingLeft: props.icon ? "20px" : null,
                }}>
                    {
                        props.notText ?
                            props.children
                            :
                            <DialogContentText id="alert-dialog-description">
                                {props.children}
                            </DialogContentText>
                    }
                </DialogContent>

                <DialogActions style={{ padding: "8px 24px", justifyContent: 'flex-start', paddingLeft: props.icon ? "20px" : '24px', }}>
                    {
                        props.primaryAction ? (
                            <Button
                                onClick={props.primaryAction}
                                bgcolor={props.primarybgcolor ? props.primarybgcolor : colors.red.regular}
                                bgcolorhover={props.primarybgcolorhover ? props.primarybgcolorhover : colors.red.regular}
                                color={props.primarycolor ? props.primarycolor : colors.white}
                                colorhover={props.primarycolorhover ? props.primarycolorhover : colors.white}
                                text={props.primaryText ? props.primaryText : 'Supprimer'}
                                disabled={props.primaryDisabled ? props.primaryDisabled : false}
                                autoFocus
                            />
                        ) : null
                    }
                    {
                        props.secondaryAction ? (
                            <Button
                                onClick={props.secondaryAction}
                                color={props.secondarycolor ? props.secondarycolor : colors.grey.regular}
                                colorhover={props.secondarycolorhover ? props.secondarycolorhover : colors.grey.regular}
                                bgcolor={props.secondarybgcolor ? props.secondarybgcolor : colors.white}
                                bgcolorhover={props.secondarybgcolorhover ? props.secondarybgcolorhover : colors.white}
                                text={props.secondaryText ? props.secondaryText : 'Annuler'}
                                border={props.secondaryborder ? props.secondaryborder : null}
                            />
                        ) : null
                    }
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default DialogModal;