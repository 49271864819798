import React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from '../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../js/constants/alert-types';
import PictoCSV from '../../../assets/pictos/csv.svg';
import PictoZIP from '../../../assets/pictos/zip.svg'
import axios from '../../../js/utils/axios';
import request from '../../../js/utils/fetch';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import {Grid, Typography, Box} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import {withTranslation} from 'react-i18next'


const LinearProgressCustom = styled(LinearProgress)`
    .MuiLinearProgress-root{
        height: 5px;
    }
    .MuiLinearProgress-bar{
        background-color: #59C870;
    }
`;

const WrapperButton = styled.div`
    background: ${colors.blue.darker.hue300};
    border-radius: 4px;
    color: white;
    margin-top: -8px;
    padding: 8px 16px;
    width: 190px;
    text-align: center;
    margin-bottom: 16px;
    cursor: pointer;
    &:hover{
        background: ${colors.black.regular}
    }
`;
const OverlayUpload = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
`;
const SectionCustom = styled.section`
    border: dashed 2px #C5DEEC;
    background-color: ${props => props.hover ? '#eee' : '#fff'};
    border-radius: 4px;
    height: 300px;
    cursor: pointer;
    width: calc(100% - 10px);
    margin: 0px auto;
    img{
        height: 75px;
        margin-bottom: 15px;
    }
    div{
        font-size: 14px;
    }
    @media screen and (max-width: 1450px){
        height: 250px;
        img{
            height: 70px;
        }
        div{
            font-size: 13px;
        }
    }
    @media screen and (max-width: 1280px){
        height: 200px;
        img{
            height: 55px;
        }
        div{
            font-size: 12px;
        }
    }
    @media screen and (max-width: 1000px){
        height: 150px;
        img{
            height: 40px;
        }
    }
`;

class UploadFile extends React.Component {  
    state = {
        hover: false,
        file: [],
        uploading: false,
        headers: [],
        resultMedia: [],
        result: [],
        pictos:{
            "only-zip":PictoZIP,
            zip:PictoZIP,
            csv:PictoCSV,
            xls:PictoCSV,
        },
        typeUpload :this.props.input.typeUpload,
        progressValue: 0,
        error: false,
    };

    callbackProgress = (data) => {
        let totalProgress = (data.loaded / data.total) * 100;
        this.setState({
            progressValue:totalProgress
        })
    }

    error = (error) => {
        this.props.snack(ALERT_ERROR, error);
        this.setState({ hover: false, error: true });
    };

    onDrop = (acceptedFiles, rejected, index) => {
        const { stateCallback } = this.props;
        let translated = null;
        if(this.props.input.translated){
            translated = true;
        }
        let file = acceptedFiles[0];

        if (!file)
            return this.error('Fichier non supporté');
        if (this.props.input.typeUpload === "zip" || this.props.input.typeUpload === "only-zip"){
            if (file.name.indexOf(`.zip`) < 0)
            return this.error('Le fichier doit être de type ZIP');
        }else if(this.props.input.typeUpload === "xls") {
            if (file.name.indexOf('.xls') < 0 && file.name.indexOf('.xlsx') < 0)
                return this.error('Le fichier doit être de type XLS ou XLSX');
            // else if (file.name.indexOf('.xlsx') < 0)
            //     return this.error('Le fichier doit être de type XLS ou XLSX');
        }else {
            if (file.name.indexOf('.csv') < 0 && file.name.indexOf('.xml') < 0)
            return this.error('Le fichier doit être de type CSV');
        }
        if(this.props.input.typeUpload === "xls"){
            this.setState({ hover: false, uploading: true, error: false }, async () => {
                try {
                    let formData = new FormData();
                    formData.append('file', file);
                    // formData.append('type', 'csv');

                    let convertToCSV = await axios(`${process.env.REACT_APP_API}/export/excel-to-csv`, 'post', formData);
                    if (convertToCSV.success){
                        let formDataCSV = new FormData();
                        this.state.resultMedia = convertToCSV;
                        
                        let headersFormData = new FormData();
                        formDataCSV.append('type', 'csv');

                        headersFormData.append('csv', `${process.env.REACT_APP_API_ROOT}/medias/${convertToCSV.mediaObject.filePath}`);
                        headersFormData.append('separator', ";");
                        let resultHeaders = null;

                        resultHeaders = await axios(`${process.env.REACT_APP_API_ROOT}/api/export/csv/structure`, 'post', headersFormData);
                        this.state.headers = resultHeaders.message;
                        this.setState({
                            uploading: false,
                            result: {file: file, headers: this.state.headers, medias: this.state.resultMedia}
                        }, () => {
                            stateCallback(this.state.result, null, translated, null);
                        });
                    }
                } catch(e) {
                    console.log(e);
                }
            });
        }else if(this.props.input.typeUpload === "only-zip"){
            this.setState({ hover: false, uploading: true, error: false }, async () => {
                try {
                    let formDataZip = new FormData();
                    formDataZip.append('file', file);
                    formDataZip.append('type', "zip");
                    let getZip = await axios(`${process.env.REACT_APP_API}/importer/images/zip`, 'post', formDataZip, true, null, this.callbackProgress);
                    let resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formDataZip, true, '');
                    let allInfos = {...resultMedia, ...getZip};
                    this.state.resultMedia = allInfos;
                    this.setState({
                        uploading: false,
                        result: {file: file, headers: this.state.headers, medias: this.state.resultMedia}
                    }, async() => {
                        stateCallback(this.state.result, null, translated, null);
                        if (!this.props.input.noMapping){
                            let headersFormData = new FormData();
                            headersFormData.append('csv',`${process.env.REACT_APP_MEDIAS}/${getZip.filePath}`);
                            headersFormData.append('separator', ';');
                            let resultHeaders = null;
                            this.state.resultMedia = {contentUrl: `${process.env.REACT_APP_MEDIAS}/${getZip.filePath}`};
                            resultHeaders = await axios(`${process.env.REACT_APP_API_ROOT}/api/export/csv/structure`, 'post', headersFormData);
                            this.state.headers = resultHeaders.message;
                            this.setState({
                                uploading: false,
                                result: {file: null, headers: this.state.headers, medias: this.state.resultMedia}
                            }, () => {
                                stateCallback(this.state.result, 'upload_CSV', translated, null);
                            });
                        }
                    });
                } catch(e) {
                    console.log(e);
                    this.setState({ hover: true, uploading: false });
                    this.error(`Fichier trop lourd limite (${this.props.input.noMapping ? '300' : process.env.REACT_APP_FILE_UPLOAD_MAX.slice(0,3)} MB)`);
                    stateCallback(null, null, null, null);
                }
            });
        }else{
            this.setState({ hover: false, uploading: true, error: false }, async () => {
                try {
                    let formData = new FormData();
                    formData.append('file', file);
                    if (this.props.input.typeUpload){
                        formData.append('type', this.props.input.typeUpload);
                    }else {
                        formData.append('type', 'csv');
                        formData.append('isInternal', true);
                    }
                    
                    let resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                    this.state.resultMedia = resultMedia;
                    let headersFormData = new FormData();
                    if (this.props.input.typeUpload){
                        formData.append('type', this.props.input.typeUpload);
                        headersFormData.append(this.props.input.typeUpload, `${process.env.REACT_APP_API_ROOT}${resultMedia.contentUrl}`);
                    }else {
                        formData.append('type', 'csv');
                        headersFormData.append(this.props.allState['importSep'] !== "xml" ? 'csv' : 'xml', `${process.env.REACT_APP_API_ROOT}${resultMedia.contentUrl}`);
                    }
                    headersFormData.append('separator', this.props.allState['importSep']);
                    let resultHeaders = null;
                    if (this.props.input.typeUpload){
                        // resultHeaders = await axios(`${process.env.REACT_APP_API_ROOT}/api/${this.props.input.typeUpload}/structure`, 'post', headersFormData);
                    }else {
                        resultHeaders = await axios(this.props.allState['importSep'] !== "xml" ? `${process.env.REACT_APP_API_ROOT}/api/export/csv/structure` : `${process.env.REACT_APP_API_ROOT}/api/export/simplexml/structure`, 'post', headersFormData);
                        this.state.headers = resultHeaders.message;
                    }
                    this.setState({
                        uploading: false,
                        result: {file: file, headers: this.state.headers, medias: this.state.resultMedia}
                    }, () => {
                        stateCallback(this.state.result, null, translated, null);
                    });
                } catch(e) {
                    console.log(e);
                }
            });
        }
    };

    onDragEnter = () => {
        this.setState({ hover: true });
    };

    onDragLeave = () => {
        this.setState({ hover: false });
    };

    handlerMutationExport = async () => {
        let downloadType = null
        switch (this.props.input.downloadType){
            case 'product':
                downloadType = 'excel/header/products';
                break;
            default:
                downloadType = null;
            break;
        }
        
        try {
            this.props.startLoading();
            request(`${process.env.REACT_APP_API}/export/${downloadType}`, 'get', false).then(
                (data) => {
                    if(data.success){
                        window.open(data.file, '_blank');
                        this.props.stopLoading();
                        this.props.snack(ALERT_SUCCESS, `Téléchargement réussi !`, 10000);
                    }else{
                        this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
                        this.props.stopLoading();
                    }
                }
            );
        } catch(e) {
            this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
            this.props.stopLoading();
        }
    };

    linearProgressWithLabel(value) {
        return (
              <Grid container direction="column" justifyContent="center">
                <Grid container direction="column" alignItems="center" style={{maxWidth: 150}}>
                    <LinearProgressCustom variant="determinate" value={value} style={{width: "100%"}}/>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        value,
                    )}%`}</Typography>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                    (chargement...)
                </Grid>
              </Grid>
        );
      }

    render() {
        const { hover, uploading } = this.state;
        const { allState } = this.props;
        const typeUpload = this.props.input.typeUpload ? this.props.input.typeUpload : 'csv' ;
        return (
            <div style={{ paddingTop: 4, paddingBottom: 15, backgroundColor: 'rgb(250, 251, 251)', marginBottom: 16 }} >
                {
                    typeUpload === "csv" && this.props.input.downloadType ?
                        <WrapperButton onClick={() => this.handlerMutationExport()}>{this.props.t("products.categories.importation.dlexemple")}</WrapperButton>
                    : null
                }
                {
                    <Grid container direction="column" justifyContent="center" spacing={0} key={`ItemForm0`}>
                        <Grid container direction="row" spacing={1}>
                            <Dropzone 
                                onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, this.props.input.stateName)}} 
                                onDragEnter={this.onDragEnter} 
                                onDragLeave={this.onDragLeave} 
                                multiple={false}
                            >
                                {({getRootProps, getInputProps}) => (
                                    <SectionCustom hover={hover}>
                                        <div {...getRootProps()} style={{
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <input {...getInputProps()} disabled={uploading} />
                                            {   
                                                this.props.input.translated ? (
                                                    allState[allState.currentLang][this.props.input.stateName] ? 
                                                    (
                                                        <>
                                                            <img src={this.state.pictos[typeUpload]} alt="Picto upload" />
                                                            <div style={{textAlign: "center"}}>{ uploading ? this.props.input.typeUpload === "only-zip" ? this.linearProgressWithLabel(this.state.progressValue) : '(chargement...)' : allState[allState.currentLang][this.props.input.stateName].file.name  }</div>
                                                            <div style={{ textDecoration: 'underline', color: '#0273A5', marginTop: 5 }}>
                                                                Changer de fichier
                                                            </div>
                                                        </>
                                                    )
                                                    : 
                                                    (
                                                        <>
                                                            <img src={this.state.pictos[typeUpload]} alt="Picto upload" />
                                                            <div>{ uploading ? this.props.input.typeUpload === "only-zip" ? this.linearProgressWithLabel(this.state.progressValue) : '(chargement...)' : this.props.input.typeUpload ? this.props.input.typeUpload === "xls" ? `+ ${this.props.t('products.list.importation.addfiles')} (.${this.props.input.typeUpload} .xlsx)` : `+ ${this.props.t('products.list.importation.addfiles')} (.${this.props.input.typeUpload === "only-zip" ? "zip" : this.props.input.typeUpload})` : '+ Ajouter un fichier (.csv)' }</div>
                                                            <div style={{ textDecoration: 'underline', color: '#0273A5', marginTop: 5 }}>
                                                                {this.props.t('products.categories.importation.selection')}
                                                            </div>                         
                                                        </>
                                                    )
                                                ) :
                                                (
                                                    allState[this.props.input.stateName] ? 
                                                    (
                                                        <>
                                                            <img src={this.state.pictos[typeUpload]} alt="Picto upload" />
                                                            <div style={{textAlign: "center"}}>{ uploading ? this.props.input.typeUpload === "only-zip" ? this.linearProgressWithLabel(this.state.progressValue) : '(chargement...)' : allState[this.props.input.stateName].file.name }</div>
                                                            <div style={{ textDecoration: 'underline', color: '#0273A5', marginTop: 5 }}>
                                                                Changer de fichier
                                                            </div>
                                                        </>
                                                    )
                                                    : 
                                                    (
                                                        <>
                                                            <img src={this.state.pictos[typeUpload]} alt="Picto upload" />
                                                            <div>{ uploading ? this.props.input.typeUpload === "only-zip" ? this.linearProgressWithLabel(this.state.progressValue) : '(chargement...)' : this.props.input.typeUpload ? this.props.input.typeUpload === "xls" ? `+ ${this.props.t('products.list.importation.addfiles')} (.${this.props.input.typeUpload} .xlsx)` : `+ ${this.props.t('products.list.importation.addfiles')} (.${this.props.input.typeUpload === "only-zip" ? "zip" : this.props.input.typeUpload})` : `+ ${this.props.t('products.list.importation.addfiles')} (.csv)` }</div>
                                                            <div style={{ textDecoration: 'underline', color: '#0273A5', marginTop: 5 }}>
                                                            {this.props.t('products.categories.importation.selection')}
                                                            </div>                         
                                                        </>
                                                    )
                                                ) 
                                            }    
                                            {
                                                this.state.error ? <Typography variant='body1' color="error" style={{marginTop: 8}}>Fichier trop lourd limite ({this.props.input.noMapping ? '300' : process.env.REACT_APP_FILE_UPLOAD_MAX.slice(0,3)} MB)</Typography> : null
                                            }                    
                                        </div>
                                    </SectionCustom>
                                )}
                            </Dropzone>
                            {
                                uploading ? 
                                    <Typography variant='body2' style={{marginTop: 8}}>Lors du chargement du fichier veuillez ne faire aucune action. <br/>(Pas de reload ni de changement de page)</Typography>
                                : null
                            }
                        </Grid>
                    </Grid>
                }
                {
                    uploading ? 
                        <OverlayUpload />
                    : null
                }
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withTranslation()(connect(null, mapDispatchToProps)(UploadFile));