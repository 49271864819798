import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING  } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { Box, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, TextField } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import { eventService } from '../../../../js/services/event.service';
import Button from '../../../ui/button/Button';
import { withApollo } from 'react-apollo';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import CardMerchandisingMain from '../../../layouts/Card/cardContent/CardMerchandisingMain';
import CardCustom from '../../../layouts/Card/CardCustom';
import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { GET_PRODUCTS_SEARCH_PAGINATION, GET_PRODUCTS_SEARCH_PAGINATION_NO_VARIANTS } from '../../../../queries/products';
import CloseIcon from '@material-ui/icons/Close';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import NoImage from '../../../../assets/images/not-found.png';
import { ROUTE_PRODUCTS_COVER_RATE_DETAIL } from '../../../../js/constants/route-names';
import request from '../../../../js/utils/fetch';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';

const TextFieldCustom = styled(TextField)`
    &:hover{
        fieldset{
            border-color: rgba(0, 0, 0, 0.23);
        }
    }
    input, .MuiSelect-select{
        font-size: 14px;
        padding: 13px;
        padding-right: 120px;
    }
    & > p{
        margin: 0;
    }
`;

const WrapperAttr = styled(Grid)`
    max-width: 200px;
    p{
        font-size: 13px;
        text-align: left;
        color: ${colors.black.regular};
    }
`

const ContainerButton = styled(Box)`
    display: flex;
    justify-content: flex-end;

`

let timer = null;

function ProductsCoverRate(props) {
    const [currentLang, setCurrentLang] =  React.useState(props.locales[0].node.code);
    const [loading, setLoading] =  React.useState(false);
    const [search, setSearch] =  React.useState("");
    const [productsCover, setProductsCover] =  React.useState([]);
    const [noResult, setNoResult] =  React.useState(false);

    const goTo = route => {
        props.history.push(route);
    };

    const handleLang = (event) => {
        setCurrentLang(event.target.value)
        eventService.fire();
    };

    const handleGetProducts = () => {
        setLoading(true)
        const getProducts = GET_PRODUCTS_SEARCH_PAGINATION_NO_VARIANTS;
        let productsDataFilters = [];
        let variables = 
        {
            "itemsPerPage": 9, 
            "page": 1,
        };
        variables.sku = search
        productsDataFilters.push(JSON.stringify({
            'identifier': 'product_name',
            'value': search,
            'locale': currentLang,
            'option': null
        }))
        props.client.query({
            query: getProducts,
            variables,
            fetchPolicy: 'no-cache'
        }).then(async result =>{
            let getProductsCover = result.data.researchProducts.edges
            let formData = new FormData();
            for (let product of getProductsCover){
                formData.append('skus[]', product.node.sku)
            }
            await request(`${process.env.REACT_APP_API}/products/coverage`, 'POST', formData, 'multipart/form-data').then(
                (data) => {
                    Object.keys(data).map((keyName,i) => {
                        getProductsCover.find(e => e.node.sku === keyName).moreInfos = data[keyName]
                    })
                }
            );
            if (getProductsCover.length === 0){
                setNoResult(true)
            }
            setProductsCover(getProductsCover)
            setLoading(false)
        })
    }

    const handleKeyPress = (event) => {
		if (event.key === 'Enter')
            handleGetProducts();
	};

    useEffect(() => {
		document.addEventListener("keydown", handleKeyPress, false);

		return () => document.removeEventListener("keydown", handleKeyPress, false);
	}, [search]);

    return (
        <div>
            <TopPanel
                icomoon         = {"ico-cover-rate"} 
                colorIcomoon    = {colors.blue.darker.hue300}
                title           = {props.t("spread.cover_rate.title")}
                subtitle        = {props.t("spread.cover_rate.subtitle")}
                gradientColor1  = {colors.menu.regular} 
                gradientColor2  = {colors.menu.darker} 
                // openForm        = {openForm}
                currentLang     = {currentLang} 
                handleLang      = {handleLang} 
                locales         = {props.locales}
                // handlerAdd      = {()=> initForm()} 
                // textAdd         = {props.t("spread.merchandising.textAdd")}
                hasBorder={true}
            />
                <Grid container>
                    <AccordionCustom defaultExpanded={true} title={props.t('products.list.filter.searchCover')}>
                    <Typography variant={"body2"} style={{fontSize: 14, marginBottom: 8, color: colors.black.regular}}>{props.t('products.cover_rate.search')}</Typography>
                        <Grid container justifyContent="center" alignItems="center" style={{position:"relative"}}>
                            <Grid item xs={10}>
                                <TextFieldCustom
                                    id={"search-input"}
                                    variant="outlined"
                                    value={search}
                                    onChange={evt => {
                                        setSearch(evt.target.value);
                                    }}
                                    name={"search-input"}
                                    type={"text"}
                                    placeholder={"Recherche"}
                                    style={{width: '100%'}}
                                />
                                {
                                    search && search !== '' ?
                                        <Box style={{cursor:"pointer",position: "absolute", right: 20, top: "50%", transform:"translateY(-50%)", display:"flex", flexDirection:"row", alignItems:"center"}} onClick={() => setSearch('')}>
                                            <CloseIcon style={{fill: "#E01B22"}}/>
                                            <Typography variant='body2' style={{fontWeight: "bold", color: "#E01B22", lineHeight: "24px"}}>Supprimer</Typography>
                                        </Box>
                                    : null
                                }
                            </Grid>
                            <Grid item xs={2}>
                                <Box style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                    <Button disabled={search && search !== '' ? false : true} style={{width: "100%", margin: 0 , marginLeft: 16, padding: "14px 32px"}} onClick={() => handleGetProducts()}>Rechercher</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </AccordionCustom>
                    {
                        !loading ?
                            noResult ?
                                <Typography>Pas de résultats</Typography>
                            :
                                <Grid container spacing={3} style={{margin: 0, marginTop: 8}}>
                                    {
                                        productsCover.map(product => {
                                            let name = getTraductionAttributs('product_name', product.node.productDatas.edges, currentLang);
                                            let getImage = getTraductionAttributs('product_image', product.node.productDatas.edges, currentLang, 'image');
                                            return(
                                                <Grid item lg={4} md={6} sm={6} xs={6}>
                                                    <CardCustom style={{width: "100%", height: "100%",  padding: "0"}} cardContentStyle={{height: "100%"}} contentpadding={'0'} hovercard={false} collapse={true}>
                                                        <Grid container style={{padding: 16, height: "100%"}}>
                                                            <Grid item xs={5} style={{position:'relative', display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                                                <img src={getImage !== '' && getImage!== null && getImage ? `${process.env.REACT_APP_MEDIAS}/${getImage.filePath}` : NoImage} style={{maxHeight: 180, maxWidth: '100%'}} alt={name} />
                                                                {/* <StatusRate style={{color: "#E01B22", display: 'flex', alignItems:'center', lineHeight: 1, fontWeight:'bold'}}><PlayArrowIcon style={{fontSize: '18px', transform: "rotate(30deg)",marginTop: 4}}/> 95%</StatusRate> */}
                                                            </Grid>
                                                            <Grid item xs={7} style={{paddingLeft: 16}}>
                                                                <Grid container justifyContent={"space-between"} style={{height: "100%"}}>
                                                                    <Box width={"100%"}>
                                                                        <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                            <Typography variant='body2'>Ref. {product.node.sku}</Typography>
                                                                            <Box style={{width: 'auto'}}>
                                                                                <StatusInfo fontSize={13} size={8} status={product.moreInfos?.status}></StatusInfo>
                                                                            </Box>
                                                                        </Box>
                                                                        <Typography variant='h3' style={{textTransform: "uppercase", marginTop: 4, marginBottom: 12}}>{name}</Typography>
                                                                        <Box>
                                                                            <WrapperAttr container justifyContent='space-between'>
                                                                                <Typography variant='body2'>{props.t('spread.cover_rate.detail.date')} :</Typography>
                                                                                <Typography variant='body2' style={{color: colors.black.regular}}><strong>{product.moreInfos?.[currentLang].marketIntroductionDate}</strong></Typography>
                                                                            </WrapperAttr>
                                                                        </Box>
                                                                        <Box>
                                                                            <WrapperAttr container justifyContent='space-between'>
                                                                                <Typography variant='body2'>{props.t('spread.cover_rate.detail.block_type')} :</Typography>
                                                                                <Typography variant='body2' style={{color: colors.black.regular}}><strong>{product.moreInfos?.[currentLang].articleBloqueQualite}</strong></Typography>
                                                                            </WrapperAttr>
                                                                        </Box>
                                                                    </Box>
                                                                    <ContainerButton pt={2} width="100%" style={{justifyContent: "flex-end", alignSelf: "flex-end"}}>
                                                                        <Button 
                                                                            disableElevation={true} 
                                                                            text={"Voir le détail"} 
                                                                            fontsize={ 16 } 
                                                                            style={{width: "100%", padding: 10, margin: 0}} 
                                                                            onClick={() => goTo(ROUTE_PRODUCTS_COVER_RATE_DETAIL.replace(':sku', product.node.sku))}
                                                                        />
                                                                    </ContainerButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </CardCustom>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                        : <PageLoader />
                    }
                </Grid>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }}),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsCoverRate))));