import React, { useEffect } from 'react';
import { Box, CircularProgress, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import '../../../navigation/DrawerLeft.scss';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import moment from 'moment';
import styled from 'styled-components';
import { get_productSourcePriceWatchers_by_brand } from '../../../../queries/price_watcher';
import { CHECK_IF_CAN_EXPORT_PRODUCTS_NOT_EXPORTED_YET, DELETE_BRAND, DELETE_BRAND_SOURCE_MAPPING, DELETE_BRAND_SOURCE_SCRAP, getBrands, getOneBrand } from '../../../../queries/brands';
import {listSettings,listMappers,perPageOptions} from './config/listBrands.config';
import Listing from '../../../layouts/Listing/Listing';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import Button from '../../../ui/button/Button';
import DialogModal from '../../../ui/dialog/DialogModal';
import { ROUTE_PRODUCTS_BRANDS_LIST, ROUTE_PRODUCTS_LIST_DETAIL, ROUTE_PRODUCTS_PRICE_COMPARE_DETAIL } from '../../../../js/constants/route-names';
import ListingProducts from './components/ListingProducts';
import { eventService } from '../../../../js/services/event.service';
import ImgNotFound from '../../../../assets/images/image-fake-card.png';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import TextEllipsis from '../../../layouts/Card/cardContent/TextEllipsis';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import { Alarm, Edit, Notifications } from '@material-ui/icons';
import RemoveIcon from '@material-ui/icons/Remove';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { CursorPagination } from '../../../../js/utils/pagination';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import request from '../../../../js/utils/fetch';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import formBrand from './config/formBrand.config';
import * as formHelper from '../../../../js/helpers/form'
import axios from 'axios';
import { saveElement, updateElement } from '../../../../js/utils/functions';

const ContainerImage = styled(Box)`
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    border: 1px solid ${colors.blue.lighter.hue600};
`

const WrapperAttr = styled(Grid)`
    &:not(:last-child){
        margin-bottom: 8px;
    }
    > p:first-child{
        min-width: 120px;
    }
    p{
        font-size: 13px;
        text-align: left;
        color: ${colors.black.regular};
    }
`
const WrapperAlert = styled(Grid)`
    svg{
        font-size: 16px;
        margin-right: 4px;
        fill: ${props => props.isactive ? colors.green.regular : colors.grey.lighter.hue600};
    }
    color: ${props => props.isactive ? colors.green.regular : colors.grey.lighter.hue600};
`

const TableRowDataCustom = styled(TableRow)`
    display: inline-block;
    cursor: pointer;
    &:hover{
        background-color: ${colors.grey.lighter.hue980};
    }
    td{
        border-top: 1px solid ${colors.grey.lighter.hue700};
        border-color: ${colors.grey.lighter.hue700};
    }
    td:first-child{
        border-left: 1px solid ${colors.grey.lighter.hue700};
    }
    td:last-child{
        border-right: 1px solid ${colors.grey.lighter.hue700};
    }
`;

const TableRowCustom = styled(TableRow)`
    td,th{
        border-color: #EDEEEE;
        white-space: nowrap;
        line-height: 1rem;
        padding: 8px;
    }
    th{
        color: ${colors.grey.border};
        font-weight: bold;
        background: ${colors.white};
    }
`
const TableBodyCustom = styled(TableBody)`
    .table-row{
        cursor: pointer;
        color: inherit;
        display: table-row;
        outline: 0;
        vertical-align: middle;
        text-decoration: none;
        td,th{
            padding: 8px;
        }
    }
`

const TableCellCustom = styled(TableCell)`
    font-size: 20px;
    font-weight: bold;
`

const ValueItem = styled.p`
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:14px;
    line-height: 17px;
    font-weight: normal;
    margin: 4px 0;
    text-align: center;
    strong{
        display:flex;
        align-items: center;
    }
    svg{
        font-size:14px;
        margin-left: 4px;
    }
`

function BrandsDetail(props) {
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [openForm, setOpenForm] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [reloadBrand, setReloadBrand] =  React.useState(false);
    const [searchNameLocal, setSearchNameLocal] =  React.useState(null);
    const [searchName, setSearchName] =  React.useState(null);

    const [brands, setBrands] = React.useState(null);
    const [oneBrand, setOneBrand] = React.useState(null);
    const [loading, setLoading] = React.useState(true);    
    const [loadingCanExport, setLoadingCanExport] = React.useState(false);    
    const [customState, setCustomState] = React.useState(null);
    const [errors, setErrors] =  React.useState({});
    const [seeErrors, setSeeError] =  React.useState(false);
    const [canExportNotExported, setCanExportNotExported] =  React.useState(false);

    const [loadingList, setLoadingList] = React.useState(true);
    const [prices, setPrices] = React.useState([]);

    const [localStorageFilters, setLocalStorageFilters] = React.useState(null);

    const [pagination, setPagination] = React.useState({
        perPage: 10,
        page: 0,
        startCursor: null,
        endCursor: null,
        count: 0
    });
    const [states, setStates] =  React.useState({
        brandId: null,
        brandImage:null,
        brandName: null,
        brandDesc: null,
        brandLink: null,        
        brandRows:[],
        brandEcartNegative :null,
        brandEcartPositive :null,                                
        mapOn:[],
        isActive: true,
        isActiveAlert:true
    });

    function initBrand(){
        setLoadingCanExport(true)
        props.client.query({
            query: getOneBrand,
            fetchPolicy: "no-cache",
            variables: {"id": `/api/product-sources/${props.match.params.id}`}
        }).then(result => {
            const brand = result?.data?.productSource                     
            const rows = brand?.productSourceScraps?.edges.map((s)=>{
                const row = {
                    new: false,
                    changed: false,
                    code: s.node.name,
                    values: null,
                    operator: null,
                    attributeType: null,
                    id: s.node.id,
                    checkbox: false,
                    typingChips: null,
                    showButton: false,
                }                
                return row
            })

            let mapOn =[]            
            Object.keys(brand?.productSourceMappings?.edges).map((m)=>{
                const element = brand?.productSourceMappings?.edges[m].node                
                mapOn[element.attribute.id] = element?.productSourceScrap?.name                
            })  
            
            const newStates = {
                brandId: brand.id,
                brandImage:brand.media,
                brandName: brand.libelle,
                brandDesc: brand.description,
                brandLink: brand.link,        
                brandRows:rows,
                brandEcartNegative :null,
                brandEcartPositive :null,                                
                mapOn:mapOn,
                isActive: brand.status,
                isActiveAlert:brand.activeAlert,
            }
            setStates(newStates)            
            setOneBrand(result.data.productSource);
            props.client.query({
                query: CHECK_IF_CAN_EXPORT_PRODUCTS_NOT_EXPORTED_YET,
                fetchPolicy: "no-cache",
                variables: {"productSources_id": parseInt(props.match.params.id)}
            }).then((result2) => {
                setCanExportNotExported(result2?.data.productSourceWorkflows?.totalCount)
                setLoadingCanExport(false)
                setLoading(false)
            })
        }); 
    }

    const handlerMutation = async () => {
        console.log('errors', errors)
        if (hasErrors()) {
            props.snack(ALERT_ERROR, props.t("spread.active_assets.toastCheckField"));
            return eventService.fire();
        }
        props.startLoading();
        let variables = null;        
        variables={             
            id:oneBrand.id,   
            libelle: states.brandName, 
            description: states.brandDesc,                
            media:states.brandImage?.id,
            status:states.isActive,
            isDisplay:true,
            isBrand:true,                
            activeAlert: states.isActiveAlert,
            link: states.brandLink,
            brandEcartPositive:states.brandEcartPositive,
            brandEcartNegative:states.brandEcartNegative
        }

        try{
            let updateBrand = await updateElement(states,'productSource', variables, oneBrand,{enableLoad:false, setDates:false})
            let updateSources
            if (updateBrand){                    
                Promise.all(states.brandRows.map(async(row)=>{
                    if (row.new) {
                        let scrapVariables = {
                            name:row.code,
                            link:row.code,
                            codeScrap:row.code,
                            productSource:oneBrand.id,
                        }
                        try {
                            const createSource = await saveElement('productSourceScrap', scrapVariables, {enableLoad:false, setDates:false})                                                                                                                
                            Object.keys(states.mapOn).map(async(m)=>{
                                if (row.code === states.mapOn[m]) {
                                    let mapVariables = {
                                        productSource:oneBrand.id,
                                        attribute:m,
                                        productSourceScrap:createSource.id,
                                    }
                                    try {
                                        await saveElement('productSourceMapping', mapVariables, {enableLoad:false, setDates:false})                                                                                    
                                    } catch (error) {
                                        props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création de la marque');
                                        console.log(error)
                                    }
                                }                                
                            })                        
                        } catch (error) {
                            props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création de la marque');
                            console.log(error)
                        }
                    }
                    if (row.changed) {                        
                        let scrapVariables = {
                            name:row.code,
                            link:row.code,
                            codeScrap:row.code,
                            productSource:updateBrand.id,
                        }
                        try {
                            updateSources = await updateElement(states,'productSourceScrap', scrapVariables, oneBrand,{enableLoad:false, setDates:false})                                                                                                                
                            Object.keys(states.mapOn).map(async(m)=>{    
                                if (row.code === states.mapOn[m]) {                    
                                    let mapVariables = {
                                        productSource:updateBrand.id,
                                        attribute:m,
                                        productSourceScrap:updateSources.id,
                                    }
                                    try {
                                        await updateElement(states,'productSourceMapping', mapVariables, oneBrand,{enableLoad:false, setDates:false})                                                                                    
                                    } catch (error) {
                                        props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la modification de la marque');
                                        console.log(error)
                                    }
                                }
                            })                        
                            
                        } catch (error) {
                            props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la modification de la marque');
                            console.log(error)
                        }
                    }
                    // if (states.mapOn.length !== oneBrand.productSourceMappings.edges.length) {
                    //     Object.keys(states.mapOn).map(async(m)=>{    
                    //         if (row.code === states.mapOn[m]) {                    
                    //             let mapVariables = {
                    //                 productSource:updateBrand.id,
                    //                 attribute:m,
                    //                 productSourceScrap:updateSources.id,
                    //             }
                    //             try {
                    //                 await updateElement(states,'productSourceMapping', mapVariables, oneBrand,{enableLoad:false, setDates:false})                                                                                    
                    //             } catch (error) {
                    //                 props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la modification de la marque');
                    //                 console.log(error)
                    //             }
                    //         }
                    //     })   
                    // }
                    if (row.deleted) {
                        oneBrand.productSourceMappings.edges.map(async(m)=>{    
                            if (row.code === m.node.productSourceScrap.name) {   
                                console.log(m)            
                                const isValueChange = []
                                Object.keys(states.mapOn).map((e)=>{
                                    if (e === m.node.attribute.id) {
                                        isValueChange.push({
                                            [e]:states.mapOn[e]
                                        })
                                    }                                    
                                })
                                console.log(isValueChange)
                                // await deleteSourceMap(s.node.id)
                                try {
                                    
                                } catch (error) {
                                    props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la modification de la marque');
                                    console.log(error)
                                }
                            }
                        })   
                        // await deleteSource(row.id)
                    }
                }))
                setReloadBrand(true)
                setOpenForm(!openForm)
                props.snack(ALERT_SUCCESS, 'Marque modifiée avec succès');
            }
        }catch(err){
            props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la modification de la marque');
            console.log(err)
        }
        
        props.stopLoading();
    };

    //--- call 1 fois dans le cycle de vie du composant
    useEffect(() => {
        initBrand();
        let oneBrandVariables = {
            // value: diff_value,
            date: moment().format("YYYY-MM-DD"),
            nbperpage: pagination?.perPage,
            //---- permets de chercher les produits par source :
            productSourcePriceWatchers_source: `/api/product-sources/${props.match.params.id}`,
            //--- permets de chercher uniquement les produits ayant des écarts : 
            productSourcePriceWatchers_hasDiff: true
        };
        getPricesOneBrand(get_productSourcePriceWatchers_by_brand, oneBrandVariables, false);
    }, [])

    const realoadBrand = async ()=>{
        setLoading(true)
        await initBrand()
    }


    const goTo = (route) => {
        props.history.push({
            pathname: route,
        });
    };

    const handleToggleDrawer = () => {
        setOpenForm(!openForm)
    };

    const initForm=()=>{
        handleToggleDrawer('openForm');
    }

    const getBrandsCallback = () => {
        setReloadBrand(false)
    }
    const filterBrands = () => {
        setSearchName(searchNameLocal)
    };


    const deleteSourceMap = (id)=>{         
        return new Promise((resolve, reject) => {
            props.client.query({
                query: DELETE_BRAND_SOURCE_MAPPING,
                fetchPolicy: 'no-cache',
                variables: {
                    "id": id
                }
            }).then(()=>{
                resolve()
            }).catch((e)=>{  
                reject(e)
            })
        });
    }

    const deleteSource = (id)=>{
        return new Promise((resolve, reject) => {
            props.client.query({
                query: DELETE_BRAND_SOURCE_SCRAP,
                fetchPolicy: 'no-cache',
                variables: {
                    "id": id
                }
            }).then(()=>{
                resolve()
            }).catch((e)=>{  
                reject(e)
            })
        });
    }
    
    const deleteBrand = () => {
        if (oneBrand) {            
            props.startLoading();
            try {                
                Promise.all(oneBrand?.productSourceMappings?.edges.map(async (s) => {
                    await deleteSourceMap(s.node.id)
                }))
                .then(()=>{
                    Promise.all(oneBrand?.productSourceScraps?.edges.map(async(sourceScrap) => {
                       await deleteSource(sourceScrap.node.id)
                   }))
                   .then(()=>{
                        props.client.query({
                          query: DELETE_BRAND,
                          fetchPolicy: 'no-cache',
                          variables: {
                              "id": `/api/product-sources/${props.match.params.id}`
                          }
                      }).then(()=>{
                          props.history.push({
                              pathname: ROUTE_PRODUCTS_BRANDS_LIST,
                          })
                          props.stopLoading();
                          props.snack(ALERT_SUCCESS, `Marque supprimée avec succès`);             
                      })
                   })              
                })
            } catch (error) {
                props.stopLoading();
                props.snack(ALERT_ERROR, `Un problème est survenu lors de la suppression!`);
                console.log(error)
            }            
        }
    };

    const handleLang = (event) => {
        setCurrentLang(event.target.value);
        eventService.fire();
    };

    const handleExportProducts = () => {
        try {
            props.startLoading();
            let data = new FormData();
            data.append('name', 'export-produits-marques-validated');
            data.append('format', 'csv');
            data.append('locale', 'fr_FR');
            data.append('status', 'all');
            data.append('image', 'all');
            data.append('onlyNew', false);
            data.append('productSourceWorkflowCompleted', true);
            data.append('productSourceWorkflowExported', true);
            data.append('productSourceIds[]', props.match.params.id);

            let urlencoded = new URLSearchParams(data).toString();

            request(`${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`, 'get', null, 'application/x-www-form-urlencoded')
                .then(async (data) => {
                    if (data.success) {
                        window.open(`${process.env.REACT_APP_API_ROOT}/medias/export/${data.mediaObject.filePath + '.csv'}`, '_blank');
                        props.stopLoading();
                        props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
                    } else {
                        props.stopLoading();
                        props.snack(ALERT_ERROR, `Un problème est survenu lors de l'exportation !`);
                    }

                });
        } catch (e) {
            props.snack(ALERT_ERROR, `L'exportation a échoué !`);
            props.stopLoading();
        }
    };
    const handleExportProductsNotExported = () => {
        try {
            props.startLoading();
            let data = new FormData();
            data.append('name', 'export-produits-marques-notExported');
            data.append('format', 'csv');
            data.append('locale', 'fr_FR');
            data.append('status', 'all');
            data.append('image', 'all');
            data.append('onlyNew', false);
            data.append('productSourceWorkflowCompleted', true);
            data.append('productSourceWorkflowExported', false);
            data.append('productSourceIds[]', props.match.params.id);

            let urlencoded = new URLSearchParams(data).toString();

            request(`${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`, 'get', null, 'application/x-www-form-urlencoded')
                .then(async (data) => {
                    if (data.success) {
                        window.open(`${process.env.REACT_APP_API_ROOT}/medias/export/${data.mediaObject.filePath + '.csv'}`, '_blank');
                        props.stopLoading();
                        props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
                    } else {
                        props.stopLoading();
                        props.snack(ALERT_ERROR, `Un problème est survenu lors de l'exportation !`);
                    }

                });
        } catch (e) {
            props.snack(ALERT_ERROR, `L'exportation a échoué !`);
            props.stopLoading();
        }
    };

    const resetPagination = () => {
        return {
            perPage: 10,
            page: 0,
            startCursor: null,
            endCursor: null,
            count: 0
        };
    }

    const getPricesOneBrand = (query = get_productSourcePriceWatchers_by_brand, variables = { date: moment().format("YYYY-MM-DD") }, pageInfo) => {
        setLoadingList(true);
        props.client.query({
            query,
            fetchPolicy: "no-cache",
            variables
        }).then(result => {

            let getPrices = result.data.products.edges;

            let page = pageInfo || pagination;

            let newPagination = {
                ...page,
                ...result.data.products.pageInfo,
                count: result.data.products.totalCount
            };

            if (!pageInfo)
                pageInfo = resetPagination();

            setPagination(newPagination);
            setPrices(getPrices);
            setLoadingList(false);
        });
    }

    const getListingValues = (array, type) => {
        return (
            array.map((value2, index2) => {
                switch (type) {
                    case 'date':
                        return (
                            <ValueItem key={index2}><strong>{moment(value2.node.created_at).format('DD/MM/YYYY')}</strong></ValueItem>
                        );
                    case 'retailer':
                        return (
                            <Grid key={index2} container justifyContent='space-between' alignItems='center' style={{maxWidth: 250}}>
                                <ValueItem>{value2.node.source.libelle}</ValueItem>
                                <ValueItem>
                                    <strong>
                                        {value2.node.product_price.toFixed(2)}EUR</strong>
                                </ValueItem>
                            </Grid>
                        )
                    case 'diff':
                        return (
                            <ValueItem key={index2} style={{justifyContent:"flex-end"}}>
                                <strong>{value2.node.source_price.toFixed(2)}EUR &nbsp;</strong>
                                ({value2.node.diffPercent <= 0 ? '' : '+'}{value2.node.diffPercent.toFixed(2)}%) 
                                {value2.node.diff_flat == 0 ? <RemoveIcon style={{ fill: "#C9C9C9" }} /> : value2.node.diff_flat < 0 ? <CallMadeIcon style={{ fill: colors.green.regular, transform: 'rotate(-45deg)' }} /> : <CallMadeIcon style={{ fill: colors.red.regular, transform: 'rotate(135deg)' }} />}
                            </ValueItem>
                        )

                    default:
                        break;
                }
            })
        )
    };

    const searchPrices = (pageInfo) => {
        let page = pageInfo;

        if (!page)
            page = resetPagination();

        let variables = {
            productSourcePriceWatchers_source: `/api/product-sources/${props.match.params.id}`,
            productSourcePriceWatchers_hasDiff: true,
            date: moment().format("YYYY-MM-DD"),
            nbperpage: page?.perPage,
            cursor: page?.startCursor,
            cursorLast: page?.endCursor,
        };
        getPricesOneBrand(get_productSourcePriceWatchers_by_brand, variables, page);
    }

    const changePage = newPage => {
        let newPagination = {
            ...pagination,
            page: newPage,
            startCursor: newPage > pagination.page ? pagination.endCursor : null,
            endCursor: newPage > pagination.page ? null : pagination.startCursor
        };

        setPagination(newPagination);
        searchPrices(newPagination);
    };

    const changePerPage = perPage => {
        let newPagination = {
            ...resetPagination(),
            perPage
        };

        setPagination(newPagination);
        searchPrices(newPagination);
    };

    const handleMediaPicker=(selected,stateName)=>{        
        let getState = {...states};        
        getState[stateName] =  selected
        setBrands(getState)
    }

    const handleFormError = (stateName, error) => {
        let getErrors = errors;        
        getErrors[stateName] = error;
        setErrors(getErrors);
    };
    const hasErrors = () => {
        if (errors) {
            for (let error in errors) {
                if (errors[error])
                    return true;
            }
        }
        return false;
    };

    const handleNextStep = () =>{
        let result = formHelper.handleStep('next',errors);
        setSeeError(!result)        
        return result;
    }

    const stateCallback = (stateName, value, custom, translated, callback) => {        
        let getState = {...states};        
        getState[stateName] =  value?.target?.value ?? value;
        setStates(getState,callback)
    };

    const handleButtonGroupChange = (stateName, value) => {
        let getState = {...states};
        getState[stateName] = value;
        setStates(getState);
    };


    return (
        <div>
            <TopPanel
                colorIcomoon={colors.blue.darker.hue300}
                title={props.t('products.brands.detail_title')}
                subtitle={props.t('products.brands.detail_subtitle')}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                windowWidth={props.windowWidth}
                currentLang={currentLang}
                locales={props.locales}
                hasBorder={true}
                buttonAvailable={true}
            >
                <Button 
                    text={"Supprimer"}
                    bgcolor={'transparent'}
                    bgcolorhover={'transparent'}
                    color={colors.red.regular}
                    deleteIcon={true}
                    textTransform={'underline'}
                    fontsize={16}
                    fontWeight="bold"
                    style={{ padding: '14px 32px' }}
                    onClick={() => setOpenDialog(true)}
                />
                <Button 
                    text={"Modifier la marque"}
                    bgcolor={colors.green.regular}
                    bgcolorhover={colors.green.darker}                    
                    textTransform={'underline'}
                    fontsize={16}
                    fontWeight="bold"
                    style={{ padding: '14px 32px' }}
                    onClick={() => setOpenForm(true)}
                />
            </TopPanel>
            <Grid container direction='column'>
                <AccordionCustom forcedExpanded={true} title={"Informations liées"} custommargin={"16px 22px 22px"} style={{ borderRadius: 0 }}>
                    {
                        !loading ?
                        oneBrand ?
                                <Grid container spacing={2}>
                                    <Grid item xs={8} style={{flex: 1}}>
                                        <Typography variant='h2' container='h3' style={{ fontWeight: 'bold', marginBottom: '5px', fontSize: "25px", marginBottom: "6px" }}>{oneBrand.libelle}</Typography>
                                        <Box style={{maxWidth: 715}} mb={5}>
                                            <TextEllipsis 
                                                title={oneBrand.libelle}
                                                text={oneBrand.description || props.t('spread_builder.asset_view.notDescription')}
                                                hasModal={true}
                                            />
                                        </Box>
                                        <Grid container direction='row' alignItems='flex-start' justifyContent='space-between'>
                                            <Grid item xs={6}>
                                                <WrapperAttr container>
                                                    <Typography>Statut :</Typography>
                                                    <StatusInfo status={oneBrand.status} width={'auto'} />
                                                </WrapperAttr>
                                                <WrapperAttr container>
                                                    <Typography>Synchronisation :</Typography>
                                                    <StatusInfo status={oneBrand.status} width={'auto'} />
                                                </WrapperAttr>
                                                <WrapperAttr container>
                                                    <Typography>Accès directe :</Typography>
                                                    <a href={oneBrand?.link} target={"__blank"}><Typography variant="body1"><strong>{oneBrand?.link}</strong></Typography></a>
                                                </WrapperAttr>
                                                <WrapperAttr container>
                                                    <Typography>Produits complets :</Typography>
                                                    <Typography variant="body1"><strong>{oneBrand?.product?.totalCount - oneBrand?.productSourceProductStates?.totalCount}</strong></Typography>
                                                </WrapperAttr>
                                                <WrapperAttr container>
                                                    <Typography>Produit à compléter :</Typography>
                                                    <Typography variant="body1"><strong>{oneBrand?.productSourceProductStates?.totalCount}</strong></Typography>
                                                </WrapperAttr>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <WrapperAttr container>
                                                    <Typography>Alerte écarts :</Typography>
                                                    <Grid item>
                                                        <WrapperAlert container isactive={oneBrand.activeAlert}>
                                                            <Grid item style={{display: 'flex', alignItems: 'center'}}><Notifications /></Grid>
                                                            <Grid item style={{textDecoration:'underline'}}><strong>{oneBrand.activeAlert ? 'Activée' : 'Désactivée'}</strong></Grid>
                                                        </WrapperAlert>
                                                    </Grid>
                                                    {/* <StatusInfo status={oneBrand.activeAlert} width={'auto'} /> */}
                                                </WrapperAttr>
                                                <WrapperAttr container>
                                                    <Typography>Sites référents :</Typography>
                                                    <Box>
                                                        {
                                                            oneBrand?.productSourceScraps?.edges?.map((value2, index) => (
                                                                <Typography key={index} variant="body1" style={{width: "100%"}}><strong>{value2.node.name}</strong></Typography>
                                                            ))
                                                        }
                                                    </Box>
                                                </WrapperAttr>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider variant='vertical' style={{marginLeft: 24, marginRight: 24}}/>
                                    <Grid item xs={4} style={{alignSelf: 'center'}}>
                                        <Typography variant='h3'>Réglages du scrapping</Typography>
                                        <Box width="100%" mt={2.5} mb={2.5}>
                                            {oneBrand?.productSourceMappings?.edges?.map((value, index) => {                                                
                                                return (
                                                    <WrapperAttr key={index} container style={{justifyContent: "space-between"}}>
                                                        <Typography>{value.node.attribute.identifier} :</Typography>
                                                        <Typography variant="body1"><strong>{value?.node?.productSourceScrap?.name}</strong></Typography>
                                                    </WrapperAttr>
                                                )
                                            })}
                                        </Box>
                                        {/* <Box width="100%" mt={2.5} mb={2.5}>
                                            <Button
                                                style={{
                                                    width: '100%',
                                                    height: '40px',
                                                    margin: '0px',
                                                    maxWidth: 235
                                                }}
                                                text={props.t("spread_builder.asset_view.edit")}
                                                icon={
                                                    <Edit style={{ marginRight: '5px', fontSize: '15px' }}></Edit>
                                                }
                                                color={colors.blue.darker.hue300}
                                                colorHover={colors.white}
                                                bgcolorhover={colors.blue.lighter.hue600}
                                                bgcolor={colors.blue.lighter.hue900}
                                                border={`1px solid ${colors.blue.darker.hue300}`}
                                                stopPropagation={true}
                                                // onClick={() => { this.handleToggleDrawer('openEditForm', true) }}
                                            />
                                        </Box> */}
                                    </Grid>
                                </Grid>
                                :
                                <Typography>Pas de datas</Typography>
                        : 
                        <PageLoader />
                    }
                    
                </AccordionCustom>
                <Grid container style={{marginTop: 16}}>
                    <AccordionCustom scrollable={false} title={<Typography variant={"h4"} style={{ fontSize: 14 }}>Liste des produits avec un écart</Typography>}>
                        {
                            loadingList ? <PageLoader />
                            :
                            prices.length > 0 ?
                                <Grid container>
                                    <TableContainer component={Paper} style={{maxHeight: 'calc(144px * 5)', boxShadow: 'none'}}>
                                        <Table aria-label="headerList" stickyHeader>
                                            <TableHead>
                                                <TableRowCustom>
                                                    <TableCell>Listes des produits</TableCell>
                                                    <TableCell align="left">Mon prix</TableCell>
                                                    <TableCell align="center">Date</TableCell>
                                                    <TableCell align="center" style={{ width: 250 }}>Information sites référents</TableCell>
                                                    <TableCell align="right">Ecart de mon prix par <br/>rapport au référent</TableCell>
                                                    {/* <TableCell align="right">Écart(%)</TableCell> */}
                                                </TableRowCustom>
                                            </TableHead>
                                            <TableBodyCustom>
                                                {
                                                    prices?.map((value, index) => {
                                                        return (
                                                            <TableRowDataCustom key={index} className='table-row'>
                                                                <TableCell style={{ fontSize: 16}}>
                                                                    <Box width={320}>
                                                                        <Grid container alignItems='center' direction='row' wrap='nowrap'>
                                                                            <Grid item style={{ background: "#F7F7F7" }}>
                                                                                <img src={`${process.env.REACT_APP_MEDIAS}/${value.node.productDatas?.edges.find(e => e.node.attribute.identifier === 'product_image')?.node?.media?.filePath}`} style={{ objectFit: 'cover', objectPosition: 'center', width: 125, height: 125 }} />
                                                                            </Grid>
                                                                            <Grid item style={{ marginLeft: 32 }}>
                                                                                <Typography variant="h2" style={{ lineHeight: "17px", maxWidth: 218, textOverflow: 'ellipsis', overflow: 'hidden' }}><strong>{value?.node.productDatas?.edges.find(e => e?.node.attribute.identifier === 'product_name')?.node.value}</strong></Typography>
                                                                                <Typography variant="body1" style={{ fontSize: 16 }}>REF. {value?.node.sku}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCellCustom align="left">{value?.node.productDatas?.edges.find(e => e?.node.attribute.identifier === 'product_price')?.node.value}&euro;</TableCellCustom>
                                                                <TableCellCustom align="center">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'date')}</TableCellCustom>
                                                                <TableCellCustom align="center">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'retailer')}</TableCellCustom>
                                                                {/* <TableCellCustom align="center">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'source_price')}</TableCellCustom> */}
                                                                <TableCellCustom align="right">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'diff')}</TableCellCustom>
                                                                {/* <TableCellCustom align="center">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'diffPercent')}</TableCellCustom> */}
                                                            </TableRowDataCustom>
                                                        )
                                                    })
                                                }
                                            </TableBodyCustom>
                                        </Table>
                                    </TableContainer>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="center">
                                            <CursorPagination
                                                rowLabel={`Résultats par page`}
                                                pagination={pagination}
                                                type="table"
                                                changePageCallback={changePage}
                                                changePerPageCallback={changePerPage}
                                                showPerPage={true}
                                                perPageOptions={['10', '30', '100']}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            : <Typography>Aucun produit n'a d'écart</Typography>
                        }
                    </AccordionCustom>
                </Grid>
                <Grid container style={{marginTop: 16}}>
                    <ListingProducts
                        productBypage = {{
                            card: [12,24,48,72],
                            list: [30,40,50]
                        }}
                        variables = {{
                            productSources: [props.match.params.id]
                        }}
                        isCatalog = {false}
                        nbperpage = {12}
                        currentLang={currentLang} 
                        handleLang={handleLang}
                        windowWidth={props.windowWidth}
                        openForm={openForm}
                        handlerExport={handleExportProducts}
                        handlerExportText={"Exporter tous les produits validés"}
                        loadingSecondExport={loadingCanExport}
                        handlerSecondExport={handleExportProductsNotExported}
                        handlerSecondExportText={canExportNotExported ? "Exporter les produits validés non exportés" : null}
                        reload={reloadBrand}                 
                        onReload={() => realoadBrand()}
                        // callbackFilters={this.callbackFilters}
                        // previousFilters={getItemsLocalStorage("PIM_FILTERS")}
                    />
                </Grid>
            </Grid>
            <DialogModal
                open={openDialog} 
                title={`Êtes-vous sûr de vouloir supprimer cette marque ?`}
                secondaryAction={() => setOpenDialog(false)} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                primaryAction={() => {deleteBrand(); setOpenDialog(false)}}
                primarybgcolor={colors.red.regular} primarybgcolorhover={colors.red.darker} primaryText="Supprimer"
            >
                Si vous supprimez cette marque celle-ci ne sera plus accessible. Si vous ne l'utilisez plus mais que vous ne souhaitez pas la supprimer, annulez la suppression.
            </DialogModal>
            <LayoutBuilder
                    opened={openForm} 
                    forClose={()=>handleToggleDrawer()} 
                    handlerSetup={()=>{}} 
                    dataLayout={formBrand("edit",handleMediaPicker, customState)} 
                    allState={{oneBrand,...states}}    
                    stepperButtonAction={[
                        () => {
                            if (hasErrors()) {
                                props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                setSeeError(true );
                                eventService.fire();
                                return false;
                            }

                            handleNextStep()

                            return true;
                        },
                        handleNextStep, 
                        handleNextStep,
                    ]}
                    stateCallback={stateCallback} 
                    errorCallback={handleFormError}    
                    checkError={() => {}}
                    // // // handleButtonGroupChange={this.handleButtonGroupChange}
                    // validateButton={true}
                    handlerMutation={handlerMutation}
                    handleButtonGroupChange={handleButtonGroupChange}
                />    
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(BrandsDetail)))));