import styled from 'styled-components';

export default styled.div `
    box-sizing: content-box;
    *{
        box-sizing: content-box;
    }
    &.relative{
        position: relative;
    }
    &.absolute{
        position:absolute;
    }
    &.entete{
        padding: 0 1rem;
        @media screen and (min-width: 1024px){
            padding: 0 22px;
        }
        @media screen and (min-width:1450px){
            padding : 0 60px;
        }
    }
    &.containerText{
        &>p{
            margin: inherit;
            font-size: inherit;
        }
    }
    &.bandeau-top{
        z-index: 9;
        background: rgba(0, 14, 40, 0.1);
        p{
            margin: 2px 0 4px 0;
            color: white;
        }
    }
    &.slider-top{
        .swiper-pagination-top{
            position: absolute;
            z-index: 9;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            width: auto;
            .swiper-pagination-bullet{
                background: white;
                opacity: 0.5;
                height: 12px;
                width: 12px;
                margin: 0 2px;
                &.swiper-pagination-bullet-active{
                    opacity: 1;
                }
                &:hover{
                    box-shadow: 0px 0px 10px -5px #000E28;
                }
                @media screen and (min-width: 1024px){
                    height: 18px;
                    width: 18px;
                }
                @media screen and (min-width: 1200px){
                    margin-bottom: 30px;
                }
                @media screen and (max-width: 768px){
                    margin-left: 12px;
                    margin-right: 12px;
                    width: 18px;
                    height: 18px;
                }
            }
        }
        .swiper-slide{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            div.absolute{
                width: 1200px;
                max-width: 80vw;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @media screen and (max-width: 767px){
                    justify-content: center!important;
                    align-items: center!important;
                    top: 50%!important;
                    left: 50%!important;
                    transform: translate(-50%, -50%)!important;
                }
                h2{
                    font-weight: initial;
                    margin: 0;
                    font-family: 'DINOffc-Bold', sans-serif;
                    @media screen and (max-width: 767px){
                        text-align: center!important;
                    }
                    p{
                        margin: 0;
                    }
                }
                h4{
                    font-family: 'DinRegular', sans-serif;
                    margin-top: 8px;
                    line-height: 1.3;
                    @media screen and (max-width: 767px){
                        text-align: center!important;
                    }
                }
                .containerButton{
                    @media screen and (max-width: 767px){
                        justify-content: center!important;
                    }
                    @media screen and (max-width: 600px){
                        flex-direction: column;
                        button{
                            margin: 12px 0!important;
                        }
                    }
                }
                button{
                    font-family: 'DINOffc-Bold', sans-serif;
                    text-decoration: none;
                    padding: 0.75rem 3rem;
                    margin: 0;
                    white-space: nowrap;
                }
                &.row{
                    @media screen and (max-width: 1100px){
                        flex-direction: column!important;
                    }
                }
            }
            @media screen and (max-width: 767px){
                position: relative;
            }
            &>a{
                width: 100%;
                height: auto;
                position: relative;
            }
            video{
                width: 100%;
            }
        }
    }
    &.slider-about{
        .swiper-container-about{
            margin-top: 20px;
            padding-bottom: 20px;
            overflow: inherit;
            @media screen and (min-width: 1024px){
                margin-top: 30px;
            }
        }
        
        .swiper-scrollbar-about{
            max-width: 50%;
            width: 400px;
            margin-left: 50%;
            transform: translateX(-50%);
            display: block;
            background: #E6E7EA;
            border-radius: 0;
            bottom: 0;
            &>div{
                background:#000E28;
                border-radius: 0;
            }
        }
        .about{
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;
            div.absoluteContent{
                position: absolute; 
                bottom: 40px;
                left: 20px;
            }
            a, a > *{
                text-decoration: none;
            }
            h5{
                margin-top: 18px;
                font-family: 'DINOffc-Bold', sans-serif;
                font-size: 1.4375rem;
                line-height: 1.2;
                margin-bottom: 0;
                @media screen and (min-width: 1024px){
                    margin-top: 28px;
                }
            }
            p{
                margin: 8px 0;
                font-family: 'Univers', sans-serif;
                font-size: 1rem;
                line-height: 1.2;
                @media screen and (max-width: 767px){
                    font-size: 1.25rem;
                }
            }
            div a{
                color: #4D5769;
                font-size: 1.25rem;
                line-height: 1.2;
                margin-top: 20px;
                font-family: 'Univers', sans-serif;
                @media screen and (max-width: 767px){
                    font-size: 1rem;
                }
                &:hover{
                    color: #000E28;
                    background: white;
                }
            }
        }
    }
    &.slider-instagram{
        padding: 55px 0 55px;
        .swiper-container-instagram {
            overflow: inherit;
            width: 96.5%;
            margin-left: 3.5%;
            padding-bottom: 30px;
            .swiper-scrollbar-instagram{
                max-width: 50%;
                width: 400px;
                margin-left: 50%;
                transform: translateX(-50%);
                display: block;
                background: #E6E7EA;
                border-radius: 0;
                &>div{
                    background:#000E28;
                    border-radius: 0;
                }
            }
        }
        h4{
            font-size: 3.75rem;
            line-height: 1.2;
            margin: 0;
            font-family: 'DINOffc-Bold', sans-serif;
            @media screen and (max-width: 800px){
                font-size: 2.625rem;
            }
        }
        p{
            max-width: 90%;
            font-family: 'Univers', sans-serif;
            margin: 0 auto 20px;
            font-size: 16px!important; // DONT CHANGE
            line-height: 1.2;
            @media screen and (min-width : 1024px){
                margin-bottom: 40px;
            }
        }
        .swiper-slide{
            &>img{
                width: 74%;
            }
            &>picture {
                width: 74%;
            }
            &>picture img {
                width: 100%;
            }
            &>div{
                width: calc(24% - 5px);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 10px;
                img{
                    max-width: 100%;
                }
                picture img {
                    max-width: 100%;
                }
            }
        }
    }
    &.slider-image{
        padding: 45px 0 55px;
        .swiper-container-image-fullscreen, .swiper-container-image-carre, .swiper-container-image-horizontal {
            overflow: inherit;
            width: 95%;
            margin-left: 5%;
            padding-bottom: 50px;
            padding-top: 30px;
            .swiper-scrollbar-image{
                max-width: 50%;
                width: 400px;
                margin-left: 50%;
                transform: translateX(-50%);
                display: block;
                background: #E6E7EA;
                border-radius: 0;
                &>div{
                    background:#000E28;
                    border-radius: 0;
                }
            }
        }
        h4{
            font-size: 3.75rem;
            line-height: 1.2;
            margin: 0;
            font-family: 'DINOffc-Bold', sans-serif;
            @media screen and (max-width: 800px){
                font-size: 2.625rem;
            }
        }
        p{
            max-width: 90%;
            font-family: 'Univers', sans-serif;
            margin: 0 auto 20px;
            font-size: 16px!important; // DONT CHANGE
            line-height: 1.2;
            @media screen and (min-width : 1024px){
                margin-bottom: 40px;
            }
        }
        .swiper-slide{
            &>img, &>a>img{
                width: 100%;
            }
            &>a.linkText{
                width: 100%;
                color: black;
            }
            &.flexColumn{
                flex-direction: column;
            }
        }
        .title-box{
            width: 90%;
            margin: auto;
            a{
                margin: 0;
            }
        }
    }
    &.swiper-container-bloc-text-media{
        .swiper-scrollbar{
            max-width: 50%;
            width: 400px;
            margin-left: 50%;
            transform: translateX(-50%);
            display: block;
            background: #E6E7EA;
            border-radius: 0;
            bottom: 10px;
            &>div{
                background:#000E28;
                border-radius: 0;
            }
        }

    }
    &.grid-image{        
        &>.container-grid-image{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            &>div{                
                overflow:hidden;
                img{
                    width: 100%;
                }
                video{
                    width: 100%;
                }
            }
        } 
        @media screen and (max-width: 1023px){
            padding: 40px 5% 30px;
        }
        @media screen and (max-width: 767px){
            &>.container-grid-image{
                &>div{
                    flex: 0 0 50%;
                    max-width: calc(100% / 2 - 16px);
                }
            }
        }
        @media screen and (max-width: 1023px){
            &>.container-grid-image{
                &>div{
                    flex: 0 0 25%;
                    max-width: calc(100% / 2 - 16px);
                }
            }
        }
        .title-box{
            width: 100%;
            margin: auto;
            a{
                margin: 0;
            }
        }
    }
    &.bloc-list{
        width: 94%;
        display: block;
        margin: auto;
        padding-top: 50px;
        padding-bottom: 20px;
        @media screen and (min-width: 1024px){
            padding-top: 70px;
            padding-bottom: 30px;
        }
        &>h2{
            color: #000E28;
            font-weight: bold;
            font-family: 'DINOffc-Bold', sans-serif;
        }
        &.bloc-list-products, &.bloc-list-about{
            width: 96.5%;
            margin-left: 3.5%;
        }
    }
    &.list-categories, &.list-collections{
        margin: 20px 0 0;
        display: flex;
        justify-content:flex-start;
        align-items: center;
        flex-direction: column;
        @media screen and (min-width: 800px){
            margin: 10px 0 0;
            align-items: flex-start;
            flex-direction: row;
        }
        h2{
            font-family: 'DINOffc-Bold', sans-serif;
        }
        &>.swiper-container{
            width: 100%;
            .swiper-wrapper{
                padding-bottom: 50px;
            }
            .swiper-scrollbar{
                max-width: 50%;
                width: 400px;
                margin-left: 50%;
                transform: translateX(-50%);
                display: block;
                background: #E6E7EA;
                border-radius: 0;
                &>div{
                    background:#000E28;
                    border-radius: 0;
                }
            }
        }
        .relative{
            width: 100%;
            display: flex;
            margin: 10px 0;
            picture, img.w100{
                position: relative;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                display: block;
            }
            a{
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                text-decoration: none;
                cursor: pointer;
                div{
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    position: relative;
                }
                h3{
                    font-size: 3vw;
                    line-height: 3.8vw;
                    max-width: 90%;
                    font-weight: bold;
                    margin: 0;
                    font-family: 'DINOffc-Bold', sans-serif;
                    @media screen and (max-width: 1023px) and (min-width : 800px){
                        max-width: 260px;
                    }
                    @media screen and (max-width: 1500px) and (min-width : 1024px){
                        max-width: 320px;
                    }
                    @media screen and (max-width: 1024px){
                        font-size: 3.8vw;
                        line-height: 4.5vw;
                    }
                    @media screen and (max-width: 900px){
                        font-size: 3.2vw;
                        line-height: 4vw;
                    }
                    @media screen and (max-width: 799px){
                        font-size: 7vw;
                        line-height: 8vw;
                    }
                    @media screen and (max-width: 500px){
                        font-size: 8vw;
                        line-height: 9vw;
                    }
                }
                h4{
                    font-size: 1.4vw;
                    line-height: 2vw;
                    @media screen and (max-width: 900px){
                        font-size: 1.2vw;
                        line-height: 2vw;
                    }
                    @media screen and (max-width: 799px){
                        font-size: 3vw;
                        line-height: 4vw;
                    }
                    @media screen and (max-width: 500px){
                        font-size: 4vw;
                        line-height: 5vw;
                    }
                }
                p{
                    overflow: hidden;
                    margin: 0;
                    height: 0;
                    transition: all ease 500ms;
                    text-decoration: underline;
                    font-family: 'Univers', sans-serif;
                }
                button{
                    border: 0;
                    font-size: 1vw;
                    line-height: 1.2vw;
                    padding: 1vw;
                    width: 15vw;
                    display: block;
                    margin: 20px auto 0;
                    cursor: pointer;
                    font-family: 'DINOffc-bold', sans-serif;
                    &.bottom{
                        position: absolute;
                        bottom: 0;
                        width: calc(100% - 2vw);
                    }
                    &.withimg{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        text-align: left;
                        img{
                            height: 24px;
                        }
                    }
                    @media screen and (max-width: 1200px){
                        font-size: 1.4vw;
                        line-height: 2vw;
                        padding: 1vw;
                        width: 15vw;
                        margin-top: 40px;
                    }
                    @media screen and (max-width: 799px){
                        font-weight: bold;
                        font-size: 2.4vw;
                        line-height: 3vw;
                        padding: 2vw;
                        width: 30vw;
                        &.bottom{
                            width: calc(100% - 4vw);
                        }
                    }
                    @media screen and (max-width: 500px){
                        font-size: 3.4vw;
                        line-height: 4vw;
                        padding: 3vw;
                        &.bottom{
                            width: calc(100% - 6vw);
                        }
                    }
                }
            }
            .gradient-overlay{
                background: transparent linear-gradient(180deg, #000E2800 0%, #000E28 100%) 0% 0% no-repeat padding-box;
                opacity: 0.3;
                display: block;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                transition: all ease 500ms;
                @media screen and (min-width: 800px){
                    width: 100%;
                    left: 0;
                }
            }
            img.zoom{
                transition: all ease 500ms;
            }
            &:hover{
                .gradient-overlay{
                    opacity: 0.7;
                }
                a p{
                    height: 30px;
                }
                img.zoom{
                    transform: scale(1.2);
                }
            }
        }
        &>div.relative{
            max-width: 450px;
            max-height: 450px;
            @media screen and (min-width: 800px){
                margin: 8px;
                max-width: 570px;
                max-height: 570px;
                width: calc(100% / 3 - 16px);
            }
            overflow: hidden;
            img, picture{
                &+div{
                    background: transparent linear-gradient(180deg, #000E2800 0%, #000E28 100%) 0% 0% no-repeat padding-box;
                    opacity: 0.1;
                    display: block;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    transition: all ease 500ms;
                    @media screen and (min-width: 800px){
                        width: 100%;
                        height: 100%;
                        left: 0;
                    }
                }
            }
            .gradient-overlay{
                background: transparent linear-gradient(180deg, #000E2800 0%, #000E28 100%) 0% 0% no-repeat padding-box;
                opacity: 0.3;
                display: block;
                height: calc(100% - 10px);
                width: 100%;
                left: 0;
                top: 10px;
                transition: all ease 500ms;
                @media screen and (min-width: 800px){
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &.presentation-duo > div.relative {
            max-height: inherit;
        }
        &.row-2-item{
            &>div.relative{
                @media screen and (min-width: 800px){
                    max-width: 50%;
                    width: calc(100% / 2 - 16px);
                }
            }
        }
        &.row-1-item{
            &>div.relative{
                @media screen and (min-width: 800px){
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }
    &.list-categories2{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        &>a{
            background: white;
            flex: calc(50% - 66px);
            width: calc(50% - 66px);
            max-width: calc(50% - 66px);
            border: 1px solid black;
            padding: 16px;
            margin: 16px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-direction: row;
            text-decoration: none;
            &>*{
                text-decoration: none;
            }
            &>div{
                &:first-child{
                    display: block;
                    width: 250px;
                    max-width: 40%;
                    height: 100%;
                    min-height: 250px;
                    background-size: cover;
                    background-position: center;
                }
                &:last-child{
                    padding: 16px;
                    flex-grow: 1;
                    h3{
                        font-size: 2rem;
                    }
                    h3, p{
                        margin: 4px 0;
                    }
                }
            }
            @media screen and (max-width: 1200px){
                max-width: 100%;
                flex: 100%;
                padding: 8px;
                margin: 8px;
                &>div{
                    &:first-child{
                        min-height: 150px;
                    }
                    &:last-child{
                        padding: 8px;
                        padding-left: 16px; 
                        h3{
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }
    div.swiper-button-prev, div.swiper-button-next{
        background: white;
        border-radius: 50%;
        width: 3.125rem;
        height: 3.125rem;
        border: 1px solid #eae6e7;
        outline: none;
        &:after{
            font-size: 1.875rem;
            font-weight: bolder;
            color: #000E28;
        }
        &:hover{
            border-color: #000E28;
        }
        &:focus{
            outline: none;
        }
        @media screen and (min-width: 1024px){
            border-width: 2px;
        }
        &.swiper-button-disabled{
            display: none;
        }

        &.swiper-button-prev-products{
            transform: translateY(-50%);
            left: 4px;
        }
        &.swiper-button-next-products{
            transform: translateY(-50%);
        }
        &.swiper-button-prev-about{
            transform: translateY(-100%);
            left: 4px;
        }
        &.swiper-button-next-about{
            transform: translateY(-100%);
        }
            
        
        &.swiper-button-prev-instagram{
            left: 4px;
        }
        &.swiper-button-next-instagram{
            right: 10px;
        }
    }
    
    &.list-products{
        margin: 20px 0 0;
        @media screen and (min-width: 1024px){
            margin: 30px 0 0;
        }
        .swiper-container-products{
            overflow: inherit;
            .swiper-slide{
                border: 1px solid #F9F9F9;
                &>div{
                    &:first-child{
                        height: 400px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 8px;
                        img{
                            max-height: 100%;
                        }
                    }
                    &:last-child{
                        padding: 8px;
                        .title, .price{
                            text-align: center;
                        }
                        .title{
                            font-size: 1.3rem;
                        }
                    }
                }
            }
        }
        .product{
            p, span{
                margin: 0;
            }
            .categorie{
                font-size: 1.4375rem;
                line-height: 1.2;
                font-weight: bold;
                color: #003399;
                margin-bottom: 3px;
                font-family: 'DINOffc-Bold', sans-serif;
            }
            .title{
                font-size: 1.4375rem;
                line-height: 1.2;
                color: #000E28;
                font-weight: 600;
                margin-bottom: 2px;
                min-height: 1rem;
                font-family: 'DINOffc-Medi', sans-serif;
            }
            .colors{
                font-size: 1rem;
                line-height: 1.2;
                color: #B3B7BF;
                margin-bottom: 10px;
                width: 100%;
                font-family: 'Univers', sans-serif;
            }
            .price{
                font-size: 1.25rem;
                line-height: 1.2;
                color: #000E28;
                min-height: 1rem;
                font-family: 'Univers Bold', sans-serif;
                margin-top: 5px;
            }
            @media  screen and (min-width:768px) {
                .categorie, .title{
                    font-size: 1.5rem;
                }
                .colors{
                    font-size: 1rem;
                }
                .price{
                    font-size: 1.25rem;
                }
            }
        }
    }
    
    &.storelocator-bloc-home{
        background: #FAFAFB;
        &>div{
            max-width: 93%;
            margin: auto;
            padding: 45px 0;
            display: flex;
            justify-content: space-around;
            &>div{
                padding: 10px;
                max-width: 620px;
                &:last-child{
                    max-width: 890px;
                    min-width: 500px;
                    width: 100%;
                    @media screen and (max-width: 1300px){
                        display: none;
                    }
                }
            }
            h2{
                font-weight: bold;
                color: #000E28;
                font-family: 'DINOffc-Bold', sans-serif;
                font-size: 2.5625rem;
                line-height: 1.2;
                margin-top: 0;
                margin-bottom: 0;
                & + h2{
                    font-family: 'DinRegular', sans-serif;
                    font-size: 2.4375rem;
                    line-height: 1.2;
                    margin-bottom: 35px;
                }
                @media screen and (max-width: 767px){
                    font-size: 1.9375rem;
                    & + h2{
                        margin-top: 4px;
                        font-size: 2.125rem;
                    }
                }
            }
            p{
                color: #4D5769;
                margin-bottom: 45px;
                font-family: 'Univers', sans-serif;
                font-size: 1.4375rem;
                line-height: 1.2;
            }
            a{
                background: white;
                color: #000E28;
                border: 1px solid #000E28;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                margin: 0;
                font-family: 'DINOffc-Bold', sans-serif;
                font-size: 1.25rem;
                line-height: 1.2;
                padding: 12px 24px;
                
                @media screen and (min-width: 1024px){
                    padding: 16px 24px;
                }
                @media screen and (min-width: 1450px){
                    padding: 23px 32px;
                }
                svg{
                    height: 16px;
                    width: 16px;
                    margin-right: 6px;
                    @media screen and (min-width: 1024px){
                        height: 28px;
                        width: 28px;
                        margin-right: 10px;
                    }
                }
                &:hover{
                    background: #000E28;
                    color: white;
                    svg path{
                        stroke: white;
                    }
                }
                @media screen and (max-width: 767px){
                    svg path{
                        stroke: white;
                    }
                    &:hover{
                        svg path{
                            stroke: #000E28;
                        }
                    }
                    
                }
            }
        }
    }
    
    &.title-box{
        display: flex;
        flex-direction: row; 
        justify-content: space-between;
        align-items: center;
        h2{
            font-family: 'DINOffc-Bold', sans-serif;
            font-size: 2.5625rem;
            line-height: 1.2;
            @media screen and (max-width: 767px){
                font-size: 1.875rem;
            }
        }
        a{
            color: #4D5769;
            font-size: 1.25rem;
            line-height: 1.2;
            margin-right: 5%;
            text-decoration: none;
            font-family: 'Univers', sans-serif!important;
            @media screen and (max-width: 1023px){
                text-decoration: underline;
                &:hover{
                    color: #000E28;
                }
            }
            &:hover{
                text-decoration: underline;
            }
        }
    }
    &.swiper-container{
        overflow:hidden!important;
    }
    &.w100{
        display: flex;
        width: 100%;
        &.column{
            flex-direction: column;
        }
    }
    &.hide-tablet{
        @media screen and (max-width: 1024px) and (min-width: 768px){
            display: none;
        }
    }
    &.hide-mobile{
        @media screen and (max-width: 767px){
            display: none;
        }
    }
    &.hide-desktop{
        @media screen and (min-width: 1001px){
            display: none;
        }
    }
    
`;