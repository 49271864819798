import React from 'react';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../../js/constants/action-types';
import { Grid, List, InputLabel } from '@material-ui/core';
import { DELETE_TYPO } from "../../../../../queries/brand_guideline";
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import TypoPicker from './TypoPicker';
import AddIcon from '@material-ui/icons/Add';
import { withApollo } from 'react-apollo';

const AddIconCustom = styled(AddIcon)`
    fill: white;
    background: ${colors.blue.lighter.hue150};
    border-radius: 50%;
    padding: 4px;
    width: calc(1em + 10px);
    height: calc(1em + 10px);
    margin-bottom: 10px;
`;
const SpanAddCustom = styled.span`
    color: ${colors.blue.lighter.hue150};
    text-decoration: underline;
`;
const FormCustom = styled(List)`
    padding: 1rem;
    width: 100%;
    background: rgb(250, 251, 251);
`;
const InputLabelCustom = styled(InputLabel)`
    color: ${colors.black.regular};
    line-height: 20px;
    @media screen and (min-width: 1280px){
        height: 51px;
    }
    display: flex;
    align-items: center;
    word-break: break-word;
`;

class TypographySelector extends React.Component {  
    constructor(props){
        super(props)
        this.state = {
            listTypographySystems: this.props.allState.listTypographySystems || [],
            listTypos: this.props.allState.listTypos || [],
            listMarkups: this.props.allState.listMarkups || [],
            defaultBaseSize: 16,
            defaultRatio: 1.25,
        }
    };

    calculateSize = () => {
        let array = [];
        let value = 0;
        for(let item of this.state.listMarkups){
            if(value > 0){
                value = value * this.state.defaultRatio;
            }
            else{
                value = this.state.defaultBaseSize / this.state.defaultRatio;
            }
            array.push(Math.trunc(value));
        }
        return(array.reverse());
    }

    addTypo = () => {
        let temporaryListTypos = this.state.listTypos;
        let temporaryTypo = this.state.listTypographySystems[0].node;
        temporaryTypo.markup = this.calculateSize();
        temporaryListTypos.push(temporaryTypo);

        this.setState({
            listTypos: temporaryListTypos
        }, () => {this.props.stateCallback('listTypos', this.state.listTypos)});
    };

    deleteTypo = (index) => {
        let temporaryListTypos = this.state.listTypos;
        
        if(temporaryListTypos[index].idTypo){
            this.props.client.mutate({
                mutation: DELETE_TYPO,
                variables: {id: temporaryListTypos[index].idTypo},
            })
        }

        temporaryListTypos.splice(index, 1);
        this.setState({
            listTypos: temporaryListTypos
        }, () => {this.props.stateCallback('listTypos', this.state.listTypos)});
    }

    handleChangeComplete = (typo, index) => {
        let temporaryListTypos = this.state.listTypos;
        if(temporaryListTypos[index]){
            temporaryListTypos[index] = typo;
        }else{
            temporaryListTypos.push(typo);
        }

        this.setState({
            listTypos: temporaryListTypos
        }, () => {this.props.stateCallback('listTypos', this.state.listTypos)});
    };
    
    render(){
        return (
            <FormCustom>
                {
                    this.state.listTypos.length > 0 ? (
                        <>
                            {
                                this.state.listTypos.map((typo, index) => (
                                    <Grid container direction="row" spacing={1} key={`index_listTypos_${index}`} id={`index_listTypos_${index}`}>
                                        <Grid item lg={3} xs={12}>
                                            <InputLabelCustom>Typographie n°{index+1}</InputLabelCustom>
                                        </Grid>
                                        <Grid item lg={9} xs={12}>
                                            <TypoPicker allState={this.props.allState} typo={typo} index={index} listTypos={this.state.listTypos} listMarkups={this.state.listMarkups} listTypographySystems={this.state.listTypographySystems} handleChangeComplete={(typo) => {this.handleChangeComplete(typo, index)}} deleteTypo={() => {this.deleteTypo(index)}} />
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            <div
                                onClick={() => {this.addTypo()}}
                                style={{cursor: 'pointer', width: '100%', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row', paddingTop: '13px'}}
                            >
                                <AddIconCustom style={{marginBottom: 0, marginRight: 10}} />
                                <SpanAddCustom>Ajouter une typographie</SpanAddCustom>
                            </div>
                        </>
                    ) : (
                        <div
                            onClick={() => {this.addTypo()}}
                            style={{cursor: 'pointer', width: '100%', height: '100%', minHeight: '40vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '13px'}}
                        >
                            <AddIconCustom />
                            <SpanAddCustom>Ajouter une typographie</SpanAddCustom>
                        </div>
                    )
                }
            </FormCustom>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(TypographySelector));