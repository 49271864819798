import React, { useEffect } from 'react';
import { Box, Grid, Typography, Tooltip, TableContainer, Table, TableBody, TableRow, TableHead, TableCell, AccordionDetails, AccordionSummary, Modal, Select, MenuItem, FormControlLabel, Divider, TextField, LinearProgress } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import * as moment from 'moment';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import Swiper from "react-id-swiper";
import "../../../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from 'react-image-gallery';

import CardCustom from '../CardCustom';
import notFound from '../../../../assets/images/image-fake-card.png';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import StoreIcon from '@material-ui/icons/Store';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import UpdateIcon from '@material-ui/icons/Update';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ROUTE_PRODUCTS_LIST_DETAIL, ROUTE_PRODUCTS_PRICE_COMPARE_LIST } from '../../../../js/constants/route-names';
import iconSet from "../../../../assets/selection.json";
import IcomoonReact from "icomoon-react";
import StyleIcon from '@material-ui/icons/Style';
import CardProduct from '../../../layouts/Card/cardContent/CardProduct';
import { withTranslation } from 'react-i18next';
import AccordionCustom from '../../Accordion/AccordionCustom';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import CallMadeIcon from '@material-ui/icons/CallMade';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import CarouselCustom from '../../Carousel/CarouselCustom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import OurButton from '../../../ui/button/Button';
import { CheckBox } from '@material-ui/icons';
import { LinkCustom } from '../../../../builder/shareable/components/assets/minisite/components/_next/navigation';
import TextEllipsis from './TextEllipsis';
import CarouselListing from '../../Carousel/CarouselListing';
import { red } from '@material-ui/core/colors';
import parseHtml from '../../../../js/utils/parseHtml';

const Sku = styled(Typography)``
const BoxCustom = styled(Box)`
    width: auto;
`;
const AbsoluteButtonWrapper = styled(Grid)`
    display: inline-block;
    width: auto;
    z-index: 10;
    position: fixed;
    bottom: 5vh;
    right: 24px;
`;
const BoxStyleCheck = styled(Box)`
    &.canCheck{
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 1px dashed transparent;
        &:hover{
            border: 1px dashed ${colors.blue.darker.hue300};
        }
    }
    &.checked{
        border: 1px dashed ${colors.blue.darker.hue300};
    }
`;

const BoxCustomAttributs = styled(Box)`
    width: 100%;
`;

const modalStyle = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
const WrapperTitle = styled(Box)`        
    border-right: ${props => props.border === 'right' ? `0.5px solid ${colors.grey.lighter.hue700}` : 'none'} ;
    border-bottom: ${props => props.border === 'bottom' ? `0.5px solid ${colors.grey.lighter.hue700}` : 'none'} ;
    padding-right:${props => props.border === 'right' ? "50px" : '0'} ;
    padding-bottom:${props => props.border === 'bottom' ? "30px" : '0'} ;
    p{
        font-size: 16px;
        line-height: 21px;
        vertical-align: top;
        margin: 0;
        color: ${colors.grey.darker};
    }
`;

const TitleAttributs = styled(Typography)`
    color: ${colors.black.regular};
`;

const TypographyAttributs = styled(Typography)`    
    color: ${colors.grey.regular};
    font-weight:bold;
    p{
        margin: 0;
    }
`;

const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;

const SpanColor = styled.span`
    color: ${colors.blue.darker.hue300};
    font-weight: bold;
`;

const Title = styled(Typography)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    font-size: 25px;
    line-height: 21px;
    font-weight: bold;
    &.canCheck{
        display: inline-block;
        cursor: pointer;
        border: 1px dashed transparent;
        &:hover{
            border: 1px dashed ${colors.blue.darker.hue300};
        }
    }
    &.checked{
        display: inline-block;
        border: 1px dashed ${colors.blue.darker.hue300};
    }

`

const Subtitle = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
    margin-right: 4px !important;
`;

const AttributContainer = styled(Grid)`
    margin-bottom: 10px;
    &.canCheck{
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 1px dashed transparent;
        &:hover{
            border: 1px dashed ${colors.blue.darker.hue300};
        }
    }
    &.checked{
        border: 1px dashed ${colors.blue.darker.hue300};
    }
`;



const CircularProgressCustom = styled(CircularProgress)`
    color: ${props => props.value > 30
        ? props.value > 65
            ? colors.green.regular : "#f6d607"
        : colors.red.regular};
    margin-left: ${props => props.marginleft};
`;

const CardCompare = styled(Box)`
    border: 1px solid ${colors.grey.border};
    padding:20px;
    div{
        margin-top:20px;
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
`;

const LinearProgressCustom = styled(LinearProgress)`
    background-color:${props=> props.bgcolor };
    margin-top:10px ;
    width:150px;
    height:10px;
    border-radius:5px;
    .MuiLinearProgress-bar1Determinate{
        background-color: ${props=> props.color };
        border-radius: 34px;
    }
`

const TextCustomCompare = styled(Typography)`
    font-size:25px;
    max-width: 100%;
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;
    line-height:30px;
    font-weight:bold;
`;


const BoxCustomGallery = styled(Box)`
    padding-right: 32px;
    max-width: 460px;
    @media screen and (max-width: 1250px){
        margin: auto;
        padding-right: 0;
        margin-bottom: 32px;
    }
    .image-gallery{
        display: flex;
        justify-content: center;
        align-items: center;
        &.fullscreen-modal{
            .image-gallery-content{
                top: 40%;
                transform: translateY(-50%);
                left: 10%;
            }
        }
        .image-gallery-content{
            width: 100%;
        }
    }
    .image-gallery-thumbnails{
        overflow: auto;
    }
    .image-gallery-thumbnails-wrapper{
        width: 20%;
    }
    .image-gallery-thumbnails .image-gallery-thumbnails-container{
        transform: none !important;
        height: 100%;
        cursor: default;
    }
    .image-gallery-slides{
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .image-gallery-slide-wrapper{
        width: calc((100% - 20%) - 10px);
    }
    .image-gallery-right-nav, .image-gallery-left-nav {
        padding: 0;
    }
    .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
        height: 30px !important;
        width: 30px;
        padding: 0;
        stroke-width: 2;
    }
    .image-gallery-icon{
        opacity: 0;
        color: ${colors.black.regular};
        filter: none;
        height: 40px !important;
        width: 40px;
        background: ${colors.grey.lighter.hue900};
        padding: 0px;
        border-radius: 50%;
    }
    .image-gallery-swipe{
        height: 100%
        .image-gallery-swipe{
            height: 100%
            .image-gallery-slide{
                height: 100%   
            }
        }
    }
    .image-gallery-slide img{
        width: 100%;
        height: auto;
        overflow: hidden;
    }
    .fullscreen{
        .image-gallery-swipe{
            max-height: none !important;
        }
        .image-gallery-slide {
            max-height: none !important;
            img {
                max-height: 60vh !important;
            }
        }
        .image-gallery-fullscreen-button{
            right: -70px;
            opacity: 1 !important;
            svg{
                top: 2px;
                position: relative;
            }
        }
    }
    .image-gallery-slide-wrapper:hover .image-gallery-icon{
        opacity: 0.4;
    }
    .image-gallery-icon:hover{
        opacity: 0.8 !important;
    }
    .image-gallery-fullscreen-button svg{
        height: 20px !important;
    }
    .image-gallery-thumbnail{
        transition: unset;
        height: calc((100% /4) + 2px);
        width: 100%;
        cursor: pointer;
    }
    .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus{
        border: 2px solid ${colors.blue.darker.hue300};
    }
`;

const WrapperAttr = styled(Grid)`
    &.canCheck{
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 1px dashed transparent;
        &:hover{
            border: 1px dashed ${colors.blue.darker.hue300};
        }
    }
    &.checked{
        border: 1px dashed ${colors.blue.darker.hue300};
    }
    &:not(:last-child){
        margin-bottom: 8px;
    }
    > p:first-child{
        min-width: 180px;
    }
    p{
        font-size: 13px;
        text-align: left;
        color: ${colors.black.regular};
    }
`;

const AccordionDetailsCustom = styled(AccordionDetails)`
    padding: 0;
    margin-top: 8px;
`;

const AccordionSummaryCustom = styled(AccordionSummary)`
    padding: 0;
    border-bottom: 1px solid ${colors.grey.lighter.hue900};
    min-height: 40px !important;
    .MuiAccordionSummary-content{
        margin: 8px 0 !important;
        align-items: center;
    }
`;

const TableCellCustom = styled(TableCell)`
    border: 1px solid ${colors.blue.regular};
`;
const CheckCircleIconCustom = styled(CheckCircleIcon)`
    width: 0.8em;
    height: 0.8em;
`;

const Color = styled.div`
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 6px;
    border: 1px solid ${colors.grey.border};
    background-color: ${props => props.colorelement};
`

const SelectCustom = styled(Select)`
    border-radius: 0; 
    // width: 100%; 
    display: inline-block;
    margin-right: 16;
    fieldset{
        border: none;
    }
    :before, &:after{
        display: none !important;
    }
    input, .MuiSelect-select{
        font-size: 14px;
        padding: 16px;
        padding-left: 8px;
        margin-right: 16px;
        color: ${colors.blue.darker.hue300};
        &:focus{
            background: none;
        }
        @media screen and (max-width: 1450px){
            font-size: 14px;
            line-height: 18px;
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;
            line-height: 17px;
        }
        @media screen and (max-width: 960px){
            font-size: 12px;
            line-height: 16px;
        }
    }
    svg{
        fill: ${colors.blue.darker.hue300};
    }
    & > p{
        margin: 0;
    }
`;

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.black.regular,
    },
    tooltip: {
        backgroundColor: colors.black.regular,
        fontSize: 14,
        padding: 10,
        textAlign: 'left',
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class CardProductDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            displayAttributes: "12"
        }
    }

    handleChangeDisplay = (event) => {
        event.stopPropagation();
        this.setState({
            displayAttributes: event.target.value
        })
    }

    render() {
        const product = this.props.product;
        const totalImages = this.props.imageAttributes.length;
        let imageLoaded = null;
        let values = [];
        let completude = product?.fetchedProduct?.flatCompletudes?.edges.find(e => e.node.locale.id === this.props.locale.node.id) ?? null;

        let metaTitle = product?.fetchedProduct?.productDatas?.edges.find(e => e.node.attribute.identifier === "product_metatitle") ?? null;            
        let metaDesc = product?.fetchedProduct?.productDatas?.edges.find(e =>e.node.attribute.identifier === "product_metadesc") ?? null;            
        
        let stock = 0;
        let cart = 0;
        let reappro = 0;
        let realTime = 0;
        if (this.props.datasStocks) {
            cart = this.props.datasStocks.cart.value;
            for (let item of this.props.datasStocks.stock) {
                stock += item.value;
            }
            for (let item of this.props.datasStocks.reappro) {
                reappro += item.value;
            }
            realTime = stock - cart;
        }

        if (this.props.locales) {
            for (let locale of this.props.locales) {
                values.push({
                    code: locale.node.code,
                    name: this.props.allState[locale.node.code]?.product_name?.value ?? null,
                    description: this.props.allState[locale.node.code]?.product_description?.value ?? null,
                    price: this.props.allState[locale.node.code]?.product_price?.value ?? null,
                    ean: this.props.allState[locale.node.code]?.product_ean?.value ?? null,
                });
            }
        }

        const defaultName = values.find(e => e.name);
        const defaultDesc = values.find(e => e.description !== '<p><br></p>');
        const defaultPrice = values.find(e => e.price);
        const defaultEAN = values.find(e => e.ean);

        const valueName = values.find(e => e.code === this.props.currentLang);
        const valueDesc = values.find(e => e.code === this.props.currentLang && e.description !== '<p><br></p>');
        let newIndex = 0;
        
        let map = (attribute, index) => {
            const defaultLabel = attribute.node.translation.translationDatas.edges[0];
            
            const label = attribute.node.translation.translationDatas.edges.find(
                lang => lang.node.locale.code === this.props.currentLang
                );
                
                let values = [];
                
            for (let locale of this.props.locales) {
                values.push({
                    code: locale.node.code,
                    value: this.props.allState[locale.node.code]?.[attribute.node.identifier]?.value ?? null
                });
            }
            const defaultValue = values.find(e => attribute.node.attributeType.input === 'file' ? e.value?.data ? e.value?.data ?? null : e.value : e.value);
            const value = values.find(e => e.code === this.props.currentLang);
            // console.log(attribute)
            let local = moment.tz.guess();
            const finalAttributeValue = attribute.node.attributeType.input === 'file'
                ? (value?.value?.file ?? null)
                    ? value.value.file.name
                    : (value?.value?.data ?? null)
                        ? value.value.data
                        : (defaultValue?.value?.file ?? null)
                            ? defaultValue.value.file.name
                            : (defaultValue?.value?.data ?? null)
                                ? defaultValue?.value?.data
                                : defaultValue?.value?.filePath
                : attribute.node.attributeType.input === 'date'
                    ? value?.value
                        ? moment.tz(value?.value, local).format('L')
                        : defaultValue ? moment.tz(defaultValue?.value, local).format('L') ?? null : null
                    : value?.value ?? (defaultValue?.value ?? null);

            let finalAttributeOptionValue = null;

            if (attribute.node.attributeType.input === 'select') {
                if (finalAttributeValue) {
                    let option = attribute.node.attributeOptions.edges.find(e => e.node.id === finalAttributeValue);

                    if (option) {
                        const optionDefaultLabel = option.node.translation.translationDatas.edges[0];

                        const optionLabel = option.node.translation.translationDatas.edges.find(
                            lang => lang.node.locale.code === this.props.currentLang
                        );

                        finalAttributeOptionValue = optionLabel?.node.value ?? optionDefaultLabel?.node.value ?? option.node.identifier;
                    }
                }
            }
            if ((finalAttributeValue || finalAttributeOptionValue) !== null) {
                newIndex++
            }

            return (
                (finalAttributeValue || finalAttributeOptionValue) !== null ?
                    <Grid item lg={this.state.displayAttributes} md={this.state.displayAttributes} sm={this.state.displayAttributes} xs={12} key={`attribute-container-${index}`} style={{ padding: this.state.displayAttributes === "12" ? 0 : ((newIndex % 2) !== 0) ? "0px 32px 0px 16px" : "0px 16px 0px 32px", borderRight: ((newIndex % 2) !== 0 && this.state.displayAttributes !== "12") ? `1px solid #E3E4E4` : "none" }}>
                        <AttributContainer
                            container
                            direction="row"
                            key={attribute.node.identifier}
                            className={this.props.isScrap ? this.props.allState.selectedScrap?.find(e => e.key === attribute.node.identifier) ? "canCheck checked" : "canCheck" : null}
                            onClick={this.props.isScrap ? () => this.props.handleSelectScrap('selectedScrap', attribute.node.identifier, (attribute.node.attributeType.input === 'file' ? defaultValue.value.file?.name || finalAttributeValue : attribute.node.attributeType.input === 'select' ? finalAttributeOptionValue : finalAttributeValue)) : null}
                        >
                            <Grid item style={{ maxWidth: 300, minWidth: 300 }}>
                                <TitleAttributs variant="body2">
                                    {
                                        label?.node.value
                                        ?? defaultLabel?.node.value
                                        ?? attribute.node.identifier
                                    } :
                                </TitleAttributs>
                            </Grid>
                            <Grid item style={{ flex: 1 }}>
                                {
                                    attribute.node.attributeType.input === 'textarea' ?
                                        <TypographyAttributs variant="body2" style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: finalAttributeValue }} />
                                        :
                                        attribute.node.attributeType.input === 'file' ?
                                            <a target="__blank" href={`${process.env.REACT_APP_MEDIAS}/${defaultValue.value.file?.name || finalAttributeValue}`}>
                                                <TypographyAttributs variant="body2" style={{ wordBreak: 'break-word' }}>
                                                    {defaultValue.value.name}
                                                </TypographyAttributs>
                                            </a>
                                            :
                                            attribute.node.attributeType.input === 'file' ?
                                                <a target="__blank" href={`${process.env.REACT_APP_MEDIAS}/${defaultValue.value.file?.name || finalAttributeValue}`}>
                                                    <TypographyAttributs variant="body2" style={{ wordBreak: 'break-word' }}>
                                                        {defaultValue.value.name}
                                                    </TypographyAttributs>
                                                </a>
                                                :
                                                attribute.node.attributeType.input === 'color' ?
                                                    <TypographyAttributs variant="body2" style={{ wordBreak: 'break-word', display: 'flex', alignItems: 'center' }} className={`attribute-${attribute?.node?.identifier}`}>
                                                        <Color colorelement={finalAttributeValue} />                                                        
                                                        {
                                                            attribute.node.attributeType.input === 'select'
                                                                ? finalAttributeOptionValue
                                                                : typeof finalAttributeValue === 'string'
                                                                    ? finalAttributeValue?.indexOf('http') > -1
                                                                        ? (
                                                                            <a target="_blank" href={finalAttributeValue}>
                                                                                <TypographyAttributs variant="body2" style={{ wordBreak: 'break-word' }}>
                                                                                    {finalAttributeValue}
                                                                                </TypographyAttributs>
                                                                            </a>
                                                                        )
                                                                        : finalAttributeValue
                                                                    : finalAttributeValue
                                                        }
                                                    </TypographyAttributs>
                                                    :
                                                    <TypographyAttributs variant="body2" style={{ wordBreak: 'break-word' }} className={`attribute-${attribute?.node?.identifier}`}>
                                                        {/* {console.log(finalAttributeValue)} */}
                                                        {
                                                            attribute.node.attributeType.input === 'select'
                                                                ? finalAttributeOptionValue
                                                                : typeof finalAttributeValue === 'string'
                                                                    ? finalAttributeValue?.indexOf('http') > -1
                                                                        ? (
                                                                            <a target="_blank" href={finalAttributeValue}>
                                                                                <TypographyAttributs variant="body2" style={{ wordBreak: 'break-word' }}>
                                                                                    {finalAttributeValue}
                                                                                </TypographyAttributs>
                                                                            </a>
                                                                        )
                                                                        : finalAttributeValue
                                                                    : finalAttributeValue
                                                        }                                                        
                                                    </TypographyAttributs>
                                }
                            </Grid>
                            {
                                this.props.isScrap ?
                                    <Grid item>
                                        <OurButton onClick={(e) => e.preventDefault()}>
                                            {this.props.allState.selectedScrap?.find(e => e.key === attribute.node.identifier) ? "Enlever" : "Choisir"}
                                        </OurButton>
                                    </Grid>
                                    : null
                            }
                        </AttributContainer>
                    </Grid>
                    : null
            );
        };

        let catalogList = []
        if (this.props.allState?.categoriesCatalog?.length > 0) {
            for (let catalog of this.props.allState.categoriesCatalog) {
                if (catalog.catalog) {
                    if (catalogList.indexOf(catalog.catalog.libelle) === -1) {
                        catalogList.push(catalog.catalog.libelle)
                    }
                }
            }
        }
        let images = this.props.imageAttributes.map((attribute, index) => {
            let values = [];
            for (let locale of this.props.locales) {
                values.push({
                    code: locale.node.code,
                    value: this.props.allState[locale.node.code]?.[attribute.node.identifier]?.value?.id ? this.props.allState[locale.node.code]?.[attribute.node.identifier]?.value ?? null : null
                });
            }

            const defaultValue = values.find(e => e.value);
            const value = values.find(e => e.code === this.props.currentLang);

            const finalValue = value?.value ?? defaultValue?.value ?? null;
            return {
                original:
                    finalValue ?
                        finalValue.changed ?
                            finalValue.data ?
                                finalValue.data
                                : notFound
                            : finalValue.data || finalValue.filePath ? `${process.env.REACT_APP_MEDIAS}/${finalValue.data ? finalValue.data : finalValue.filePath}` : notFound
                        : index > 0 ?
                            null
                            : notFound,

                thumbnail:
                    finalValue ?
                        finalValue.changed ?
                            finalValue.data ?
                                finalValue.data
                                : notFound
                            : finalValue.data || finalValue.filePath ? `${process.env.REACT_APP_MEDIAS}/${finalValue.data ? finalValue.data : finalValue.filePath}` : notFound
                        : index > 0 ?
                            null
                            : notFound,
            };
        });
        imageLoaded = 0;
        for (var i = images.length - 1; i >= 0; i--) {
            if (images[i].original === null) {
                images.splice(i, 1);
            }
            else if (images[i].original !== notFound) {
                imageLoaded++;
            }
        }

        this.props.allState.imagesSelected = images

        const imagesUrlList = this.props.allState.imagesSelected.map((image) => {
            return image.original
        })

        const formatedImages = this.props.allState.imagesSelected.map((image) => {
            return image.original
        })

        const imagesWithoutPlaceholder = this.props.allState.imagesSelected.map((image) => {
            return image.original
        })

        const nb = totalImages - formatedImages.length

        for (let i = 0; i < nb; i++) {        
            formatedImages.push(notFound)
          }    

        

        const params = {
            slidesPerView: 5.5,
            spaceBetween: 10,
            pagination: false,
            breakpoints: {
                2000: {
                    slidesPerView: this.props.isScrapNew?2.5 : 5.5,                  
                  },
                1700: {
                  slidesPerView: this.props.isScrapNew?2.5 : 4.5,                  
                },
                1300: {
                  slidesPerView:this.props.isScrapNew?1.5 : 3.5,                  
                },                
                320: {
                  slidesPerView: this.props.isScrapNew?1.5 : 2.5,                  
                }
              }
            // navigation: {
            //     nextEl: '.swiper-button-next-catalog',
            //     prevEl: '.swiper-button-prev-catalog'
            //   },
            // renderPrevButton: () => <Arrow style={{left:0}}><ArrowBackIosIcon style={{fontSize: 20}}/></Arrow>,
            // renderNextButton: () => <Arrow style={{right:0}}><ArrowForwardIosIcon style={{fontSize: 20}}/></Arrow>,
        }


        const getColorFromLength = (value,min,max)=>{                
            if (value < min - 10 || value > max + 10 || !value) {
                return colors.red.regular                
            }else if (value >= min && value <=max) {
                return colors.green.regular
            }else{
                return colors.orange.regular
            }            
        }

        const getLinearProgressValue = (value,max)=>{                     
            if (value * 100 /max > 100) {
                return 100
            }else{
                return value * 100 /max
            }
        }
        
        const clampTextWithNumber = (value,length)=>{
            if (value?.length > length) {
                return value.slice(0,length) + "..."
            }
            return value
        }       

        return (
            <Grid container spacing={3} direction="row" style={{ paddingBottom: this.props.isScrap ? 70 : 0 ,marginTop:0}}>
                {
                    this.props.isScrapNew && this.props.allState.selectedScrap.length > 0 ?
                        <AbsoluteButtonWrapper container justifyContent='flex-end'>
                            <Button text="Valider la sélection" bgColor={colors.green.regular} style={{ margin: 0 }} onClick={this.props.handleValidateSelection} />
                        </AbsoluteButtonWrapper>
                        : null
                }
                {
                    process.env.REACT_APP_ALLOW_CONTEXT === "true"?
                    <Grid item xs={12} style={{paddingBottom:0}}>
                        <Box style={{
                            display:"flex",                        
                        }}>                                    
                            <OurButton onClick={()=>this.props.handleChangeCollection("-1")} border={`0.5px solid #0273A5`} bgcolor={!this.props.allState.currentCollection ||this.props.allState.currentCollection === "-1"  ? "#0273A5":"#F0F9FD"} bgcolorhover={!this.props.allState.currentCollection ||this.props.allState.currentCollection === "-1" ? "#53839D" :colors.blue.lighter.hue600} color={!this.props.allState.currentCollection||this.props.allState.currentCollection === "-1" ? "#fff":"#0273A5"} style={{margin:0}}>Contexte global</OurButton>
                            {this.props.allState?.listCollections?.map((option, i) => (
                                this.props.product.fetchedProduct.attributeGroup.identifier === 'mobilier'?
                                    option.node.id === "/api/selections/4"?
                                        <OurButton key={i} onClick={()=>this.props.handleChangeCollection(option.node.id)} border={`0.5px solid #0273A5`} bgcolor={this.props.allState.currentCollection === option.node.id ? "#0273A5":"#F0F9FD"} color={this.props.allState.currentCollection=== option.node.id ? "#fff":"#0273A5"} bgcolorhover={this.props.allState.currentCollection=== option.node.id ? "#53839D":colors.blue.lighter.hue600} style={{margin:0}}>{option.node.identifier}</OurButton>                            
                                        :null
                                        :option.node.id !== "/api/selections/4"?                                     
                                        <OurButton key={i} onClick={()=>this.props.handleChangeCollection(option.node.id)} border={`0.5px solid #0273A5`} bgcolor={this.props.allState.currentCollection === option.node.id ? "#0273A5":"#F0F9FD"} color={this.props.allState.currentCollection=== option.node.id ? "#fff":"#0273A5"} bgcolorhover={this.props.allState.currentCollection=== option.node.id ? "#53839D":colors.blue.lighter.hue600} style={{margin:0}}>{option.node.identifier}</OurButton>                            
                                        :null
                            ))}                   
                        </Box>
                    </Grid>
                    :null
                }
                
                <Grid item xs={12} style={{paddingTop:0}}>
                        <Grid container style={{backgroundColor:"white",border:`0.5px solid rgba(0, 0, 0, 0.12)`}}>
                            <Grid item xs={12} style={{padding:"20px 42px 0px 42px"}}>
                                <Title variant="h4"
                                    style={{ display: this.props.isScrap ? 'flex' : 'inline-flex', justifyContent: this.props.isScrap ? 'space-between' : 'initial' }}
                                    className={this.props.isScrap ? this.props.allState.selectedScrap?.find(e => e.key === 'product_name') ? "canCheck checked" : "canCheck" : null}
                                    onClick={this.props.isScrap ? () => this.props.handleSelectScrap('selectedScrap', 'product_name', valueName?.name ?? defaultName?.name ?? null) : null}
                                >
                                    {/* {valueName?.name ?? defaultName?.name ?? null} */}
                                    {
                                        this.props.isScrapNew ? 
                                        this.props.allState.selectedScrap?.find(e => e.key === 'product_name') ? this.props.allState.selectedScrap?.find(e => e.key === 'product_name').value : valueName?.name ?? defaultName?.name ?? null
                                        :valueName?.name ?? defaultName?.name ?? null
                                    }
                                    {this.props.type === 'dashboard' ? '' :
                                        process.env.REACT_APP_MODE_SPREAD !== "hub" && !this.props.isScrap ?
                                            (
                                                <Box ml={3}>
                                                    <StatusInfo status={product.status} width={'auto'} />
                                                </Box>
                                            ) : null
                                    }
                                    {
                                        this.props.isScrap ?
                                            <OurButton onClick={(e) => e.preventDefault()}>
                                                {this.props.allState.selectedScrap?.find(e => e.key === 'product_name') ? "Enlever" : "Choisir"}
                                            </OurButton>
                                            : null
                                    }
                                </Title>
                            </Grid>
                            <Grid item xs={12} style={{padding:"20px 42px"}}>                                
                                <AccordionCustom overflow={"visible"} custompadding={"22px 0px !important"} noborder={true} title={<Typography variant={"h4"} style={{ fontSize: 14, fontWeight: '600' }}>Images</Typography>}>
                                    <CarouselCustom slidesNb={this.props.isScrapNew ? 2.5:null} images={formatedImages} imagesWithoutPlaceholder={imagesWithoutPlaceholder} />
                                </AccordionCustom>            
                            </Grid>                                                        
                            <Grid container item direction={"row" } justifyContent="center" alignItems={this.props.isScrapNew ? 'start':'center'} style={{ padding: " 20px 42px", flexWrap: "nowrap", gap: "50px" }}>
                                <WrapperTitle border={ 'right'}>
                                    <Typography style={{color:colors.black.regular,fontSize:25,marginBottom:10,fontWeight:"bold"}}>Description</Typography>
                                    <BoxStyleCheck
                                        className={this.props.isScrap ? this.props.allState.selectedScrap?.find(e => e.key === 'product_description') ? "canCheck checked" : "canCheck" : null}
                                        onClick={this.props.isScrap ? () => this.props.handleSelectScrap('selectedScrap', 'product_description', valueDesc?.description ?? defaultDesc?.description ?? null) : null}
                                    >                                        
                                        <TextEllipsis
                                            text={this.props.allState.selectedScrap?.find(e => e.key === 'product_description') ?this.props.allState.selectedScrap?.find(e => e.key === 'product_description').value:valueDesc?.description ?? defaultDesc?.description ?? null }
                                            maxLine={4}
                                            ellipsis=' ...Lire la suite'
                                            hasModal={true}
                                            fontSize={13}
                                        />                                        
                                        {
                                            this.props.isScrap ?
                                                <Box>
                                                    <OurButton style={{ marginLeft: 50 }} onClick={(e) => e.preventDefault()}>
                                                        {this.props.allState.selectedScrap?.find(e => e.key === 'product_description') ? "Enlever" : "Choisir"}
                                                    </OurButton>
                                                </Box>
                                                : null
                                        }
                                    </BoxStyleCheck>
                                </WrapperTitle>
                                <Box style={{height:"100%",display:"flex",flexDirection:"column",justifyContent:"center",width:"100%"}}>
                                    <Box style={{ display: "flex", gap: "30px", width: this.props.windowWidth > 1500 ? 'auto' : '100%' }}>
                                        <Box style={{ width: "max-content" }}>
                                            {
                                                !this.props.isScrap ?
                                                    <WrapperAttr container>
                                                        <Typography> {this.props.t('products.list.cardproduct.nbCompleteness')} :</Typography>
                                                        <Typography variant="body1" style={{ color: colors.green.regular }}><strong>{Math.round(completude?.node?.completude ?? 0)} %</strong></Typography>
                                                    </WrapperAttr>
                                                    : null
                                            }
                                            {
                                                valueName?.price ?? defaultPrice?.price ?
                                                    <WrapperAttr container
                                                        className={this.props.isScrap ? this.props.allState.selectedScrap?.find(e => e.key === 'product_price') ? "canCheck checked" : "canCheck" : null}
                                                        onClick={this.props.isScrap ? () => this.props.handleSelectScrap('selectedScrap', 'product_price', valueName?.price ?? defaultPrice?.price ?? null) : null}
                                                    >
                                                        <Typography> {this.props.t('products.list.cardproduct.price')} :</Typography>
                                                        <Typography variant="body1"><strong>                                                        
                                                            {
                                                                this.props.allState.selectedScrap?.find(e => e.key === 'product_price')?
                                                                this.props.allState.selectedScrap?.find(e => e.key === 'product_price').value+ ' €':
                                                                (valueName?.price ?? defaultPrice?.price ?? null) 
                                                                ?(valueName?.price ?? defaultPrice?.price ?? null) + ' €'
                                                                :    null
                                                            }                                                        
                                                        </strong></Typography>
                                                        {
                                                            this.props.isScrap ?
                                                                <OurButton style={{ marginLeft: 50 }} onClick={(e) => e.preventDefault()}>
                                                                    {this.props.allState.selectedScrap?.find(e => e.key === 'product_price') ? "Enlever" : "Choisir"}
                                                                </OurButton>
                                                                : null
                                                        }
                                                    </WrapperAttr>
                                                : 
                                                    null
                                            }

                                            {
                                                !this.props.isScrap ?
                                                    <>
                                                        { this.props.sku ?
                                                            (<WrapperAttr container>
                                                                <Typography> {this.props.t('products.list.cardproduct.article')} :</Typography>
                                                                <Typography variant="body1"><strong>{this.props.sku}</strong></Typography>
                                                            </WrapperAttr>)
                                                        : null}
                                                        {
                                                           valueName?.ean ?? defaultEAN?.ean ?
                                                            <WrapperAttr container>
                                                                <Typography> EAN13 :</Typography>
                                                                <Typography variant="body1"><strong>{valueName?.ean ?? defaultEAN?.ean ?? null}</strong></Typography>
                                                            </WrapperAttr>
                                                           : null 
                                                        }
                                                        {
                                                            this.props.selectedProducts?.length > 0 ? (
                                                                <WrapperAttr container>
                                                                    <Typography> Produit{this.props.selectedProducts.length > 1 ? 's' : null} associé{this.props.selectedProducts.length > 1 ? 's' : null} :</Typography>
                                                                    <Typography variant="body1"><strong>{this.props.selectedProducts.length}</strong></Typography>
                                                                </WrapperAttr>
                                                            ) : null
                                                        }
                                                    </>
                                                    : null
                                            }
                                        </Box>
                                        <Box style={{ width: "max-content" }}>
                                            {
                                                !this.props.isScrap ?
                                                    <Grid item xs={12}>
                                                        <WrapperAttr container>
                                                            <Typography> {this.props.t('products.list.cardproduct.nbImages')} :</Typography>
                                                            <Typography variant="body1"><strong>{imageLoaded}/{totalImages}</strong></Typography>
                                                        </WrapperAttr>
                                                        {
                                                            this.props.creation ? null :
                                                                <WrapperAttr container>
                                                                    <Typography> {process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t('products.list.cardproduct.inCatalog') : this.props.t('products.list.cardproduct.inWarehouse')} :</Typography>
                                                                    <Typography variant="body1"><strong>{product.nbCatalog}</strong></Typography>
                                                                </WrapperAttr>
                                                        }
                                                        { this.props.categories && this.props.categories.length > 0 ?
                                                            (<WrapperAttr container>
                                                                <Typography>{this.props.t('products.list.cardproduct.categories')} :</Typography>
                                                                <Typography variant="body1">
                                                                    <strong>{this.props.categories.map((categorie, index) => {
                                                                        let allNames = categorie.categoryDatas.edges.filter(e => e.node.attribute.identifier === 'category_name');
                                                                        const defaultValue = allNames[0];
                                                                        const value = allNames.find(e => e.node.locale.code === this.props.currentLang);
                                                                        let finalValue = value?.node.value ?? defaultValue?.node.value ?? categorie.libelle;
                                                                        return `${finalValue}${this.props.categories?.length === index + 1 ? '' : ' / '}`;
                                                                    })}</strong>
                                                                </Typography>
                                                            </WrapperAttr>)
                                                        : null}
                                                        { this.props.allState.initialVariant && this.props.allState.initialVariant.length > 0 ?
                                                            (<WrapperAttr container>
                                                                <Typography>Nombre de variante :</Typography>
                                                                <Typography variant="body1"><strong>{this.props.allState.initialVariant?.length}</strong></Typography>
                                                            </WrapperAttr>)
                                                        : null}
                                                    </Grid>
                                                    : null
                                            }
                                        </Box>
                                    </Box>                   
                                    {
                                        process.env.REACT_APP_ENABLE_PRICE_WATCHER === 'true' ?
                                            <Box>
                                                <OurButton disabled={!this.props.listSources?.length > 0} margin={"20px 0 0 0"} onClick={()=>{this.props.handleOpenScrapDrawer();this.props.handleChangeCollection(null)}}>Scrapping produit</OurButton>
                                            </Box>
                                        :null
                                    }
                                </Box>
                                {this.props.type === 'dashboard' ?
                                    (
                                        <Grid container>
                                            <Box pt={4}>
                                                <StatusInfo status={product.status} />
                                            </Box>
                                        </Grid>
                                    ) : ''
                                }
                                {
                                    process.env.REACT_APP_MODE_SPREAD === "hub" ?
                                        (
                                            <Grid item xs={12} style={{ marginBottom: 32 }}>
                                                <BoxCustom onClick={this.props.history.goBack} style={{ marginBottom: 16 }}>
                                                    <ReturnLink variant={'body2'}>&lt; {this.props.t("spread.active_assets.return")}</ReturnLink>
                                                </BoxCustom>
                                                <Grid container direction="row" justifyContent="center" spacing={4}>
                                                    <Grid item xs={11}>
                                                        <Grid container direction="row" justifyContent="center" spacing={0}>
                                                            <Grid item xs={3}>
                                                                <CardCustom style={{ background: colors.black.regular, borderRadius: '5px 0 0 5px', borderRight: '1px solid white' }} cardContentStyle={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px' }}>
                                                                    <Typography variant="h3" style={{ color: 'white', fontSize: 14, lineHeight: '20px' }}>Disponible Real-time</Typography>
                                                                    <Typography variant="body1" style={{ color: 'white', fontSize: 36, lineHeight: '36px' }}>
                                                                        <UpdateIcon /> {realTime}
                                                                    </Typography>
                                                                </CardCustom>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <CardCustom style={{ background: colors.black.regular, borderRadius: '0', borderRight: '1px solid white' }} cardContentStyle={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px' }}>
                                                                    <Typography variant="h3" style={{ color: 'white', fontSize: 14, lineHeight: '20px' }}>Stock</Typography>
                                                                    <Typography variant="body1" style={{ color: 'white', fontSize: 36, lineHeight: '36px' }}>
                                                                        <EqualizerIcon />{stock}
                                                                    </Typography>
                                                                </CardCustom>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <CardCustom style={{ background: colors.black.regular, borderRadius: '0', borderRight: '1px solid white' }} cardContentStyle={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px' }}>
                                                                    <Typography variant="h3" style={{ color: 'white', fontSize: 14, lineHeight: '20px' }}>Réservé</Typography>
                                                                    <Typography variant="body1" style={{ color: 'white', fontSize: 36, lineHeight: '36px' }}>
                                                                        <AddShoppingCartIcon /> {cart}
                                                                    </Typography>
                                                                </CardCustom>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <CardCustom style={{ background: colors.black.regular, borderRadius: '0 5px 5px 0' }} cardContentStyle={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '12px 12px 16px' }}>
                                                                    <Typography variant="h3" style={{ color: 'white', fontSize: 14, lineHeight: '20px' }}>Réassort</Typography>
                                                                    <Typography variant="body1" style={{ color: 'white', fontSize: 36, lineHeight: '36px' }}>
                                                                        <PlayForWorkIcon />{reappro}
                                                                    </Typography>
                                                                </CardCustom>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <CardCustom style={{ height: '100%' }} cardContentStyle={{ padding: 12, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            {stock === 0 ?
                                                                (<CancelIcon style={{ fill: colors.red.regular, width: '1.3em', height: '1.3em' }} />)
                                                                :
                                                                (<CheckCircleIcon style={{ fill: colors.green.regular, width: '1.3em', height: '1.3em' }} />)
                                                            }
                                                        </CardCustom>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : null
                                }
                                {
                                    this.props.creation
                                        ? null
                                        : this.props.canModify && process.env.REACT_APP_MODE_SPREAD !== "hub" && this.props.type === 'dashboard' ?
                                            <Grid item lg={this.props.windowWidth > 1500 ? 8 : 7} md={7} sm={12} xs={12} >
                                                <Grid container direction="row" justifyContent="flex-end">
                                                    (
                                                    <Box>
                                                        <Button text="Voir la fiche produit >" bgColor={colors.green.regular} onClick={this.props.routeProduct} />
                                                    </Box>
                                                    )
                                                </Grid>
                                            </Grid>
                                            : null
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>                                
                            </Grid>
                            {
                                !this.props.isScrap && !this.props.creation && this.props.listSources?.length > 0 ?
                                    <Grid item xs={12} style={{padding:"20px 42px"}}>
                                        <AccordionCustom
                                            noborder={true}
                                            title={
                                            <Grid container justifyContent='space-between' alignItems='center'>
                                                <Typography variant={"h4"} style={{ fontSize: 14, fontWeight: '600' }}>{this.props.t("products.list.cardproduct.priceCompare")}</Typography>
                                                {
                                                    process.env.REACT_APP_ENABLE_PRICE_WATCHER === 'true' ? 
                                                    <Typography 
                                                    style={{
                                                        margin: 0,
                                                        padding: '8px 30px',
                                                        fontSize:12,
                                                        textDecoration:"underline",
                                                        color:colors.blue.darker.hue300
                                                    }} 
                                                    onClick={() => {
                                                        let ean = this.props.product?.fetchedProduct?.productDatas?.edges?.find(e => e.node?.attribute?.identifier === 'product_ean')?.node?.value;
                                                        this.props.history.push(ROUTE_PRODUCTS_PRICE_COMPARE_LIST + `#${ean}`);
                                                    }}>
                                                        Voir le détail
                                                    </Typography>
                                                    : null
                                                }
                                            </Grid>
                                        }>
                                            <Box style={{width:"100%"}}>                                                
                                                <Swiper {...params}>
                                                    <CardCompare>
                                                        <TextCustomCompare>Prix de vente conseillé</TextCustomCompare>
                                                        <div>
                                                            <Typography style={{fontSize:14}}>Prix : </Typography>
                                                            <Typography style={{fontSize:25,lineHeight:"30px",fontWeight:"bold"}}>{defaultPrice?.price} €</Typography>
                                                        </div>
                                                    </CardCompare>
                                                    {
                                                        this.props.listSources.map((item,index)=>{       
                                                            let comparePrice= item.node?.productSourceDatas?.edges[0]?.node?.value                            
                                                            let productPrice =defaultPrice?.price
                                                            let diff = Number(comparePrice) - Number(productPrice)                                                            
                                                            let indicator = diff < 0 ? <ArrowDownwardRoundedIcon style={{ color: colors.green.regular,fontSize: 12  }} />:diff === 0?<RemoveIcon style={{ color: colors.grey.regular,fontSize: 12  }}/>:<ArrowUpwardIcon style={{ color: colors.red.regular,fontSize: 12 }}/>                                                            
                                                            return (
                                                                <CardCompare>                                                                    
                                                                    <TextCustomCompare>{item.node.libelle}</TextCustomCompare>
                                                                    <div>
                                                                        <Typography style={{fontSize:14}}>Prix : </Typography>
                                                                        <Typography style={{fontSize:14,lineHeight:"30px",fontWeight:"bold"}}>{item.node?.productSourceDatas?.edges[0]?.node?.value} € <span style={{fontWeight:"normal"}}>({diff.toFixed(2)}€) {indicator}</span> </Typography>
                                                                    </div>
                                                                </CardCompare>
                                                            )
                                                        })
                                                    }                      
                                                </Swiper>
                                            </Box>                                          
                                        </AccordionCustom>
                                    </Grid>
                                    : null
                            }
                            {
                                !this.props.isScrap && !this.props.creation && this.props.listSources?.length > 0 ?
                                <Grid item xs={12}>
                                    <Divider/>                                
                                </Grid>
                                :null
                            }           
                            {
                                this.props.customAttributes && this.props.customAttributes?.filter(e => e.node.attributeType.input !== 'image' && !e.node.isSearchable && e.node.status).length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub"
                                    ? (
                                        <Grid item xs={12} style={{padding:"20px 42px"}}>
                                            <AccordionCustom 
                                                noborder={true}
                                                title={
                                                    <>
                                                        <Typography variant={"h4"} style={{ fontSize: 14, fontWeight: '600' }}>{this.props.customAttributes?.length > 1 ? this.props.t("products.list.cardproduct.attributes") : this.props.t("products.list.cardproduct.attribute")}</Typography>
                                                        <SelectCustom
                                                            id="collection-select"
                                                            onClick={(event) => event.stopPropagation()}
                                                            onChange={(event) => this.handleChangeDisplay(event)}
                                                            disabled={false}
                                                            value={this.state.displayAttributes}
                                                            style={{marginLeft: 16}}
                                                        >
                                                            <MenuItem value={'12'}>Affichage 1 colonne</MenuItem>
                                                            <MenuItem value={'6'}>Affichage 2 colonnes</MenuItem>
                                                        </SelectCustom>
                                                    </>
                                                }
                                            >
                                                <BoxCustomAttributs>
                                                    <Grid container>
                                                        {
                                                            process.env.REACT_APP_ENABLE_PRICE_WATCHER === 'true' || process.env.REACT_APP_ALLOW_PRODUCT_VALIDATE_BUTTON === 'true' 
                                                                ? this.props.customAttributes.length > 0
                                                                    ? this.props.customAttributes.filter(e => e.node.attributeType.input !== 'image' && !e.node.isSearchable && e.node.status).map(map)
                                                                    : null
                                                                : this.props.customAttributes.length > 0
                                                                    ? this.props.customAttributes.filter(e => e.node.attributeType.input !== 'image' && e.node.status).map(map)
                                                                    : null
                                                        }
                                                    </Grid>
                                                </BoxCustomAttributs>
                                            </AccordionCustom>
                                        </Grid>
                                    ) : null
                            }     
                            {
                                this.props.customAttributes && this.props.customAttributes?.filter(e => e.node.attributeType.input !== 'image' && !e.node.isSearchable && e.node.status).length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub"?
                                    <Grid item xs={12}>
                                        <Divider/>                                
                                    </Grid>
                                :null

                            }  
                            {/* <Grid item xs={12} style={{padding:"20px 42px"}}>
                                <AccordionCustom noborder title={<Typography variant={"h4"} style={{ fontSize: 14, fontWeight: '600' }}>Google preview</Typography>}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} style={{paddingLeft:"0",paddingBottom:5}}>
                                            <Typography style={{fontWeight:"bold"}}>Aperçu</Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{paddingBottom:5}}>
                                            <Typography style={{fontWeight:"bold"}}>Titre</Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{border:`0.5px solid ${colors.grey.border}`,borderRadius:"3px",padding:"20px",display:"flex",flexDirection:"column"}}>
                                            <Box style={{display:'flex',alignItems:'center',gap:5,marginBottom:30}}>
                                                <Box
                                                    style={{
                                                        background:"black",
                                                        width:15,
                                                        height:15
                                                    }}
                                                />
                                                <Typography>www.monsite.com </Typography>
                                                <ArrowForwardIosIcon style={{fontSize:15}}/> 
                                                <Typography style={{color:colors.grey.regular}}>Product-page-seo</Typography>
                                            </Box>
                                            <Box style={{
                                                paddingBottom:20
                                            }}>
                                                <Typography style={{fontWeight:"bold",fontSize:25,color:colors.blue.darker.hue300,marginBottom:10,lineHeight:"30px"}}>
                                                    {clampTextWithNumber(metaTitle?.node?.value?.length > 0?metaTitle?.node?.value:defaultName?.name,60)}
                                                </Typography>
                                                <Typography style={{fontWeight:"bold",fontSize:16,color:colors.green.regular,marginBottom:10}}>www.monurl.com</Typography>                                                    
                                                <Typography style={{fontSize:22,lineHeight:"30px"}}>
                                                {
                                                    clampTextWithNumber(parseHtml(metaDesc?.node?.value?.length > 0?metaDesc?.node?.value:defaultDesc?.description),160)
                                                }
                                                </Typography>                                                    
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} style={{display:"flex",flexDirection:'column',justifyContent:'space-between',paddingTop:0,paddingBottom:0,rowGap:20}}>
                                            <Box>                                                                               
                                                <TextField
                                                    fullWidth
                                                    variant='outlined'
                                                    value={metaTitle?.node?.value.length > 0?metaTitle?.node?.value:defaultName?.name}
                                                    disabled
                                                />
                                                <Box
                                                    style={{
                                                        border:`0.5px solid ${colors.grey.border}`,
                                                        borderTop:"none",
                                                        display:"flex",
                                                        justifyContent:"space-between",
                                                        padding:"5px 10px"
                                                    }}
                                                >
                                                    <Typography>Caractères: <span style={{color:getColorFromLength(metaTitle?.node?.value?.length > 0?metaTitle?.node?.value?.length:defaultName?.name?.length,30,60)}}>{metaTitle?.node?.value.length > 0?metaTitle?.node?.value.length:defaultName?.name.length}</span></Typography>
                                                    <Typography>Bonne pratique: <span style={{fontWeight:"bold"}}>30 / 60 caractères</span></Typography>
                                                </Box>
                                                <Box style={{
                                                    display:"flex",
                                                    justifyContent:"space-between",
                                                    alignItems:"center",
                                                    marginBottom:5
                                                }}>
                                                    <LinearProgressCustom color={getColorFromLength(metaTitle?.node?.value?.length > 0?metaTitle?.node?.value?.length:defaultName?.name?.length,30,60)} bgcolor={colors.grey.lighter.hue900} variant="determinate" value={getLinearProgressValue(metaTitle?.node?.value.length > 0?metaTitle?.node?.value.length:defaultName?.name.length,60)} />                                                
                                                    <Typography 
                                                        style={{textDecoration:"underline",color:"#0273A5",cursor:'pointer',marginTop:"10px"}}
                                                        onClick={this.props.toggleDrawer}
                                                    >
                                                            Modifier
                                                    </Typography>
                                                </Box>                 
                                            </Box>
                                            <Box>
                                                <Box style={{
                                                    display:"flex",
                                                    justifyContent:"space-between",
                                                    alignItems:"center",
                                                    marginBottom:5
                                                }}>
                                                    <Typography style={{fontWeight:"bold"}}>Description</Typography>                                                    
                                                </Box>
                                                <TextField
                                                    fullWidth
                                                    variant='outlined'
                                                    multiline                                                    
                                                    minRows={4}
                                                    maxRows={4}
                                                    value={parseHtml(metaDesc?.node?.value?.length > 0?metaDesc?.node?.value: defaultDesc?.description)}
                                                    disabled
                                                />
                                                <Box
                                                    style={{
                                                        border:`0.5px solid ${colors.grey.border}`,
                                                        borderTop:"none",
                                                        display:"flex",
                                                        justifyContent:"space-between",
                                                        padding:"5px 10px"
                                                    }}
                                                >
                                                    <Typography>Caractères: <span style={{color:getColorFromLength(metaDesc?.node?.value?.length > 0?metaDesc?.node?.value?.length:defaultDesc?.description?.length,50,160)}}>{metaDesc?.node?.value?.length > 0?metaDesc?.node?.value?.length: defaultDesc?.description?.length}</span></Typography>
                                                    <Typography>Bonne pratique: <span style={{fontWeight:"bold"}}>50 / 160 caractères</span></Typography>
                                                </Box>
                                                <Box style={{
                                                    display:"flex",
                                                    justifyContent:"space-between",
                                                    alignItems:"center",
                                                    marginBottom:5
                                                }}>                                                    
                                                    <LinearProgressCustom color={getColorFromLength(metaDesc?.node?.value?.length > 0?metaDesc?.node?.value?.length:defaultDesc?.description?.length,50,160)} bgcolor={colors.grey.lighter.hue900} variant="determinate" value={getLinearProgressValue(metaDesc?.node?.value?.length > 0?metaDesc?.node?.value?.length: defaultDesc?.description?.length,160)} />                                                                                                                                                    
                                                    <Typography 
                                                        style={{textDecoration:"underline",color:"#0273A5",cursor:'pointer',marginTop:"10px"}}
                                                        onClick={this.props.toggleDrawer}
                                                    >
                                                            Modifier
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </AccordionCustom>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>                                
                            </Grid> */}
                            {
                                this.props.customAttributes && this.props.customAttributes?.filter(e => e.node.attributeType.input !== 'image' && e.node.isSearchable && e.node.status).length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_ENABLE_PRICE_WATCHER === 'true' || process.env.REACT_APP_ALLOW_PRODUCT_VALIDATE_BUTTON === 'true'
                                    ? (
                                        <Grid item xs={12} style={{padding:"20px 42px"}}>
                                            <AccordionCustom noborder title={<Typography variant={"h4"} style={{ fontSize: 14, fontWeight: '600' }}>Catégorisation automatique d'attributs</Typography>}>
                                                <BoxCustomAttributs>
                                                    <Grid container>
                                                        {
                                                            this.props.customAttributes.length > 0
                                                                ? this.props.customAttributes.filter(e => e.node.attributeType.input !== 'image' && e.node.isSearchable && e.node.status).map(map)
                                                                : null
                                                        }
                                                    </Grid>
                                                </BoxCustomAttributs>
                                            </AccordionCustom>
                                        </Grid>
                                    ) : null
                            }
                            {
                                this.props.customAttributes && this.props.customAttributes?.filter(e => e.node.attributeType.input !== 'image' && e.node.isSearchable && e.node.status).length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub" && process.env.REACT_APP_ENABLE_PRICE_WATCHER === 'true' ?
                                    <Grid item xs={12}>
                                        <Divider/>                                
                                    </Grid>
                                :null
                            }                                 
                            {
                                this.props.listTaxos && this.props.listTaxos?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub"
                                    ? (
                                        <Grid item xs={12}>
                                            <AccordionCustom title={<Typography variant={"h4"} style={{ fontSize: 14, fontWeight: '600' }}>{this.props.listTaxos?.length > 1 ? this.props.t("products.list.cardproduct.taxonomy") : this.props.t("products.list.cardproduct.attribute")}</Typography>}>
                                                {
                                                    this.props.listTaxos.length > 0
                                                        ? this.props.listTaxos.map((taxo, index) => (
                                                            <Typography style={{ marginBottom: this.props.listTaxos.length !== index + 1 ? 4 : 0 }}>Assignation à la catégorie <SpanColor>{taxo.node.categoryName}</SpanColor> grâce au terme "<SpanColor>{taxo.node.taxoValue}</SpanColor>" trouvée dans l'attribut <SpanColor>{taxo.node.attributeName}</SpanColor>.</Typography>
                                                        ))
                                                        : null
                                                }
                                            </AccordionCustom>
                                        </Grid>
                                    ) : null
                            }
                            {
                                this.props.listTaxos && this.props.listTaxos?.length > 0 && process.env.REACT_APP_MODE_SPREAD !== "hub"?
                                    <Grid item xs={12}>
                                        <Divider/>                                
                                    </Grid>
                                :null
                            }                            
                            {
                                this.props.allState?.initialVariant?.length > 0 && !this.props.creation ?
                                    <Grid item xs={12} style={{padding:"20px 42px"}}>
                                        <Grid container>
                                            <AccordionCustom noborder={true} title={<Typography variant={"h4"} style={{ fontSize: 14, fontWeight: '600' }}>Variante{this.props.allState?.initialVariant?.length > 1 ? 's' : ''}</Typography>}>
                                                <Grid container>
                                                <TableContainer 
                                                    style={{
                                                        border: `1px solid rgba(224, 224, 224, 1)`,
                                                        borderBottom:"none"
                                                    }}
                                                >
                                                    <Table  aria-label="simple table">                                                                        
                                                      <TableBody>
                                                    {
                                                        this.props.allState?.initialVariant?.length > 0 ?
                                                            this.props.allState.initialVariant.map((variant,i) => {                                                                                                                               
                                                                return (                                                                    
                                                                    <TableRow key={i} style={{backgroundColor: i % 2 === 0 ? "white"  : "#F5F5F5"}} >
                                                                      <TableCell style={{width:"25%",padding:" 0  0 0 20px"}} size={'small'} component="th" scope="row">                                                                        
                                                                            <TextEllipsis
                                                                                color={colors.black.regular}
                                                                                text={`Ref. ${variant.sku}`}
                                                                                maxLine={1}
                                                                                ellipsis='...'
                                                                                hasModal={false}
                                                                            />
                                                                      </TableCell>
                                                                      <TableCell style={{width:"25%",fontWeight:"bold",padding:" 0  0 0 20px"}} align="left">{variant.name}</TableCell>
                                                                      <TableCell style={{width:"25%",padding:" 0  0 0 20px"}} align="left">Code variante : <span style={{fontWeight:"bold"}}> {variant.allAttr[0].valuesForSelect.find(e=>e.node.id ===variant.allAttr[0].values).node.translation.translationDatas.edges[0].node.value}</span></TableCell>                                                                      
                                                                      <TableCell align="right" style={{padding:" 0  20px 0 0"}}>
                                                                        {
                                                                        this.props.goTo ?
                                                                            <Grid container justifyContent="flex-end">
                                                                                <Typography 
                                                                                    onClick={() => this.props.goTo(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', variant.idVariant.replace('/api/products/', '')))}
                                                                                    style={{
                                                                                        textDecoration:"underline",
                                                                                        color:colors.blue.darker.hue300,
                                                                                        cursor:'pointer',
                                                                                        padding:"10px 0"
                                                                                    }}
                                                                                >
                                                                                    Voir le produit
                                                                                </Typography>
                                                                                {/* <Button text={'Voir le produit'} arrow={'right'} onClick={() => this.props.goTo(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', variant.idVariant.replace('/api/products/', '')))} /> */}
                                                                            </Grid>
                                                                            : null
                                                                    }
                                                                    </TableCell>
                                                                    </TableRow>                                                                  
                                                                )
                                                            })
                                                            : <PageLoader />
                                                    }
                                                        </TableBody>
                                                      </Table>
                                                    </TableContainer>      
                                                </Grid>
                                            </AccordionCustom>
                                        </Grid>
                                    </Grid>
                                    : null
                            }                            
                            {
                                this.props.allState?.initialVariant?.length > 0 && !this.props.creation ?
                                    <Grid item xs={12}>
                                        <Divider/>                                
                                    </Grid>
                                :null
                            }
                            {
                                process.env.REACT_APP_MODE_SPREAD !== "hub" && product.retailersInfos?.length > 0 ?
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <AccordionCustom title={<Typography variant={"h4"} style={{ fontSize: 14, fontWeight: '600' }}>Fiche{this.props.allState.suggestionsProduct.length > 1 ? 's' : ''} produit{this.props.allState.suggestionsProduct.length > 1 ? 's' : ''} retailers</Typography>}>
                                                <Grid container direction="row" style={{ marginTop: 8 }}>
                                                    {
                                                        product.retailersInfos.map((retailer) => {
                                                            return (
                                                                <Grid item xs={3} onClick={() => { window.open(retailer.url, retailer.retailer, `width=960, height=800, top=100, right=100, left=${window.screen.width - 1060}`) }} style={{ display: "flex", alignItem: "center", cursor: 'pointer', color: colors.grey.regular, padding: '0 8px' }}>
                                                                    <CardCustom style={{ width: '100%' }} cardContentStyle={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                                                            <img src={`${process.env.REACT_APP_API_ROOT}/${retailer.retailer_logo}`} style={{ maxHeight: 50 }} />
                                                                            <Typography variant="h4">{retailer.retailer}</Typography>
                                                                        </Grid>
                                                                        <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ marginTop: 16 }}>
                                                                            <OpenInNewIcon />
                                                                            <Typography variant="body1">Voir la page</Typography>
                                                                        </Grid>
                                                                    </CardCustom>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </AccordionCustom>
                                        </Grid>
                                    </Grid>
                                    : null
                            }
                            {
                                process.env.REACT_APP_MODE_SPREAD !== "hub" && product.retailersInfos?.length > 0 ?
                                    <Grid item xs={12}>
                                        <Divider/>                                
                                    </Grid>
                                :null
                            }
                            {
                                process.env.REACT_APP_MODE_SPREAD === "hub" && product.stocksProduct.length > 0 ?
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <AccordionCustom title={<Typography variant={"h4"} style={{ fontSize: 14, fontWeight: '600' }}>Stock</Typography>}>
                                                <Grid container >
                                                    <Grid item xs={4}>
                                                        {
                                                            product.stocksProduct.map((stock) => {
                                                                if (stock.value > 0) {
                                                                    return (
                                                                        <Grid container alignItems="center">
                                                                            {stock.type === 'store' ?
                                                                                <StoreIcon style={{ color: colors.grey.regular }} />
                                                                                :
                                                                                <EqualizerIcon style={{ color: colors.grey.regular }} />
                                                                            }
                                                                            <Typography variant="body1" style={{ marginLeft: 4 }}>{stock.libelle}: <b><SpanColor>{stock.value}</SpanColor></b></Typography>
                                                                        </Grid>
                                                                    )
                                                                } else {
                                                                    return null
                                                                }
                                                            })
                                                        }
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="h3" style={{ textDecoration: 'underline', marginBottom: 8 }}>Réapprovisionnement</Typography>
                                                        {
                                                            this.props.datasStocks.reappro.map((value) => {
                                                                let date = moment(value.date);
                                                                let diff = date.diff(moment(), 'days') + 1;
                                                            
                                                                return (<Typography variant="body1" style={{ marginTop: 4 }}>
                                                                    <SpanColor>{value.value}</SpanColor> unité{value.value > 1 ? 's' : null} le {date.format('DD MMMM YYYY')} {diff > 0 ? `(J+${diff})` : null}
                                                                </Typography>)
                                                            })
                                                        }
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="h3" style={{ textDecoration: 'underline', marginBottom: 8 }}>Commandes</Typography>
                                                        <Typography variant="body1" style={{ marginTop: 4 }}>Produit dans <SpanColor>{product.commandsProduct.total > 0 ? product.commandsProduct.total : 'aucune'}</SpanColor> {product.commandsProduct.total > 1 ? 'commandes.' : 'commande.'}</Typography>
                                                        <Typography variant="body1" style={{ marginTop: 4 }}><SpanColor>{product.commandsProduct.pending > 0 ? product.commandsProduct.pending : "Aucun"}</SpanColor> {product.commandsProduct.pending > 1 ? 'produits en attente de traitement' : 'produit en attente de préparation'} en préparation.</Typography>
                                                        <Typography variant="body1" style={{ marginTop: 4 }}><SpanColor>{product.commandsProduct.preparation > 0 ? product.commandsProduct.preparation : "Aucun"}</SpanColor> {product.commandsProduct.preparation > 1 ? 'produits en cours de préparation.' : 'produit en cours de préparation.'}</Typography>
                                                        <Typography variant="body1" style={{ marginTop: 4 }}><SpanColor>{product.commandsProduct.shipped > 0 ? product.commandsProduct.shipped : "Aucun"}</SpanColor> {product.commandsProduct.shipped > 1 ? 'produits expédiés.' : 'produit expédié.'}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionCustom>
                                        </Grid>
                                    </Grid>
                                    : null
                            }
                            {
                                process.env.REACT_APP_MODE_SPREAD === "hub" && product.stocksProduct.length > 0 ?
                                    <Grid item xs={12}>
                                        <Divider/>                                
                                    </Grid>
                                :null
                            }
                            {
                                product.fetchedProduct?.productInfos?.edges?.length > 0 ?
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <AccordionCustom title={<Typography variant={"h4"} style={{ fontSize: 14, fontWeight: '600' }}>Infos</Typography>}>
                                                <Grid container >
                                                    <Grid item xs={12}>
                                                        {
                                                            product.fetchedProduct.productInfos.edges.map((info, index) => {
                                                                return(
                                                                    <>
                                                                        <Grid container>
                                                                            <Grid container>
                                                                                <a href={`${process.env.REACT_APP_MEDIAS}/${info.node.image.filePath}`} target={"_blank"}>{info.node.image.filePath}</a>
                                                                                <Box ml={1} mr={1}>
                                                                                    {'>'}
                                                                                </Box>
                                                                                <Box>
                                                                                    <a href={`${process.env.REACT_APP_MEDIAS}/${info.node.image2.filePath}`} target={"_blank"}>{info.node.image2.filePath}</a>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Box>
                                                                                <Typography variant="body1" dangerouslySetInnerHTML={{__html: info.node.info}}/>
                                                                            </Box>
                                                                        </Grid>
                                                                        {
                                                                            product.fetchedProduct.productInfos.edges.length === index + 1 ?
                                                                                null
                                                                            : <Divider style={{marginTop: 16, marginBottom: 16}}/>
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </AccordionCustom>
                                        </Grid>
                                    </Grid>
                                    : null
                            }                            
                        </Grid>
                    
                </Grid>                                                                                                                                
            </Grid>

        );
    }
}
export default withTranslation()(withRouter(CardProductDetails));