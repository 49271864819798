import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';


const CardStyled = styled(Card)`
    box-shadow: none !important;
    border-radius: 0;
    border: ${props => props.collapse ? '0' : '0.5px solid ' + colors.grey.lighter.hue700};
    width : ${props => props.width ? props.width : 'unset'};
    margin-top:${props => props.margintop ? props.margintop : ''};
    margin-bottom:${props => props.marginbottom ? props.marginbottom : ''};
    &:hover{
        border-color: ${props =>
        props.onClick ? colors.blue.darker.hue300
            : props.hoverbordercolor ? props.hoverbordercolor : colors.grey.lighter.hue700};
    }
`;

const CardContentStyled = styled(CardContent)`
    padding: ${props => props.contentpadding ? `${props.contentpadding} !important` : ''};
    height: 100%;
    padding-bottom: ${props => props.paddingbottom ? `${props.paddingbottom} !important` : ''};
    padding-top: ${props => props.paddingtop ? props.paddingtop : ''};
    margin-left: ${props => props.marginleft ? props.marginleft : ''};
`;

export default function CardCustom(props) {
    const { hoverbordercolor, paddingbottom, paddingtop, contentpadding, hovercard, width, margintop, marginbottom, collapse, marginleft, onClick } = props;

    return (
        <CardStyled style={props.style} hoverbordercolor={hoverbordercolor} paddingbottom={paddingbottom} paddingtop={paddingtop} hovercard={hovercard ? hovercard.toString() : null} width={width} margintop={margintop} marginbottom={marginbottom} collapse={collapse} onClick={onClick ? onClick : null}>
            <CardContentStyled contentpadding={contentpadding} style={props.cardContentStyle ? props.cardContentStyle : null} paddingbottom={paddingbottom} marginleft={marginleft}>
                {props.children}
            </CardContentStyled>
        </CardStyled>
    );
}
