import React from 'react';
import { Box, Grid, Typography, Tooltip, TextField, Checkbox, MenuItem } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/not-found.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DoneIcon from '@material-ui/icons/Done';

const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 13px;
        padding: 10px;
    }
    & > p{
        margin: 0;
    }
`;
const ButtonCustom = styled(Button)`
    margin: 0;
    text-transform: unset;
    font-weight: bold;
`;
const Title = styled(Typography)`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;
const Sku = styled(Typography)``;

const SpanColor = styled.span`
    color: ${colors.blue.darker.hue300};
    font-weight: bold;
    display: block;
    width: 100%;
`;
const TableCellCustom = styled(TableCell)`
    border: none;
    // display: inline-block;
    // width: calc(100% / 9);
    padding: 3px 16px;
`;
const CircularProgressCustom = styled(CircularProgress)`
    color: ${props => props.value > 30 
            ? props.value > 65 
                ? colors.green.regular : "#f6d607"
            : colors.red.regular};
    margin-left: ${props => props.marginleft};
`;

const CheckboxCustom = styled(Checkbox)`
    background: white;
    border-radius: 5px;
    padding: 2px;
    svg{
        fill: ${colors.blue.darker.hue300}
    }
    &:hover{
        background: white!important;
        svg{
            fill: ${colors.blue.lighter.hue150}
        }
    }
    input{
        width: 40px;
    }
`;

const CheckboxCustomlist = styled(CheckboxCustom)`
    background: transparent;
    margin: 0;
`
const BoxCustom = styled(Box)`
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
`


export default function CardProductList(props){

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.black.regular,
        },
        tooltip: {
            backgroundColor: colors.black.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }

    const product = props.product.node;
    //const attributes = product.productDatas.edges;

    let name = '';
    let image= '';
    let imageSelected = [];
    let getImageByTranslation = '';
    
    // for(let attribute of attributes){
    //     if(attribute.node.attribute.isSystem){
    //         imageSelected = attributes.filter(e => e.node.media !== null)
    //         getImageByTranslation = imageSelected.filter(e => e.node.locale.code === props.currentLang)
    //         name = getTraductionAttributs('product_name', attributes, props.currentLang);
    //         if(attribute.node.attribute.identifier === 'product_image' && image === ''){
    //             image = getTraductionAttributs('product_image', attributes, props.currentLang, 'image');
    //         }
    //     }
    // }
    
    name = product.flatProducts?.edges[0]?.node.name;
    image = product.flatProducts?.edges[0]?.node.image;

    let sku = product.flatProducts?.edges[0]?.node.sku;
    let productStatus = product.flatProducts?.edges[0]?.node.status;
    let categoriesNb = product.flatProducts?.edges[0]?.node.categoriesNb || 0;
    let catalogsNb = product.flatProducts?.edges[0]?.node.catalogsNb;
    let childrenNb = product.flatProducts?.edges[0]?.node.childrenNb;
    let imagesNb = product.flatProducts?.edges[0]?.node.imagesNb || 0;
    let completude = product.flatProducts?.edges[0]?.node.completude;
    let nbAttrImages = product.flatProducts?.edges[0]?.node.imagesMaxNb || 1;

    // let localStorage_GET_NB_ATTR_IMAGES = localStorage.getItem('GET_NB_ATTR_IMAGES');
    // if(!localStorage_GET_NB_ATTR_IMAGES){
    //     let localStorageAttributes  = localStorage.getItem('ATTRIBUTES');
    //     let attributes = JSON.parse(localStorageAttributes);
    //     for (let attribute of attributes.product.attributes.edges){
    //         if(attribute.node.attributeType.input=='image'){
    //             nbAttrImages++;
    //         }
    //     } 
    //     localStorage.setItem('GET_NB_ATTR_IMAGES',nbAttrImages);
    // } else {
    //     nbAttrImages = localStorage.getItem('GET_NB_ATTR_IMAGES');
    // }

    let catalogList = []
    // for (let category of product.productCategories.edges){
    //     if (category.node.category.catalog){
    //         if (catalogList.indexOf(category.node.category.catalog.libelle) === -1){
    //             catalogList.push(category.node.category.catalog.libelle)
    //         }
    //     }
    // }

    const goTo = props.routeProduct;
    console.log('imagesNb', imagesNb)
    return(
        <TableRow key={`rowProduct${props.index}`} onClick={props.selectedProducts ? () => props.handleToggle(props.product) : null} style={{cursor: props.selectedProducts ? 'pointer' : 'initial'}}>
            {
                props.selectedProducts ?
                <TableCellCustom component="th" scope="row" align="center">
                    <CheckboxCustomlist
                        edge="start"
                        checked={props.reverseCheck ? props.selectedProducts?.indexOf(product.id) !== -1 ? false : true : props.selectedProducts?.indexOf(product.id) === -1 ? false : true}
                    />
                </TableCellCustom>
                : null
            }
            <TableCellCustom component="th" scope="row" align="center">
                {
                    image && image !== '' ? 
                        (<img src={`${process.env.REACT_APP_MEDIAS}/${image}`} alt={name} style={{maxWidth: '100%', maxHeight: 40}}/>) 
                    : 
                        (<img src={NoImage} style={{maxWidth: '100%', maxHeight: 40}} alt={name}/>)
                }
            </TableCellCustom>
            <TableCellCustom >
                <Sku variant="body2">{product.sku}</Sku>
            </TableCellCustom>
            <TableCellCustom style={{minWidth: props.minimalForm ? null : 150}}>
                <Title variant="body1">{name || 'N/A'}</Title>
            </TableCellCustom>
            {
                !props.minimalForm ?
                <>
                    <TableCellCustom align="center" >
                       
                        {
                            <BootstrapTooltip 
                                title={categoriesNb}
                                arrow
                            >
                                <SpanColor> {categoriesNb}</SpanColor>
                            </BootstrapTooltip>
                        }
                    </TableCellCustom>
                    <TableCellCustom align="center">
                        {
                            catalogsNb> 0 ? (
                                <BootstrapTooltip 
                                    title={catalogsNb}
                                    arrow
                                >
                                    <SpanColor> {catalogsNb}</SpanColor>
                                </BootstrapTooltip>
                            ) : ( <Typography variant="body1"><SpanColor>Aucun catalogue</SpanColor></Typography> ) 
                        }
                    </TableCellCustom>
                </>
                : null
            }
            <TableCellCustom align="center" >
                <StatusInfo status={product.status} noText={true} justify={"center"}/>
            </TableCellCustom>
            {
                process.env.REACT_APP_MODE_SPREAD !== "hub" && !props.minimalForm ? 
                    <>
                        <TableCellCustom align="center">
                            <BoxCustom position="relative" display="inline-flex">
                                <CircularProgressCustom variant="determinate" thickness={2.5} value={completude} style={{ position: "absolute", left: 0, top: 0, zIndex: 2 }} size={50}/>
                                <CircularProgress
                                    variant="determinate"
                                    value={100}
                                    disableShrink
                                    thickness={2.5}
                                    size={50}
                                />
                                <Box
                                    top={0}
                                    left={0}
                                    bottom={0}
                                    right={0}
                                    position="absolute"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography variant="h5" style={{color: colors.green.regular, fontWeight: 'bold'}}>
                                        {completude > 0 ? `${Math.round(completude)}%` : `N/A`}
                                    </Typography>
                                </Box>
                            </BoxCustom>
                        </TableCellCustom>
                        <TableCellCustom align="center">
                            <Grid container alignItems="center" justifyContent="center">
                                <Typography variant="body1"><SpanColor style={{width: "auto", display:"inline"}}>{imagesNb}</SpanColor>/{nbAttrImages}</Typography>
                            </Grid>
                        </TableCellCustom>
                    </>
                : null
            }
            <TableCellCustom align="right">
                <ButtonCustom disableElevation={true} stopPropagation={props.inForm} text={props.minimalForm ? null :"Voir"} arrow="right" bgColor={colors.green.regular}  onClick={goTo} />
            </TableCellCustom>
        </TableRow>
    );
}