import { Box, Divider, Fade, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import { useState } from "react"
import styled from "styled-components";
import colors from "../../../config/theme/colors";
import MoreVertIcon from '@material-ui/icons/MoreVert';

const MenuCustom = styled(Menu)`
    & .MuiMenu-paper:before {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      right: 29px;
      width: 20px;
      height: 20px;
      transform: translateY(-50%) rotate(45deg);
      background-color: white;
      border-top: ${props => '1px solid' + colors.grey.border};
      border-left: ${props => '1px solid' + colors.grey.border};
    }
    & .MuiMenu-paper {
      overflow: visible;
      margin-top: 15px;
      margin-left: 30px;

      & .MuiList-root {
        margin: 0px;
        padding: 0px;
      }
    }
`

const MenuItemCustom = styled(MenuItem)`
    & {
      min-width: 230px;
      background: ${props => props.isDisabled ? '#e0e0e0' : 'white'} !important;
      cursor: ${props => props.isDisabled ? 'initial' : 'pointer'};

      span{
        color: ${props => props.isDisabled ? `${colors.grey.lighter.hue600}` : props.colorhover} !important;
      }
      svg{
        fill: ${props => props.isDisabled ? `${colors.grey.lighter.hue600}` : props.colorhover} !important;
      }
    }

    &:hover, &:focus{
        background-color: ${props => props.isDisabled ? '#e0e0e0' : props.colorhover} !important;

        span{
          color: ${props => props.isDisabled ? `${colors.grey.lighter.hue600}` : 'white'} !important;
        }
        svg{
          fill: ${props => props.isDisabled ? `${colors.grey.lighter.hue600}` : 'white'} !important;
        }
    }

    & > ul {
      margin: 0px;
      padding: 0px;
    }
`

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid #d3d4d5',
    boxShadow: 'none',
    borderRadius: '0px',
  }
}));

const OurMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);

  const classes = useStyles();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box>
      {
        props.hasSquare ? (
          <Box aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick} style={{
            width: '20px',
            height: '35px',
            backgroundColor: colors.white,
            zIndex: 100,
            border: '1px solid ' + colors.grey.border,
            position: 'absolute',
            top: '0',
            right: '0',
            ...props.customSquareStyle
          }}>
            <Box style={{
              width: '100%',
              height: '100%',
              position: 'relative'
            }}>
              <Typography variant="h4" style={{ 
                color: colors.black.regular, 
                fontSize: 25, 
                fontWeight: 'bold', 
                lineHeight: 0, 
                cursor: 'pointer',
                // transform: !open ? 'translate(8px, -8px)' : null,
                // rotate: !open ? '90deg' : null,
                rotate: '90deg',
                transition: 'all .5s',
                zIndex: '100',
                position: 'absolute',
                top: '50%',
                right: '-50%',
                ...props.customStyle
              }}>...</Typography>
            </Box>
          </Box>
        ) : (
          <Box aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            <Typography variant="h4" style={{ 
              color: colors.black.regular, 
              fontSize: 25, 
              fontWeight: 'bold', 
              lineHeight: 0, 
              cursor: 'pointer',
              // transform: !open ? 'translate(8px, -8px)' : null,
              // rotate: !open ? '90deg' : null,
              transform: 'translate(8px, -8px)',
              rotate: '90deg',
              transition: 'all .5s',
              zIndex: '100',
              ...props.customStyle
            }}>...</Typography>
          </Box>
        )
      }
      <MenuCustom
        disableAutoFocusItem
        style={{
          marginTop: props.hasSquare ? '30px' : '20px',
          marginLeft: props.hasSquare ? '10px' : null,
        }}
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: props.hasSquare ? 'center' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableScrollLock={false}
        PopoverClasses={{
          paper: classes.paper
        }}
        {...props}
      >
        {props.menuOptions.map((option, index, arr) => (
          <Box>
            <MenuItemCustom key={index} colorhover={option.color} isDisabled={option.isDisabled} onClick={(e) => {
              if(option.isDisabled) {
                e.stopPropagation();
                return
              }
              props.handleClick(e, option)
              handleClose(e)
            }
            }>
              <Box mr={1} fontSize={12} display="flex" alignItems={"center"}>
                {option.icon}
              </Box>
              <Typography variant="inherit" noWrap style={{ fontWeight: 'bold', fontSize: '12px', color: option.color }}>
                {option.label}
              </Typography>
            </MenuItemCustom>
            {arr.length !== index + 1 ? (<Divider />) : null}
          </Box>
        ))}
      </MenuCustom>
    </Box>
  )

}

export default OurMenu