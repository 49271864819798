import React from 'react';
import { Grid } from '@material-ui/core';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardContent from '../../../layouts/Card/cardContent/CardContent';
import TabCategories from '../products/components/TabCategories';
import Typography from '../../../ui/typography/Typography';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import styled from 'styled-components';
import { ROUTE_CONTENU_LIST_DETAIL, ROUTE_CONTENU_ADD, ROUTE_HOME } from '../../../../js/constants/route-names';
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import { GET_CONTENTS_PAGINATION, GET_CONTENTS_CURSOR, SEARCH_CONTENTS_SKU, SEARCH_CONTENTS_SKU_BY_CAT } from '../../../../queries/contents';
import { GET_CONTENT_CATEGORIES_ONLY } from '../../../../queries/content_categories';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import importProductsTypesConfig from './config/importProductsTypes.config';
import importProductsConfig from './config/importProducts.config';
import importFichier from '../../../../assets/pictos/icon-import-fichier.svg';
import importAPI from '../../../../assets/pictos/icon-import-api.svg';
import importFlux from '../../../../assets/pictos/icon-import-flux.svg';
import emptyProduct from '../../../../assets/pictos/empty-picto/product.svg';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import request from '../../../../js/utils/fetch';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';
import Pagination from '@material-ui/lab/Pagination';
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect';
import { CONTENTS, CONTENTS_CONTENTS, VIEW, CREATE, IMPORT } from '../../../../js/constants/constant-rights';
import TreeCategories from '../../../ui/tree-view/TreeCategories';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import BreadcrumbCustom from '../../../ui/breadcrumb/Breadcrumb';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import FolderSharpIcon from '@material-ui/icons/FolderSharp';
import OurTooltip from '../../../ui/tooltip/Tooltip';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import CardCatalog from '../../../layouts/Card/cardContent/CardCatalog';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1400px){
        padding: 16px 8px 8px!important;
    }
`;

const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;

const FolderContainer = styled(Grid)`
    display: grid;
    grid-template-columns:
        ${props => props.windowWidth > 1500 ? 'repeat(auto-fill, minmax(20%, 1fr))' // 4 columns
        : props.windowWidth > 765 ? 'repeat(auto-fill, minmax(25%, 1fr))' // 3 columns
            : 'repeat(auto-fill, minmax(33%, 1fr))'}; // 2 columns
    width: 100%;
    gap: 17px;
    margin-top: 9px;
`

const FolderCustom = styled(Grid)`
    background-color: ${colors.white};
    border: 0.5px solid ${colors.grey.lighter.hue700};
    padding: 10px 15px;
    color: ${colors.black.regular};
    font-weight: bold;
    height: 52px;
    gap: 10px;
    cursor: pointer;
    '&:hover': {
        background-color: ${colors.grey.lighter.hue900}
    }
`

class ListContents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLang: props.locales[0].node.code,
            openForm: false,
            openFormImport: false,
            catalogFormData: {},
            ready: false,
            page: 1,
            nbperpage: 12,
            countPage: 0,
            cursor: null,
            skulist: [],
            searchValue: '',
            arrayCatFilter: [],

            previousFolders:[],
            currentFolder: null,            
            categories:[],
            areCategoriesLoading: true,
            contents:[],
            contentIsLoading:true
        }
        this.handleChange = this.handleChange.bind(this);
        this.typingSearchTimer = null;
    }

    handleRecursiveCat = (breadcrumb) => {
        this.state.activeCategorie.push('Home');
        if (breadcrumb) {
            for (let categorie of breadcrumb) {
                let translatedLibelle = categorie.categoryDatas.find(e => e.locale.code === this.state.currentLang)
                if (translatedLibelle) {
                    this.state.activeCategorie.push(` > ${translatedLibelle.value}`);
                } else {
                    this.state.activeCategorie.push(` > ${categorie.libelle}`);
                }
            }
        }
    };

    handleSearch = () => {
        this.props.startLoading();
        let value = this.state.searchValue;
        if (value !== "" && this.state.listCursors.length > 0) {
            this.props.client.query({
                query: SEARCH_CONTENTS_SKU,
                variables: { "needle": value },
                fetchPolicy: 'no-cache'
            }).then(result => {
                let stockSku = this.state.skulist;
                this.setState({
                    skulist: [],
                    page: 1,
                    cursor: this.state.listCursors[0].cursor
                }, () => {
                    if (this.state.activeCategorie.length <= 1) {
                        for (let item of result.data.contentDatas.edges) {
                            if (this.state.skulist.indexOf(item.node.content.sku) === -1) {
                                this.state.skulist.push(item.node.content.sku);
                            }
                        }
                    }
                    else {
                        for (let sku of stockSku) {
                            let check = false;
                            for (let item of result.data.contentDatas.edges) {
                                if (sku === item.node.content.sku && !check) {
                                    check = true;
                                }
                            }
                            if (check) {
                                this.state.skulist.push(sku);
                            }
                        }
                    }
                });
                if (this.state.skulist.length === 0) {
                    this.setState({ noResult: true });
                    this.props.stopLoading();
                }
                else {
                    this.setState({ noResult: false }, () => {
                        this.handleGetContents();
                    });
                }
            });
        } else {
            this.setState({
                skulist: [],
                noResult: false
            }, () => {
                if (this.state.activeCategorie.length <= 1) {
                    this.handleGetContents();
                }
                else {
                    this.handleChangeTab(null, null, this.state.idActiveCat);
                }
            });
        }
    }

    handleChange(e, value) {
        clearTimeout(this.typingSearchTimer);
        if (e?.target?.value) {
            this.setState({ searchValue: e.target.value });
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearch();
            }, 500);
        }
        else if (value) {
            this.setState({
                searchValue: e.target.value
            }, this.handleSearch);
        }
        else {
            this.setState({
                skulist: [],
                noResult: false,
                searchValue: ''
            }, () => {
                if (this.state.activeCategorie.length <= 1) {
                    this.handleGetContents();
                }
                else {
                    this.handleChangeTab(null, null, this.state.idActiveCat);
                }
            });
        }
    }

    getChildrenCat = (id) => {
        for (let categorie of this.state?.categories) {
            if (categorie.id === id) {
                for (let child of categorie?.childrens) {
                    this.state.arrayCatFilter.push(child.id);
                }
            }
        }
    }

    handleChangeTab = (event, id, breadcrumb) => {
        this.props.startLoading();
        if (id) {
            this.setState({
                idActiveCat: id,
                arrayCatFilter: [id],
            }, () => {
                this.getChildrenCat(id);
                this.props.client.query({
                    query: SEARCH_CONTENTS_SKU_BY_CAT,
                    variables: { "idlist": this.state.arrayCatFilter },
                    fetchPolicy: 'no-cache'
                }).then(result => {                    
                    this.setState({
                        skulist: [],
                        page: 1,
                        cursor: this.state.listCursors[0].cursor
                    }, () => {
                        for (let item of result.data.contentCategories) {
                            for (let content of item.contents.edges) {
                                if (this.state.skulist.indexOf(content.node.sku) === -1) {
                                    this.state.skulist.push(content.node.sku);
                                }
                            }
                        }
                        if (this.state.searchValue && this.state.searchValue !== '') {
                            this.handleChange(null, true);
                        }
                        else {
                            if (this.state.skulist.length === 0) {
                                this.setState({ noResult: true });
                                this.props.stopLoading();
                            }
                            else {
                                this.setState({ noResult: false }, () => {
                                    this.handleGetContents();
                                })
                            }
                        }
                    });
                    this.setState({ activeCategorie: [] }, () => { this.handleRecursiveCat(breadcrumb) });
                });
            });


        } else {
            this.setState({
                activeCategorie: ['Tout voir'],
                noResult: false
            }, () => {
                this.handleChange(null, false);
            });
        }
    };

    handleGetCursorsContents = () => {
        const getCursorsContents = GET_CONTENTS_CURSOR;
        this.props.client.query({
            query: getCursorsContents,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursors: result.data.contents.edges
            });
        });
    }

    handleGetContents(id = null, breadcrumb = null) {
        return new Promise((resolve, reject) => {
            // Root
            if (!id) {
                const getContents = GET_CONTENTS_PAGINATION;
                let variables =
                {
                    "nbperpage": this.state.nbperpage,
                };
                if (this.state.cursor && this.state.listCursors && this.state.cursor !== this.state.listCursors[0].cursor) {
                    variables.cursor = this.state.cursor;
                }
                if (this.state.skulist.length > 0) {
                    variables.skulist = this.state.skulist;
                }
                this.props.client.query({
                    query: getContents,
                    variables,
                    fetchPolicy: 'no-cache'
                }).then(result => {
                    this.listContents = result.data.contents.edges;
                    this.setState({
                        countPage: Math.ceil(result.data.contents.totalCount / this.state.nbperpage),
                        listContents: this.listContents,
                    });
                    this.props.stopLoading();
                    resolve();
                });
            } else {
                // Not root
                this.setState({
                    idActiveCat: id,
                    arrayCatFilter: [id],
                }, () => {
                    this.getChildrenCat(id);
                    this.props.client.query({
                        query: SEARCH_CONTENTS_SKU_BY_CAT,
                        variables: { "idlist": this.state.arrayCatFilter },
                        fetchPolicy: 'no-cache'
                    }).then(result => {
                        this.setState({
                            skulist: [],
                            page: 1,
                            cursor: this.state.listCursors[0].cursor
                        }, () => {
                            for (let item of result.data.contentCategories) {
                                for (let content of item.contents.edges) {
                                    if (this.state.skulist.indexOf(content.node.sku) === -1) {
                                        this.state.skulist.push(content.node.sku);
                                    }
                                }
                            }
                            this.setState({
                                listContents: result.data.contentCategories[0].contents.edges,
                                ready: true,
                            })
                            if (this.state.searchValue && this.state.searchValue !== '') {
                                this.handleChange(null, true);
                            }
                            else {
                                if (this.state.skulist.length === 0) {
                                    this.setState({ noResult: true });
                                    this.props.stopLoading();
                                }
                                else {
                                    this.setState({ noResult: false }, () => {
                                        this.handleGetContents();
                                    })
                                }
                            }
                        });
                        this.setState({ activeCategorie: [] }, () => { this.handleRecursiveCat(breadcrumb) });
                    });
                });
            }
        });
    }

    handleGetCategories() {
        return new Promise((resolve, reject) => {
            request(`${process.env.REACT_APP_API}/categories-list/content`, 'get').then(
                (data) => {
                    if (data.success) {
                        this.setState({
                            categories: data.datas,
                            activeCategorie: ['Tout voir']
                        });
                    }
                    resolve();
                }
            );
        });
    }

    componentDidMount() {
        checkRouting(this.props);
        this.getAll();
    }

    getSavedContentCategoryId(){
        const savedContentCategory = JSON.parse(localStorage.getItem('CONTENT_CATEGORY'));
        if(savedContentCategory?.parent?.id){
            return savedContentCategory?.id.split('/').pop();
        }
        return null;
    }

    async getAll() {
        await this.handleGetCategories();
        await this.handleGetCursorsContents();
        await this.handleGetContents(this.getSavedContentCategoryId());
        this.setState({ ready: true });
    }

    handleToggleDrawer = (stateDrawer) => {
        this.setState({
            [stateDrawer]: !this.state[stateDrawer]
        });
    };

    handleFormImport = (type, title) => {
        this.setState({
            catalogFormData: {
                type,
                title
            },
            mapper: [],
            media: null,
            headers: null,
            importFile: null,
            importSep: ';',
            importValues: {},
            importLang: this.props.locales[0].node.id
        });

        this.handleToggleDrawer('openFormImport');
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();

            this.forceUpdate();
        });
    };

    handlerMutation = async () => {
        this.props.startLoading();

        let importConfig = {
            "url": `${process.env.REACT_APP_API_ROOT}${this.state.media?.contentUrl ?? null}`,
            "mapper": this.state.mapper,
            "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'content').node.id,
            "locale": this.state.importLang,
            "delimiter": this.state.importSep
        };

        try {
            // todo thomas
            await request(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
            this.props.snack(ALERT_SUCCESS, this.props.t("content.content.importMediaSuccess"), 6000);
            this.getAll();
            this.handleToggleDrawer('openForm');
            this.handleToggleDrawer('openFormImport');
            this.props.stopLoading();
        } catch (e) {
            this.props.snack(ALERT_ERROR, this.props.t("content.content.importMediaFailed"));
            this.props.stopLoading();
        }
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        this.setState({
            [stateName]: value?.target?.value ?? value
        }, callback);
    };

    changePage = (event, page) => {
        this.props.startLoading();
        let index = 0;
        if (page > 1) {
            index = (page * this.state.nbperpage) - this.state.nbperpage - 1
        }
        this.setState({
            cursor: this.state.listCursors[index].cursor,
            page: page
        }, () => {
            this.handleGetContents();
        });
    };

    // Breadcrumb
    getClickedCategory = (id) => {
        /* The user has clicked one category from the breadcrumb */
        if (id) {
            const selectedFolderId = this.state.previousFolders.filter(folder => folder).findIndex(folder => folder.id === id)
            /* Change the current folder. Not counting the Root */
            this.setState({
                currentFolder: this.state.previousFolders[selectedFolderId + 1]
            }, () => {
                /* Change the previous folders. Remove the clicked one and forward. Not counting the Root */
                this.state.previousFolders.splice(selectedFolderId + 1)
                this.setState({
                    categories: this.state.currentFolder.childrens
                }, () => {
                    this.filterMedias()
                })
            })

            /* The user has clicked "Root" to come back to the root folder and reset the breadcrumb */
        } else {
            this.setState({
                previousFolders: [],
                currentFolder: null,
                categories: this.state.rootCategories
            })
        }
    }

    handleGetCategories() {
        this.setState({
            areCategoriesLoading: true,
        })
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_CONTENT_CATEGORIES_ONLY,
                fetchPolicy: 'no-cache'
            }).then(result =>{
                // result.data.mediaObjectCategories.edges.map(
                    //     e => categoriesRemoveNode.push(e.node)
                    // )
                    // this.setState({
                        //     categories: categoriesRemoveNode,
                        //     rootid: result.data.mediaObjectCategories.edges[0].node.id,
                        //     activeCategorie: ['Tout voir']
                        // });          
                        console.log(result)              
                let categories = []                                
                this.setState({
                    categories: result.data.contentCategories,
                    rootCategories: result.data.contentCategories,
                    areCategoriesLoading: false,
                })

                resolve();
            });
            // request(`${process.env.REACT_APP_API}/categories-list/media`, 'get').then(
            //     (data) => {
            //         if (data.success) {
            //             /* const mapCategories = data.datas.map((category) => category['node']); */

            //             this.setState({
            //                 categories: data.datas,
            //                 rootCategories: data.datas,
            //                 areCategoriesLoading: false,
            //             });
            //         }
            //         resolve();
            //     }
            // );
        });
    }

    filterContents =()=>{
        this.setState({
            contents: [],
            contentIsLoading : true
        })

        this.props.client.query({
            query: GET_CONTENTS_PAGINATION,
            variables:{
                "nbperpage": this.state.nbperpage,
                "skuList": ["7"],
            },
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.listContents = result.data.contents.edges;
            this.setState({
                countPage: Math.ceil(result.data.contents.totalCount / this.state.nbperpage),
                contents: this.listContents,
                contentIsLoading : false
            });
            this.props.stopLoading();
            console.log('filterresult',result)
            // resolve();
        });


        // console.log(this.state.currentFolder)          
        // const id = this.state.currentFolder.id.split('/').pop()
        // this.props.client.query({
        //     query: SEARCH_CONTENTS_SKU_BY_CAT,
        //     fetchPolicy: 'no-cache',
        //     variables: { "idlist": [id] },
        // }).then(result => {
        //     console.log(result)
        //     // this.setState({
        //     //     medias: result.data.mediaObjects.edges,
        //     //     countPage: Math.ceil(result.data.mediaObjects.totalCount / this.state.nbperpage)
        //     // })
        // })
    }

    async preparePage() {
        // this.prepareTree();
        await this.handleGetCategories();
        // await this.handleGetCursorsMedias();
        // this.filterMedias()
        // if (this.props.history.location.state?.types) {
        //     this.filterMedias();
        // }
        // else {
        //     // this.handleGetAllMedias();            
        // }
        // this.setState({ ready: true });
    }

    componentDidMount() {
        checkRouting(this.props);
        this.preparePage();
    }

    render() {
        return (
            <div style={{ position: 'relative', minHeight: 'calc(100% - 64px)' }}>
                <TopPanel
                    icomoon="picto-content"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.t("content.content.manageContents")}
                    subtitle={this.props.t("content.content.manageContentSubtitle")}
                    handlerAdd={() => this.goTo(ROUTE_CONTENU_ADD)}
                    textAdd={this.props.t("content.content.addContent")}
                    handlerImport={() => this.handleToggleDrawer('openForm')}
                    textImport={this.props.t("content.content.importContent")}
                    searchHandler={this.handleChange}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                />
                <Grid container alignItems="center" style={{ background: 'white', borderBottom: `1px solid ${colors.grey.lighter.hue800}` }}>                    
                    <Grid item xs={12}>
                        <TraductionSelect
                            currentLang={this.state.currentLang}
                            handleLang={this.handleLang}
                            locales={this.props.locales}
                        />
                    </Grid>
                </Grid>
                <div>                    
                    <Grid container>
                        <Grid item xs={12} style={{paddingBottom:10}}>                                                        
                            <Typography style={{ color: colors.grey.lighter.hue600, fontWeight: 'bold', fontSize: this.props.windowWidth < 1200 ? '18px' : `25px` }}>
                                {this.state.currentFolder ?
                                    <>
                                        <span style={{cursor:"pointer",color:"black",textDecoration:"underline"}} onClick={()=>this.setState({currentFolder:null})} s>Tous les contenus</span>
                                        <ArrowForwardIosSharpIcon fontSize='small' />  
                                        {this.state.currentFolder.libelle} 
                                    </>
                                    :<span>Tous les contenus</span>
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>                            
                        {
                            this.state.categories && !this.state.areCategoriesLoading ?
                                this.state.categories.length > 0 ?
                                !this.state.currentFolder?
                                 (
                                    /* List of categories */
                                    <FolderContainer container ref={this.ref} windowWidth={this.props.windowWidth}>                                        
                                        {
                                            this.state.categories.map((e, i) => {
                                                if (e.libelle !== 'Root'&& e.libelle !== 'root') {
                                                    return (
                                                        <OurTooltip title={`${e.libelle}`} arrow>
                                                            <FolderCustom
                                                                style={{
                                                                    display: 'flex',
                                                                    height: '80px',
                                                                    padding: '0px 25px'
                                                                }}
                                                                key={`cat-${e.id}`}
                                                                alignItems={'center'}
                                                                onClick={() => {   
                                                                    const folders = [...this.state.previousFolders]
                                                                    folders.push(this.state.currentFolder);   
                                                                    console.log(folders)                                                                                                                          
                                                                    this.setState({                        
                                                                        previousFolder:folders,                                            
                                                                        currentFolder: e,                                                                        
                                                                    }, () => {
                                                                        this.filterContents()
                                                                    })
                                                                }}
                                                            >
                                                                <Grid item>
                                                                    <FolderSharpIcon style={{ marginTop: '3px', fontSize: '28px' }} />
                                                                </Grid>
                                                                <Grid item
                                                                    style={{
                                                                        overflow: 'hidden',
                                                                        whiteSpace: 'nowrap',
                                                                        textOverflow: 'ellipsis',
                                                                        fontSize: '20px'
                                                                    }}
                                                                >                                                                    
                                                                    {e.libelle} {`(${e.contents.totalCount})`}
                                                                </Grid>
                                                            </FolderCustom>
                                                        </OurTooltip>
                                                    );
                                                }
                                            })
                                        }
                                    </FolderContainer>
                                ) 
                                :null
                                : (
                                    /* Message when there are no categories */
                                    <Typography variant='body1' color='primary' style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '10px' }}>Pas de catégories</Typography>
                                )
                                : this.state.areCategoriesLoading ? (
                                    <PageLoader />
                                ) : (
                                    /* Message when there are no categories */
                                    <Typography variant='body1' color='primary' style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '10px' }}>Pas de catégories</Typography>
                                )
                        }
                        </Grid>
                        <Grid item container xs={12} spacing={2} style={{paddingTop:20}}>
                            {
                                this.state.currentFolder ?
                                    !this.state.contentIsLoading ?
                                        this.state.contents.length > 0 ?
                                            this.state.contents.map((content,index)=>{
                                                return (
                                                    <Grid item xs={4}>                                                        
                                                        <CardCustom key={index} paddingbottom={0} paddingtop={0} style={{ width: "100%" }} contentpadding={'0 0px 10px 0px'} >
                                                            <CardContent
                                                                content={content}
                                                                textButton={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("catalogs.dashboard.seeCatalog") : this.props.t("catalogs.catalogs.seeWarehouse")}
                                                                openForm={this.state.openForm}
                                                                textButtonSecond={this.props.t("spread.active_assets.duplicate")}
                                                                // actionButtonSecond={() => this.handleDuplicate(catalog.node.id)}
                                                                // actionButtonDelete={() => this.handleDelete(catalog.node.id)}
                                                                windowWidth={this.props.windowWidth}
                                                            />
                                                        </CardCustom>
                                                        </Grid>
                                                )
                                            })
                                            :<p>Pas de data</p>
                                    :<PageLoader/>
                                :null
                            }
                        </Grid>
                    </Grid>
                </div>
                <LayoutBuilder
                    isSublayout={false}
                    opened={this.state.openForm}
                    forClose={() => this.handleToggleDrawer('openForm')}
                    dataLayout={importProductsTypesConfig}
                    drawerWidth={this.props.drawerWidth}
                    dataCard={[
                        // {
                        //     'libelle': this.props.t("content.content.importVia"),
                        //     'bicoloreText':  this.props.t("content.content.feed"),
                        //     'colorhover': '#0273A5',
                        //     'picto': importFlux,
                        //     'disabled': true,
                        //     'textButton': this.props.t("medias.medias.import"),
                        //     'description': this.props.t("content.content.feedInfo"), 
                        //     'catalogDescription' : this.props.t("content.content.completeFields"),
                        //     'onClick': () => this.handleFormImport(null, 'flux')
                        // },
                        // {
                        //     'libelle': this.props.t("content.content.importViaTwo"),
                        //     'bicoloreText': 'API',
                        //     'colorhover': '#0273A5',
                        //     'picto': importAPI,
                        //     'disabled': true,
                        //     'textButton': this.props.t("medias.medias.import"),
                        //     'description': this.props.t("content.content.apiInfo"), 
                        //     'catalogDescription': this.props.t("content.content.completeFields"),
                        //     'onClick': () => this.handleFormImport(null, 'api')
                        // },
                        {
                            'libelle': this.props.t("content.content.importVia"),
                            'bicoloreText': this.props.t("content.content.file"),
                            'colorhover': '#0273A5',
                            'picto': importFichier,
                            'disabled': false,
                            'textButton': this.props.t("medias.medias.import"),
                            'description': this.props.t("content.content.fileInfo"),
                            'catalogDescription': this.props.t("content.content.completeFields"),
                            'onClick': () => this.handleFormImport(null, 'fichier')
                        }
                    ]}
                />

                <LayoutBuilder
                    isSublayout={true}
                    opened={this.state.openFormImport}
                    icomoon={'ico-import-fichier'}
                    forClose={() => this.handleToggleDrawer('openFormImport')}
                    dataLayout={importProductsConfig}
                    drawerWidth={this.state.drawerWidth}
                    // handleCancel={this.handleCancel}
                    handlerMutation={this.handlerMutation}
                    allState={this.state}
                    stateCallback={this.stateCallback}
                    stepperButtonDisabled={[() => this.state.headers === null, null]}
                    stepperButtonAction={[null, null]}
                    backStepperButtonAction={[null, null, null]}
                />

            </div>
        );
    }

    goTo = (route, state) => {
        this.props.history.push({
            pathname: route,
            state: {
                contentId: state?.id,
                name: state?.name
            }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListContents))));
