import React from "react";
import Swiper from "react-id-swiper";
import notFound from '../../../../../../src/assets/images/not-found.png';
import { Box } from "@material-ui/core";
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import './SliderActu.scss';

const ContainerImage = styled.h3`
    max-height: 208px;
    overflow: hidden;
    display: flex;
`;
const Title = styled.h1`
    font-size: 16px !important;
    line-height: 22px;
    color: ${colors.black.regular} !important;
    font-weight: bold !important;
    margin: 0 !important;
`;
const TextActu = styled.h5`
    font-size: 14px !important;
    line-height: 19px !important;
    color: ${colors.grey.regular} !important;
    font-weight: bold !important;
`;
const LinkActu = styled.p`
    color: ${colors.blue.darker.hue300} !important;
    font-size: 14px !important;
    line-height: 17px !important;
    font-weight: bold !important;
    text-decoration: underline;
    opacity: 0.7;
    &:hover{
        text-decoration: underline;
    }
`;
const ImageArticle = styled.img`
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
`;

export default function SliderActu (props){
  const params = {
    effect: "fade",
    loop: true,
    speed: 250,
    watchSlidesVisibility: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
  };
  
  return (
    <div id="swiper-home" style={{paddingBottom: 32}}>
      <div className="slider-area">
        <div className="slider-active-2 nav-style-2">
          <Swiper {...params}>
            {props.sliderData &&
              props.sliderData.map((single, key) => {
                return (
                  <div
                    className={`swiper-slide`}
                    style={{ background: 'white'}}
                    key={key}
                  >
                    <div className="container" style={{padding: 0}}>
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                          <div className="slider-animated-1">
                            <ContainerImage className="animated">
                                <ImageArticle className="animated" src={single.image ? single.image : notFound} style={{width: '100%'}}/>
                            </ContainerImage>
                            <Box className="animated" mt={2}>
                                <Title className="animated">{single.title}</Title>
                            </Box>
                            <Box className="animated" mt={1}>
                                <TextActu className="animated">{single.text}</TextActu>
                            </Box>
                            <Box className="animated d-flex justify-content-end" mt={0.5} pb={4}>
                                <LinkActu className="animated">En savoir plus</LinkActu>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
