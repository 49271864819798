import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { BUILDER, BUILDER_ASSETS, VIEW, UPDATE } from '../../../../js/constants/constant-rights';
import { withTranslation } from 'react-i18next';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import userGroupsEdit from './config/userGroupsEdit.config';
import { eventService } from '../../../../js/services/event.service';
import { saveElement, updateElement } from '../../../../js/utils/functions';
import { listSettings, listMappers, perPageOptions } from './config/listUserGroups.config';
import Listing from '../../../layouts/Listing/Listing';
import { Box, Grid } from '@material-ui/core';
import * as moment from "moment";
import { DELETE_USERGROUP } from '../../../../queries/user_groups';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../ui/button/Button';
import { withApollo } from 'react-apollo';
import { checkRouting } from '../../../../js/utils/checkRouting';
import styled from 'styled-components';
import DeleteDialogModal from '../../../ui/dialog/DeleteDialogModal';

const LayoutWrapper = styled(Box)`
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;

    .layout-wrapper{
        display: none;
    }
`;

function SettingsUsersGroups(props) {
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [editType, setEditType] = React.useState(null);
    const [openForm, setOpenForm] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [reloadGroupUsers, setReloadGroupUsers] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [states, setStates] = React.useState({
        id: null,
        code: null,
    });

    useEffect(() => {
        checkRouting(props);
    }, [])

    const goTo = route => {
        props.history.push(route);
    };

    const initGroups = () => {
        // return new Promise((resolve, reject) => {
        //     request(`${process.env.REACT_APP_API}/application/usergroups`, 'get', false).then(
        //         (data) => {
        //             console.log('data')
        //             if(data.success){
        //                 setListGroups(data.datas)
        //                 setReady(true)
        //             }
        //             resolve()
        //         }
        //     );
        //     resolve()
        // })
    }

    const getGroupUsersCallback = () => {
        setReloadGroupUsers(false)
    }

    const handleToggleDrawer = (stateDrawer, reset) => {
        setOpenForm(!openForm)
        if (reset) {
            resetStates()
        }
    };
    const handleToggleDialog = (groupId = null) => {
        setOpenDialog(!openDialog)
        if (groupId) setStates(prev => ({ ...prev, id: groupId }))
    };

    const resetStates = () => {
        setStates({
            id: null,
            code: null,
        })
    }

    const handleLang = (event) => {
        setCurrentLang(event.target.value)
        eventService.fire();
    };

    const stateCallback = (stateName, value, custom, translated, callback) => {
        let getState = { ...states };
        getState[stateName] = value?.target?.value ?? value;
        setStates(getState)
    };

    const handleFormError = (stateName, error) => {
        let getErrors = errors;
        getErrors[stateName] = error;
        setErrors(errors)
    };

    const editHandler = (userGroup) => {
        setEditType('edit')
        let getState = { ...states };
        getState['code'] = userGroup.code;
        getState['id'] = userGroup.id;
        setStates(getState);
        handleToggleDrawer('openForm');
    }
    const initForm = () => {
        setEditType('add')
        handleToggleDrawer('openForm');
    }

    const deleteMutation = async () => {
        props.startLoading();
        props.client.mutate({
            mutation: DELETE_USERGROUP,
            variables: { id: states.id }
        }).then(result => {
            props.stopLoading();
            props.snack(ALERT_SUCCESS, 'Groupe supprimé avec succès');
            setReloadGroupUsers(true);
            handleToggleDialog();
        }).catch(error => {
            props.stopLoading();
            props.snack(ALERT_ERROR, `Impossible de supprimer le groupe`);
            setReloadGroupUsers(true);
            handleToggleDialog();
        });
    }

    const handlerMutation = async () => {
        // props.startLoading()
        let variables = null;
        if (editType === "add") {
            variables = {
                code: states.code,
                createdAt: moment().format(),
                updatedAt: moment().format(),
                menuEntry: [],
            }
            try {
                let createElement = await saveElement('userGroup', variables, { enableLoad: true })
                props.snack(ALERT_SUCCESS, 'Groupe d\'utilisateur ajouté avec succès');
                if (createElement) {
                    handleToggleDrawer('openForm', true);
                    setReloadGroupUsers(true)
                }
            } catch {
                props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création du groupe d\'utilisateur');
            }
        } else {
            variables = {
                id: states.id,
                code: states.code,
                updatedAt: moment().format()
            }
            try {
                let updateElementResult = await updateElement(states, 'userGroup', variables, null, { enableLoad: true })
                props.snack(ALERT_SUCCESS, 'Groupe d\'utilisateur modifié avec succès');
                if (updateElementResult) {
                    handleToggleDrawer('openForm', true);
                    setReloadGroupUsers(true)
                }
            } catch (e) {
                console.log('e', e)
                props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la modification du groupe d\'utilisateur');
            }
        }
    }

    return (
        <LayoutWrapper>
            <TopPanel
                icomoon={"ico_gestion_acces"}
                colorIcomoon={colors.blue.darker.hue300}
                title={props.t("settings.userGroups.title")}
                subtitle={'Gestion de vos groupe d’utilisateurs et gestion d’accès'}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                openForm={openForm}
                currentLang={currentLang}
                handleLang={handleLang}
                locales={props.locales}
                handlerAdd={() => initForm()}
                textAdd={"Ajouter un groupe"}
                hasBorder={true}
                buttonAvailable={true}
            />
            <>
                <Grid container style={{ marginTop: 16 }}>
                    <Listing
                        label='header'
                        settings={listSettings}
                        cardProps={{
                            openForm: openForm,
                            currentLang: currentLang,
                            textButton: 'Gérer le groupe',
                            handlerButton: editHandler,
                            handlerMenu: {
                                toggleDeleteDialog: handleToggleDialog,
                                toggleDuplicateDialog: () => {}
                            },
                            windowWidth: props.windowWidth,
                        }}
                        perPageOptions={perPageOptions}
                        mappers={listMappers}
                        currentLang={currentLang}
                        identifier='userGroups'
                        // queryVariables={{}}
                        viewsOptions={{
                            current: 'card',
                            settings: ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                        }}
                        reload={reloadGroupUsers}
                        listingCallback={getGroupUsersCallback}
                    />
                </Grid>
                <LayoutBuilder
                    isSublayout={false}
                    icomoon="ico_gestion_acces"
                    opened={openForm}
                    forClose={() => handleToggleDrawer('openForm', true)}
                    handlerSetup={() => { }}
                    dataLayout={userGroupsEdit(editType)}
                    drawerWidth={props.drawerWidth}
                    allState={states}
                    stateCallback={stateCallback}
                    errorCallback={handleFormError}
                    validateButton={true}
                    handlerMutation={handlerMutation}
                />
                <DeleteDialogModal
                    open={openDialog}
                    onClose={handleToggleDialog}
                    title={'Êtes-vous sûr de vouloir supprimer ce groupe d\'utilisateur ?'}
                    primaryAction={deleteMutation}
                    secondaryAction={handleToggleDialog}
                    windowWidth={props.windowWidth}
                >
                    <DialogContentText id="alert-dialog-description">
                        Si vous supprimez ce groupe, celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                    </DialogContentText>
                </DeleteDialogModal>
            </>
        </LayoutWrapper>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsUsersGroups))));