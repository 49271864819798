import colors from "../../../config/theme/colors"
import OurTypography from "../typography/Typography"
import DialogModal from "./DialogModal"
import InfoSharpIcon from '@material-ui/icons/InfoSharp';

const DeleteDialogModal = (props) => {

  return (
    <DialogModal
      open={props.open}
      onClose={props.onClose}
      icon={<InfoSharpIcon style={{ color: colors.red.regular, fontSize: '40px', marginTop: '26px', marginLeft: '24px' }} />}
      title={
        <OurTypography fontweighttext={900} text={props.title} colortext={colors.black.regular} style={{ fontSize: '25px', lineHeight: '25px', marginTop: '10px', marginBottom: '-10px' }} />
      }
      notText={true}
      secondaryAction={props.secondaryAction}
      secondaryText="Annuler la suppresion"
      primaryAction={props.primaryAction}
      primaryText="Supprimer"
      primarycolorhover={colors.red.lighter}
      secondarycolorhover={colors.grey.lighter.hue800}

      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth}
      customStylePaper={{
        boxSizing: 'border-box',
        borderLeft: `13px solid ${colors.red.regular}`,
        borderRadius: '0px',
        minWidth: props.windowWidth > 1250 ? '785px' : '75%',
        ...props.customStyle
      }}
    >
      {props.children}
    </DialogModal>
  )
}

export default DeleteDialogModal