import React, { useEffect, useRef, useState } from "react";
import Div from '../shared/div';
import styled from 'styled-components';
import Button from "../shared/button";


const DivTitleText = styled(Div)`
    padding: 0;
    padding-top: 15px;
    padding-bottom: 1px;
    @media screen and (min-width: 768px){
        padding-top: 40px;
        padding-bottom: 30px;
        text-align: center;
    }
    @media screen and (min-width: 1024px){
        padding-top: 60px;
        padding-bottom: 50px;
    }
`;

const H3Custom = styled.h3`
    font-size: ${props => props.fontSize}px;
    color: ${props => props.color};
    line-height: 1.2;
    font-family: 'DINBold', sans-serif;
    font-weight: bold;
    margin: 20px 0;
    @media screen and (max-width: 1400px){
        font-size: ${props => props.fontSize ? `${(4.7 * props.fontSize / 100) > 3 ? 4.7 * props.fontSize / 100 : 4}vw` : '4vw'};
    }
    @media screen and (max-width: 650px){
        margin-top: 0;
        font-size: ${props => props.fontSize ? `${(5.5 * props.fontSize / 100) > 4 ? 5.5 * props.fontSize / 100 : 5}vw` : '5vw'};
    }
`;
    
const PCustom = styled.p`
    font-size: ${props => props.fontSize}px;
    color: ${props => props.color};
    line-height: 1.2;
    margin: 20px 0;
    font-family: 'DinRegular', sans-serif;
    @media screen and (max-width: 1400px){
        font-size: ${props => props.fontSize ? `${(2.5 * props.fontSize / 100) > 2.5 ? 2.5 * props.fontSize / 100 : 2}vw` : '2vw'};
    }
    @media screen and (max-width: 650px){
        font-size: ${props => props.fontSize ? `${(4.4 * props.fontSize / 100) > 3.5 ? 4.4 * props.fontSize / 100 : 3.5}vw` : '3.5vw'};
    }
    @media screen and (max-width: 400px){
        font-size: ${props => props.fontSize ? `${(5 * props.fontSize / 100) > 4 ? 5 * props.fontSize / 100 : 4}vw` : '4vw'};
    }
`;

const TitleText = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;

    console.log(data)

    return (
        <Div style={{position: 'relative', background: data.inputs.backgroundColor.value}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <DivTitleText style={{textAlign: data.inputs.centerText.value ? 'center' : 'inherit', paddingTop: data.inputs.noPaddingTop?.value ? 0 : null, paddingBottom: data.inputs.noPaddingBottom?.value ? 0 : null,margin:data.inputs.noPaddingHorizontal?.value ? 0: "auto",display:data.blocks.button?.inputs.position.value==="nextTo"?"flex":"block",alignItems:data.blocks.button?.inputs.position.value==="nextTo"?"center":null,width:data.inputs?.noPaddingHorizontal.value?"100%":"90%"}}>
                <div style={{width:data.blocks.button?.inputs.position.value==="nextTo"?"80%":"100%"}}>
                    <H3Custom 
                        color={data.blocks.title.inputs.color?.value ? data.blocks.title.inputs.color.value : '#000' }
                        fontSize={data.blocks.title.inputs.fontSize?.value ? data.blocks.title.inputs.fontSize.value : 41}
                    >
                        {data.blocks.title.inputs.value?.value}
                    </H3Custom>

                    <div className="description" dangerouslySetInnerHTML={{__html: data.blocks.text.inputs.value?.value}} />
                </div>
                {
                    data.blocks.button?.inputs.showButton.value?
                    <div style={{width:data.blocks.button?.inputs.position.value==="nextTo"?"20%":"100%",display:"flex",justifyContent:data.blocks.button.inputs.positionHorizontal.value}}>
                        <Button
                            backgroundColor={data.blocks.button.inputs?.backgroundColor?.value}
                            color={data.blocks.button.inputs?.color?.value}
                            textTransform={data.blocks.button.inputs?.textTransform?.value}
                            borderWidth={data.blocks.button.inputs?.borderWidth?.value}
                            borderColor={data.blocks.button.inputs?.borderColor?.value}
                            paddingVertical={data.blocks.button.inputs?.paddingVertical?.value}
                            paddingHorizontal={data.blocks.button.inputs?.paddingHorizontal?.value}
                        >
                            {data.blocks.button.inputs.value.value}
                        </Button>
                    </div>
                    :null
                }
            </DivTitleText>            
        </Div>
    );
};

export default TitleText;