import React from 'react';
import { Grid, Typography, Box, Tooltip } from '@material-ui/core';
import colors from '../../../config/theme/colors';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

const TypographyCustom = styled(Typography)`
    font-weight: bold;
    padding-left: 6px;

    // color: ${props => props.colortext === 'ONLINE' ? colors.green.regular : props.colortext === 'DISABLED' ? colors.grey.lighter.hue600 : props.colortext === 'ERROR' || props.colortext === 'INACTIF' ? colors.red.regular : colors.orange.regular};
    color: ${props => props.colortext ? colors.green.regular : colors.grey.lighter.hue600} !important;

`;
const CircleStatus = styled.div`
    // background-color: ${props => props.colorcircle === 'ONLINE' ? colors.green.regular : props.colorcircle === 'DISABLED' ? colors.grey.lighter.hue600 : props.colorcircle === 'ERROR' ? colors.red.regular : colors.orange.regular};
    background-color: ${props => props.colorcircle ? colors.green.regular : process.env.REACT_APP_MODE_SPREAD === "hub" ? colors.red.regular : colors.grey.lighter.hue600};
    border-radius: 50%;
    width: 10px;
    height: 10px;
`;

function StatusInfo(props){
    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: props.status ? colors.green.regular : colors.grey.lighter.hue600,
        },
        tooltip: {
            backgroundColor: props.status ? colors.green.regular : colors.grey.lighter.hue600,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));
    
    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }

    return (
        
        <Grid container justifyContent={props.justify ? props.justify : 'flex-start'} alignItems="center" direction="row" style={{
            width: props.width,
            flexWrap: 'nowrap'
        }}>
            {
                !props.noText ?
                    <>
                        <Box>
                            <Grid container alignItems="center">
                            {
                                props.isMini ? (
                                    <CircleStatus colorcircle={props.status} style={{width: 8, height: 8}}/>
                                )
                                :
                                (
                                    <CircleStatus colorcircle={props.status} style={{width: props.size ? props.size : 10, height: props.size ? props.size : 10}}/>
                                )
                            }
                            </Grid>
                        </Box>
                        <Box>
                            <Grid container alignItems="center">
                                <TypographyCustom variant={"body2"} colortext={props.status ? 1 : 0} className="active-value" style={{fontSize : props.isMini ? 10 : props.fontSize ? props.fontSize : 14 , lineHeight: props.isMini ? "13px" : props.fontSize ? `${props.fontSize + 2}px` : '16px', textTransform : props.isMini ? 'uppercase' : 'none'}}>
                                    {props.status
                                        ? props.statusText
                                            ? props.statusText
                                            : process.env.REACT_APP_MODE_SPREAD === "hub" ? props.t("spread_builder.asset_view.inStock") : props.t("spread_builder.asset_view.active") 
                                        : process.env.REACT_APP_MODE_SPREAD === "hub" ? props.t("spread_builder.asset_view.outOfStock") : props.t("spread_builder.asset_view.inactive")}
                                </TypographyCustom>
                            </Grid>
                        </Box>
                    </>
                :
                    <BootstrapTooltip 
                        title={
                            <span key={`status-text`} style={{display: 'block'}}>
                                {
                                    props.status
                                        ? props.statusText 
                                            ? props.statusText 
                                            : process.env.REACT_APP_MODE_SPREAD === "hub" ? "EN STOCK" : "ACTIF" 
                                    : process.env.REACT_APP_MODE_SPREAD === "hub" ? "RUPTURE" : 'INACTIF'
                                }
                            </span>
                        } 
                        arrow
                    >
                        <Box>
                            <Grid container alignItems="center">
                                <CircleStatus colorcircle={props.status} style={{width: props.isMini ? 8 : props.size ? props.size : 10, height: props.isMini ? 8 : props.size ? props.size : 10}}/>
                            </Grid>
                        </Box>
                    </BootstrapTooltip>
            }
        </Grid>
    );
};

export default withTranslation()(StatusInfo);