import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultSlideTop } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Slider home',
    inputs:{
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        className:{
            label: 'Classe(s)',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        optimisedImage:{
            label: 'Utiliser des images optimisées',
            type: FormInputTypes.SWITCH,
            value: true,
            id: uuidv4(),
        }
    },
    blocks:{
        slides:{
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER,
            buttonAdd: 'une slide',
            limit: 3,
            config: [ 
                getDefaultSlideTop()
            ]
        }
    }
};