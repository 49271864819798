import React, { useEffect, useRef, useState } from "react";
import Swiper from 'swiper';
import A from '../shared/a';
import H1 from '../shared/h1';
import Div from '../shared/div';
import Button from '../shared/a';
import styled from 'styled-components';


const BreadCrumb = styled(Div)`
    padding-top: 1rem;
    font-size: 0.875rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    > a {
        font-weight: bold;
        text-decoration: none;
    }
    > * {
        padding: 0.25rem;
        font-size: 12px;
        color: black;
        font-family: 'Univers', sans-serif;
        &:first-child{
            font-weight: bold;
        }
    }
    @media screen and (max-width: 1024px){
        margin-bottom: 4px;
    }
    @media screen and (min-width: 1024px){
        padding-top: 2.5rem;
    }
`;

const Entete = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        data = null,
    } = props;

    return (
        <Div style={{position: 'relative'}} className="entete" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <BreadCrumb>
                <a href="/">Accueil</a>
                <span>/</span>
                <span>{data.blocks.title.inputs.value.value}</span>
            </BreadCrumb>
            <H1 className="landing-title">{data.blocks.title.inputs.value.value}</H1>
        </Div>
    );
};

export default Entete;