import React  from "react";
import { v4 as uuidv4 } from "uuid";
import { AssetTypes, FormInputTypes, ItemTypes, MenuTypes, Pages, SocialNetworks } from "../../shareable/types";
import { getDefaultConfig, getDefaultData } from "../../utils/data";
import FlipbookSuccessEmail from '../../../email/flipbook/emailSuccess';
import FlipbookWinEmail from '../../../email/flipbook/emailWin';
import FlipbookLooseEmail from '../../../email/flipbook/emailLoose';
import GameEmail from "../../../email/game/mailBase";

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import DehazeIcon from '@material-ui/icons/Dehaze';
import { Draggable  } from "../components/builder";
import { Alert, AlertTitle } from "@material-ui/lab";
import arrayMove from "array-move";
import { useContext, useState } from "react";
import { BuilderContext } from "../../builder";
import { makeStyles, Paper } from "@material-ui/core";
import colors from "../../../config/theme/colors";
import { getTypeName } from "../components/builder/dnd/getTypeName";
import { Code, Dashboard, Image, Minimize, Title, ViewCarousel } from "@material-ui/icons";

export const getDefaultPageConfig = (page, type, config, custom) => {
    switch (type) {
        case AssetTypes.FLIPBOOK:
        case AssetTypes.NEWSLETTER:
            switch(page) {
                case Pages.HOME:
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_BOOK,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_BOOK)
                            },
                            {
                                type: ItemTypes.TOP_HOMEPAGE_BOOK,
                                key: "top-homepage-book",
                                data: getDefaultData(ItemTypes.TOP_HOMEPAGE_BOOK)
                            },
                            {
                                type: ItemTypes.POSTCARD_BOOK,
                                key: "postcard-book",
                                data: getDefaultData(ItemTypes.POSTCARD_BOOK)
                            },
                            {
                                type: ItemTypes.FOOTER_BOOK,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_BOOK)
                            },
                        ]
                    };
                case Pages.FLIPBOOK:
                    let variante = custom || (config?.params?.menu?.value ?? "wishlist");

                    if (variante === "cart") {
                        return {
                            elements: [
                                {
                                    type: ItemTypes.FLIPBOOK_WITH_CART,
                                    key: "flipbook",
                                    data: getDefaultData(ItemTypes.FLIPBOOK_WITH_CART)
                                },
                                {
                                    type: ItemTypes.POSTCARD_BOOK,
                                    key: "postcard-book",
                                    data: getDefaultData(ItemTypes.POSTCARD_BOOK)
                                },
                                {
                                    type: ItemTypes.FOOTER_BOOK,
                                    key: "page-footer",
                                    data: getDefaultData(ItemTypes.FOOTER_BOOK)
                                },
                            ]
                        };
                    } else {
                        return {
                            elements: [
                                {
                                    type: ItemTypes.HEADER_BOOK,
                                    key: "page-header",
                                    data: getDefaultData(ItemTypes.HEADER_BOOK)
                                },
                                {
                                    type: ItemTypes.FLIPBOOK,
                                    key: "flipbook",
                                    data: getDefaultData(ItemTypes.FLIPBOOK)
                                },
                                {
                                    type: ItemTypes.POSTCARD_BOOK,
                                    key: "postcard-book",
                                    noButton: true,
                                    data: getDefaultData(ItemTypes.POSTCARD_BOOK)
                                },
                                {
                                    type: ItemTypes.FOOTER_BOOK,
                                    key: "page-footer",
                                    data: getDefaultData(ItemTypes.FOOTER_BOOK)
                                },
                            ]
                        };
                    };
                case Pages.REGISTER_BOOK:
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_BOOK,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_BOOK)
                            },
                            {
                                type: ItemTypes.RECAP_BLOCK_BOOK,
                                key: "recap-book",
                                data: getDefaultData(ItemTypes.RECAP_BLOCK_BOOK)
                            },
                            {
                                type: ItemTypes.COPYRIGHT_BOOK,
                                key: "copyright-book",
                                data: getDefaultData(ItemTypes.COPYRIGHT_BOOK)
                            },
                            {
                                type: ItemTypes.FOOTER_BOOK,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_BOOK)
                            },
                        ]
                    };
                case Pages.REGISTER_SUCCESS_BOOK:
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_BOOK,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_BOOK)
                            },
                            {
                                type: ItemTypes.RESULTS_BOOK,
                                key: "results-book",
                                data: getDefaultData(ItemTypes.RESULTS_BOOK)
                            },
                            {
                                type: ItemTypes.BANNER_LINK_BOOK,
                                key: "banner-link-book",
                                data: getDefaultData(ItemTypes.BANNER_LINK_BOOK)
                            },
                            {
                                type: ItemTypes.FOOTER_BOOK,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_BOOK)
                            },
                        ]
                    };
                case Pages.EMAIL_SUCCESS_BOOK:
                    return {
                        html: <FlipbookSuccessEmail data={getDefaultData(ItemTypes.EMAIL_SUCCESS_BOOK)} id={uuidv4()} />,
                        elements: [
                            {
                                type: "email_register",
                                data: getDefaultData(ItemTypes.EMAIL_SUCCESS_BOOK),
                                key: "email_register",
                            }
                        ]
                    };
                case Pages.EMAIL_WIN_BOOK:
                case Pages.EMAIL_WIN_BOOK_2:
                    return {
                        html: <FlipbookWinEmail data={getDefaultData(ItemTypes.EMAIL_WIN_BOOK)} id={uuidv4()} />,
                        elements: [
                            {
                                type: "email_win",
                                data: getDefaultData(ItemTypes.EMAIL_WIN_BOOK),
                                key: "email_win",
                            }
                        ]
                    };
                case Pages.EMAIL_LOOSE_BOOK:
                    return {
                        html: <FlipbookLooseEmail data={getDefaultData(ItemTypes.EMAIL_LOOSE_BOOK)} id={uuidv4()} />,
                        elements: [
                            {
                                type: "email_loose",
                                data: getDefaultData(ItemTypes.EMAIL_LOOSE_BOOK),
                                key: "email_loose",
                            }
                        ]
                    };
                default: return null;
            };
        case AssetTypes.MINISITE:
            switch(page) {
                case Pages.HOME:
                    return {
                        elements: [
                            {
                                type: ItemTypes.SLIDER,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.SLIDER)
                            },
                        ]
                    };
                case Pages.LANDING:
                    return {
                        elements: [
                            {
                                type: ItemTypes.PAGE_LANDING,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.PAGE_LANDING)
                            }
                        ]
                    };
                case Pages.PRODUCT_DETAILS:
                    return {
                        elements: [
                            {
                                type: ItemTypes.PAGE_PRODUCT,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.PAGE_PRODUCT)
                            }
                        ]
                    };
                case Pages.CONTACT:
                    return {
                        elements: [
                            {
                                type: ItemTypes.PAGE_CONTACT,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.PAGE_CONTACT)
                            }
                        ]
                    };
                case Pages.WISHLIST:
                    return {
                        elements: [
                            {
                                type: ItemTypes.PAGE_WISHLIST,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.PAGE_WISHLIST)
                            }
                        ]
                    };
                case Pages.EMAIL_CONTACT:
                    return {
                        data: getDefaultData(ItemTypes.EMAIL_CONTACT),
                        pageType: Pages.EMAIL_CONTACT,
                        isEmail: true,
                        html: null
                    };
                default: return null;
            };
        case AssetTypes.GAME:
            switch(page) {
                case 'home_game':
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.HOME_GAME,
                                key: "page-home",
                                data: getDefaultData(ItemTypes.HOME_GAME)
                            },
                            {
                                type: ItemTypes.EXPLANATIONS_GAME,
                                key: "page-explanations",
                                data: getDefaultData(ItemTypes.EXPLANATIONS_GAME)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'login_game': 
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.LOGIN_GAME,
                                key: "page-login",
                                data: getDefaultData(ItemTypes.LOGIN_GAME)
                            },
                            {
                                type: ItemTypes.EXPLANATIONS_GAME,
                                key: "page-explanations",
                                data: getDefaultData(ItemTypes.EXPLANATIONS_GAME)
                            },
                            {
                                type: ItemTypes.DOTATIONS,
                                key: "page-dotations",
                                data: getDefaultData(ItemTypes.DOTATIONS)
                            },
                            {
                                type: ItemTypes.RULES,
                                key: "page-rules",
                                data: getDefaultData(ItemTypes.RULES)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'game_game': 
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.GAME_PLACEHOLDER,
                                key: "game-placeholder",
                                data: null
                            },
                            {
                                type: ItemTypes.EXPLANATIONS_GAME,
                                key: "page-explanations",
                                data: getDefaultData(ItemTypes.EXPLANATIONS_GAME)
                            },
                            {
                                type: ItemTypes.DOTATIONS,
                                key: "page-dotations",
                                data: getDefaultData(ItemTypes.DOTATIONS)
                            },
                            {
                                type: ItemTypes.RULES,
                                key: "page-rules",
                                data: getDefaultData(ItemTypes.RULES)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'index_game': 
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.INDEX_GAME,
                                key: "page-index",
                                data: getDefaultData(ItemTypes.INDEX_GAME)
                            },
                            {
                                type: ItemTypes.EXPLANATIONS_GAME,
                                key: "page-explanations",
                                data: getDefaultData(ItemTypes.EXPLANATIONS_GAME)
                            },
                            {
                                type: ItemTypes.DOTATIONS,
                                key: "page-dotations",
                                data: getDefaultData(ItemTypes.DOTATIONS)
                            },
                            {
                                type: ItemTypes.RULES,
                                key: "page-rules",
                                data: getDefaultData(ItemTypes.RULES)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'results_win_game':
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.RESULTS_WIN_GAME,
                                key: "page-results",
                                data: getDefaultData(ItemTypes.RESULTS_WIN_GAME)
                            },
                            {
                                type: ItemTypes.REDIRECT_ON_GAME,
                                key: "page-redirect-on",
                                data: getDefaultData(ItemTypes.REDIRECT_ON_GAME)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'results_loose_game': 
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.RESULTS_LOOSE_GAME,
                                key: "page-results",
                                data: getDefaultData(ItemTypes.RESULTS_LOOSE_GAME)
                            },
                            {
                                type: ItemTypes.REDIRECT_ON_GAME,
                                key: "page-redirect-on",
                                data: getDefaultData(ItemTypes.REDIRECT_ON_GAME)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'alreadyplayed_game': 
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.ALREADYPLAYED_GAME,
                                key: "page-alreadyplayed",
                                data: getDefaultData(ItemTypes.ALREADYPLAYED_GAME)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'alreadywin_game': 
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.ALREADYPLAYED_GAME,
                                key: "page-alreadywin",
                                data: getDefaultData(ItemTypes.ALREADYPLAYED_GAME)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'ended_game': 
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.ENDED_GAME,
                                key: "page-ended",
                                data: getDefaultData(ItemTypes.ENDED_GAME)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'not_started_game': 
                    return {
                        elements: [
                            {
                                type: ItemTypes.HEADER_GAME,
                                key: "page-header",
                                data: getDefaultData(ItemTypes.HEADER_GAME)
                            },
                            {
                                type: ItemTypes.NOT_STARTED_GAME,
                                key: "page-not-started",
                                data: getDefaultData(ItemTypes.NOT_STARTED_GAME)
                            },
                            {
                                type: ItemTypes.FOOTER_GAME,
                                key: "page-footer",
                                data: getDefaultData(ItemTypes.FOOTER_GAME)
                            },
                        ]
                    };
                case 'mail_confirmation':
                case 'confirmation_email_random_draw':
                    return {
                        html: <GameEmail data={getDefaultData(ItemTypes.EMAIL_CONFIRMATION_IG_GAME)} id={uuidv4()} />,
                        elements: [
                            {
                                type: "mail_confirmation",
                                data: getDefaultData(ItemTypes.EMAIL_CONFIRMATION_IG_GAME),
                                key: "mail_confirmation",
                            }
                        ]
                    };
                case 'mail_win':
                case 'email_game_win_random_draw':
                    return {
                        html: <GameEmail data={getDefaultData(ItemTypes.EMAIL_WIN_IG_GAME)} id={uuidv4()} />,
                        elements: [
                            {
                                type: "mail_win",
                                data: getDefaultData(ItemTypes.EMAIL_WIN_IG_GAME),
                                key: "mail_win",
                            }
                        ]
                    };
                case 'mail_share':
                    return {
                        html: <GameEmail data={getDefaultData(ItemTypes.EMAIL_SHARE_IG_GAME)} id={uuidv4()} />,
                        elements: [
                            {
                                type: "mail_share",
                                data: getDefaultData(ItemTypes.EMAIL_SHARE_IG_GAME),
                                key: "mail_share",
                            }
                        ]
                    };

                default: return null;
            };
        case AssetTypes.PAGES:
        case AssetTypes.SHOPIFY:
        case AssetTypes.MAGENTO:
            switch(page) {
                case Pages.CMS_HOME:
                    return {
                        elements: [
                            {
                                type: ItemTypes.CMS_SLIDER,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER)
                            },
                            {
                                type: ItemTypes.CMS_CATEGORIES_HOME,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_CATEGORIES_HOME)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_PRODUCTS_HOME,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_PRODUCTS_HOME)
                            },
                            {
                                type: ItemTypes.CMS_COLLECTIONS_HOME,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_COLLECTIONS_HOME)
                            },
                            {
                                type: ItemTypes.CMS_PRESENTATION_DUO,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_PRESENTATION_DUO)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_ABOUT,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_ABOUT)
                            },
                            {
                                type: ItemTypes.CMS_LIST_CATEGORIES,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_LIST_CATEGORIES)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_BY_TAB,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_BY_TAB)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_PRODUCTS_HOME,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_PRODUCTS_HOME)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_INSTAGRAM,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_INSTAGRAM)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_BLOCS_TEXT_MEDIA,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_BLOCS_TEXT_MEDIA)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_MOMENTS,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_MOMENTS)
                            },
                            {
                                type: ItemTypes.CMS_SLIDER_IMAGE,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SLIDER_IMAGE)
                            },
                            {
                                type: ItemTypes.CMS_GRID_IMAGE,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_GRID_IMAGE)
                            },
                            {
                                type: ItemTypes.CMS_TEXT_MEDIA,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_TEXT_MEDIA)
                            },
                            {
                                type: ItemTypes.CMS_MULTIPLE_TEXT,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_MULTIPLE_TEXT)
                            },
                            {
                                type: ItemTypes.CMS_BANNER_MEDIA,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_BANNER_MEDIA)
                            },
                            {
                                type: ItemTypes.CMS_SECTION_DESCRIPTION_PAGE,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SECTION_DESCRIPTION_PAGE)
                            },
                            {
                                type: ItemTypes.CMS_SECTION_MANIFEST_BLOCK,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_SECTION_MANIFEST_BLOCK)
                            },
                            {
                                type: ItemTypes.CMS_BLOC_STORELOCATOR_HOME,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.CMS_BLOC_STORELOCATOR_HOME)
                            },
                        ]
                    };
            };
        default:
            switch (page) {
                case Pages.GIFT_FINDER: 
                    return {
                        elements: [
                            {
                                type: ItemTypes.GIFT_FINDER,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.GIFT_FINDER)
                            }
                        ]
                    };
                case Pages.MAGENTO: 
                    return {
                        elements: [
                            {
                                type: ItemTypes.PLACEHOLDER,
                                key: uuidv4(),
                                placeholderConfig: {
                                    text: "Entête de page",
                                    height: 250,
                                    bgColor: '#ccc',
                                    color: '#9a9a9a',
                                    fullWidth: true
                                }
                            }, {
                                type: ItemTypes.SLIDER_WITH_RIGHT_IMAGES,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.SLIDER_WITH_RIGHT_IMAGES)
                            }, {
                                type: ItemTypes.SLIDER_MULTIPLE,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.SLIDER_MULTIPLE)
                            }, {
                                type: ItemTypes.PLACEHOLDER,
                                key: uuidv4(),
                                placeholderConfig: {
                                    text: "Bandeau produits",
                                    height: 200,
                                    bgColor: '#ccc',
                                    color: '#9a9a9a',
                                    fullWidth: false
                                }
                            }, {
                                type: ItemTypes.THREE_IMAGES_ROW,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.THREE_IMAGES_ROW)
                            }, {
                                type: ItemTypes.THREE_IMAGES_GRID,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.THREE_IMAGES_GRID)
                            }, {
                                type: ItemTypes.BLOC_TEXT,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.BLOC_TEXT)
                            }, {
                                type: ItemTypes.PLACEHOLDER,
                                key: uuidv4(),
                                placeholderConfig: {
                                    text: "Pied de page",
                                    height: 200,
                                    bgColor: '#ccc',
                                    color: '#9a9a9a',
                                    fullWidth: true,
                                    noBottomMargin: true
                                }
                            }
                        ]
                    };
                case Pages.PRESENTATION: 
                    return {
                        elements: [
                            {
                                type: ItemTypes.OFFER_FRONT_PAGE,
                                key: uuidv4(),
                                data: getDefaultData(ItemTypes.OFFER_FRONT_PAGE)
                            },
                        ]
                    };
                case 'wordpress': return getDefaultConfig(Pages.WORDPRESS);
                case 'facebook': return getDefaultConfig(Pages.FACEBOOK);
                case 'linkedin': return getDefaultConfig(Pages.LINKEDIN);
                default: return null;
            }; 
    }
};

export const getDefaultParams = (type) => {
    switch (type) {
        case AssetTypes.MINISITE:
        case AssetTypes.NEWSLETTER:
            return {
                logo: {
                    id: "main-logo",
                    name: "Logo Header",
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/200x100"
                },
                logoFooter: {
                    id: "main-logo",
                    name: "Logo Footer",
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/200x100"
                },
                menu: {
                    id: "header-menu",
                    name: "Menu",
                    type: FormInputTypes.MENU,
                    value: [
                        {
                            type: MenuTypes.LINK,
                            readonly: true,
                            id: "home",
                            name: "Accueil",
                            value: "/"
                        },
                        {
                            type: MenuTypes.LINK,
                            readonly: true,
                            id: "contact",
                            name: "Contact",
                            value: "/contact"
                        }
                    ]
                },
                emailContact: {
                    id: "emailContact",
                    name: "Email de contact",
                    type: FormInputTypes.TEXT,
                    value: ""
                },
                hasHeaderFooter: {
                    id: "has-header-footer",
                    name: "Activer header / footer",
                    type: FormInputTypes.SWITCH,
                    value: true
                },
                hasContact: {
                    id: "has-contact",
                    name: "Formulaire de contact",
                    type: FormInputTypes.SWITCH,
                    value: true
                },
                hasSearch: {
                    id: "has-search",
                    name: "Recherche",
                    type: FormInputTypes.SWITCH,
                    value: true
                },
                hasWishlist: {
                    id: "has-wishlist",
                    name: "Wishlist",
                    type: FormInputTypes.SWITCH,
                    value: true
                },
                isExternal: {
                    id: "is-external",
                    name: "Externe",
                    type: FormInputTypes.SWITCH,
                    value: false
                },
                displayPrices: {
                    id: "display-prices",
                    name: 'Afficher les prix',
                    type: FormInputTypes.SWITCH,
                    value: true
                },
                isDocumentVersion: {
                    id: "is-document-version",
                    name: 'Version documents',
                    type: FormInputTypes.SWITCH,
                    value: false
                },
                documentAttribute: {
                    id: "document-attribute",
                    name: 'Attribut document',
                    type: FormInputTypes.TEXT,
                    value: ''
                },
                moto: {
                    id: "moto",
                    name: "Slogan",
                    type: FormInputTypes.TEXTAREA,
                    value: "Lorem ipsum"
                },
                copyright: {
                    id: "copyright",
                    name: "Copyright",
                    type: FormInputTypes.TEXT,
                    value: "© 2020 [Votre entreprise]. All Rights Reserved."
                },
                social: {
                    id: "social-networks",
                    name: "Réseaux sociaux",
                    type: FormInputTypes.SOCIAL,
                    value: [
                        { icon: SocialNetworks.FACEBOOK, value: "//facebook.com" },
                        { icon: SocialNetworks.TWITTER, value: "//twitter.com" },
                        { icon: SocialNetworks.INSTAGRAM, value: "//instagram.com" },
                        { icon: SocialNetworks.PINTEREST, value: "" },
                        { icon: SocialNetworks.YOUTUBE, value: "" },
                        { icon: SocialNetworks.LINKEDIN, value: "" },
                    ]
                },
                numberOne: {
                    name: 'Numéro (1)',
                    type: FormInputTypes.TEXT,
                    value: "+33 0 00 00 00 00"
                },
                numberTwo: {
                    name: 'Numéro (2)',
                    type: FormInputTypes.TEXT,
                    value: "+33 0 00 00 00 00"
                },
                webOne: {
                    name: 'Website (1)',
                    type: FormInputTypes.TEXT,
                    value: "google.com",
                    placeholder: 'Texte'
                },
                webOneLink: {
                    type: FormInputTypes.TEXT,
                    value: "//google.com",
                    placeholder: 'Lien'
                },
                webTwo: {
                    name: 'Website (2)',
                    type: FormInputTypes.TEXT,
                    value: "google.com",
                    placeholder: 'Texte'
                },
                webTwoLink: {
                    type: FormInputTypes.TEXT,
                    value: "//google.com",
                    placeholder: 'Lien'
                },
                addressOne: {
                    name: 'Adresse (1)',
                    type: FormInputTypes.TEXT,
                    value: "1400 Park Avenue Emeryville"
                },
                addressTwo: {
                    name: 'Adresse (2)',
                    type: FormInputTypes.TEXT,
                    value: "CA 94608 United States"
                },
                footerColor: {
                    id: "footer-color",
                    name: "Couleur du pied de page",
                    type: FormInputTypes.COLOR,
                    value: '#000'
                },
                footerTextColor: {
                    id: "footer-text-color",
                    name: "Couleur du texte pied de page",
                    type: FormInputTypes.COLOR,
                    value: '#fff'
                },
            };
        case AssetTypes.FLIPBOOK:
            return {
                variante: {
                    type: FormInputTypes.SELECT,
                    label: "Variante de catalogue",
                    value: 'wishlist',
                    params: [{ label: 'Wishlist', value: 'wishlist' }, { label: 'Ajout au panier', value: 'cart' }]
                },
                switch: {
                    label: "Désactiver le panier / wishlist",
                    type: FormInputTypes.SWITCH,
                    value: false,
                }
            };
        case AssetTypes.GAME:
            return {
                bg: {
                    id: "global-background",
                    name: "Fond entier",
                    type: FormInputTypes.IMAGE,
                    value: null,
                },
                rulesColorBackground: {
                    id: "global-rules-color-background",
                    name: "Couleur de fond du règlement",
                    type: FormInputTypes.COLOR,
                    value: '#000'
                },
                rulesModal: {
                    id: "global-rules",
                    name: "Règlement du jeu",
                    type: FormInputTypes.WYSIWYG,
                    value: null,
                },         
                rulesColorText: {
                    id: "global-rules-color-text",
                    name: "Couleur du texte",
                    type: FormInputTypes.COLOR,
                    value: '#FFF'
                },
                maxWidth: {
                    type: FormInputTypes.SLIDER,
                    value: 1920,
                    label: "Taille maximale (px)",
                    params: {max: 1920, min: 360, step: 10},
                },
                retailers: {
                    id: "retailer",
                    name: "Les retailers",
                    type: FormInputTypes.GAME_RETAILER,
                    value: []
                },
            };
        default: return null;
    }
};

export const getDraggablesConfig = (type, presentation = false) => {
    if (presentation) {
        return [{
            label: 'Général',
            items: [
                { icon: ViewCarousel, type: ItemTypes.OFFER_FRONT_PAGE },
                { icon: ViewCarousel, type: ItemTypes.OFFER_SECTION_TITLE },
                { icon: ViewCarousel, type: ItemTypes.OFFER_ABOUT_SLIDE },
                { icon: ViewCarousel, type: ItemTypes.OFFER_TRIPLE_BLOCK_LIST },
                { icon: ViewCarousel, type: ItemTypes.OFFER_TRIPLE_BLOCK },
                { icon: ViewCarousel, type: ItemTypes.OFFER_TRIPLE_BLOCK_TEXT },
                { icon: ViewCarousel, type: ItemTypes.OFFER_TEXT_WITH_IMAGE },
                { icon: ViewCarousel, type: ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE },
                { icon: ViewCarousel, type: ItemTypes.OFFER_DEVIS },
            ]
        }];
    }

    switch (type) {
        case AssetTypes.PAGES:
        case AssetTypes.SHOPIFY:
        case AssetTypes.MAGENTO:
        case AssetTypes.WORDPRESS:
            return [
                {
                    label: 'Carousels',
                    items: [
                        { icon: ViewCarousel, type: ItemTypes.CMS_SLIDER },
                        { icon: ViewCarousel, type: ItemTypes.CMS_SLIDER_ABOUT },
                        { icon: ViewCarousel, type: ItemTypes.CMS_SLIDER_BY_TAB },
                        { icon: ViewCarousel, type: ItemTypes.CMS_SLIDER_INSTAGRAM },
                        { icon: ViewCarousel, type: ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA },
                        { icon: ViewCarousel, type: ItemTypes.CMS_SLIDER_MOMENTS },
                        { icon: ViewCarousel, type: ItemTypes.CMS_SLIDER_IMAGE },
                        { icon: ViewCarousel, type: ItemTypes.CMS_SLIDER_PRODUCTS_HOME },
                        { icon: ViewCarousel, type: ItemTypes.CMS_SLIDER_BLOCS_TEXT_MEDIA },
                        { icon: ViewCarousel, type: ItemTypes.CMS_LIST_CATEGORIES },
                        { icon: ViewCarousel, type: ItemTypes.CMS_SECTION_DESCRIPTION_PAGE },
                    ]
                },
                {
                    label: 'Entêtes',
                    items: [
                        { icon: Image, type: ItemTypes.CMS_TOP_IMAGE_FULLSCREEN },
                        { icon: Title, type: ItemTypes.CMS_ENTETE },
                    ]
                },
                {
                    label: 'Présentation',
                    items: [
                        { icon: Dashboard, type: ItemTypes.CMS_CATEGORIES_HOME },
                        { icon: Dashboard, type: ItemTypes.CMS_COLLECTIONS_HOME },
                        { icon: Dashboard, type: ItemTypes.CMS_PRESENTATION_DUO },
                        { icon: Dashboard, type: ItemTypes.CMS_GRID_IMAGE },
                        { icon: Dashboard, type: ItemTypes.CMS_BANNER_MEDIA },
                        { icon: Dashboard, type: ItemTypes.CMS_TEXT_MEDIA },
                        { icon: Dashboard, type: ItemTypes.CMS_SECTION_MANIFEST_BLOCK },
                        { icon: Dashboard, type: ItemTypes.CMS_BLOC_STORELOCATOR_HOME },
                        { icon: Dashboard, type: ItemTypes.CMS_BLOC },
                        { icon: Minimize, type: ItemTypes.CMS_SEPARATOR },
                        { icon: Code, type: ItemTypes.CMS_CUSTOM },
                    ]
                },
                {
                    label: 'Textes',
                    items: [
                        { icon: Title, type: ItemTypes.CMS_ENTETE },
                        { icon: Title, type: ItemTypes.CMS_SLOGAN },
                        { icon: Title, type: ItemTypes.CMS_TITLE_TEXT },
                        { icon: Title, type: ItemTypes.CMS_MULTIPLE_TEXT },
                    ]
                }
            ];
        case AssetTypes.FLIPBOOK: 
            return [{
                label: 'Général',
                items: [
                    { icon: ViewCarousel, type: ItemTypes.HEADER_BOOK },
                    { icon: ViewCarousel, type: ItemTypes.HEADER_CART_BOOK },
                    { icon: ViewCarousel, type: ItemTypes.FOOTER_BOOK },
                    { icon: ViewCarousel, type: ItemTypes.TOP_HOMEPAGE_BOOK },
                    { icon: ViewCarousel, type: ItemTypes.POSTCARD_BOOK },
                    { icon: ViewCarousel, type: ItemTypes.RECAP_BLOCK_BOOK },
                    { icon: ViewCarousel, type: ItemTypes.RESULTS_BOOK },
                    { icon: ViewCarousel, type: ItemTypes.BANNER_LINK_BOOK },
                    { icon: ViewCarousel, type: ItemTypes.FLIPBOOK },
                    { icon: ViewCarousel, type: ItemTypes.FLIPBOOK_WITH_CART },
                    // { icon: ViewCarousel, type: ItemTypes.FLIPBOOK_WITH_WISHLIST },
                ]
            }];
        case AssetTypes.GAME:
            return [
                {
                    label: 'Général',
                    items: [
                        { icon: ViewCarousel, type: ItemTypes.PRE_HEADER_GAME },
                        { icon: ViewCarousel, type: ItemTypes.ALREADYPLAYED_GAME_SIMPLE },
                        { icon: ViewCarousel, type: ItemTypes.HOME_COMPLETE_FORM_GAME },
                        { icon: ViewCarousel, type: ItemTypes.HOME_COMPLETE_FORM_GAME_TOULOUSE },
                        { icon: ViewCarousel, type: ItemTypes.BASIC_BLOC2 },
                        { icon: ViewCarousel, type: ItemTypes.RECAP_WISHLIST },
                        { icon: Code, type: ItemTypes.CMS_CUSTOM },
                    ]
                },
                {
                    label: 'Explications',
                    items: [
                        { icon: ViewCarousel, type: ItemTypes.EXPLANATIONS_ACV_GAME },
                        { icon: ViewCarousel, type: ItemTypes.EXPLANATIONS_BF_GAME },
                        { icon: ViewCarousel, type: ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME },
                    ]
                },
                {
                    label: 'Jeu',
                    items: [
                        { icon: ViewCarousel, type: ItemTypes.AMAZING_GAME },
                        { icon: ViewCarousel, type: ItemTypes.TITLE_GAME },
                        { icon: ViewCarousel, type: ItemTypes.PARAGRAPH_GAME },
                        { icon: ViewCarousel, type: ItemTypes.RULES_AMAZING_GAME },
                        { icon: ViewCarousel, type: ItemTypes.RULES_INLINE_FLEX_AMAZING_GAME },
                        { icon: ViewCarousel, type: ItemTypes.DOTATIONS_ONE_IMAGE },
                        { icon: ViewCarousel, type: ItemTypes.SOCIAL_LIST },
                        { icon: ViewCarousel, type: ItemTypes.BUTTON_GAME },
                        { icon: ViewCarousel, type: ItemTypes.GALLERY_GAME },
                    ]
                },
                {
                    label: 'Redirections',
                    items: [
                        { icon: ViewCarousel, type: ItemTypes.REDIRECT_ON_GAME3 },
                        { icon: ViewCarousel, type: ItemTypes.REDIRECT_ON_GAME4 },
                        { icon: ViewCarousel, type: ItemTypes.BANNER_IMAGE },
                    ]
                },
                {
                    label: 'Mini-jeux',
                    items: [
                        { icon: ViewCarousel, type: ItemTypes.GAME_RAIN },
                        { icon: ViewCarousel, type: ItemTypes.GAME_CLICKER },
                        { icon: ViewCarousel, type: ItemTypes.GAME_QUIZ_PERSONALITY },
                        { icon: ViewCarousel, type: ItemTypes.GAME_SCRATCH_IMAGE },
                        { icon: ViewCarousel, type: ItemTypes.GAME_WISHLIST },
                        { icon: ViewCarousel, type: ItemTypes.GAME_CODE_FINDER },
                        { icon: ViewCarousel, type: ItemTypes.GAME_PLACEHOLDER },
                    ]
                }
            ];
        case AssetTypes.MINISITE:
            return [
                {
                    label: 'Carousels',
                    items: [
                        { icon: ViewCarousel, type: ItemTypes.SLIDER },
                        { icon: ViewCarousel, type: ItemTypes.SLIDER_MULTIPLE },
                        { icon: ViewCarousel, type: ItemTypes.SLIDER_WITH_RIGHT_IMAGES },
                        { icon: ViewCarousel, type: ItemTypes.SLIDER_PRODUCT },
                    ]
                },
                {
                    label: 'Présentation',
                    items: [
                        { icon: Dashboard, type: ItemTypes.TAB_PRODUCT },
                        { icon: Dashboard, type: ItemTypes.IMAGE_ROW },
                        { icon: Title, type: ItemTypes.SECTION_TITLE },
                        { icon: Dashboard, type: ItemTypes.THREE_IMAGES_GRID },
                        { icon: Dashboard, type: ItemTypes.THREE_IMAGES_ROW },
                        { icon: Title, type: ItemTypes.BLOC_TEXT },
                        { icon: Dashboard, type: ItemTypes.GIFT_FINDER },
                        { icon: Dashboard, type: ItemTypes.INFORMATION_BANNER },
                        { icon: Dashboard, type: ItemTypes.LINK_BANNER },
                        { icon: Dashboard, type: ItemTypes.CATEGORY },
                        { icon: Dashboard, type: ItemTypes.TEXT_IMAGE },
                        { icon: Dashboard, type: ItemTypes.TEXT_VIDEO },
                        { icon: Dashboard, type: ItemTypes.CMS_COLLECTIONS_HOME },
                    ]
                },
                {
                    label: 'Textes',
                    items: [
                        { icon: Title, type: ItemTypes.CMS_ENTETE },
                        { icon: Title, type: ItemTypes.CMS_SLOGAN },
                        { icon: Title, type: ItemTypes.CMS_TITLE_TEXT },
                    ]
                }
            ];
        default: 
            return [];
    }
};

export const getDraggables = (type, presentation = false) => {
    if (presentation) {
        return (
            <>
                <Draggable type={ItemTypes.OFFER_FRONT_PAGE} />
                <Draggable type={ItemTypes.OFFER_SECTION_TITLE} />
                <Draggable type={ItemTypes.OFFER_ABOUT_SLIDE} />
                <Draggable type={ItemTypes.OFFER_TRIPLE_BLOCK_LIST} />
                <Draggable type={ItemTypes.OFFER_TRIPLE_BLOCK} />
                <Draggable type={ItemTypes.OFFER_TRIPLE_BLOCK_TEXT} />
                <Draggable type={ItemTypes.OFFER_TEXT_WITH_IMAGE} />
                <Draggable type={ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE} />
                <Draggable type={ItemTypes.OFFER_DEVIS} />
            </>
        );
    }

    switch (type) {
        case AssetTypes.FLIPBOOK: 
            return(
                <>
                    <Draggable type={ItemTypes.HEADER_BOOK} />
                    <Draggable type={ItemTypes.HEADER_CART_BOOK} />
                    <Draggable type={ItemTypes.FOOTER_BOOK} />
                    <Draggable type={ItemTypes.TOP_HOMEPAGE_BOOK} />
                    <Draggable type={ItemTypes.POSTCARD_BOOK} />
                    <Draggable type={ItemTypes.RECAP_BLOCK_BOOK} />
                    <Draggable type={ItemTypes.RESULTS_BOOK} />
                    <Draggable type={ItemTypes.BANNER_LINK_BOOK} />
                    <Draggable type={ItemTypes.FLIPBOOK} />
                    <Draggable type={ItemTypes.FLIPBOOK_WITH_CART} />
                    {/* <Draggable type={ItemTypes.FLIPBOOK_WITH_WISHLIST} /> */}
                </>

            )
        case AssetTypes.MINISITE:
            return (
                <>
                    <Draggable type={ItemTypes.SLIDER} />
                    <Draggable type={ItemTypes.SLIDER_MULTIPLE} />
                    <Draggable type={ItemTypes.SLIDER_WITH_RIGHT_IMAGES} />
                    <Draggable type={ItemTypes.TAB_PRODUCT} />
                    <Draggable type={ItemTypes.IMAGE_ROW} />
                    <Draggable type={ItemTypes.SECTION_TITLE} />
                    <Draggable type={ItemTypes.THREE_IMAGES_GRID} />
                    <Draggable type={ItemTypes.THREE_IMAGES_ROW} />
                    <Draggable type={ItemTypes.BLOC_TEXT} />
                    <Draggable type={ItemTypes.GIFT_FINDER} />
                    <Draggable type={ItemTypes.INFORMATION_BANNER} />
                    <Draggable type={ItemTypes.LINK_BANNER} />
                    <Draggable type={ItemTypes.CATEGORY} />
                    <Draggable type={ItemTypes.TEXT_IMAGE} />
                    <Draggable type={ItemTypes.SLIDER_PRODUCT} />
                    <Draggable type={ItemTypes.TEXT_VIDEO} /> 
                </>
            );
        case AssetTypes.PAGES:
        case AssetTypes.SHOPIFY:
        case AssetTypes.MAGENTO:
        case AssetTypes.WORDPRESS:
            return (
                <>
                    <Draggable type={ItemTypes.CMS_ENTETE} />
                    <Draggable type={ItemTypes.CMS_TOP_IMAGE_FULLSCREEN} />
                    <Draggable type={ItemTypes.CMS_SLOGAN} />
                    <Draggable type={ItemTypes.CMS_TITLE_TEXT} />
                    <Draggable type={ItemTypes.CMS_SEPARATOR} />
                    <Draggable type={ItemTypes.CMS_SLIDER} />
                    <Draggable type={ItemTypes.CMS_SLIDER_ABOUT} />
                    <Draggable type={ItemTypes.CMS_LIST_CATEGORIES} />
                    <Draggable type={ItemTypes.CMS_SLIDER_BY_TAB} />
                    <Draggable type={ItemTypes.CMS_SLIDER_INSTAGRAM} />
                    <Draggable type={ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA} />
                    <Draggable type={ItemTypes.CMS_SLIDER_MOMENTS} />
                    <Draggable type={ItemTypes.CMS_SLIDER_IMAGE} />
                    <Draggable type={ItemTypes.CMS_GRID_IMAGE} />
                    <Draggable type={ItemTypes.CMS_TEXT_MEDIA} />
                    <Draggable type={ItemTypes.CMS_BANNER_MEDIA} />
                    <Draggable type={ItemTypes.CMS_SECTION_DESCRIPTION_PAGE} />
                    <Draggable type={ItemTypes.CMS_SECTION_MANIFEST_BLOCK} />
                    <Draggable type={ItemTypes.CMS_CATEGORIES_HOME} />
                    <Draggable type={ItemTypes.CMS_COLLECTIONS_HOME} />
                    <Draggable type={ItemTypes.CMS_PRESENTATION_DUO} />
                    <Draggable type={ItemTypes.CMS_SLIDER_PRODUCTS_HOME} />
                    <Draggable type={ItemTypes.CMS_BLOC_STORELOCATOR_HOME} />
                    <Draggable type={ItemTypes.CMS_SLIDER_BLOCS_TEXT_MEDIA} />
                    <Draggable type={ItemTypes.CMS_BLOC} />
                    <Draggable type={ItemTypes.CMS_CUSTOM} />
                </>
            );
        case AssetTypes.GAME:
            return (
                <>
                    <Draggable type={ItemTypes.PRE_HEADER_GAME} />
                    <Draggable type={ItemTypes.ALREADYPLAYED_GAME_SIMPLE} />
                    <Draggable type={ItemTypes.HOME_GAME}>
                        <Draggable type={ItemTypes.HOME_COMPLETE_FORM_GAME} />
                        <Draggable type={ItemTypes.HOME_COMPLETE_FORM_GAME_TOULOUSE} />
                        <Draggable type={ItemTypes.PARAGRAPH_GAME} />
                    </Draggable>
                    
                    <Draggable type={ItemTypes.EXPLANATIONS_GAME}>
                        <Draggable type={ItemTypes.EXPLANATIONS_ACV_GAME} />
                        <Draggable type={ItemTypes.EXPLANATIONS_BF_GAME} />
                        <Draggable type={ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME} />
                    </Draggable>
                  
                    <Draggable type={ItemTypes.AMAZING_GAME}>
                        <Draggable type={ItemTypes.TITLE_GAME} />
                        <Draggable type={ItemTypes.PARAGRAPH_GAME} />
                       
                        <Draggable type={ItemTypes.RULES_AMAZING_GAME} />
                        <Draggable type={ItemTypes.RULES_INLINE_FLEX_AMAZING_GAME} />
                        <Draggable type={ItemTypes.DOTATIONS_ONE_IMAGE} />
                        <Draggable type={ItemTypes.SOCIAL_LIST} />
                        <Draggable type={ItemTypes.BUTTON_GAME} />
                        <Draggable type={ItemTypes.GALLERY_GAME} />
                    </Draggable>
                    <Draggable type={ItemTypes.BASIC_BLOC}>
                        <Draggable type={ItemTypes.BASIC_BLOC2} />
                    </Draggable>
                    <Draggable type={ItemTypes.REDIRECT_ON_GAME}>
                        <Draggable type={ItemTypes.REDIRECT_ON_GAME3} />
                        <Draggable type={ItemTypes.REDIRECT_ON_GAME4} />
                        <Draggable type={ItemTypes.BANNER_IMAGE} />
                    </Draggable>
                    <Draggable type={ItemTypes.MINI_GAMES}>
                        <Draggable type={ItemTypes.GAME_RAIN} />
                        <Draggable type={ItemTypes.GAME_CLICKER} />
                        <Draggable type={ItemTypes.GAME_QUIZ_PERSONALITY} />
                        <Draggable type={ItemTypes.GAME_SCRATCH_IMAGE} />
                        <Draggable type={ItemTypes.GAME_WISHLIST} />
                        <Draggable type={ItemTypes.GAME_CODE_FINDER} />
                        <Draggable type={ItemTypes.GAME_PLACEHOLDER} />
                    </Draggable>
                    <Draggable type={ItemTypes.RECAP_WISHLIST} />
                    <Draggable type={ItemTypes.CMS_CUSTOM} />
                </>
            );
        default: 
            return (
                <Alert severity="warning" style={{ width: "calc(100% - 40px)", marginTop: 10, marginBottom: 0 }}>
                    <AlertTitle>Attention</AlertTitle>
                    Aucun <strong>composant</strong> disponible pour le moment
                </Alert>
            );
    }
};

// Sort components
const Sortable = (props) => {
    const { 
        setLocalConfig,
        page
    } = useContext(BuilderContext);

    const onSortEnd = ({oldIndex, newIndex}) => {
        setLocalConfig(prev => {
            return (
                {...prev, 
                    [page]: {
                        elements: arrayMove(props?.items, oldIndex, newIndex)
                    }
                })
        })
    }

    return <SortableBox items={props?.items} onSortEnd={onSortEnd} lockAxis='y' />;
}

const SortableBox = SortableContainer(({items}) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            cursor: "move",
            width: '100%',
        }
    }))
    const classes = useStyles()

    return (
        <div className={classes.root}>
            {items?.map((value, index) => (
                <SortableItem key={`item-${value?.key}`} index={index} value={getTypeName(value?.type)} />
            ))}
        </div>
    );
});

const SortableItem = SortableElement(({value}) => {

    const useStyles = makeStyles(themes => ({
        root: {
            visibility: "visible",
            display: "flex",
            flexWrap: "wrap",
            marginBottom: 10,
            height: "auto",
            flexDirection: "row",
            position: "relative",
            border: "solid 1px #eee"
        }
    }))

    const classes = useStyles();

    return (
        <div className={classes.root} style={{
            zIndex: 10,
        }}>
            <Paper 
                tabIndex={0}
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "10px 0 10px 0",
                    width: "100%",
                    borderRadius: 0,
                    boxShadow: "none"
                }}
            >
                <div
                    style={{ 
                        position: "absolute", 
                        top: 10, 
                        right: 10, 
                        display: "flex",
                    }}
                >
                    
                    <DehazeIcon 
                        style={{ 
                            fill: colors.black.regular,
                            position: "relative",
                            top: "-3px" 
                        }} 
                    />
                </div>
                <span
                    style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        padding: "0 10px",
                        maxWidth: "80%",
                    }}
                >
                    {value}
                </span>
            </Paper>
        </div>
    )
});

export const getCurrentDraggables = (contentPage) => (
    <Sortable items={contentPage?.elements} />
);
