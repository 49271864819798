import React from 'react';
import {Grid} from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardCurrencies from '../../../layouts/Card/cardContent/CardCurrencies';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";

import { 
    ROUTE_PRODUCTS_CATEGORIES, 
    ROUTE_PRODUCTS_ATTRIBUTS, 
    ROUTE_PRODUCTS_COMPLETUDE,
    ROUTE_MEDIAS_CATEGORIES, 
    ROUTE_MEDIAS_ATTRIBUTS,
    ROUTE_MEDIAS_TAGS,
    ROUTE_CONTENU_CATEGORIES,
    ROUTE_CONTENU_ATTRIBUTES
} from '../../../../js/constants/route-names';
import { withRouter } from "react-router";
import {withTranslation} from 'react-i18next';
import styled from 'styled-components';
import iconSet from "../../../../assets/selection.json";
import IcomoonReact from "icomoon-react";
import Button from '../../../ui/button/Button';
import colors from '../../../../config/theme/colors';
import { checkRouting } from '../../../../js/utils/checkRouting';


const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;


class SettingsTemplate extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            listSettings: []
        }
    }

    initCards = (type) => {
        let settingsList = [];
        switch(type) {
            case 'products': 
                settingsList = [
                    {
                        name : 'drawer.categories',
                        route: ROUTE_PRODUCTS_CATEGORIES,
                        icone:'picto-categorie',
                    },
                    {
                        name : 'drawer.attributes',
                        route: ROUTE_PRODUCTS_ATTRIBUTS,
                        icone:'picto-attribut',
                    },
                    {
                        name : 'drawer.completeness',
                        route: ROUTE_PRODUCTS_COMPLETUDE,
                        icone:'picto-produit', 
                    },
                ]
                break;
            case 'medias': 
                settingsList = [
                    {
                        name : 'drawer.categories',
                        route: ROUTE_MEDIAS_CATEGORIES,
                        icone: 'picto-categorie'
                    },
                    // {
                    //     name : 'drawer.attributes',
                    //     route: ROUTE_MEDIAS_ATTRIBUTS,
                    //     icone: 'picto-attribut'
                    // },
                    {
                        name : 'drawer.tags',
                        route: ROUTE_MEDIAS_TAGS,
                        icone: 'picto-label'
                    },
                ]
                break;
            case 'contents': 
                settingsList = [
                    {
                        name : 'drawer.categories',
                        route: ROUTE_CONTENU_CATEGORIES,
                        icone: 'picto-categorie'
                    },
                    {
                        name : 'drawer.models',
                        route: ROUTE_CONTENU_ATTRIBUTES,
                        icone: 'picto-attribut'
                    },
                ]
                break;
            default: // Hello
                console.log('\nU too! <3\n'.blue);
                break;
        }
        this.setState({
            loading: false,
            listSettings: settingsList
        })
    };

    componentDidMount = () => {
        checkRouting(this.props)
        this.initCards(this.props.typeSettings);
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.typeSettings !== this.props.typeSettings){
            this.setState({
                loading:true
            })
            this.initCards(this.props.typeSettings);
        }
    }

    render() {
        const { t } = this.props;        
        return (
            <div>
                <TopPanel 
                icomoon="ico_parametre" 
                colorIcomoon={colors.blue.darker.hue300} 
                title={t('drawer.configuration')} 
                // subtitle={this.props.t('settings.tax.subTitle')} 
                // handlerAdd={!this.state.loading ? this.handleAddTax : null} 
                // gradientColor1={colors.menu.regular} 
                // gradientColor2={colors.menu.darker} 
                // buttonAvailable={true}
                hasBorder={true}
                windowWidth={this.props.windowWidth}
                />
                <Grid container direction="column" justifyContent="center" spacing={0} style={{marginTop: 16}}>
                    <Grid container direction="row" spacing={4}>
                        {
                            !this.state.loading && this.state.listSettings.length > 0 ?
                            this.state.listSettings.map((setting, index) => {
                                return(
                                    <GridCustom item xl={3} lg={4} md={4} xs={6} key={`SettingTile-${index}`} onClick={() => this.goTo(setting.route)} style={{cursor:'pointer'}}>
                                        <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'20px 20px 20px 20px'}>
                                            <Grid container alignItems='center' direction='row'>
                                                <Grid item style={{paddingRight: 16}}>
                                                    <IcomoonReact iconSet={iconSet} size={60} icon={setting.icone} color={colors.blue.darker.hue300}/>
                                                </Grid>
                                                <Grid item style={{flex: 1}}>
                                                    <Grid container direction='column' justifyContent='space-between'>
                                                        <Typography variant="h2">{t(setting.name)}</Typography>
                                                        <Button 
                                                            text={t("homepage.notification.learnmore")}
                                                            bgcolor={colors.blue.lighter.hue900} 
                                                            color={colors.blue.darker.hue300} 
                                                            bgcolorhover={colors.blue.lighter.hue600}  
                                                            colorhover={colors.blue.darker.hue300}  
                                                            border={`1px solid ${colors.blue.darker.hue300}`}
                                                            onClick={() => this.goTo(setting.route)} 
                                                            arrow="right"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardCustom>
                                    </GridCustom>
                                )
                            })
                                : 
                                (<PageLoader />)
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }
    goTo = route => this.props.history.push(route);
}

export default withTranslation()(withRouter(SettingsTemplate));