import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import CatalogFinal from '../../../../../assets/pictos/empty-picto/empty_products.png';
import Mapping from '../components/Mapping';
import {withTranslation} from 'react-i18next'


const ImportProductsApi =  {
    titleForm: 'products.list.importation.title',
    subTitleForm: 'products.list.importation.subtitle',
    langSelect: false,
    drawerType: 'drawer',
    noForm: true,
    component: Button,
    formConfig: {
        type: STEPPER,
        finalStep: {
            picto: CatalogFinal,
            title: 'Bientôt prêt !',
            subtitle: 'Votre fichier est prêt à être importé :)',
            textButton: 'Importer les produits'
        },
        children: [
            {
                labelName: 'products.list.importation.settings',
                isOptionnal: false,
                // component: UploadFile,
                optionsInputs: [
                    {
                        type: 'select',
                        label: 'Type',
                        translated: false,
                        helper: {
                            text: 'products.list.importation.importtype',
                            link: false,
                        },
                        required: true,
                        stateName: 'importTypeFile',
                        value: [
                            {
                                value : 'csv',
                                label : '.csv', 
                            },
                            {
                                value : 'xml',
                                label : '.xml', 
                            }
                        ]
                    },
                    {
                        type: 'text',
                        label: 'URL',
                        helper: {
                            text: 'Url de l\'API',
                            link: false,
                        },
                        
                        required: true,
                        stateName: 'importUrl',
                        disabled: false,
                    },
                    {
                        type: 'text',
                        label: 'products.categories.creation.id',
                        helper: {
                            text: 'products.list.importation.idaccess',
                            link: false,
                        },
                        
                        required: false,
                        stateName: 'importUsername',
                        disabled: false,
                    },
                    {
                        type: 'text',
                        label: 'products.list.importation.password', //password, trouver label pour t().
                        helper: {
                            text: 'products.list.importation.pwaccess',
                            link: false,
                        },
                        
                        required: false,
                        stateName: 'importPassword',
                        disabled: false,
                    },
                ]
            },
            {
                labelName: 'Mapping',
                isOptionnal: false,
                component: Mapping,
                optionsInputs: []
            }
        ]
    }
};

export default ImportProductsApi;