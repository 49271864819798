import { getDefaultTextCms, getDefaultLinkCms } from '../../helpersCms';
import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Bloc Top image',
    inputs:{
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
    },
    blocks:{
        title: getDefaultTextCms("Titre", "Titre principal"),
        subtitle: getDefaultTextCms("Sous-titre", "Un savoir-faire"),
        subtitleTop: getDefaultTextCms("Sous-titre en haut", ""),
        image: {
            type: EditableTypes.IMAGE,
            name: "Image de fond",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/1920x1080"
                }
            }
        },
    }
};