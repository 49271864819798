import React from 'react';
import { withRouter } from 'react-router';
import { Grid, LinearProgress, Dialog, DialogTitle, DialogContent, DialogActions, Accordion, AccordionSummary, AccordionDetails, Box } from '@material-ui/core'
import { connect } from "react-redux";
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import TopPanel from '../../../layouts/TopPanel/TopPanel'
import Typography from '../../../ui/typography/Typography';
import { ExpandMore, Maximize } from '@material-ui/icons';
import FolderSharpIcon from '@material-ui/icons/FolderSharp';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import ChipMedias from './components/ChipMedias'
import MediaModal from './components/MediaModal'
import { eventService } from '../../../../js/services/event.service';
import request from '../../../../js/utils/fetch';

import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import SearchTags from '../../../ui/inputs/SearchTags';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import EmptyMedias from '../../../../assets/pictos/empty-picto/empty_medias.png';

import CardCustom from '../../../layouts/Card/CardCustom';
import CardMedia from '../../../layouts/Card/cardContent/CardMedia';

import { withApollo } from 'react-apollo';
import { GET_MEDIA_CATEGORIES } from '../../../../queries/mediaCategories';
import { GET_MEDIAS_IMAGES, GET_MEDIAS_CURSOR, UPDATE_TAG, ADD_TAG } from '../../../../queries/medias';
import { GET_ALL_CHANNELS } from '../../../../queries/channels'

import LayoutFormBuilder from '../../../ui/form/LayoutFormBuilder';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import axios from '../../../../js/utils/axios';

import mediasConfig from './config/medias.config'
import mediasEditConfig from './config/mediasEdit.config'

import { ALLOWED, VIDEOS, IMAGES, DOCUMENTS, CSV, LIST_MEDIAS } from '../../../../js/constants/medias-types';
import { ROUTE_CHANNELS_MEDIAS, ROUTE_HOME } from '../../../../js/constants/route-names';
import Pagination from '@material-ui/lab/Pagination';
import TabCategoriesMedia from '../products/components/TabCategories';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import mediasImportConfig from './config/mediasImport.config';
import mediasImportStepperConfig from './config/mediasImportStepper.config';

import { MEDIAS, MEDIAS_MEDIAS, VIEW, CREATE, IMPORT, DELETE, UPDATE } from '../../../../js/constants/constant-rights';
import { getFileConfig } from '../../../../js/helpers/files';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import { getParams } from '../../../../js/utils/getParams';
import SearchBar from '../../../ui/search/SearchBar';
import Button from '../../../ui/button/Button';
import BreadcrumbCustom from '../../../ui/breadcrumb/Breadcrumb';
import OurTooltip from '../../../ui/tooltip/Tooltip';
import { node } from 'prop-types';
import Listing from '../../../layouts/Listing/Listing';
import { listMappers, listSettings, perPageOptions } from './config/listMedias.config';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';

const MediaContainer = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top:20px;
    /*&>.MuiGrid-item{
        height: 65vh;
        overflow: auto;
    }*/
`;

const ContainerInput = styled(Grid)`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    padding-top:0!important;
    padding-bottom:0!important;
`;

const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;

const FolderContainer = styled(Grid)`
    display: grid;
    grid-template-columns:
        ${props => props.windowWidth > 1500 ? 'repeat(auto-fill, minmax(20%, 1fr))' // 4 columns
        : props.windowWidth > 765 ? 'repeat(auto-fill, minmax(25%, 1fr))' // 3 columns
            : 'repeat(auto-fill, minmax(33%, 1fr))'}; // 2 columns
    width: 100%;
    gap: 17px;
    margin-top: 9px;
`

const FolderCustom = styled(Grid)`
    background-color: ${colors.white};
    border: 0.5px solid ${colors.grey.lighter.hue700};
    padding: 10px 15px;
    color: ${colors.black.regular};
    font-weight: bold;
    height: 52px;
    gap: 10px;
    cursor: pointer;
    '&:hover': {
        background-color: ${colors.grey.lighter.hue900}
    }
`

const PageWrapper = styled(Box)`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    height: 100%;
`;

let timer = null;
class MediasCategories extends React.Component {

    constructor(props) {
        super(props)
        this.clearTagsFilter = this.clearTagsFilter.bind(this)
        this.ref = React.createRef(null)
        this.state = {
            openForm: this.props.history.location?.state?.formOpen
                ? props.history.location?.state?.formOpen
                : props.history.location.state?.openForm
                    ? props.history.location.state.openForm
                    : false,
            openMediaForm: props.history.location.state?.openMediaForm ? props.history.location.state.openMediaForm : false,
            openFormImport: false,
            dataLayout: null,
            identifier: '',
            parent: [],
            parentCat: '',
            formImage: null,
            fileName: null,
            fileAlt: 'Image',
            treeCats: [],
            medias: null,
            /*allMedias       : null,*/
            filterByType: props.history.location.state?.types ? props.history.location.state.types : LIST_MEDIAS,
            openModal: false,
            buttonAvailable: props.history.location.state?.buttonAvailable ? false : true,
            toDelete: null,
            openDeleteModal: false,
            selectedNodes: [],
            formAction: 'add',
            currentId: '',
            openDeleteCatModal: false,
            loading: false,
            page: 1,
            nbperpage: 12,
            countPage: 0,
            cursor: null,
            errors: [],
            /* filter by */
            searchedName: '',
            filterByTags: [],
            /* Filter & Reset */
            isCleared: false,
            /* Folders for breadcrumb */
            categories: [],
            rootCategories: [],
            currentFolder: null,
            previousFolders: [],
            areCategoriesLoading: false,
            categoriesData: [],
            openFormImportStepper: false,
            isZip: false,
        };
        this.cats = [
            {
                libelle: this.props.t("medias.medias.all"),
                type: 'all',
            },
            {
                libelle: this.props.t("medias.medias.images"),
                type: IMAGES,
            },
            {
                libelle: this.props.t("medias.medias.videos"),
                type: VIDEOS,
            },
            {
                libelle: this.props.t("medias.medias.documents"),
                type: DOCUMENTS,
            },
            /*{
                libelle:'360°',
                type:[],
            },
            {
                libelle:'Audio',
                type:[],
            }*/
        ]

    }


    //#region UTILS
    copyArrayOfObjects = array => array.map(a => ({ ...a }));

    resetState() {
        this.setState({
            cat_id: '',
            identifier: '',
            parentCat: '',
            parent: [],
            action: '',
            formAction: '',
            toDelete: null,
            fileName: '',
            fileAlt: '',
            formImage: null,
            /*dataAddLayout: mediasImport,*/
        });
    }

    getFileFromURL = (url, name) => {
        return new Promise((resolve, reject) => {
            fetch(url)
                .then(res => res.blob()) // Gets the response and returns it as a blob
                .then(blob => {
                    let file = new File([blob], name);
                    resolve(file)
                });
        })
    }

    handleNextStep = () => {
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
            this.setState({
                seeErrors: true,
            });
            eventService.fire();
            return false;
        }

    }

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.error"));

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleInputChange = (stateName, evt) => {
        const value = evt?.target?.value ?? evt;

        this.setState({
            ...this.state,
            [stateName]: value
        });
    };

    checkChannel(channels, identifier) {
        let channel = channels.filter(channel => channel.node.attributeGroup.identifier === identifier && !channel.node.isSystem);
        if (channel && channel.length > 0) {
            return { isSetUp: true, channels: channel };
        }
        else {
            return { isSetUp: false };
        }
    };

    changePage = (event, page) => {
        this.props.startLoading();
        let index = 0;
        if (page > 1) {
            index = (page * this.state.nbperpage) - this.state.nbperpage - 1
        }
        this.setState({
            cursor: this.state.listCursors[index].cursor,
            page: page
        }, async () => {
            this.filterMedias(true);
            this.props.stopLoading();
        });
    };

    //#endregion


    //#region GET MEDIAS
    handleGetCursorsMedias = (variables) => {
        this.props.client.query({
            query: GET_MEDIAS_CURSOR,
            fetchPolicy: 'no-cache',
            variables: variables,
        }).then(result => {
            this.setState({
                listCursors: result.data.mediaObjects.edges
            });
        });
    }

    handleGetCategories() {
        this.setState({
            areCategoriesLoading: true,
        })
        return new Promise((resolve, reject) => {
            // this.props.client.query({
            //     query: GET_MEDIA_CATEGORIES,
            //     fetchPolicy: 'no-cache'
            // }).then(result =>{
            //     let categoriesRemoveNode = []
            //     result.data.mediaObjectCategories.edges.map(
            //         e => categoriesRemoveNode.push(e.node)
            //     )
            //     this.setState({
            //         categories: categoriesRemoveNode,
            //         rootid: result.data.mediaObjectCategories.edges[0].node.id,
            //         activeCategorie: ['Tout voir']
            //     });
            //     resolve();
            // });
            request(`${process.env.REACT_APP_API}/categories-list/media`, 'get').then(
                (data) => {
                    if (data.success) {
                        /* const mapCategories = data.datas.map((category) => category['node']); */
                        this.setState({
                            categories: data.datas,
                            rootCategories: data.datas,
                            areCategoriesLoading: false,                            
                        });
                    }
                    resolve();
                }
            );
        });
    }
    //#endregion


    //#region FILTER
    filterByType = (cat) => {
        let filter;
        if (cat === 'all') {
            filter = LIST_MEDIAS;
        }
        else {
            filter = cat;
        }
        this.setState({
            filterByType: filter
        }, () => {
            this.filterMedias();
        })
    }

    filterMedias = async (pagination) => {
        this.setState({
            medias: null,
        })

        const variables = {
            types: this.state.filterByType ?? LIST_MEDIAS,
            categories: [this.state.currentFolder ? this.state.currentFolder.id : null],
            nbperpage: this.state.nbperpage,
            id_list: this.state.filterByTags ?? [],
            name: this.state.searchedName ?? null
        }

        if (!pagination) {
            await this.handleGetCursorsMedias(variables);
            this.setState({
                page: 1
            })
        } else {
            if (this.state.cursor && this.state.listCursors && this.state.cursor !== this.state.listCursors[0].cursor) {
                variables.cursor = this.state.cursor;
            }
        }

        this.props.client.query({
            query: GET_MEDIAS_IMAGES,
            fetchPolicy: 'no-cache',
            variables: variables,
        }).then(result => {
            this.setState({
                medias: result.data.mediaObjects.edges,
                countPage: Math.ceil(result.data.mediaObjects.totalCount / this.state.nbperpage)
            })
        })
    }

    /* handleChangeCategorie = (categorie, childrens, id, currentNode, breadcrumb) => {
        if (id) {
            // let id2 = id.replace('/api/categories', '');
            //     id2 = id2.replace('/', '');
            //     id2 = parseInt(id2);

            this.setState({
                idActiveCat: id,
                arrayCatFilter: [id],

                filterByCategory: id,
                selectedNodeId: id,
                hasMultiCat: false,

            }, () => {
                this.filterMedias();
            });
        } else {
            this.setState({
                filterByCategory: ['Tout voir'],
                selectedNodeId: ['Tout voir'],
                hasMultiCat: true,
            }, () => {
                this.filterMedias();
            });
        }


    } */

    filterByTags = (tags) => {
        let listID = []
        if (tags.length > 0) {
            for (let tag of tags) {
                for (let media of tag.mediaObjects) {
                    media = parseInt(media.replace('/api/media-objects/', ''), 10)
                    if (!listID.includes(media)) {
                        listID.push(media);
                    }
                }

            }
        }

        this.setState({
            filterByTags: listID,
        })
    }

    clearTagsFilter(tags) {
        this.setState({
            isCleared: false,
            filterByTags: [],
            medias: null,
        }, () => {
            this.filterMedias();
        })
    }
    //#endregion


    //#region LAYOUT FORMS
    handleToggleDrawer(form) {
        console.log(this.state.openMediaForm)
        if (form === 'addMediaForm') {
            this.setState({
                openMediaForm: !this.state.openMediaForm,
                // buttonAvailable: !this.state.buttonAvailable
            });
        }

        this.setState({
            [form]: !this.state[form]
        });
    }

    addMedia = () => {
        this.resetState();
        let defaultCat = this.state.selectedNodeId ? this.state.selectedNodeId : this.state.categoriesData?.find(e => e.node.parent === null);
        this.setState({
            parent: [defaultCat],
        }, () => {
            this.handleToggleDrawer('addMediaForm');
        });

    }

    editMedia = (media) => {
        this.resetState();
        if (media.node) {
            media = media.node;
        }
        let mediaCat = media.category.edges.length > 0 ? media.category.edges : [this.state.categoriesData.find(e => e.node.parent === null)];
        let currentTags = media.mediaObjectTags.edges;
        let fileTags = media.mediaObjectTags.edges.map(tag => {
            let mediaObjects = tag.node.mediaObjects.edges.map(media => media.id);
            return {
                id: tag.node.id,
                tag: tag.node.tag,
                mediaObjects: mediaObjects
            }
        });
        this.setState({
            formAction: 'edit',
            currentId: media.id,
            fileName: media.name,
            fileAlt: media.alt,
            filePath: getFileConfig(media.type).image ? getFileConfig(media.type).image : media.filePath,
            fileTags: [...fileTags],
            currentTags: [...currentTags],
            mediaType: media.type,
            parent: mediaCat,
            formImage: {
                changed: false,
                data: getFileConfig(media.type).image ? getFileConfig(media.type).image : media.filePath,
                file: media
            },
            // fileDescription: media.description
        }, () => {
            this.handleToggleDrawer('addMediaForm');

        })
    }

    handleFormImport = (type, title) => {
        // this.handleToggleDrawer('openFormImport');
        this.props.startLoading();
        this.props.client.query({
            query: GET_ALL_CHANNELS,
            fetchPolicy: 'no-cache'
        }).then(result => {
            let allChannels = result.data.channels.edges;
            let importCards = [
                {
                    logo: 'medias/gdrive-logo.png',
                    libelle: 'Google Drive',
                    isLocaleImage: true,
                    identifier: 'googledrive',

                },
                {
                    logo: 'medias/dropbox-logo.png',
                    libelle: 'Dropbox',
                    isLocaleImage: true,
                    identifier: 'dropbox',

                },
                {
                    logo: 'medias/onedrive-logo.png',
                    libelle: 'OneDrive',
                    isLocaleImage: true,
                    identifier: 'onedrive'

                },
                {
                    logo: 'medias/winrar.png',
                    libelle: this.props.t("medias.medias.zipFile"),
                    // textButton : this.props.t("medias.medias.comingSoon"),
                    // secondaryStyle : true,
                    isLocaleImage: true,
                    // disabled:true,
                    identifier: 'zip'

                }
            ]
            importCards = importCards.map(card => {
                if (card.identifier === 'zip') {
                    return {
                        ...card,
                        textButton: this.props.t("medias.medias.import"),
                        description: this.props.t("medias.medias.importFrom") + ' ' + card.libelle,
                        onClick: () => this.setUpImport([], true),
                    };
                }
                else {
                    let checkChannel = this.checkChannel(allChannels, card.identifier);
                    let isSetUp = checkChannel.isSetUp;
                    return {
                        ...card,
                        textButton: isSetUp ? this.props.t("medias.medias.import") : this.props.t("medias.medias.createChannel"),
                        secondaryStyle: !isSetUp,
                        description: isSetUp ? this.props.t("medias.medias.importFrom") + ' ' + card.libelle : null,
                        secondaryText: isSetUp ? null : this.props.t("medias.medias.notConfiguredChannel"),
                        onClick: isSetUp ? () => this.setUpImport(checkChannel.channels) : () => this.goTo(ROUTE_CHANNELS_MEDIAS),

                    }
                }

            })
            let defaultCat = this.state.selectedNodeId ? this.state.selectedNodeId : this.state.categoriesData.find(e => e.node.parent === null);

            this.setState({
                importMediasCard: importCards,
                parent: [defaultCat],
            }, () => {
                this.props.stopLoading()
                this.handleToggleDrawer('openFormImport')
            })

        })

    };

    setUpImport = (channels, isZip = false) => {
        if (isZip) {
            this.setState({
                parent: this.state.selectedNodeId ? this.state.selectedNodeId : this.state.categoriesData.find(e => e.node.parent === null).node.id,
            })
        }
        this.setState({
            isZip: isZip,
            availableChannels: channels
        }, () => {
            this.handleToggleDrawer('openFormImportStepper')
        })
    }
    //#endregion

    //#region MODALS
    handleCallbackModal = (newList) => {
        this.setState({
            medias: newList,
            currentMedias: newList,
        }, () => {
            this.filterMedias();
        })
    }
    //#endregion


    //INIT
    async preparePage() {
        this.prepareTree();
        await this.handleGetCategories();
        await this.handleGetCursorsMedias();
        this.filterMedias()
        if (this.props.history.location.state?.types) {
            this.filterMedias();
        }
        else {
            // this.handleGetAllMedias();            
        }
        this.setState({ ready: true });
    }

    prepareTree() {
        this.props.client.query({
            query: GET_MEDIA_CATEGORIES,
            fetchPolicy: 'no-cache'
        }).then(result => {

            const cats = result.data.mediaObjectCategories.edges;
            const data = cats.filter(e => e.node.parent === null);

            this.setState({ 
                categoriesData: cats, 
                defaultRoot: data ,
                // currentFolder:this.state.currentFolder === null ?result.data.mediaObjectCategories.edges[0]:this.state.currentFolder
            });
            if (getParams(this.props).includes('formOpen') || this.props.history.location?.state?.formOpen) {
                this.addMedia()
            }
            /*root.children=data;
            tree=tree.concat(root);*/

        })
    }


    //#region MUTATION
    handleAddMedia = (action = 'add') => {

        return new Promise(async (resolve, reject) => {
            if (action === 'edit' || this.state.formImage?.data !== null) {

                this.props.startLoading();
                await this.saveMedia(action);
                this.handleToggleDrawer('addMediaForm');
                // this.handleGetAllMedias(true);
                this.filterMedias();
                this.props.stopLoading();
                if (action === 'edit') {
                    this.props.snack(ALERT_SUCCESS, this.props.t("medias.medias.mediaUpdated"));
                } else {
                    this.props.snack(ALERT_SUCCESS, this.props.t("medias.medias.mediaAdded"));
                }
                this.setState({
                    formImage: null
                })


            }

            resolve();
        });
    }

    handleImportMedia = async () => {
        let cats = this.state.parent;
        let tags = this.state.fileTags;
        let formData = new FormData();
        if (this.state.isZip) {
            this.props.startLoading();
            let file = this.state.upload_ZIP;
            let resultMediaImporter = await axios(`${process.env.REACT_APP_API}/importer/medias/zip`, 'post', {
                "mediaObjectId": parseInt(file.medias.id),
                "mediaObjectCategoryId": parseInt(this.state.parent.replace('/api/media-object-categories/', ''))
            },true,'application/json');

            // let resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData); 
            // let resultMediaImporter = await axios(`${process.env.REACT_APP_API}/importer/medias/zip`, 'post', {
            //     "mediaObjectId": ,
            // }); 
            // if(tags?.length>0){
            //     let tagsToUpdate    = tags.filter(tag=>tag.id !== null);
            //     let tagsToCreate    = tags.filter(tag=>tag.id === null);
            //     await this.handleMediaTags(resultMedia.id,tagsToCreate,tagsToUpdate,null);
            // }
            this.handleToggleDrawer('openFormImportStepper');
            this.handleToggleDrawer('openFormImport');
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, this.props.t("medias.medias.mediaInfoSuccess"));
        } else {
            this.setState({
                mediasPending: this.state.mediasToImport.length,
                mediasUploaded: 0,
                openImportModal: true,
            })
            for (let media of this.state.mediasToImport) {
                let mediaFile;
                if (media.fromLink) {
                    mediaFile = await this.getFileFromURL(media.image, media.name);
                }
                let file = mediaFile;
                formData.append('file', file);
                formData.append('type', media.type);
                formData.append('name', media.name.replace(/ /g, "_").toLowerCase());
                formData.append('alt', media.name);
                if (cats.length > 0) {
                    let mediaCats = cats.map(cat => parseInt(cat.node.id.replace('/api/media-object-categories/', ''), 10));
                    for (let mediaCat of mediaCats) {
                        formData.append('categories[]', mediaCat);
                    }
                }
                let resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                if (tags?.length > 0) {
                    let tagsToUpdate = tags.filter(tag => tag.id !== null);
                    let tagsToCreate = tags.filter(tag => tag.id === null);
                    await this.handleMediaTags(resultMedia.id, tagsToCreate, tagsToUpdate, null);

                }
                this.setState({
                    mediasUploaded: this.state.mediasUploaded + 1,
                })
            }
            this.setState({
                openImportModal: false,
                mediasToImport: [],
            }, () => {
                this.handleToggleDrawer('openFormImportStepper');
                this.handleToggleDrawer('openFormImport');
                this.filterMedias();
                this.props.snack(ALERT_SUCCESS, this.props.t("medias.medias.mediaInfoSuccess"));

            })
        }
    }

    saveMedia = async (action = 'add') => {

        let cats = this.state.parent;
        let tags = this.state.fileTags;
        /*Retirer les parents des catégories enfantes
        let parentList=cats.map(category=>category.node.parent?.id);
        cats=cats.filter(cat=>!parentList.includes(cat.value))  */
        let resultMedia;
        let formData = new FormData();
        switch (action) {

            case 'add':
                let media = this.state.formImage;
                formData.append('file', media.file);
                formData.append('type', media.file.type);
                formData.append('name', this.state.fileName.replaceAll(/ /g, "_").toLowerCase());
                formData.append('alt', this.state.fileAlt);
                // formData.append('description',this.state.fileDescription);

                if (cats.length > 0) {

                    let mediaCats = cats.map(cat => cat.node ? parseInt(cat.node.id.replace('/api/media-object-categories/', ''), 10) : cat);
                    for (let mediaCat of mediaCats) {
                        formData.append('categories[]', mediaCat);
                    }
                }

                resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                if (tags?.length > 0) {
                    let tagsToUpdate = tags.filter(tag => tag.id != null);
                    let tagsToCreate = tags.filter(tag => tag.id == null);
                    await this.handleMediaTags(resultMedia.id, tagsToCreate, tagsToUpdate, null);

                }
                this.filterMedias()
                break;

            case 'edit':
                let mediaCats = [];
                if (cats.length > 0) {
                    mediaCats = cats.map(cat => cat.node ? parseInt(cat.node.id.replace('/api/media-object-categories/', ''), 10) : cat);
                    for (let mediaCat of mediaCats) {
                        formData.append('categories[]', mediaCat);
                    }
                }
                let mediaEdit = this.state.formImage;
                if (mediaEdit.changed) {
                    formData.append('file', mediaEdit.file);
                    formData.append('type', mediaEdit.file.type);
                }

                formData.append('id', this.state.currentId.replace('/api/media-objects/', ''));
                formData.append('name', this.state.fileName.replaceAll(/ /g, "_").toLowerCase());
                formData.append('alt', this.state.fileAlt);
                // formData.append('description',this.state.fileDescription);
                resultMedia = await axios(`${process.env.REACT_APP_API_ROOT + this.state.currentId}`, 'POST', formData);
                if (tags?.length > 0) {
                    let tagsToAdd = tags.filter(tag => !this.state.currentTags.map(tag => tag.node.id).includes(tag.id));
                    let tagsToUpdate = tagsToAdd.filter(tag => tag.id != null);
                    let tagsToCreate = tagsToAdd.filter(tag => tag.id == null);
                    let tagsToDelete = this.state.currentTags.filter(currentTag => typeof (tags.find(tag => tag.id === currentTag.node.id)) === 'undefined')
                    await this.handleMediaTags(resultMedia.id, tagsToCreate, tagsToUpdate, tagsToDelete);

                }
                this.filterMedias()
                break;
            default:
                return null;
        }

    }


    handleMediaTags = (id, toCreate, toUpdate, toDelete) => {
        return new Promise(async (resolve, reject) => {
            if (toCreate) {
                for (let tag of toCreate) {
                    await this.props.client.mutate({

                        mutation: ADD_TAG,
                        variables: {
                            tag: tag.tag,
                            mediaObjects: ['/api/media-objects/' + id],

                        }
                    })
                }

            }

            if (toUpdate) {
                for (let tag of toUpdate) {
                    tag.mediaObjects.push('/api/media-objects/' + id)
                    await this.props.client.mutate({

                        mutation: UPDATE_TAG,
                        variables: {
                            id: tag.id,
                            mediaObjects: tag.mediaObjects,
                        }
                    })
                }
            }

            if (toDelete) {
                for (let tag of toDelete) {
                    let target = '/api/media-objects/' + id;
                    let mediaObjects = tag.node.mediaObjects.edges.filter(media => media.node.id !== target).map(media => media.node.id);
                    await this.props.client.mutate({
                        mutation: UPDATE_TAG,
                        variables: {
                            id: tag.node.id,
                            mediaObjects: mediaObjects,
                        }
                    })
                }
            }
            resolve();
        })
    }
    //#endregion


    /*COMPONENTS*/
    componentDidMount() {
        checkRouting(this.props);
        this.preparePage();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.formImage !== this.state.formImage) {
            let name = this.state.formImage?.file?.name.replace(/ /g, "_").toLowerCase();
            this.setState({
                fileName: name
            })
        }
    }

    //  /* BREADCRUMB */
    //  getClickedCategory = (id) => {
    //     /* The user has clicked one category from the breadcrumb */
    //     if (id) {
    //         const selectedFolderId = this.state.previousFolders.filter(folder => folder).findIndex(folder => folder.id === id)
    //         /* Change the current folder. Not counting the Root */
    //         this.setState({
    //             currentFolder: this.state.previousFolders[selectedFolderId + 1]
    //         }, () => {
    //             /* Change the previous folders. Remove the clicked one and forward. Not counting the Root */
    //             this.state.previousFolders.splice(selectedFolderId + 1)
    //             this.setState({
    //                 categories: this.state.currentFolder.childrens
    //             }, () => {
    //                 this.filterMedias()
    //             })
    //         })

    //         /* The user has clicked "Root" to come back to the root folder and reset the breadcrumb */
    //     } else {
    //         this.setState({
    //             previousFolders: [],
    //             currentFolder: null,
    //             categories: this.state.rootCategories
    //         }, () => {
    //             this.filterMedias()
    //         })
    //     }
    // }

    /* BREADCRUMB */
    getClickedCategory = (id) => {
        /* The user has clicked one category from the breadcrumb */
        if (id) {
            const selectedFolderId = this.state.previousFolders.filter(folder => folder).findIndex(folder => folder.id === id)
            /* Change the current folder. Not counting the Root */
            this.setState({
                currentFolder: this.state.previousFolders[selectedFolderId + 1]
            }, () => {
                /* Change the previous folders. Remove the clicked one and forward. Not counting the Root */
                this.state.previousFolders.splice(selectedFolderId + 1)
                this.setState({
                    categories: this.state.currentFolder.childrens
                }, () => {
                    this.filterMedias()
                })
            })

            /* The user has clicked "Root" to come back to the root folder and reset the breadcrumb */
        } else {
            this.setState({
                previousFolders: [],
                currentFolder: null,
                categories: this.state.rootCategories
            }, () => {
                this.filterMedias()
            })
        }
    }

    render() {
        const normalise = value => (value - 0) * 100 / (this.state.mediasPending - 0);
        return (
            <PageWrapper>
                <TopPanel
                    icomoon="picto-media"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.t("medias.medias.title")}
                    subtitle={this.props.t("medias.medias.subtitle")}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    handlerAdd={() => this.handleToggleDrawer("addMediaForm")}
                    textAdd={'+ ' + this.props.t("medias.medias.addMedia")}
                    buttonAvailable={this.state.medias ? this.state.buttonAvailable : false}
                    handlerImport={() => this.handleFormImport()}
                    textImport={'+ ' + this.props.t("medias.medias.importMedia")}
                />

                <Box style={{
                    padding: 'unset',
                    display: 'grid',
                    gridTemplateRows: 'auto auto 1fr',
                    height: '100%',
                }}>
                    {/* Accordion filters */}
                    <Box style={{ padding: '24px 24px 0px 24px' }}>
                        <AccordionCustom defaultExpanded={false} title={'Filtres et Recherche'}>
                            {/* Search by tags */}
                            <SearchTags
                                allState={this.state}
                                searchOptions={{
                                    placeholder: this.props.t("medias.medias.searchMedia"),
                                    isFilter: true,
                                }}
                                stateCallback={this.filterByTags}
                                clearCallback={this.clearTagsFilter}
                            />
                
                            {/* Search by name */}
                            <SearchBar noIcon
                                variant="standard"
                                underlined={false}
                                style={{
                                    margin: '15px auto 8px auto',
                                    width: '100%',
                                    background: 'white',
                                }}
                                onChange={(e) => this.setState({ searchedName: e.target.value.trim() })}
                                value={this.state.searchedName}
                            />
                
                            {/* Filter buttons */}
                            <ContainerInput container>
                                <Grid container justifyContent="flex-end">
                                    {
                                        this.state.searchedName || this.state.filterByTags.length > 0 ?
                                            <Button onClick={() => {
                                                this.setState({
                                                    searchedName: '',
                                                    isCleared: true,
                                                })
                                            }} color={colors.red.darker} bgcolor={colors.red.lighterv2} bgcolorhover={colors.red.lighterv2Hover} style={{ marginBottom: 0, marginRight: 8, borderRadius: 0, border: `1px solid ${colors.red.darker}` }}>Effacer</Button>
                                            : null
                                    }
                                    <Button onClick={(e) => {
                                        this.filterMedias()
                                    }} style={{ marginBottom: 0, borderRadius: 0 }}>Filtrer</Button>
                                </Grid>
                            </ContainerInput>
                        </AccordionCustom>
                    </Box>
                    
                    {/* Breadcrumb */}
                    <Grid container alignItems="center" style={{ padding: '18px 24px 0px 24px' }}>
                        <Grid item xs={12}>
                            <BreadcrumbCustom
                                getClickedCategory={this.getClickedCategory}
                                previousFolders={this.state.previousFolders}
                                currentFolder={this.state.currentFolder}
                                separator={<ArrowForwardIosSharpIcon fontSize='small' />}
                                nbPreviousItems={4}
                                size={25}
                                windowWidth={this.props.windowWidth}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <TabCategoriesMedia categories={this.state.categories} rootid={this.state.rootid} handleChangeTab={this.handleChangeCategorie} currentLang={this.state.currentLang} isMedia={true} /> */}
                            <Typography color='primary' style={{
                                fontSize: '20px',
                                margin: '18px 0px',
                            }}>Dossiers</Typography>
                            {
                                this.state.categories && !this.state.areCategoriesLoading ?
                                    this.state.categories.length > 0 ? (
                                        /* List of categories */
                                        <FolderContainer container ref={this.ref} windowWidth={this.props.windowWidth}>
                                            {
                                                this.state.categories.map((e, i) => {
                                                    return (
                                                        <FolderCustom
                                                            style={{
                                                                display: 'flex',
                                                                height: '80px',
                                                                padding: '0px 25px'
                                                            }}
                                                            key={`cat-${e.id}`}
                                                            alignItems={'center'}
                                                            onClick={() => {
                                                                const folders = [...this.state.previousFolders]
                                                                folders.push(this.state.currentFolder);
                                                                this.setState({
                                                                    previousFolders: folders,
                                                                    currentFolder: e,
                                                                    categories: e.childrens
                                                                }, () => {
                                                                    this.filterMedias()
                                                                })
                                                            }}
                                                        >
                                                            <Grid item>
                                                                <FolderSharpIcon style={{ marginTop: '3px', fontSize: '28px' }} />
                                                            </Grid>
                                                            <Grid item
                                                                style={{
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    fontSize: '20px'
                                                                }}
                                                            >
                                                                {e.libelle} {e.childrens?.length > 0 ? `(${e.childrens?.length})` : ''}
                                                            </Grid>
                                                        </FolderCustom>                                                        
                                                    );
                                                })
                                            }
                                        </FolderContainer>
                                    ) : (
                                        /* Message when there are no categories */
                                        <Typography variant='body1' color='primary' style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '10px' }}>Pas de catégories</Typography>
                                    )
                                    : this.state.areCategoriesLoading ? (
                                        <PageLoader />
                                    ) : (
                                        /* Message when there are no categories */
                                        <Typography variant='body1' color='primary' style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '10px' }}>Pas de catégories</Typography>
                                    )
                            }
                        </Grid>
                    </Grid>
                    
                    {/* Listing */}
                    <Box style={{ padding: '0px 24px 24px 24px', position: 'relative' }}>
                        {
                            this.state.currentFolder !== null ? (
                                <Box style={{height: '100%'}}>
                                    {
                                        this.state.ready ?
                                            <Box style={{ paddingTop: '38px', paddingBottom: '18px', display: 'grid', gridTemplateRows: 'auto 1fr', height: '100%' }}>                                                            
                                                <Box><ChipMedias categories={this.cats} handleChangeTab={this.filterByType} defaultTab={this.props.history.location.state?.index ? this.props.history.location.state.index : null} /></Box>                                                                            
                                                    <Listing
                                                        label = 'medias'
                                                        settings = {listSettings}
                                                        perPageOptions = {perPageOptions}
                                                        mappers = {listMappers}                                
                                                        pagination={true}
                                                        identifier = 'mediaObjects'
                                                        queryVariables={{                                            
                                                            types: this.state.filterByType ?? LIST_MEDIAS,
                                                            categories: [this.state?.currentFolder?.id],
                                                            nbperpage: this.state.nbperpage,
                                                            id_list: this.state.filterByTags ?? [],
                                                            name: this.state.searchedName ?? null                                                              
                                                        }}                                              
                                                        cardProps={{                                            
                                                            windowWidth: this.props.windowWidth,    
                                                            handleEditMedia:()=>{console.log("edit")},                                    
                                                            handleDeleteModal:()=>{console.log("delete")}, 
                                                            handleDownload:()=>{console.log("dl")}                                        
                                                        }}                                
                                                        viewsOptions = {{
                                                            current : 'card',
                                                            settings : ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                                                        }}
                                                    />                                                                                        
                                            </Box>
                                        : this.state.currentFolder !== null ? <PageLoader /> : null
                                    }
                                </Box>
                            ) : null
                        }
                         {this.state.openMediaForm ?
                                        
                                        <LayoutFormBuilder
                                            isSublayout={false}
                                            icomoon="ico-importer-media"
                                            opened={this.state.openMediaForm}
                                            forClose={() => { this.handleToggleDrawer('addMediaForm') }}
                                            dataLayout={this.state.formAction === 'edit' ? mediasEditConfig(this.state.categoriesData, this.state.parent, this.state.filePath, this.state.mediaType) : mediasConfig(this.state.categoriesData, this.state.parent)}
                                            allState={this.state}
                                            stateCallback={this.handleInputChange}
                                            handlerMutation={this.state.formAction === 'edit' ? () => { this.handleAddMedia('edit') } : () => { this.handleAddMedia('add') }}
                                            validateButton={true}
                                            stepperButtonAction={[
                                                this.handleNextStep,
                                                this.handleNextStep,
                        
                                            ]}
                                            errorCallback={this.handleFormError}
                        
                        
                                        />
                        
                                        : null}
                                    {this.state.modalData
                        
                                        ?
                                        <MediaModal
                                            open={this.state.openModal}
                                            onClose={this.handleCloseModal}
                                            modalData={this.state.modalData}
                                            handleNav={this.handleNextMedia}
                                            lastMedia={this.state.medias?.length - 1}
                                            handleDeleteModal={this.handleDeleteModal}
                                        />
                        
                                        : null}
                        
                                    {this.state.importMediasCard ?
                                        <LayoutBuilder
                                            isSublayout={false}
                                            opened={this.state.openFormImport}
                                            forClose={() => { this.handleToggleDrawer('openFormImport') }}
                                            dataLayout={mediasImportConfig}
                                            dataCard={this.state.importMediasCard}
                                        />
                                        : null}
                                    {this.state.availableChannels ?
                                        <LayoutBuilder
                                            icomoon="ico-importer-media"
                                            isSublayout={true}
                                            opened={this.state.openFormImportStepper}
                                            forClose={() => this.handleToggleDrawer('openFormImportStepper')}
                                            dataLayout={mediasImportStepperConfig(this.state.availableChannels, this.state.categoriesData, this.state.parent, this.state.isZip)}
                                            allState={this.state}
                                            stateCallback={this.handleInputChange}
                                            stepperButtonAction={[
                                                this.handleNextStep,
                                                this.handleNextStep,
                                                this.handleNextStep,
                                                this.handleNextStep,
                                            ]}
                                            backStepperButtonAction={[
                                                () => this.setState({ mediasToImport: [] }),
                                                () => this.setState({ mediasToImport: [] }),
                                                null,
                                                null,
                                                null
                                            ]}
                                            errorCallback={this.handleFormError}
                                            handlerMutation={this.handleImportMedia}
                                        />
                                        : null}
                    
                        {/* <DialogModal
                    open            = {this.state.openDeleteModal}
                    secondaryAction = {()=>{this.deleteMedia('cancel')}}
                    primaryAction   = {()=>{this.deleteMedia('delete')}}
                    title           = 'Supprimer ce média' 
                    
                    <Typography variant="body2">Êtes-vous sûr de vouloir supprimer ce média ? <strong>Cette action est irréversible</strong></Typography>
                    alogModal> */}
                    
                        {
                            this.state.openImportModal ?
                                <Dialog
                                    onClose={() => { }}
                                    open={this.state.openImportModal}
                                    fullWidth={true}
                                    maxWidth='sm'
                                >
                                    <DialogTitle id="max-width-dialog-title">Import en cours</DialogTitle>
                                    <DialogContent>
                                        <Grid container justifyContent="center">
                    
                                            <Typography variant="h4" style={{ width: '100%', textAlign: 'center' }}>{this.state.mediasUploaded} / {this.state.mediasPending} medias uploadés</Typography>
                                            <LinearProgress variant="determinate" value={normalise(this.state.mediasUploaded)} style={{ width: '80%', height: 6, backgroundColor: colors.blue.darker.hue300, marginTop: 20 }} />
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions />
                    
                                </Dialog>
                                : null
                        }
                    </Box>
                </Box>
            </PageWrapper >
        );
    }

    copyArrayOfObjects = array => array.map(a => ({ ...a })); // be careful, only breaks references at objects level

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};
export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(MediasCategories))));
