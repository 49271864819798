import {useState} from 'react';

import styled from 'styled-components';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';

import HeadingCell from './cells/HeadingCell'
import CellText from './cells/CellText';
import CellImage from './cells/CellImage';
import CellLink from './cells/CellLink';
import CellAvatars from './cells/CellAvatars';
import CellProfilePicture from './cells/CellProfilePicture';
import colors from '../../../../../config/theme/colors';
import Button from '../../../../ui/button/Button';

    
const CustomRow = styled(TableRow)`
    background : ${props => props.styling.rowBackground ? props.styling.rowBackground : 'white'};
    ${props => props.styling.type == 'cards' ? 
        `
        border-radius : 10px;
        &:hover{
            box-shadow :0px 1px 9px -1px rgba(0,0,0,0.15);
        }
        `
    : null}
    ${props => props.styling.type == 'striped' ? 
        `   
            background : #f2f2f2;
            &:nth-of-type(odd){
                background :  white;
            }
        `
    : null}
`
const CustomTable = styled(Table)`
    th{
        background: ${colors.blue.darker.hue300};
    }
    ${props => props.styling.type == 'cards' ? `
        border-spacing: 0px 10px;
        border-collapse:separate;
    `:null}
`

const HeadRow = styled(TableRow)`
    background : ${props=> props.styling?.headingBackground ? props.styling.headingBackground: '#003e4d' };

    th{
        color : ${props=> props.styling?.headingColor ? props.styling.headingColor : 'white' };
    }
`
const ButtonCustom = styled(Button)`
    &.delete{
        background : ${colors.red.regular};
    }
`

const cells = {
    'text' : CellText,
    'image' : CellImage,
    'link'  : CellLink,
    'avatars' : CellAvatars,
    'profil_picture' : CellProfilePicture,
    'date' : CellText,
    'switch': CellText,
    'select': CellText,
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const Rows = (props) => {
    return (
        <CustomRow 
            key={`list-${capitalize(props.label)}-${props.index}`}
            styling = {props.styling}
        >
            {props.columns.map((column)=>{
                let Cell = cells[column.cellType];
                let value = props.item.node[column.field];
                let media = null;
                console.log('props', props.item)
                console.log('column.field', column.field)
                if (!value || value === undefined){
                    if (props.item.node.shellDatas.edges.find(e => e.node.attribute.identifier === column.field)?.node.attribute.attributeType.input === "image"){
                        media = props.item.node.shellDatas.edges.find(e => e.node.attribute.identifier === column.field)?.node.media
                    }
                    value = props.item.node.shellDatas.edges.find(e => e.node.attribute.identifier === column.field)?.node.value
                }
                return(
                    <Cell value={value} media={media} width={column.width} column={column} {...column.cellProps} attribute={props.item.node.shellDatas?.edges.find(e => e.node.attribute.identifier === column.field  && e.node.locale?.code === props.currentLang)?.node}/>
                )
            })}
            <TableCell align='center'>
                <Button text={props.tableProps.textButton} onClick={() => props.tableProps.handlerButton(props.item.node.id)}/> 
                {
                    props.tableProps.textButtonSecondary ?
                        <ButtonCustom className={props.tableProps.typeButtonSecondary} text={props.tableProps.textButtonSecondary} onClick={() => props.tableProps.handlerButtonSecondary(props.item.node.id)}/>
                    : null 
                }
            </TableCell>
        </CustomRow>
    )
}

export default function TableView(props){
    let {settings,items,label,currentLang} = props;
    const columns = settings?.columns;
    
    return(
        <div style={{height: 650, position:'relative', overflow:'auto'}}>
            <CustomTable aria-label="headerList" styling={settings.style} stickyHeader>
                {
                    columns && columns.length > 0 ? 
                        <TableHead>
                            <HeadRow styling={settings.style}>
                                {columns.map((column)=>(
                                    <HeadingCell {...column.cellProps} sortCallback = {props.sortCallback} width={column.cellProps?.width} label={column.label} filter={column.filter} sortBy={props.sortBy}/>
                                ))}
                                <HeadingCell label={"Actions"} width={props.tableProps.textButtonSecondary ? "auto" : "10%"} align={"center"}/>
                            </HeadRow>
                        </TableHead>
                    : null
                }
                <TableBody>
                    {
                        props.ready && items ? 
                            items.map((item,index)=>(
                                <Rows item={item} index={index} columns={columns} styling={props.settings.style} key={`row-${index}`} label={label} {...props} />
                            ))
                        : 
                        <tr>
                            <td colspan={columns?.length ? columns.length : 1}><PageLoader/></td>                 
                        </tr>
                    }
                </TableBody>
            </CustomTable>
        </div>
    )
}

