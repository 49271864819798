import {ADD_COMPANY_DATA,ADD_COMPANY,UPDATE_COMPANY,UPDATE_COMPANY_DATA,SEARCH_COMPANIES,GET_COMPANIES_PAGINATION} from '../../queries/companies';
import {UPDATE_CUSTOMER,GET_CUSTOMERS,SEARCH_CUSTOMERS, ADD_CUSTOMER, ADD_CUSTOMER_DATA, UPDATE_CUSTOMER_DATA} from '../../queries/customers';
import { ADD_SELECTION, GET_SELECTIONS_PAGINATION, UPDATE_SELECTION } from '../../queries/selections';
import { ADD_TOP_HEADER_MESSAGE, GET_TOP_HEADER_MESSAGE_PAGINATION, UPDATE_TOP_HEADER_MESSAGE } from '../../queries/assetMagentoTopHeader';
import { ADD_MAGENTO_PAGE, ADD_MAGENTO_PAGE_CONTENT, GET_MAGENTO_CONTENTS_PAGINATION, GET_MAGENTO_PAGES_PAGINATION, UPDATE_MAGENTO_PAGE, UPDATE_MAGENTO_PAGE_CONTENT } from '../../queries/assetMagentoPage';
import { GET_LOOKBOOKS_PAGINATION, CREATE_LOOKBOOK, UPDATE_LOOKBOOK } from '../../queries/look_book';
import { GET_CATALOGS_PRODUCTS_PAGINATION } from '../../queries/catalogs';
import { CREATE_RATE_GROUP, UPDATE_RATE_GROUP, GET_RATE_GROUPS_PAGINATION } from '../../queries/rate_group';
import { CREATE_INFO_MANAGEMENT, GET_INFO_MANAGEMENTS_PAGINATION, UPDATE_INFO_MANAGEMENT } from '../../queries/info_management';
import { GET_RETAILER_PRODUCT_DATAS } from '../../queries/retailers';
import { GET_PRODUCTS_SEARCH_PAGINATION } from '../../queries/products';
import { GET_PAGES_PAGINATION, ADD_PAGE, UPDATE_PAGE, ADD_PAGE_CONTENT, UPDATE_PAGE_CONTENT } from '../../queries/assetPagesPage';
import { GET_CONTENTS_PAGINATION } from '../../queries/contents';
import { CREATE_APPNOTIFICATION } from '../../queries/app_notification';
import { CREATE_USERGROUP, GET_USERGROUPS_PAGINATION, UPDATE_USERGROUP } from '../../queries/user_groups';
import { CREATE_ASSET_FOOTER, GET_ASSET_FOOTERS_PAGINATION, UPDATE_ASSET_FOOTER, UPDATE_ASSET_FOOTER_COLUMN, UPDATE_ASSET_FOOTER_COLUMN_LINK, CREATE_ASSET_FOOTER_COLUMN, CREATE_ASSET_FOOTER_COLUMN_LINK } from '../../queries/asset_footer';
import { CREATE_MERCHANDISING, GET_MERCHANDISINGS_PAGINATION_INACTIVE, UPDATE_MERCHANDISING } from '../../queries/merchandising';
import { CREATE_CROSS_SELL, GET_CROSS_SELLS, UPDATE_CROSS_SELL } from '../../queries/crossSell';
import { GET_SHELL, GET_SHELL_BY_EAVTYPE, GET_SHELL_BY_EAVTYPE_SEARCH } from '../../queries/shells';
import { ADD_ASSET_TOP_MESSAGE, GET_ASSET_TOP_MESSAGES_PAGINATION, UPDATE_ASSET_TOP_MESSAGE } from '../../queries/asset_top_message';
import { ADD_ASSET_CART_RULE, ADD_ASSET_CART_RULE_COUPON, GET_ASSET_CART_RULES_PAGINATION, UPDATE_ASSET_CART_RULE, UPDATE_ASSET_CART_RULE_COUPON } from '../../queries/asset_cart_rule';
import { GET_BRANDS_PAGINATION ,ADD_BRAND, ADD_BRAND_SOURCE_SCRAP, UPDATE_BRAND_SOURCE_SCRAP, ADD_BRAND_SOURCE_MAPPING, UPDATE_BRAND} from '../../queries/brands';
import { GET_MEDIAS_IMAGES } from '../../queries/medias';

export const addQueries = {
    'company' : ADD_COMPANY,
    'customer' : ADD_CUSTOMER,
    'selection': ADD_SELECTION,
    'assetMagentoTopHeader': ADD_TOP_HEADER_MESSAGE,
    'assetMagentoPage': ADD_MAGENTO_PAGE,
    'assetPagesPage': ADD_PAGE,
    'assetMagentoPageContent': ADD_MAGENTO_PAGE_CONTENT,
    'assetPagesPageContent': ADD_PAGE_CONTENT,
    'lookBook': CREATE_LOOKBOOK,
    'rateGroup': CREATE_RATE_GROUP,
    'infoManagement': CREATE_INFO_MANAGEMENT,
    'appNotification': CREATE_APPNOTIFICATION,
    "userGroup": CREATE_USERGROUP,
    'merchandising': CREATE_MERCHANDISING,
    'assetFooter': CREATE_ASSET_FOOTER,
    'assetFooterColumn': CREATE_ASSET_FOOTER_COLUMN,
    'assetFooterColumnLink': CREATE_ASSET_FOOTER_COLUMN_LINK,
    'crossSell': CREATE_CROSS_SELL,
    'assetTopMessage': ADD_ASSET_TOP_MESSAGE,
    'assetCartRule': ADD_ASSET_CART_RULE,
    'assetCartRuleCoupon': ADD_ASSET_CART_RULE_COUPON,
    'productSource':ADD_BRAND,
    'productSourceScrap':ADD_BRAND_SOURCE_SCRAP,
    'productSourceMapping':ADD_BRAND_SOURCE_MAPPING
}

export const updateQueries = {
    'company' : UPDATE_COMPANY,
    'customer': UPDATE_CUSTOMER,
    'selection': UPDATE_SELECTION,
    'assetMagentoTopHeader': UPDATE_TOP_HEADER_MESSAGE,
    'assetMagentoPage': UPDATE_MAGENTO_PAGE,
    'assetPagesPage': UPDATE_PAGE,
    'assetMagentoPageContent': UPDATE_MAGENTO_PAGE_CONTENT,
    'assetPagesPageContent': UPDATE_PAGE_CONTENT,
    'rateGroup': UPDATE_RATE_GROUP,
    'infoManagement': UPDATE_INFO_MANAGEMENT,
    'lookBook': UPDATE_LOOKBOOK,
    'userGroup': UPDATE_USERGROUP,
    'merchandising': UPDATE_MERCHANDISING,
    'assetFooter': UPDATE_ASSET_FOOTER,
    'assetFooterColumn': UPDATE_ASSET_FOOTER_COLUMN,
    'assetFooterColumnLink': UPDATE_ASSET_FOOTER_COLUMN_LINK,
    'crossSell': UPDATE_CROSS_SELL,
    'assetTopMessage': UPDATE_ASSET_TOP_MESSAGE,
    'assetCartRule': UPDATE_ASSET_CART_RULE,
    'assetCartRuleCoupon': UPDATE_ASSET_CART_RULE_COUPON,
    'productSource':UPDATE_BRAND,
    // 'updateBrandSourceScrap':UPDATE_BRAND_SOURCE_SCRAP,
}

export const updateAttributesQueries = {
    'company' : UPDATE_COMPANY_DATA,
    'customer' : UPDATE_CUSTOMER_DATA
}

export const addAttributesQueries= {
    'company' : ADD_COMPANY_DATA,
    'customer':ADD_CUSTOMER_DATA
}

export const getQueries = {
    'customer' : GET_CUSTOMERS,
    'customers' : GET_CUSTOMERS,
    'companies' : GET_COMPANIES_PAGINATION,
    'selections': GET_SELECTIONS_PAGINATION,
    'assetMagentoTopHeaders': GET_TOP_HEADER_MESSAGE_PAGINATION,
    'assetMagentoPages': GET_MAGENTO_PAGES_PAGINATION,
    'assetPagesPages': GET_PAGES_PAGINATION,
    'assetMagentoPageContents': GET_MAGENTO_CONTENTS_PAGINATION,
    'assetPagesPageContents': GET_CONTENTS_PAGINATION,
    'lookBooks': GET_LOOKBOOKS_PAGINATION,
    'researchProducts': GET_PRODUCTS_SEARCH_PAGINATION,
    'rateGroups': GET_RATE_GROUPS_PAGINATION,
    'infoManagements': GET_INFO_MANAGEMENTS_PAGINATION,
    'retailerProductDatas': GET_RETAILER_PRODUCT_DATAS,
    'userGroups': GET_USERGROUPS_PAGINATION,
    'assetFooters': GET_ASSET_FOOTERS_PAGINATION,
    'merchandisings': GET_MERCHANDISINGS_PAGINATION_INACTIVE,
    'crossSells': GET_CROSS_SELLS,
    'shells': GET_SHELL_BY_EAVTYPE,
    'customSearchableShells': GET_SHELL_BY_EAVTYPE_SEARCH,
    'assetTopMessages': GET_ASSET_TOP_MESSAGES_PAGINATION,
    'assetCartRules': GET_ASSET_CART_RULES_PAGINATION,
    'productSources': GET_BRANDS_PAGINATION,
    'contents': GET_CONTENTS_PAGINATION,
    'mediaObjects': GET_MEDIAS_IMAGES
}

export const searchQueries = {
    'customer' : SEARCH_CUSTOMERS,
    'company'  : SEARCH_COMPANIES,
}