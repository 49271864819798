import React from "react";
import { ItemTypes } from "../../../../shareable/types";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import SubtitlesIcon from "@material-ui/icons/Subtitles";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import AppsIcon from "@material-ui/icons/Apps";
import ImageIcon from "@material-ui/icons/Image";
import SearchIcon from "@material-ui/icons/Search";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import LinkIcon from "@material-ui/icons/Link";
import CategoryIcon from "@material-ui/icons/Category";
import FormatIndentIncreaseIcon from "@material-ui/icons/FormatIndentIncrease";
import colors from "../../../../../config/theme/colors";

export function getTypeIcon(type, fontSize){
    switch (type) {
      case ItemTypes.SLIDER:
        return (
          <ViewCarouselIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.SLIDER_MULTIPLE:
        return (
          <ViewCarouselIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.SLIDER_WITH_RIGHT_IMAGES:
        return (
          <ViewCarouselIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.TAB_PRODUCT:
        return (
          <AppsIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.IMAGE_ROW:
        return (
          <ViewColumnIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.SECTION_TITLE:
        return (
          <SubtitlesIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.THREE_IMAGES_GRID:
        return (
          <ViewCompactIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.THREE_IMAGES_ROW:
        return (
          <ViewColumnIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.BLOC_TEXT:
        return (
          <SubtitlesIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.IMAGE:
        return (
          <ImageIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.TEXT:
        return (
          <TextFieldsIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.GIFT_FINDER:
        return (
          <SearchIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.INFORMATION_BANNER:
        return (
          <SubtitlesIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.LINK_BANNER:
        return (
          <LinkIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.CATEGORY:
        return (
          <CategoryIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.TEXT_IMAGE:
        return (
          <FormatIndentIncreaseIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.TEXT_VIDEO:
        return (
          <FormatIndentIncreaseIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      case ItemTypes.SLIDER_PRODUCT:
        return (
          <ViewCarouselIcon
            style={{
              fontSize,
              color: colors.white,
              background: colors.blue.lighter.hue600,
              borderRadius: "50%",
              padding: 6,
              marginLeft: 10,
            }}
          />
        );
      default:
        return null;
    }
  };