import React, { useEffect }  from 'react';

import { Grid, InputLabel } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import InputBuilder from '../../../ui/form/InputBuilder';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import DialogModal from '../../../ui/dialog/DialogModal';

import { withApollo } from 'react-apollo';
import { DELETE_CURRENCY } from '../../../../queries/currencies';
import { DELETE_TAXRATE } from '../../../../queries/tax_rates';
import {withTranslation} from 'react-i18next'
import DeleteDialogModal from '../../../ui/dialog/DeleteDialogModal';


const InputLabelCustom = styled(InputLabel)`
    color: ${colors.black.regular};
    margin-top: ${props => props.margintop || 0};
    margin-bottom: 24px;
`;
const GridFlexCenter = styled(Grid)`
    display: flex;
    align-items: center;
`;

function CardCurrencies(props){
    const currency = props.currency;
    const [open, setOpen] = React.useState(false);
    const [libelle, setLibelle] = React.useState(currency.libelle);
    const [code, setCode] = React.useState(currency.code);
    const [rate, setRate] = React.useState(currency.rate);
    const [countryCode, setCountryCode] = React.useState(currency.countryCode);
    const [rateGroup, setRateGroup] = React.useState(currency.rateGroup?.id);

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const deleteCurrency = (id) => {
        let deleteQuery = props.typeTax ? DELETE_TAXRATE : DELETE_CURRENCY;
        props.client.query({
            query: deleteQuery,
            fetchPolicy: 'no-cache',
            variables: {id: id}
        }).then(result =>{
            props.handleRemove();
            handleClose();
        });
    }
    
    useEffect(() => {
        if(currency.code !== code || currency.libelle !== libelle || currency.countryCode !== countryCode || currency.rate !== rate || currency.rateGroup?.id !== rateGroup){
            props.handleInputChange({
                id: currency.id,
                data: {
                    libelle,
                    code,
                    countryCode,
                    rate,
                    rateGroup
                }
            })
        }
    }, [code, libelle, countryCode, rate, rateGroup]);

    const selectCurrencies = {
        type: 'text',
        label: 'Nom de la devise',
        helper: 'Nom de la devise',
        required: true,
        stateName: 'libelle',
        disabled: props.disabled,
        value: ''
    };
    const codeCurrencie = {
        type: 'text',
        label: props.typeTax ? props.t('settings.tax.code') : props.t('settings.currencies.code'),
        helper: props.typeTax ? props.t('settings.tax.code') : props.t('settings.currencies.code'),
        required: true,
        stateName: 'code',
        disabled: props.disabled,
        value: ''
    };

    const taxRate = {
        type: 'decimal',
        label: props.t('settings.tax.taxrate'),
        helper: 'Taux',
        required: true,
        stateName: 'rate',
        disabled: props.disabled,
        value: ''
    };

    const typeCountryCode = {
        type: 'text',
        label: props.t('settings.tax.codepays'),
        helper: 'Code Pays',
        required: true,
        stateName: 'countryCode',
        disabled: props.disabled,
        value: ''
    }
    const typeGroupTax = {
        type: 'select',
        label: props.t('settings.currencies.title'),
        helper: 'Groupe de taxes',
        required: true,
        stateName: 'rateGroup',
        disabled: props.disabled,
        value: props.allGroupRates?.map(group => {
            return(
                {
                    value : group.node.id,
                    label : group.node.code,
                    // disabled : node.node.id === attributeType,
                }
            )
        })
    }
    return(
        <Grid container direction="row" spacing={2}>
            {
                props.typeTax ? null
                :
                <Grid item lg={4} sm={6} xs={12}>
                    <GridFlexCenter container direction="column" justifyContent="center" spacing={0}>
                        <Grid container direction="row" spacing={1}>
                            <GridFlexCenter item xs={2}>
                                <InputLabelCustom>{props.t('settings.langues.choice')}</InputLabelCustom>
                            </GridFlexCenter>
                            <InputBuilder xs={9} value={libelle} input={selectCurrencies} stateCallback={(evt) => {
                                setLibelle(evt.target.value);
                            }} /> 
                        </Grid>
                    </GridFlexCenter>
                </Grid>
            }
                <Grid item lg={4} sm={6} xs={12}>
                    <GridFlexCenter container direction="column" justifyContent="center" spacing={0}>
                        <Grid container direction="row" spacing={1}>
                            <GridFlexCenter item xs={3}>
                                <InputLabelCustom>{codeCurrencie.label}</InputLabelCustom>
                            </GridFlexCenter>
                            <InputBuilder xs={9} value={code} input={codeCurrencie} stateCallback={(evt) => {
                                setCode(evt.target.value);
                            }} /> 
                        </Grid>
                    </GridFlexCenter>
                </Grid>
            {
                props.typeTax ? 
                <>
                    <Grid item lg={4} sm={6} xs={12}>
                        <GridFlexCenter container direction="column" justifyContent="center" spacing={0}>
                            <Grid container direction="row" spacing={1}>
                                <GridFlexCenter item xs={3}>
                                    <InputLabelCustom>{taxRate.label}</InputLabelCustom>
                                </GridFlexCenter>
                                <Grid item xs={9}>
                                    <InputBuilder value={rate} input={taxRate} stateCallback={(evt) => {
                                        setRate(evt.target.value);
                                    }} /> 
                                </Grid>
                            </Grid>
                        </GridFlexCenter>
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                        <GridFlexCenter container direction="column" justifyContent="center" spacing={0}>
                            <Grid container direction="row" spacing={1}>
                                <GridFlexCenter item xs={3}>
                                    <InputLabelCustom>{typeCountryCode.label}</InputLabelCustom>
                                </GridFlexCenter>
                                <Grid item xs={9}>
                                    <InputBuilder value={countryCode} input={typeCountryCode} stateCallback={(evt) => {
                                        setCountryCode(evt.target.value);
                                    }} /> 
                                </Grid>
                            </Grid>
                        </GridFlexCenter>
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                        <GridFlexCenter container direction="column" justifyContent="center" spacing={0}>
                            <Grid container direction="row" spacing={1}>
                                <GridFlexCenter item xs={3}>
                                    <InputLabelCustom>{typeGroupTax.label}</InputLabelCustom>
                                </GridFlexCenter>
                                <Grid item xs={9}>
                                    <InputBuilder value={rateGroup} input={typeGroupTax} stateCallback={(evt) => {
                                        setRateGroup(evt.target.value);
                                    }} /> 
                                </Grid>
                            </Grid>
                        </GridFlexCenter>
                    </Grid>
                </>
                : null
            }
            {
                props.handleRemove !== null ?
                    <Grid item lg={props.typeTax ? 0 : 4} xs={12} style={{justifyContent: 'flex-end', display: 'flex', alignItems: 'flex-start'}}>
                        <Button style={{margin: props.windowWidth < 1400 ? 0 : null}} color={colors.red.regular} colorhover={colors.red.darker} bgcolor="transparent" bgcolorhover="transparent" onClick={handleClickOpen}><DeleteSharpIcon/> {props.typeTax ? props.t('settings.tax.delete') : props.t('settings.currencies.delete')}
                        </Button>                    
                    </Grid>
                : null
            }

            <DeleteDialogModal
                open={open}
                title={'Êtes-vous sûr de vouloir supprimer cette devise ?'}
                primaryAction={props.type === 'existing' ? () => {deleteCurrency(currency.id)} : () => {props.handleRemove(); handleClose()}}
                secondaryAction={handleClose}
                windowWidth={props.windowWidth}
            >
                Si vous supprimez cette devise celle-ci ne sera plus accessible. Si vous ne l'utilisez plus mais que vous ne souhaitez pas la supprimer, annulez la suppression et déclarer cette devise comme inactive.
            </DeleteDialogModal>
        </Grid>
    );
}


export default withTranslation()(withApollo(CardCurrencies));