import React from "react";
import { Box, Grid, Typography, Tooltip } from "@material-ui/core";
import Button from "../../../ui/button/Button";
import StatusInfo from "../../../ui/status-info/StatusInfo";
import colors from "../../../../config/theme/colors";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import ImgNotFound from "../../../../assets/images/image-fake-card.png";
import { Link } from "react-router-dom";
import ModeEditIcon from "@material-ui/icons/Edit";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FolderIcon from '@material-ui/icons/Folder';
import moment from "moment";

const WrapperAttr = styled(Grid)`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  > p:first-child {
    min-width: 200px;
  }
  p {
    font-size: 13px;
    text-align: left;
    color: ${colors.black.regular};
  }
`;

const ContainerImage = styled(Box)`
  overflow: hidden;
  background: url(${(props) => props.backgroundimage});
  background-size: cover;
  background-position: center;
`;

const Libelle = styled(Typography)`
  font-size: 25px;
  line-height: 1.5;
  font-weight: bold;
  max-height: 40px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;


export default function CardPages(props) {
  return(
    <Box width={"100%"}>
      <Box padding={"16px 16px 10px 16px"}>
        {!props.isContent && (
          <ContainerImage height={210} backgroundimage={ImgNotFound} />
        )}
        <Grid container style={{ position: "relative", height: "100%" }}>
          <Grid container direction="column">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Libelle variant="h4" colortext={colors.black.regular}>
                  {props.data.node.libelle}
                </Libelle>  
              </Grid>
              {props.isContent && (
                <Grid item>
                  <StatusInfo isMini="true" />
                </Grid>
              )}
            </Grid>
            {!props.isContent && (
              <Typography variant="body2">
                {props.data.node.magentoPageRealId
                  ? `Magento page id ${props.data.node.magentoPageRealId}`
                  : "Aucun id disponible"}
              </Typography>
            )}

            {props.isContent && (

              <Box pb={2} pt={2} mt={2}>
                <WrapperAttr container>
                  <Typography>Date de début :</Typography>
                  <Typography>
                    {props.data.node.startDate ? moment(props.data.node.startDate).format('DD/MM/YYYY') : "N/A"}
                  </Typography>
                </WrapperAttr>

                <WrapperAttr container>
                  <Typography>Date de fin :</Typography>
                  <Typography>
                    {props.data.node.endDate ? moment(props.data.node.endDate).format('DD/MM/YYYY') : "N/A"}
                  </Typography>
                </WrapperAttr>
              </Box>
              
            )}

            {/* Condition s'affichant sur la liste des contenus */}
            {!props.isContent && (
              <Box
                pb={2}
                pt={2}
                mt={2}
                style={{
                  borderTop: `0.5px solid ${colors.grey.lighter.hue700}`,
                  width: "100%",
                }}
              >
                <WrapperAttr container>
                  <Typography>Statut :</Typography>
                  <Box>
                    <StatusInfo
                      status={props.data.node.isActive}
                      width={"auto"}
                    />
                  </Box>
                </WrapperAttr>
                <WrapperAttr container>
                  <Typography>Nombre de contenu :</Typography>
                  <Typography>
                    {props.data.node.pageContents?.totalCount}
                  </Typography>
                </WrapperAttr>
                {props.handlerButtonThird ? (
                  <WrapperAttr container>
                    <Typography>Cache landing :</Typography>

                    <Link
                      onClick={() => props.handlerButtonThird(props.data.node)}
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "unset",
                        color: colors.blue.darker.hue300,
                      }}
                      stopPropagation={true}
                    >
                      {props.textButtonThird}
                    </Link>
                  </WrapperAttr>
                ) : null}
              </Box>
            )}
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Grid container>
                {!props.data.node.isDefaultContent || props.isContent ? (
                  <Box width="calc(70% - 12px)">
                    <Button
                      onClick={() =>
                        props.handlerButtonSecondary(props.data.node)
                      }
                      icon={
                        props.isContent && 'exitApp'
                      }
                      arrow={props.isContent ? true : false}
                      text={props.textButtonSecondary}
                      style={{ width: "100%" }}
                    />
                  </Box>
                ) : null}

                {
                  props.isContent && (
                    <Box ml={1.5} width="30%">
                    <Button
                      onClick={() =>
                        props.handlerButtonThird(props.data.node)
                      }
                      icon={
                        <FolderIcon style={{ fontSize: 12, marginRight: 7 }} />
                      }
                      text={props.textButtonThird}
                      style={{ width: "100%" }}
                    />
                  </Box>
                  )
                }

                { !props.isContent && (
                  <Box ml={1.5} width={"30%"}>
                  <Button
                    onClick={() => props.handlerButton(props.data.node)}
                    text={props.textButton}
                    icon={
                      <ModeEditIcon style={{ fontSize: 12, marginRight: 2 }} />
                    }
                    style={{
                      width: "100%",
                      backgroundColor: colors.blue.lighter.hue900,
                      border: `1px solid ${colors.blue.darker.hue300}`,
                      color: colors.blue.darker.hue300,
                      paddingBottom: 12,
                    }}
                  />
                </Box>
                )}
                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) ;
}
