import React from 'react';


export const htmlBuildLooseEmail = (datas, spread) => {

    const STYLE = `
        <style type="text/css">
            tr, td, table, tbody{
                border-collapse: collapse;
                border-spacing: 0;
                padding: 0;
            }
            h1{
                color: #2B4553;
                line-height: 36px;
                font-size: 28px;
            }
            .name_product{
                font-family:Arial, Helvetica, sans-serif;
                font-size: 10px;
                line-height: 12px;
                text-align: center;
                margin-top: 20px;
            }
            #footer a{
                color: white;
            }
            @media screen and (max-width: 520px){
                #containerBottom td{
                    font-size: 25px !important;
                    line-height: 32px !important;
                }
                #containerBottom td a{
                    width: 213px !important;
                    font-size: 21px !important;
                }
            }
            @media screen and (max-width: 375px){
                .message_felicitation{
                    font-size: 18px !important;
                    line-height: 22px !important;
                }
                #containerBottom td{
                    font-size: 22px !important;
                    line-height: 31px !important;
                }
                #containerBottom td a{
                    width: 213px !important;
                    font-size: 21px !important;
                }
                #footer td{
                    font-size: 14px !important;
                    line-height: 20px !important;
                }
            }
        </style>
    `
    const START = `
        <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
            <html lang="fr" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
                <!--[if gte mso 9]><xml>
                <o:OfficeDocumentSettings>
                <o:AllowPNG/>
                <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
                </xml><![endif]-->
                <head>
                    <meta charset="utf-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1">
                    ${STYLE}
                </head>
        <body>
    `;

    const END = `
            </body>
        </html>
    `;

    return `
        ${!spread ? START : ''}
        ${spread ? STYLE : ''}
        <center>
            <table width="600" style="border-collapse: collapse;width: 600px" cellpadding="0" cellspacing="0">
                <tr>
                    <td 
                        background="${typeof datas?.inputs.backgroundImage.value === 'string' ? `url(${datas?.inputs.backgroundImage.value})` : datas?.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${datas?.inputs.backgroundImage.value.filePath})` : 'none'}" 
                        bgcolor="${datas?.inputs?.backgroundColor.value}" 
                        valign="top" 
                        style="background-color: ${datas?.inputs.backgroundColor.value}; background-image: ${typeof datas?.inputs.backgroundImage.value === 'string' ? `url(${datas?.inputs.backgroundImage.value})` : datas?.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${datas?.inputs.backgroundImage.value.filePath})` : 'none'};background-size:cover;"
                    >
                        <table id="container1" width="600">
                            <tr>
                                <td width="8%"></td>
                                <td>
                                    <table width="600">
                                        <tr width="600">
                                            <td width="600" style="text-align: center;">
                                                ${datas.blocks.logoLink?.inputs.value.value ? (`<a href="${datas.blocks.logoLink?.inputs.value.value}" target="_blank" rel="noopener noreferrer"><img src=${typeof datas.blocks?.logo.inputs.value.value === 'string' ? datas.blocks?.logo.inputs.value.value : datas.blocks?.logo.inputs.value?.value?.filePath ? `${process.env.REACT_APP_MEDIAS}/${datas.blocks?.logo.inputs.value.value.filePath}` : '/img/not-found.png'} style="width: 600px;" /></a>`) : (`<img src=${typeof datas.blocks?.logo.inputs.value.value === 'string' ? datas.blocks?.logo.inputs.value.value : datas.blocks?.logo.inputs.value?.value?.filePath ? `${process.env.REACT_APP_MEDIAS}/${datas.blocks?.logo.inputs.value.value.filePath}` : '/img/not-found.png'} style="width: 600px;" />`)}
                                            </td>
                                        </tr>
                                        <tr width="600">
                                            <td width="600" style="font-family: Arial, Helvetica, sans-serif; text-align: ${datas.blocks?.title.inputs.textAlign?.value}; color: ${datas.blocks?.title.inputs.color?.value}; background-color: ${datas.blocks?.title.inputs.backgroundColor?.value}; font-size: ${datas.blocks?.title.inputs.size?.value}px; line-height: 1.2; text-transform: ${datas.blocks?.title.inputs.textTransform?.value}; font-weight: bold; padding-top: 40px; padding-left: 50px; padding-right: 50px;">
                                                ${datas?.blocks.title.inputs.value?.value}
                                            </td>
                                        </tr>
                                        ${
                                            datas?.blocks.subtitle.inputs.value?.value ? (
                                                `<tr width="600">
                                                    <td 
                                                        width="600" 
                                                        class="message_felicitation" 
                                                        style="font-family: Arial, Helvetica, sans-serif; text-align: ${datas.blocks?.subtitle.inputs.textAlign?.value}; color: ${datas.blocks?.subtitle.inputs.color?.value}; background-color: ${datas.blocks?.subtitle.inputs.backgroundColor?.value}; font-size: ${datas.blocks?.subtitle.inputs.size?.value}px; line-height: 1.2; text-transform: ${datas.blocks?.subtitle.inputs.textTransform?.value}; font-weight: bold; padding-top: 45px; padding-left: 50px; padding-right: 50px;"
                                                    >
                                                        ${datas?.blocks.subtitle.inputs.value?.value}
                                                    </td>
                                                </tr>`
                                            ) : ''                                       
                                        }
                                        <tr width="600">
                                            <td 
                                                class="message_felicitation" 
                                                width="600" 
                                                style="font-family: Arial, Helvetica, sans-serif; color: ${datas.blocks?.description.inputs.color.value}; text-align: ${datas.blocks?.description.inputs.textAlign.value}; text-transform: ${datas.blocks?.description.inputs.textTransform.value}; font-size: ${datas.blocks?.description.inputs.size.value}px; line-height: 1.2; padding-top: 16px; padding-left: 50px; padding-right: 50px;"
                                            >
                                                ${datas?.blocks.description.inputs.value?.value}
                                            </td>
                                        </tr>
                                        <tr width="600">
                                            <td width="600" style="font-family: Arial, Helvetica, sans-serif; color: ${datas.blocks?.titleList.inputs.color.value}; text-align: ${datas.blocks?.titleList.inputs.textAlign.value}; text-transform: ${datas.blocks?.titleList.inputs.textTransform.value}; font-size: ${datas.blocks?.titleList.inputs.size.value}px; line-height: 1.2; font-weight: bold; padding-top: 45px; padding-left: 50px; padding-right: 50px;">
                                                ${datas?.blocks.titleList.inputs.value?.value}
                                            </td>
                                        </tr>
                                        <tr width="600">
                                            <td style="padding-top: 16px">
                                                <table width="600">
                                                    <tr>
                                                        <td width="8%">
                                                        </td>
                                                        <td width="84%">
                                                            <table width="100%">
                                                                <tr height="130">
                                                                    <td width="130" style="border: 1px solid #ADBBBA; width: 130px;margin-right: 16px;background-color: white;">
                                                                        <a href="%p1_link%">
                                                                            <table width="100%" height="100%">
                                                                                <tr>
                                                                                    <td style="text-align: center;">
                                                                                        <img src="%p1_image%" width="130" style="display: block; margin: 0 auto; max-height: 80px; max-width: 100px; width: auto;">
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="name_product" style="padding-bottom: 16px;padding-top: 16px;color:black;text-decoration: none;">
                                                                                        %p1_name% - %p1_ref%
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style="color: #000000; text-align: center; padding: 12px; background-color: #E9863E;">Voir la fiche produit</td>
                                                                                </tr>
                                                                            </table>
                                                                        </a>
                                                                    </td>
                                                                    <td width="10"></td>
                                                                    <td width="130" style="border: 1px solid #ADBBBA; width: 130px;margin-left: 16px;background-color: white;">
                                                                        <a href="%p2_link%">
                                                                            <table width="100%" height="100%">
                                                                                <tr>
                                                                                    <td style="text-align: center;">
                                                                                        <img src="%p2_image%" width="130" style="display: block; margin: 0 auto; max-height: 80px; max-width: 100px; width: auto;">
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="name_product" style="padding-bottom: 16px;padding-top: 16px;color:black;text-decoration: none;">
                                                                                        %p2_name% - %p2_ref%
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style="color: #000000; text-align: center; padding: 12px; background-color: #E9863E;">Voir la fiche produit</td>
                                                                                </tr>
                                                                            </table>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                        <td width="8%">
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td height="20"></td>
                                                        <td height="20"></td>
                                                        <td height="20"></td>
                                                    </tr>
                                                    <tr>
                                                        <td width="8%" style="padding-top: 12px"></td>
                                                        <td width="84%">
                                                            <table width="100%">
                                                                <tr height="130">
                                                                    <td width="130" style="border: 1px solid #ADBBBA; width: 130px;margin-right: 16px;background-color: white;">
                                                                        <a href="%p3_link%">
                                                                            <table width="100%" height="100%">
                                                                                <tr>
                                                                                    <td style="text-align: center;">
                                                                                        <img src="%p3_image%" width="130" style="display: block; margin: 0 auto; max-height: 80px; max-width: 100px; width: auto;">
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="name_product" style="padding-bottom: 16px;padding-top: 16px;color:black;text-decoration: none;">
                                                                                        %p3_name% - %p3_ref%
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style="color: #000000; text-align: center; padding: 12px; background-color: #E9863E;">Voir la fiche produit</td>
                                                                                </tr>
                                                                            </table>
                                                                        </a>
                                                                    </td>
                                                                    <td width="10"></td>
                                                                    <td width="130" style="border: 1px solid #ADBBBA; width: 130px;margin-left: 16px;background-color: white;">
                                                                        <a href="%p4_link%">
                                                                            <table width="100%" height="100%">
                                                                                <tr>
                                                                                    <td style="text-align: center;">
                                                                                        <img src="%p4_image%" width="130" style="display: block; margin: 0 auto; max-height: 80px; max-width: 100px; width: auto;">
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="name_product" style="padding-bottom: 16px;padding-top: 16px;color:black;text-decoration: none;">
                                                                                        %p4_name% - %p4_ref%
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style="color: #000000; text-align: center; padding: 12px; background-color: #E9863E;">Voir la fiche produit</td>
                                                                                </tr>
                                                                            </table>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                        <td width="8%">
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td height="20"></td>
                                                        <td height="20"></td>
                                                        <td height="20"></td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td>
                                                <tr width="600">
                                                    <td width="600" style="text-align: center;">
                                                        ${datas.blocks.imagePreFooterLink?.inputs.value.value ? (`<a href="${datas.blocks.imagePreFooterLink.inputs.value.value}"><img src=${typeof datas.blocks?.imagePreFooter.inputs.value.value === 'string' ? datas.blocks?.imagePreFooter.inputs.value.value : datas.blocks?.imagePreFooter.inputs.value?.value?.filePath ? `${process.env.REACT_APP_MEDIAS}/${datas.blocks?.imagePreFooter.inputs.value.value.filePath}` : '/img/not-found.png'} style="width: 600px;" /></a>`) : (`<img src=${typeof datas.blocks?.imagePreFooter.inputs.value.value === 'string' ? datas.blocks?.imagePreFooter.inputs.value.value : datas.blocks?.imagePreFooter.inputs.value?.value?.filePath ? `${process.env.REACT_APP_MEDIAS}/${datas.blocks?.imagePreFooter.inputs.value.value.filePath}` : '/img/not-found.png'} style="width: 600px;" />`)}
                                                    </td>
                                                </tr>
                                            </td>
                                        </tr>
                                        <tr width="600">
                                            <td width="600">
                                                <table 
                                                id="footer" 
                                                width="600"
                                                bgcolor="#333333"
                                                style="
                                                    background-color: ${datas.blocks?.footer.inputs.backgroundColor.value};
                                                    background-image: ${typeof datas.blocks?.footer.inputs.backgroundImage.value === 'string' ? `url(${datas.blocks?.footer.inputs.backgroundImage.value})` : datas.blocks?.footer.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${datas.blocks?.footer.inputs.backgroundImage.value.filePath})` : 'none'};
                                                    background-size: cover;
                                                ">
                                                    <tr width="600">
                                                        <td width="600" style="text-align: justify;font-family:Arial, Helvetica, sans-serif; color: white; font-size: 16px; line-height: 23px;font-weight: 600;padding: 32px 20px;">
                                                        ${datas.blocks?.footerText.inputs.value.value}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td width="8%"></td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </center>
        ${!spread ? END : ''}
    `;
};

function FlipbookLooseEmail({ data }) {
    return (
        <div dangerouslySetInnerHTML={{ __html: htmlBuildLooseEmail(data, true) }} />
    )
};

export default FlipbookLooseEmail;