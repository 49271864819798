import {  Box, Grid, Modal } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PageLoader from "../../ui/loadings/page-loader/PageLoader";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import { SNACK } from '../../../js/constants/action-types';
import { connect } from "react-redux";
import { ALERT_SUCCESS } from "../../../js/constants/alert-types";
import IcomoonReact from 'icomoon-react';
import iconSet from "../../../assets/selection.json";
import colors from "../../../config/theme/colors";

const CarouselContainer = styled(Grid)`
    
    > * {
      outline: none !important;
    } 
    .slick-slider{
        width:100%;
        // margin: 0 -10px;
    }    
    .slick-slide {        
        outline: none;
        // margin: 0 10px;
    }
    .slick-track{
      margin-left:0;
      
    }
`
const ContainerSliderModal = styled.div`
  max-width: 30%;
  *:focus {
    outline: none !important;
  }
`

const Thumbnail = styled.img`
    width:400px;
    height:400px;
    cursor:pointer;
    object-fit:cover;
    border:0.2px solid rgba(0, 0, 0, 0.05);
    &:focus-visible {
      outline: none !important;
    }
`

const ThumbnailZoom = styled.img`
    width:600px;
    height:600px;
    cursor:pointer;
    object-fit:cover;
`

const CustomDiv = styled.div`
    height:100%;
    overflow:hidden;
    &> div >div{
      background-size:contain !important;
    }
`

const CopyIcon = styled(Box)`
  padding: 5px;
  border: ${props => `1px solid ${colors.grey.border}`};
  display: inline-block;
  background-color: ${props => colors.white};
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 30px;
  display: none;
`
const ImageWrapper = styled(Box)`
  position: relative;
  &:focus-visible {
    outline: none !important;
  }
  &:hover {
    ${CopyIcon} {
      display: block;
    }
  }
`

const useStyles = makeStyles((theme) => ({
  arrow: {
    display:'block',    
    zIndex:1,
    fill:'black',
    backgroundColor:'white',
    border:"0.5px solid black",
    borderRadius:"50%",
    width:'30px',
    height:'30px',
    opacity:'1', 
    "&:hover":{
      opacity:'1 !important',
      fill:'black',
      backgroundColor:'white',
    }   
  },
  right:{
    right:'-20px'
  },
  left:{
    left:'-20px'
  }
}));


const CarouselCustom = props=>{    
    const {images,imagesWithoutPlaceholder}=props
    const [openModal,setOpenModal]= useState(false)
    const [isSwiping,setIsSwiping]= useState(false)    
    const [currentClientPosition,setCurrentClientPosition]= useState(null)
    const [isLoading,setIsLoading]= useState(false)
    const ref = useRef()
    const firstCarouselRef = useRef()
    const [currentSlideIndex,setCurrentSlideIndex]= useState(0)
    const classes = useStyles();
    const [initialModalSlide,setInitialModalSlide]= useState(null)

    const handlerDrag = (next) => {
      setCurrentSlideIndex(next);
      setIsSwiping(true);
    }

    function Arrow(props) {
      const { className, style, onClick } = props;
      const handle = ()=>{
        if (props.value === 'next') {
          ref.current.slickGoTo(initialModalSlide + 1,true)
        }else if (props.value === 'prev') {
          ref.current.slickGoTo(initialModalSlide - 1,true)
        }
      }
      return (
        <div
          className={className}
          style={{ ...style, display: "block"}}
          onClick={handle}
        />
      );
    }
    
    const handleEvent = (event,i) => {
      setInitialModalSlide(i)
      if (event.type === "mousedown") {
            setCurrentClientPosition(event.clientX)
         } else {
            if (event.clientX === currentClientPosition){
              handleOpen(event)
            }
         }
     }

    useEffect(() => {
      if (isSwiping){
        return window.setTimeout(() => {
          setIsSwiping(false);
        }, 100);
      }
    }, [isSwiping])

    const SampleArrow = (props) =>{
      const { className, style, onClick,value } = props;           
      if (value === 'prev') {
        if (currentSlideIndex === 0) {
          return null
        }
      }
      if (value === 'next') {
        if (firstCarouselRef.current?.props.slidesToShow + currentSlideIndex >= images.length) {
          return null
        }
      }      
      return (        
        value === 'next'?
        <ChevronRightIcon className={[classes.arrow,classes.right,className]} onClick={onClick}/>
        :<ChevronLeftIcon className={[classes.arrow,classes.left,className]} onClick={onClick}/>
      );
    }     

    const settings = {
      infinite: false,
      dots: false,
      slidesToShow: props.slidesNb? props.slidesNb: 3.5,
      slidesToScroll: 2,            
      autoplay: false,    
      arrows:true ,      
      nextArrow: <SampleArrow value={'next'}/>,
      prevArrow: <SampleArrow value={'prev'} />,
      beforeChange: (current, next) =>handlerDrag(next),
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 3.5,
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2.5,                    
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }                
      ]               
    };

    const modalSliderSettings = {
      infinite: false,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,            
      autoplay: false,    
      arrows:true ,
      swipe:true,     
      initialSlide:initialModalSlide,
      nextArrow: <Arrow value={'next'} />,
      prevArrow: <Arrow value={'prev'}/>,
      beforeChange:(oldIndex,newIndex)=>{setInitialModalSlide(newIndex)},      
    };

    const handleOpen = (e)=>{    
      setOpenModal(true)    
    }

    const handleClose = ()=>{
      setOpenModal(false)   
      setInitialModalSlide(null)
    }

    const handleSlide = (e,i)=>{      
      if (i < imagesWithoutPlaceholder.length) {
        setInitialModalSlide(i)
        handleOpen()
      }
    }

    const handleCopyImageUrl = (imageUrl) => {
      navigator.clipboard.writeText(imageUrl)
      props.snack(ALERT_SUCCESS, 'URL de l\'image copiée');
    }

    return (
        <CarouselContainer container>  
            <Slider ref={firstCarouselRef} {...settings}>
            {                                
                images.map((image,i)=>{
                    return (                                     
                        <ImageWrapper>
                          <CopyIcon onClick={() => handleCopyImageUrl(image)}>
                            <IcomoonReact iconSet={iconSet} color={colors.black.lighter} size={25} icon={"ico_copy"} />
                          </CopyIcon>
                          <Thumbnail                                                          
                            onClick={(e)=>handleSlide(e,i)}
                            src={image} 
                            alt=''
                          />
                        </ImageWrapper>
                    )
                })
            }                                           
            </Slider>    
            <Modal              
              disableEnforceFocus={true}
              open={openModal}     
              onClose={handleClose}                                     
              style={{
                  display:"flex",
                  alignItems:'center',
                  justifyContent:"center"
                }}
                >                                
                <ContainerSliderModal>                                    
                    <Slider                     
                    {...modalSliderSettings}       
                    ref={ref}                                                            
                    >
                    {     
                      isLoading ? (
                      <Box>
                          <PageLoader/>
                      </Box>
                      ) : 
                      imagesWithoutPlaceholder.map((image,i)=>{
                        return (  
                          <CustomDiv>
                            <ThumbnailZoom 
                              key={i} 
                              onMouseDown={handleEvent}
                              onMouseUp={handleEvent}
                              src={image} 
                              alt=''
                            />                            
                          </CustomDiv>
                        )
                      })
                    }                                           
                    </Slider>     
                </ContainerSliderModal>
            </Modal>                 
        </CarouselContainer>
    )
}

const mapDispatchToProps = dispatch => {
  return {
      snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }}),
  };
};

export default connect( null, mapDispatchToProps)(CarouselCustom);