import React, { useState } from 'react';
import styled from 'styled-components';

import { Box, Fade, Grid, Menu, MenuItem } from '@material-ui/core';

import Typography from '../../../ui/typography/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../../assets/selection.json';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../config/theme/colors';
import videoLogo from '../../../../assets/pictos/video.png';
import pngBackground from '../../../../assets/images/medias/pixelBackground.jpg';
import Chip from '@material-ui/core/Chip';
import DialogModal from '../../../ui/dialog/DialogModal';
import MediaModal from '../../../screens/dashboard/medias/components/MediaModal'

import axios from '../../../../js/utils/axios';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { getFileConfig } from '../../../../js/helpers/files';
import { connect } from "react-redux";
import { DOCUMENTS, IMAGES, CSV } from "../../../../js/constants/medias-types";
import moment from 'moment';
import DeleteDialogModal from '../../../ui/dialog/DeleteDialogModal';
import OurMenu from '../../../ui/menu/Menu';


const BigImage = styled(Box)`
    cursor: pointer;
    height: ${props => props.windowWidth > 1500 ? '380px' : '230px'};
    position: relative !important;
    .bigImageContainer{
        overflow: hidden;
        text-align:center;
        height:70%;
        width:100%;
        position: relative;
        /* background-size:100%;
        background-position:center;
        background-repeat:no-repeat; 
        background-image: url(${props => props.imagebackground}), url(${props => props.typeimage === "PNG" ? pngBackground : ''}); */
        
        img{
            max-width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .bigImageInfo{
        margin-top: 10px;
        width:100%;
        border-top:none;
        font-style:italic;
        height:30%;
    }
    .editicon{
        z-index: 99;
        @media screen and (max-width: 850px){
            z-index: 99;
        }
    }
    &:hover{
        .bigImageInfo{
            background-size:100% 100%;   
        }
        .editicon{
            display: flex;
        }
    }
`;
const Title = styled(Typography)`
margin-top: ${props => props.windowWidth > 1500 ? '23px' : '0px'};;
    transition :color .5s;
    width:100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    font-size: ${props => props.windowWidth > 1500 ? '20px' : '13px'};
    text-align:left;
`;

const useStyles = makeStyles((theme) => ({
    editIcon: {
        position: 'absolute',
        color: colors.black.regular,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 25,
        top: -10,
        right: 10,
        transition: 'all .5s',
        display: 'none',
    },
    menu: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    paper: {
        border: '1px solid #d3d4d5',
    }
}));

function getMediaType(type) {
    var res = type?.match(/\.[0-9a-z]+$/i)?.[0].replace('.', '').toUpperCase()
    return res;
}


function CardMedia(props) {
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [toDelete, setToDelete] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [downloadConfirmation, setDownloadConfirmation] = useState(false);
    const open = Boolean(anchorEl);

    const classes = useStyles();

    let media = props.data;

    if (media?.node) {
        media = media?.node;
    }
    let type = {};
    type = {
        value: getMediaType(media?.filePath),
        style: {
            background: getFileConfig(media?.type).color,
            color: 'white',
            fontWeight: 'bold'
        }
    }
    // if(['video/mpeg','video/webm','video/mp4'].includes(media.type)){
    //     isVideo=true;
    // }

    //#region MODALS
    const handleCloseModal = () => {
        setOpenModal(!openModal)
    }

    const handleMediaModal = (media, index, isNav = false) => {
        let setupModalData = {
            ...media,
            index: index
        }

        if (!isNav) {
            setOpenModal(!openModal)
        }
        setModalData(setupModalData)
    }

    // handleNextMedia = (step,index) => {
    //     let next;

    //     switch(step){
    //         case 'next':
    //             next = index +1;
    //             break;

    //         case 'prev':
    //             next = index-1;
    //             break;

    //         default :
    //             next = next;
    //             break;
    //     }


    //     let media = this.state.medias[next];
    //     this.handleMediaModal(media,next,true);
    // }

    const handleDeleteModal = (id) => {
        setToDelete(id);
        setOpenDeleteModal(!openDeleteModal);
    }

    const closeAllModal = () => {
        setOpenModal(false);
        setOpenDeleteModal(false);
    }
    //#endregion

    const deleteMedia = async (action) => {
        switch (action) {
            case 'delete':
                await axios(`${process.env.REACT_APP_API}/media-objects/${toDelete.replace('/api/media-objects/', '')}`, 'delete');

                let currentList = props.allMedias;
                let newList = currentList.filter(media => props.isDashboard ? media.id !== toDelete : media?.node.id !== toDelete)

                props.callback(newList);
                props.snack(ALERT_SUCCESS, 'Média supprimé');

                closeAllModal();

                /*Reload en arrière plan de la liste des médias*/
                // this.handleGetAllMedias(true);
                // props.callback(newList);

                break;

            case 'cancel':
                // this.setState({
                //     openDeleteModal : !this.state.openDeleteModal

                // })    
                setOpenDeleteModal(!openDeleteModal);
            default:
                return null;
        }
        setToDelete(null);
    }

    const getFileSize = (size) => {
        const ko = size / 1024
        const mo = ko / 1024
        return mo < 1 ? ko.toFixed(0) + ' ko' : (mo).toFixed(0) + ' mo'
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = (event) => {
        setAnchorEl(null);
        event.stopPropagation();
    };

    const editMenuOptions = [
        // {
        //     label: 'Modifier',
        //     color: colors.blue.darker.hue300,
        //     icon: <EditIcon style={{ fill: colors.blue.lighter.hue300 }} />,
        //     action: (e, media) => {
        //         props.handleEditMedia(media)
        //         handleClose(e);
        //     }
        // },
        {
            label: 'Supprimer',
            color: colors.red.regular,
            icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
            action: (e, media) => {
                handleDeleteModal(media.id);
                handleClose(e);
            }
        },
        {
            label: 'Télécharger',
            color: colors.grey.regular,
            icon: <GetAppSharpIcon style={{ fill: colors.black.regular }} />,
            action: (e, media) => {
                handleDownload(media)
                handleClose(e);
            }
        }
    ]

    const handleDownload = (media) => {
        if (media.expirationAt) {
            const currentDate = moment();
            const expiration = moment(media.expirationAt);
            if (currentDate.isAfter(expiration)) {
                setDownloadConfirmation(true);
            } else {
                window.open(`${process.env.REACT_APP_API_ROOT}/medias/${media?.filePath}`, '_blank')
            }

        } else {
            window.open(`${process.env.REACT_APP_API_ROOT}/medias/${media?.filePath}`, '_blank')
        }
    }


    return (
        <Box
        style={{
            padding:"16px",

        }}
        >
            <BigImage
                onClick={() => handleMediaModal(media, props.currentIndex)}
                typeimage={type.value}
                windowWidth={props.windowWidth}
                style={{
                    position: 'relative',
                }}
            // imagebackground={isVideo==true ? videoLogo : process.env.REACT_APP_API_ROOT+'/medias/'+media.filePath }
            >
                <Box>
                    <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e, media)} hasSquare={true} customSquareStyle={{
                        top: '16px',
                        right: '16px',
                    }} />            
                </Box>
                {/* Image */}
                <Box className="bigImageContainer">
                    <img src={getFileConfig(media?.type, false).image ? getFileConfig(media?.type, false).image : process.env.REACT_APP_API_ROOT + '/medias/' + media?.filePath} style={{ height: getFileConfig(media?.type).image ? '100%' : '' }} />
                </Box>
                {/* Title */}
                <Box className="bigImageInfo" style={{ fontStyle: 'normal' }}>
                    <Title windowWidth={props.windowWidth} variant="h3" title={media?.name ? media?.name : media?.filePath}>
                        {media?.name ? media?.name : media?.filePath}
                    </Title>
                    {/* Type */}
                    <Grid container justifyContent='space-between' alignItems='center' style={{ marginTop: props.windowWidth > 1500 ? '10px' : '2px', fontSize: '12px' }}>
                        <Grid item >
                            <Grid container alignItems='center' style={{ gap: '2px' }}>
                                <Grid item style={{ marginRight: '5px' }}>
                                    <img src={getFileConfig(media?.type, true).icon} alt='test' style={{
                                        width: '20px',
                                        marginTop: '7px',
                                    }}></img>
                                </Grid>
                                <Grid item>
                                    <Typography style={{ fontSize: props.windowWidth > 1500 ? '17px' : '13px', color: colors.grey.regular, fontWeight: '200' }}>Type de fichier: .{type.value}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{ color: colors.grey.regular, fontWeight: '200', fontSize: props.windowWidth > 1500 ? '17px' : '13px' }}>
                            {getFileSize(media?.size)}
                        </Grid>
                    </Grid>
                </Box>
            </BigImage>
            {/* Info modal */}
            {modalData
                ?
                <MediaModal
                    open={openModal}
                    onClose={handleCloseModal}
                    modalData={modalData}
                    // handleNav           = {handleNextMedia}
                    // lastMedia           = {this.state.medias?.length-1}
                    handleDeleteModal={handleDeleteModal}
                    readOnly={props.readOnly}
                    windowWidth={props.windowWidth}
                />

                : null}
            {/* Delete modal */}
            <DeleteDialogModal
                open={openDeleteModal}
                title={'Êtes-vous sûr de vouloir supprimer ce média ?'}
                primaryAction={() => { deleteMedia('delete') }}
                secondaryAction={() => { deleteMedia('cancel') }}
                windowWidth={props.windowWidth}
            >
                <Typography variant="body2">Êtes-vous sûr de vouloir supprimer ce média ? <strong>Cette action est irréversible</strong></Typography>
            </DeleteDialogModal>

            {/* Download modal */}
            {media?.expirationAt ?
                <DialogModal
                    title="Média arrivé à expiration"
                    open={downloadConfirmation}
                    onClose={() => setDownloadConfirmation(false)}
                    secondaryAction={() => setDownloadConfirmation(false)}
                    primaryAction={() => { window.open(`${process.env.REACT_APP_API_ROOT}/medias/${media?.filePath}`, '_blank') }}
                    primaryText="Télécharger"
                >

                    <p>
                        Attention, ce média est expiré depuis le <strong>{moment(media.expirationAt).format('DD/MM/YYYY à hh:mm')}</strong>.
                    </p>
                    <p>
                        Vous ne pouvez pas exploiter ce média. Télécharger quand même ?
                    </p>
                </DialogModal>
                : null}
        </Box>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardMedia);