import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';

import mapDispatchToProps from '../../../../js/helpers/mapDispatchToProps';
import mapStateToProps from '../../../../js/helpers/mapStateToProps';

import { Box, Grid, Tooltip } from '@material-ui/core';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import Empty from '../../../../assets/pictos/empty-picto/empty_products.png';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import CardCustom from '../../../layouts/Card/CardCustom';
import Typography from '../../../ui/typography/Typography';
import Button from '../../../ui/button/Button';
import DialogModal from '../../../ui/dialog/DialogModal';

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import colors from '../../../../config/theme/colors';

import { eventService } from '../../../../js/services/event.service';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { SETTINGS, SETTINGS_USERS, VIEW, CREATE, IMPORT } from '../../../../js/constants/constant-rights';

import { GET_ATTR_GROUPE_WITH_ATTRIBUTES_LIGHT } from '../../../../queries/attributes';
import { GET_COMPLETENESSGROUPS, CREATE_COMPLETENESSGROUP, UPDATE_COMPLETENESSGROUP, DELETE_COMPLETENESSGROUP } from '../../../../queries/completeness_group';

import formAddCompletenessGroup from './config/formAddCompletenessGroup.config';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import DeleteDialogModal from '../../../ui/dialog/DeleteDialogModal';



const SpanColor = styled.span`
    color: ${colors.blue.darker.hue300};
    font-weight: bold;
    display: block;
    width: 100px;
`;

const PageWrapper = styled(Box)`
    width: ${props => props.openForm ? `calc(100% - ((50% - ${props.drawerWidth}px / 2) + (${props.drawerWidth}px / 2)))` : "100%"};   
    transition: all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms; 
    padding-bottom: 32;
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
`;

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.black.regular,
    },
    tooltip: {
        backgroundColor: colors.black.regular,
        fontSize: 14,
        padding: 10,
        textAlign: 'left',
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class ProductsCompletude extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLang: props.locales[0].node.code,
            openForm: false,
            openDialog: false,
            errors: {},
            editForm: 'add',
            libelle: null,
            attributesSelected: [],
            ready: false,
            listCompletenessGroup: []
        };
    }

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setState({
            [stateName]: value,
        });
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    handleToggleDrawer = () => {
        this.setState({
            openForm: !this.state.openForm
        }, () => {
            if (!this.state.openForm) {
                this.handleReinitState();
            }
        });
    };

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog
        });
    };

    handleReinitState = () => {
        this.setState({ libelle: null, attributesSelected: [], editForm: 'add', selectedGroup: null });
    }

    handlerMutation = () => {
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            return eventService.fire();
        }
        this.props.startLoading();
        let query = this.state.editForm === 'add' ? CREATE_COMPLETENESSGROUP : UPDATE_COMPLETENESSGROUP;
        let variables = { libelle: this.state.libelle, attributes: this.state.attributesSelected };
        if (this.state.editForm === 'edit') {
            variables.id = this.state.selectedGroup;
        }
        this.props.client.query({
            query,
            variables,
            fetchPolicy: "no-cache",
        }).then(result => {
            this.handleGetCompletnessGroup();
            this.handleToggleDrawer();
            this.handleReinitState();
            this.props.stopLoading();
        });
    };


    deleteMutation = () => {
        this.props.startLoading();
        this.props.client.mutate({
            mutation: DELETE_COMPLETENESSGROUP,
            variables: { id: this.state.selectedGroup }
        }).then(result => {
            this.handleGetCompletnessGroup();
            this.handleToggleDrawer();
            this.handleToggleDialog();
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Groupe de complétude supprimé avec succès');
        }).catch(error => {
            this.handleGetCompletnessGroup();
            this.handleToggleDrawer();
            this.handleToggleDialog();
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, `Impossible de supprimer le groupe`);
        });
    };

    handleGetCompletnessGroup = () => {
        this.props.client.query({
            query: GET_COMPLETENESSGROUPS,
            fetchPolicy: "no-cache",
        }).then(result => {
            this.setState({ listCompletenessGroup: result.data.completenessGroups.edges });
        });
    }

    componentDidMount = async () => {
        checkRouting(this.props);
        await this.handleGetCompletnessGroup();
        this.initAttributs();
    }

    initAttributs() {
        this.props.client.query({
            query: GET_ATTR_GROUPE_WITH_ATTRIBUTES_LIGHT,
            variables: {
                "boolIsForContent": false,
                // "isSystem": false
            },
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({ allGroups: result.data.attributeGroups.edges.filter(e => (!e.node.isSystem) || e.node.identifier === 'default'), ready: true });
        });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }
        return false;
    };

    editGroup = (completenessGroup) => {
        let attributesSelected = [];
        for (let attr of completenessGroup.node.completenessGroupAttributes.edges) {
            attributesSelected.push(attr.node.attribute.id);
        }
        this.setState({
            selectedGroup: completenessGroup.node.id,
            libelle: completenessGroup.node.libelle,
            attributesSelected,
            editForm: 'edit',
        }, () => {
            this.handleToggleDrawer();

        });
    };

    render() {
        return (
            <PageWrapper openForm={this.state.openForm} drawerWidth={this.props.drawerWidth}>
                <TopPanel
                    icomoon="picto-produit"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.t("products.completeness.title")}
                    subtitle={this.props.t("products.completeness.subtitle")}
                    handlerAdd={this.handleToggleDrawer}
                    textAdd={this.props.t("products.completeness.addgroup")}
                    searchHandler={this.handleChange}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    buttonAvailable={this.state.openForm || !this.state.ready ? false : true}
                    openForm={this.state.openForm}
                    hasBorder={true}
                />
                {
                    this.state.ready && this.state.listCompletenessGroup?.length === 0 ? (
                        <EmptyCard title={'Aucun groupe de complétude existant'} subtitle={'Vous pouvez créer dès maintenant un groupe'} textButton={'Créer un groupe de complétude'} onClick={() => { this.handleToggleDrawer() }} picto={Empty} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                    ) : !this.state.ready ? (
                        <Box style={{
                            height: '100%',
                            width: '100%',
                            position: 'relative',
                        }}>
                            <PageLoader />
                        </Box>
                    ) : (
                        <Grid container direction="column" spacing={0} style={{ paddingBottom: 24, width: '100%', paddingTop: 24 }}>
                            <Grid container direction="row" spacing={4}>
                                {
                                    this.state.listCompletenessGroup?.map((completenessGroup, index) => (
                                        <Grid item xl={4} sm={4} xs={6} style={{ display: 'flex', flexWrap: 'wrap' }} key={`card-completeness-${index}`}>
                                            <CardCustom cardContentStyle={{ width: '100%' }} style={{ width: '100%' }} contentpadding={"16px 16px 8px"}>
                                                <Typography variant="h2">{completenessGroup.node.libelle}</Typography>
                                                <BootstrapTooltip
                                                    title={completenessGroup.node.completenessGroupAttributes.edges.map((attr, index) => {
                                                        return (<span key={`CatalogName${index}`} style={{ display: 'block' }}>{index + 1} - {attr.node.attribute.identifier}</span>)
                                                    })}
                                                    arrow
                                                >
                                                    <SpanColor variant="body1">{`${completenessGroup.node.completenessGroupAttributes.edges.length} attribut${completenessGroup.node.completenessGroupAttributes.edges.length > 1 ? 's' : ''} `}</SpanColor>
                                                </BootstrapTooltip>
                                                <Grid container justifyContent="flex-end">
                                                    <Button onClick={() => this.editGroup(completenessGroup)}>{this.props.t("products.completeness.editgroup")}</Button>
                                                </Grid>
                                            </CardCustom>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    )
                }
                {this.state.openForm ?
                    <LayoutBuilder
                        isSublayout={false}
                        opened={this.state.openForm}
                        forClose={this.handleToggleDrawer}
                        handlerMutation={() => this.handlerMutation()}
                        allState={this.state}
                        dataLayout={formAddCompletenessGroup(this.state.allGroups, this.state.editForm, this.state.currentLang, this.props.t)}
                        icomoon={'picto-produit'}
                        stateCallback={this.handleInputChange}
                        errorCallback={this.handleFormError}
                        checkError={() => { }}
                        deleteMutation={this.state.editForm === 'edit' ? this.handleToggleDialog : null}
                        deleteText={this.state.editForm === 'edit' ? this.props.t('products.completeness.delete') : null}
                        deleteButton={this.state.editForm === 'edit' ? true : false}
                        validateButton={true}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        stepperButtonAction={[
                            () => {
                                if (this.hasErrors()) {
                                    this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                    this.setState({ seeErrors: true });
                                    eventService.fire();
                                    return false;
                                }
                                return true;
                            }
                        ]}
                    />
                    : null
                }

                <DeleteDialogModal
                    open={this.state.openDialog}
                    title={this.props.t("products.completeness.ifdeletetitle")}
                    primaryAction={this.deleteMutation} primarybgcolor={colors.red.regular} primarybgcolorhover={colors.red.darker} primaryText={this.props.t("products.completeness.deletebutton")}
                    secondaryAction={() => { this.handleToggleDialog() }} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                >
                    {this.props.t("products.completeness.ifdelete")}
                </DeleteDialogModal>
            </PageWrapper>
        );
    }
    goTo = route => {
        this.props.history.push(route);
    };
}

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsCompletude))));
