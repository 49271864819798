import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import { toggleExpandedForAll } from 'react-sortable-tree';
import { withApollo } from 'react-apollo';
import {
    GET_ATTR_GROUPE,
    GET_ATTRIBUTE_TYPES,
    DELETE_ATTRIBUTE,
    ADD_ATTRIBUTE_GROUPE,
    DELETE_ATTRIBUTE_GROUPE,
    UPDATE_ATTRIBUTE,
    ADD_ATTRIBUTE,
    ADD_ATTRIBUTE_OPTION,
    DELETE_ATTRIBUTE_OPTION,
    GET_ATTRIBUTES_BY_TYPE,
    GET_ATTRIBUTE
} from '../../../../queries/attributes';
import {
    ADD_TRANSLATION,
    ADD_TRANSLATION_DATA,
    UPDATE_TRANSLATION_DATA,
    DELETE_TRANSLATION_DATA
} from '../../../../queries/translations';
import formContentGroupAttributAdd from './config/formContentGroupAttributAdd.config';
import formContentGroupAttributEdit from './config/formContentGroupAttributEdit.config';
import formContentAttributEdit from './config/formContentAttributEdit.config';
import formContentAttributAdd from './config/formContentAttributAdd.config';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import TreeView from '../../../ui/tree-view/TreeView';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import Button from '../../../ui/button/Button';
import { eventService } from '../../../../js/services/event.service';

import colors from '../../../../config/theme/colors';
import { SNACK, START_LOADING, STOP_LOADING, SET_ATTRIBUTES } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import request from '../../../../js/utils/fetch';
import slugify from 'slugify';

import { CONTENTS, CONTENTS_MODELS, VIEW, CREATE, UPDATE, DELETE } from '../../../../js/constants/constant-rights';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';

class ContentsAttributes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openForm: false,
            openDialog: false,
            editForm: false,
            eavGrpType: '',
            attr_id: '',
            grp_id: '',
            parent: '',
            libelle: '',
            isRequired: true,
            isSearchable: true,
            attrGrpIsSystem: false,
            attributeType: '',
            categoriesData: [],
            listGroupAttr: [],
            treeData: null,
            allInputTypes: [],
            inputAttributes: [],
            currentLang: props.locales[0].node.code,
            groupIdentifier: null,
            errors: {}
        };

        this.errors = {}

        this.stats =
            [
                {
                    'icon': 'picto-stock',
                    'data': '24/06/20 à 09:00',
                    'dataColor': colors.pink.regular,
                    'subtitle': this.props.t("content.models.stockUpdate"),
                    'gradientColor1': colors.pink.regular,
                    'gradientColor2': colors.pink.lighter,
                },
                {
                    'icon': 'picto-produit',
                    'data': '24/06/20 à 09:00',
                    'dataColor': colors.pink.regular,
                    'subtitle': this.props.t("content.models.stockUpdate"),
                    'gradientColor1': colors.pink.regular,
                    'gradientColor2': colors.pink.lighter,
                },
                {
                    'icon': 'picto-prix',
                    'data': '24/06/20 à 09:00',
                    'dataColor': colors.pink.regular,
                    'subtitle': this.props.t("content.models.priceUpdate"),
                    'gradientColor1': colors.pink.regular,
                    'gradientColor2': colors.pink.lighter,
                },
            ];

        this.typingTimer = null;
        this.typeTesting = 'attribute';
    }

    // [BEGIN] MUTATIONS

    // -- GROUPS

    saveAddGroup = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let identifier = `${this.state.groupIdentifier}`;

                const ADD_TRANSLATION_RESULT = await this.props.client.mutate({
                    mutation: ADD_TRANSLATION,
                    variables: { 'translationKey': `spread.attributeGroup.${identifier}` }
                });

                for (let locale of this.props.locales) {
                    if (this.state[locale.node.code]['groupName']) {
                        await this.props.client.mutate({
                            mutation: ADD_TRANSLATION_DATA,
                            variables: {
                                'value': this.state[locale.node.code]['groupName'],
                                'locale': locale.node.id,
                                'translation': ADD_TRANSLATION_RESULT.data.createTranslation.translation.id
                            }
                        });
                    }
                }

                await this.props.client.mutate({
                    mutation: ADD_ATTRIBUTE_GROUPE,
                    variables: {
                        'identifier': identifier,
                        'translation': ADD_TRANSLATION_RESULT.data.createTranslation.translation.id,
                        'isSystem': false,
                        'isForContent': true,
                    }
                });

                resolve();
            } catch (e) {
                this.handleError(e);
                reject();
            }
        });
    };

    saveEditGroup = () => {
        return new Promise(async (resolve, reject) => {
            try {
                for (let locale of this.props.locales) {
                    let formValue = this.state[locale.node.code]['groupName'];
                    let currentTranslation = this.state.currentGroup.locales.find(e => e.id === locale.node.id);
                    if (formValue) {
                        if (currentTranslation) {
                            // UPDATE STEP
                            await this.props.client.mutate({
                                mutation: UPDATE_TRANSLATION_DATA,
                                variables: {
                                    'id': currentTranslation.translationDataId,
                                    'value': formValue
                                }
                            });
                        } else {
                            // CREATE STEP
                            await this.props.client.mutate({
                                mutation: ADD_TRANSLATION_DATA,
                                variables: {
                                    'value': formValue,
                                    'locale': locale.node.id,
                                    'translation': this.state.currentGroup.node.translation.id
                                }
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP
                        await this.props.client.mutate({
                            mutation: DELETE_TRANSLATION_DATA,
                            variables: { 'id': currentTranslation.translationDataId }
                        });
                    }
                }

                resolve();
            } catch (e) {
                this.handleError(e);
                reject();
            }
        });
    };

    // -- OPTIONS

    deleteOption = (id) => {
        return new Promise(async (resolve, reject) => {
            await this.props.client.mutate({
                mutation: DELETE_ATTRIBUTE_OPTION,
                variables: { 'id': id }
            });

            resolve();
        });
    };

    createOption = (attribute, option) => {
        return new Promise(async (resolve, reject) => {
            const identifier = `${option.code}`;

            const ADD_TRANSLATION_RESULT = await this.props.client.mutate({
                mutation: ADD_TRANSLATION,
                variables: { 'translationKey': `spread.attributeOption.${attribute.identifier}.${identifier}` }
            });

            await this.props.client.mutate({
                mutation: ADD_ATTRIBUTE_OPTION,
                variables: {
                    'identifier': identifier,
                    'attribute': attribute.id,
                    'translation': ADD_TRANSLATION_RESULT.data.createTranslation.translation.id
                }
            });

            for (let locale of this.props.locales) {
                let value = option.values[locale.node.code];

                if (value) {
                    await this.props.client.mutate({
                        mutation: ADD_TRANSLATION_DATA,
                        variables: {
                            'value': value,
                            'locale': locale.node.id,
                            'translation': ADD_TRANSLATION_RESULT.data.createTranslation.translation.id
                        }
                    });
                }
            }

            resolve();
        });
    }

    updateOption = (option) => {
        return new Promise(async (resolve, reject) => {
            for (let locale of this.props.locales) {
                let formValue = option.values[locale.node.code];
                let currentTranslation = this.state.currentAttribute.options.find(e => e.id === option.id).locales.find(e => e.id === locale.node.id);
                if (formValue) {
                    if (currentTranslation) {
                        // UPDATE STEP

                        await this.props.client.mutate({
                            mutation: UPDATE_TRANSLATION_DATA,
                            variables: {
                                'id': currentTranslation.translationDataId,
                                'value': formValue
                            }
                        });
                    } else {
                        // CREATE STEP

                        await this.props.client.mutate({
                            mutation: ADD_TRANSLATION_DATA,
                            variables: {
                                'value': formValue,
                                'locale': locale.node.id,
                                'translation': this.state.currentGroup.node.translation.id
                            }
                        });
                    }
                } else if (currentTranslation) {
                    // DELETE STEP

                    await this.props.client.mutate({
                        mutation: DELETE_TRANSLATION_DATA,
                        variables: { 'id': currentTranslation.translationDataId }
                    });
                }
            }

            resolve();
        });
    };

    saveOptions = (attribute) => {
        return new Promise(async (resolve, reject) => {
            for (let option of this.state.values) {
                if (option.deleted)
                    await this.deleteOption(option.id);
                else if (option.new)
                    await this.createOption(attribute, option);
                else if (option.changed)
                    await this.updateOption(option);
            }

            resolve();
        });
    };

    // -- ATTRIBUTES

    saveAddAttr = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let identifier = `attribute_${this.state.attributeIdentifier}`;
                const ADD_TRANSLATION_RESULT = await this.props.client.mutate({
                    mutation: ADD_TRANSLATION,
                    variables: { 'translationKey': `spread.attribute.${identifier}` }
                });

                for (let locale of this.props.locales) {
                    if (this.state[locale.node.code]['attributeName']) {
                        await this.props.client.mutate({
                            mutation: ADD_TRANSLATION_DATA,
                            variables: {
                                'value': this.state[locale.node.code]['attributeName'],
                                'locale': locale.node.id,
                                'translation': ADD_TRANSLATION_RESULT.data.createTranslation.translation.id
                            }
                        });
                    }
                }

                const ADD_ATTRIBUT_RESULT = await this.props.client.mutate({
                    mutation: ADD_ATTRIBUTE,
                    variables: {
                        'identifier': identifier,
                        'attributeGroup': this.state.selectedGroups.map(e => e.node.id),
                        'eavType': this.props.attributes.eavTypes.find(e => e.node.code === 'content').node.id,
                        'attributeType': this.state.attributeType,
                        'isRequired': this.state.isRequired,
                        'isSearchable': this.state.isSearchable,
                        'isSystem': false,
                        'status': true,
                        'translation': ADD_TRANSLATION_RESULT.data.createTranslation.translation.id,
                        'internalFilter': false,
                        'useInCard': false,
                    }
                });

                if (this.state.attributeType === this.state.choiceType.node.id)
                    await this.saveOptions(ADD_ATTRIBUT_RESULT.data.createAttribute.attribute);

                // ADD ATTRIBUTE TO STORE AND LOCAL STORAGE

                const GET_ATTRIBUT_RESULT = await this.props.client.mutate({
                    mutation: GET_ATTRIBUTE,
                    variables: {
                        'id': ADD_ATTRIBUT_RESULT.data.createAttribute.attribute.id
                    }
                });

                this.props.attributes.product.attributes.edges.push({ node: GET_ATTRIBUT_RESULT.data.attribute });
                localStorage.setItem('ATTRIBUTES', JSON.stringify(this.props.attributes));
                this.props.setAttributes(this.props.attributes);

                resolve();
            } catch (e) {
                this.handleError(e);
                reject();
            }
        });
    };

    saveEditAttr = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const UPDATE_ATTRIBUTE_RESULT = await this.props.client.mutate({
                    mutation: UPDATE_ATTRIBUTE,
                    variables: {
                        id: this.state.currentAttribute.node.id,
                        attributeGroup: this.state.selectedGroups.map(e => e.node.id),
                        attributeType: this.state.attributeType,
                        isRequired: this.state.isRequired,
                        isSearchable: this.state.isSearchable,
                        internalFilter: false,
                        useInCard: false,
                    }
                });

                for (let locale of this.props.locales) {
                    let formValue = this.state[locale.node.code]['attributeName'];
                    let currentTranslation = this.state.currentAttribute.locales.find(e => e.id === locale.node.id);

                    if (formValue) {
                        if (currentTranslation) {
                            // UPDATE STEP

                            await this.props.client.mutate({
                                mutation: UPDATE_TRANSLATION_DATA,
                                variables: {
                                    'id': currentTranslation.translationDataId,
                                    'value': formValue
                                }
                            });
                        } else {
                            // CREATE STEP

                            await this.props.client.mutate({
                                mutation: ADD_TRANSLATION_DATA,
                                variables: {
                                    'value': formValue,
                                    'locale': locale.node.id,
                                    'translation': this.state.currentAttribute.node.translation.id
                                }
                            });
                        }
                    } else if (currentTranslation) {
                        // DELETE STEP

                        await this.props.client.mutate({
                            mutation: DELETE_TRANSLATION_DATA,
                            variables: { 'id': currentTranslation.translationDataId }
                        });
                    }
                }

                if (this.state.attributeType === this.state.choiceType.node.id)
                    await this.saveOptions(UPDATE_ATTRIBUTE_RESULT.data.updateAttribute.attribute);


                // UPDATE ATTRIBUTE IN STORE AND LOCAL STORAGE

                const GET_ATTRIBUT_RESULT = await this.props.client.mutate({
                    mutation: GET_ATTRIBUTE,
                    variables: {
                        'id': UPDATE_ATTRIBUTE_RESULT.data.updateAttribute.attribute.id
                    }
                });

                for (let attribute of this.props.attributes.product.attributes.edges) {
                    if (attribute.node.id === UPDATE_ATTRIBUTE_RESULT.data.updateAttribute.attribute.id) {
                        attribute.node = GET_ATTRIBUT_RESULT.data.attribute;
                    }
                }

                localStorage.setItem('ATTRIBUTES', JSON.stringify(this.props.attributes));
                this.props.setAttributes(this.props.attributes);

                resolve();
            } catch (e) {
                this.handleError(e);
                reject();
            }
        });
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.error"));

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }

        this.setState({
            openDialog: false
        });
    };

    handleSuccess = async () => {
        await this.prepareTree();

        this.props.snack(ALERT_SUCCESS, this.state.editForm === 'editAttribut'
            ? this.props.t("content.models.modifiedVariable")
            : this.state.editForm === 'addAttribut'
                ? this.props.t("content.models.addedVariable")
                : this.state.editForm === 'editGroup'
                    ? this.props.t("content.models.modifiedModel")
                    : this.props.t("content.models.modifiedModel"));

        this.handleToggleDrawer();
        this.resetState();
        this.props.stopLoading();
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handlerMutation = () => {
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
            return eventService.fire();
        }

        this.props.startLoading();

        switch (this.state.editForm) {
            case 'addAttribut':
                this.saveAddAttr().then(this.handleSuccess);
                break;
            case 'editAttribut':
                this.saveEditAttr().then(this.handleSuccess);
                break;
            case 'addGroup':
                this.saveAddGroup().then(this.handleSuccess);
                break;
            case 'editGroup':
                this.saveEditGroup().then(this.handleSuccess);
                break;
            default: return false;
        }
    };

    deleteMutation = () => {
        let query = null;
        let variables = null;

        this.props.startLoading();

        if (this.state.editForm === 'editGroup') {
            query = DELETE_ATTRIBUTE_GROUPE;
            variables = { id: this.state.currentGroup.node.id };
        } else {
            query = DELETE_ATTRIBUTE;
            variables = { id: this.state.currentAttribute.node.id };
        }

        this.props.client.mutate({
            mutation: query,
            variables
        }).then(() => {
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, this.state.editForm === 'editGroup'
                ? this.props.t("content.models.deletedModel")
                : this.props.t("content.models.variableRemoved"));

            this.removeNode(this.state.editForm === 'editGroup'
                ? this.state.currentGroup.node.id
                : this.state.currentAttribute.node.id);

            // DELETE ATTRIBUTE IN STORE AND STORAGE

            if (this.state.editForm !== 'editGroup') {
                this.props.attributes.product.attributes.edges = this.props.attributes.product.attributes.edges.filter(e => e.node.id !== this.state.currentAttribute.node.id);

                localStorage.setItem('ATTRIBUTES', JSON.stringify(this.props.attributes));
                this.props.setAttributes(this.props.attributes);
            }

            this.handleToggleDrawer();
            this.handleToggleDialog();
            this.resetState();
        }).catch(e => this.handleError(e));
    };

    // [END] MUTATIONS

    removeNode = (id) => {
        let tree = this.state.treeData;
        let tree2 = this.state.unassignedAttributes;

        tree = tree.filter(e => e.node.id !== id);
        tree2 = tree2.filter(e => e.node.id !== id);

        for (let data of tree)
            data.children = data.children.filter(e => e.node.id !== id);

        this.setState({
            treeData: this.copyArrayOfObjects(tree),
            unassignedAttributes: this.copyArrayOfObjects(tree2)
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            for (let attrGroup of this.state.treeData) {
                this.convertToNode(attrGroup, true);

                for (let attr of attrGroup.children)
                    this.convertToNode(attr, false, attrGroup, true);
            }

            for (let unassignedAttribute of this.state.unassignedAttributes)
                this.convertToNode(unassignedAttribute, true, null, true);

            this.forceUpdate();
        });
    };

    handleToggleDrawer = () => {
        this.setState({
            openForm: !this.state.openForm
        });
    };

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog
        });
    };

    resetState() {
        this.setState({
            attr_id: '',
            grp_id: '',
            libelle: '',
            parent: '',
            attributeType: '',
            isSystem: false,
            isRequired: true,
            isSearchable: true,
            attrGrpIsSystem: false,
            attributeIdentifier: null,
            groupIdentifier: null,
            errors: {}
        });
    }

    editAttribut = (nodeInfo) => {
        this.resetState();

        let choiceType = this.state.allInputTypes.find(e => e.node.input === 'select');

        for (let locale of this.props.locales) {
            let values = {};

            for (let groupLocale of nodeInfo.locales) {
                if (groupLocale.code === locale.node.code) {
                    values['attributeName'] = groupLocale.value;
                }
            }

            this.setState({
                [locale.node.code]: values
            });
        }

        let options = [];

        for (let option of nodeInfo.options) {
            let values = {};

            for (let locale of this.props.locales) {
                for (let optionLocale of option.locales) {
                    if (optionLocale.code === locale.node.code) {
                        values[optionLocale.code] = optionLocale.value;
                    }
                }
            }

            options.push({
                id: option.id,
                new: false,
                code: option.identifier.indexOf('_') > -1
                    ? option.identifier.split('_')[1]
                    : option.identifier,
                values
            });
        }

        this.setState({
            openForm: true,
            editForm: 'editAttribut',
            currentAttribute: nodeInfo,
            choiceType,
            attributeType: nodeInfo.node.attributeType.id,
            attributeIdentifier: nodeInfo.node.identifier.indexOf('_') > -1
                ? nodeInfo.node.identifier.split('_')[1]
                : nodeInfo.node.identifier,
            isSystem: nodeInfo.node.isSystem,
            isRequired: nodeInfo.node.isRequired,
            isSearchable: nodeInfo.node.isSearchable,
            selectedGroups: [].concat(nodeInfo.node.attributeGroup.edges),
            values: choiceType.node.id === nodeInfo.node.attributeType.id ? options : []
        });
    }

    addAttribut = () => {
        this.resetState();

        for (let locale of this.props.locales) {
            this.setState({
                [locale.node.code]: {}
            });
        }

        this.setState({
            openForm: true,
            editForm: 'addAttribut',
            selectedGroups: [],
            choiceType: this.state.allInputTypes.find(e => e.node.input === 'select'),
            attributeType: this.state.allInputTypes[0].node.id,
            values: []
        });
    };

    addAttributByGrp = (nodeInfo) => {
        this.resetState();

        for (let locale of this.props.locales) {
            this.setState({
                [locale.node.code]: {}
            });
        }

        this.setState({
            openForm: true,
            editForm: 'addAttribut',
            selectedGroups: [this.state.treeData.find(e => e.node.id === nodeInfo.id)],
            attributeType: this.state.allInputTypes[0].node.id,
            choiceType: this.state.allInputTypes.find(e => e.node.input === 'select'),
            values: []
        });
    };

    addGroupAttribut = () => {
        this.resetState();

        for (let locale of this.props.locales) {
            this.setState({
                [locale.node.code]: {},
            });
        }

        this.setState({
            openForm: !this.state.openForm,
            editForm: 'addGroup',
        });
    };

    editGroupAttribut = (nodeInfo) => {
        this.resetState();

        for (let locale of this.props.locales) {
            let values = {};

            for (let groupLocale of nodeInfo.locales) {
                if (groupLocale.code === locale.node.code) {
                    values['groupName'] = groupLocale.value;
                }
            }

            this.setState({
                [locale.node.code]: values
            });
        }

        this.setState({
            openForm: true,
            editForm: 'editGroup',
            currentGroup: nodeInfo,
            groupIdentifier: nodeInfo.node.identifier.indexOf('_') > -1
                ? nodeInfo.node.identifier.split('_')[1]
                : nodeInfo.node.identifier
        });
    };

    doneTyping = (stateName) => {
        if (stateName === 'attributeName') {
            this.setState({
                attributeIdentifier: slugify(this.state[this.state.currentLang].attributeName, { replacement: '_', lower: true, remove: /[^\w\-\s]+/g })
            })
        }
        else if (stateName === 'groupName') {
            this.setState({
                groupIdentifier: slugify(this.state[this.state.currentLang].groupName, { replacement: '_', lower: true, remove: /[^\w\-\s]+/g })
            })
        }

        if (this.state.attributeIdentifier) {
            this.typeTesting = 'attribute';
            request(`${process.env.REACT_APP_API}/unique/${this.typeTesting}/${this.state.attributeIdentifier}`, 'get').then(
                (data) => {
                    if (data.success) {
                        eventService.fire({ stateName: 'attributeIdentifier', errorMessage: this.props.t("spread.active_assets.userAlreadyUsed") });
                    }
                }
            );
        }
        if (this.state.groupIdentifier) {
            this.typeTesting = 'attribute_group';
            request(`${process.env.REACT_APP_API}/unique/${this.typeTesting}/${this.state.groupIdentifier}`, 'get').then(
                (data) => {
                    if (data.success) {
                        eventService.fire({ stateName: 'groupIdentifier', errorMessage: this.props.t("spread.active_assets.userAlreadyUsed") });
                    }
                }
            );
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        if (stateName === 'attributeName' || stateName === 'attributeIdentifier' || stateName === 'groupName' || stateName === 'groupIdentifier') {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
        }
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values
            });
        } else {
            this.setState({
                [stateName]: value
            });
        }
        if ((this.state.editForm === "addAttribut" || this.state.editForm === "addGroup") && (stateName === 'attributeName' || stateName === 'attributeIdentifier' || stateName === 'groupName' || stateName === 'groupIdentifier'))
            this.checkIdentifier(stateName);
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    expand = (expanded) => {
        this.setState({
            treeData: toggleExpandedForAll({
                treeData: this.state.treeData,
                expanded,
            }),
        });
    };

    componentDidMount() {
        checkRouting(this.props);
        this.prepareTree();
    };

    render() {
        const { treeData, unassignedAttributes } = this.state;
        return (
            <div>
                <TopPanel
                    icomoon="picto-template"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.t("content.models.manageModels")}
                    subtitle={this.props.t("content.models.manageModelsSubtitle")}
                    handlerAdd={this.addGroupAttribut}
                    textAdd={this.props.t("content.models.addTemplate")}
                    handlerImport={this.addAttribut}
                    textImport={this.props.t("content.models.addModelVariable")}
                    searchHandler={false}
                    // stats={this.stats} 
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    openForm={this.state.openForm}
                    buttonAvailable={treeData ? true : false}
                    windowWidth={this.props.windowWidth}
                    currentLang={this.state.currentLang}
                    handleLang={this.handleLang}
                    locales={this.props.locales}
                    hasBorder={true}
                />
                <Grid container direction="column" justifyContent="center" spacing={0} style={{ width: '100%' }}>
                    <Grid container direction="row" spacing={4}>
                        {treeData ?
                            <>
                                <Grid item xs={6}>
                                    <h3>{this.props.t("content.models.model")}</h3>
                                    <TreeView
                                        typeOfTree={'attribut'}
                                        dataTree={treeData}
                                        editCat={this.editGroupAttribut}
                                        editItem={this.editAttribut}
                                        addSubcategory={this.addAttributByGrp}
                                        expand={this.expand}
                                        onChange={treeData => this.setState({ treeData })}
                                        content={true}
                                        canModify={true}
                                        overflowed={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <h3>{this.props.t("content.models.unassignedTemplate")}</h3>
                                    <TreeView
                                        noExpand={true}
                                        typeOfTree={'attribut'}
                                        dataTree={unassignedAttributes}
                                        editCat={this.editGroupAttribut}
                                        editItem={this.editAttribut}
                                        addSubcategory={this.addAttributByGrp}
                                        onChange={unassignedAttributes => this.setState({ unassignedAttributes })}
                                        none={this.props.t("content.models.noModelVariable")}
                                        content={true}
                                        canModify={true}
                                        overflowed={true}
                                    />
                                </Grid>
                            </>
                            : <PageLoader />
                        }
                    </Grid>
                </Grid>

                {this.state.openForm ?
                    <LayoutBuilder
                        isSublayout={false}
                        opened={this.state.openForm}
                        forClose={this.handleToggleDrawer}
                        handlerMutation={this.handlerMutation}
                        dataLayout={this.state.editForm === 'editAttribut'
                            ? formContentAttributEdit(
                                this.copyArrayOfObjects(this.state.treeData),
                                this.state.attributeType,
                                this.state.currentLang,
                                this.state.allInputTypes,
                                this.props.locales,
                                this.state.selectedGroups,
                                this.state.isSystem
                            )
                            : this.state.editForm === 'addAttribut'
                                ? formContentAttributAdd(
                                    this.copyArrayOfObjects(this.state.treeData),
                                    this.state.attributeType,
                                    this.state.currentLang,
                                    this.state.allInputTypes,
                                    this.props.locales,
                                    this.state.selectedGroups
                                )
                                : this.state.editForm === 'addGroup'
                                    ? formContentGroupAttributAdd()
                                    : formContentGroupAttributEdit()
                        }
                        allState={this.state}
                        icomoon={(this.state.editForm === 'editGroup' || this.state.editForm === 'editAttribut') ? 'ico-modifier-attribut' : 'ico-ajouter-attribut'}
                        stateCallback={this.handleInputChange}
                        errorCallback={this.handleFormError}
                        checkError={() => { }}
                        deleteMutation={
                            (this.state.editForm === 'editGroup' || this.state.editForm === 'editAttribut')
                                ? this.handleToggleDialog
                                : null
                        }
                        deleteText={this.state.editForm === 'editGroup' ? this.props.t("content.models.modelDelete") : this.state.editForm === 'editAttribut' ? this.props.t("content.models.removeVariable") : null}
                        deleteButton={this.state.editForm}
                        validateButton={true}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        handleButtonGroupChange={this.handleButtonGroupChange}
                    />
                    : null
                }

                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleToggleDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {
                            this.state.editForm === 'editGroup'
                                ? this.props.t("content.models.sureDeleteModel")
                                : this.props.t("content.models.sureDeleteVariable")
                        }
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {
                                this.state.editForm === 'editGroup'
                                    ? this.props.t("content.models.verySureDeleteModel")
                                    : this.props.t("content.models.verySureDeleteVariable")
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                            {this.props.t("spread.active_assets.cancel")}
                        </Button>
                        <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                            {this.props.t("spread.active_assets.delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    prepareTree = () => {
        return new Promise(async (resolve, reject) => {
            const GET_ATTR_GROUPE_RESULT = await this.props.client.query({
                query: GET_ATTR_GROUPE,
                fetchPolicy: "no-cache",
            });


            const GET_ATTRIBUTES_BY_TYPE_RESULT = await this.props.client.query({
                query: GET_ATTRIBUTES_BY_TYPE,
                fetchPolicy: "no-cache",
                variables: { id: this.props.attributes.eavTypes.find(e => e.node.code === 'content').node.id }
            });

            let groups = [];

            for (let attrGroup of GET_ATTR_GROUPE_RESULT.data.attributeGroups.edges) {
                if ((!attrGroup.node.isSystem && attrGroup.node.isForContent) || attrGroup.node.identifier === 'content') {
                    attrGroup.children = this.copyArrayOfObjects(GET_ATTRIBUTES_BY_TYPE_RESULT.data.eavType.attributes.edges.filter(e => {
                        return e.node.attributeGroup.edges.find(e => e.node.id === attrGroup.node.id); // !e.node.isSystem && 
                    }));

                    this.convertToNode(attrGroup, true);

                    for (let attr of attrGroup.children)
                        this.convertToNode(attr, false, attrGroup, true);

                    groups.push(attrGroup);
                }
            }

            let unassignedAttributes = GET_ATTRIBUTES_BY_TYPE_RESULT.data.eavType.attributes.edges
                .filter(e => !e.node.isSystem && (e.node.attributeGroup?.edges.length === 0 ?? false));

            for (let unassignedAttribute of unassignedAttributes)
                this.convertToNode(unassignedAttribute, true, null, true);

            this.setState({
                treeData: this.copyArrayOfObjects(groups),
                unassignedAttributes: this.copyArrayOfObjects(unassignedAttributes)
            });
            const GET_ATTRIBUTE_TYPES_RESULT = await this.props.client.query({
                query: GET_ATTRIBUTE_TYPES,
                fetchPolicy: "no-cache"
            });

            this.setState({ allInputTypes: GET_ATTRIBUTE_TYPES_RESULT.data.attributeTypes.edges });

            resolve();
        });
    };

    getIdentifier = identifier => identifier.indexOf('_') > -1
        ? identifier.split('_')[1]
        : identifier;

    convertToNode(data, root, parent, isAttribute) {
        let getTraduction = data.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === this.state.currentLang
        );

        if (root && data.node.identifier === 'default')
            data.noAction = true;

        data.locales = [];

        for (let { node } of data.node.translation.translationDatas.edges) {
            data.locales.push({
                value: node.value,
                id: node.locale.id,
                code: node.locale.code,
                translationDataId: node.id
            });
        }

        if (!root || isAttribute) {
            data.options = [];

            for (let { node } of data.node.attributeOptions.edges) {
                let option = node;

                option.locales = [];

                for (let translationData of option.translation.translationDatas.edges) {
                    option.locales.push({
                        value: translationData.node.value,
                        id: translationData.node.locale.id,
                        code: translationData.node.locale.code,
                        translationDataId: translationData.node.id
                    });
                }

                data.options.push(option);
            }
        }

        if (!root) {
            data.title = getTraduction?.node?.value ?? data?.node?.translation.translationDatas.edges[0]?.node.value ?? this.getIdentifier(data.node.identifier);
            data.parent = parent.node.id;
            data.isDirectory = false;
            data.expanded = true;
            data.isItem = true;
            data.canDrag = false;
        } else {
            data.title = getTraduction?.node?.value ?? data?.node?.translation.translationDatas.edges[0]?.node.value ?? this.getIdentifier(data.node.identifier);
            data.isDirectory = true;
            data.expanded = true;
            data.isItem = isAttribute;
            data.canDrag = false;
        }
    }

    copyArrayOfObjects = array => array.map(a => ({ ...a })); // be careful, only breaks references at objects level

    goTo = route => this.props.history.push(route);
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
        setAttributes: (attributes) => dispatch({ type: SET_ATTRIBUTES, payload: { attributes } })
    }
}

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ContentsAttributes))));
